import styled from 'styled-components/macro';

export default styled.div`
  min-height: 4rem;
  padding: 0 1.2rem;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  min-width: 8rem;

  &:hover {
    opacity: 0.8;
  }

  .text {
    font-size: 1.4rem;
    font-weight: 900;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #7367f0;
    margin-left: 1.1rem;
  }
`;
