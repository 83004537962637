import {
  SET_PEOPLE_DETAILS,
  SET_TRANSACTION_DETAILS,
  TOGGLE_NOTIFICATION,
  TOGGLE_PEOPLE_DETAILS,
  TOGGLE_SAFE_DETAILS,
  TOGGLE_TRANSACTION_DETAILS,
} from './action-types';

export function toggleNotification(show) {
  return {
    type: TOGGLE_NOTIFICATION,
    show,
  };
}

export function togglePeopleDetails(show) {
  return {
    type: TOGGLE_PEOPLE_DETAILS,
    show,
  };
}

export function toggleTransactionDetails(show) {
  return {
    type: TOGGLE_TRANSACTION_DETAILS,
    show,
  };
}

export function toggleSafeDetails(show) {
  return {
    type: TOGGLE_SAFE_DETAILS,
    show,
  };
}

export function setPeopleDetails(peopleDetails) {
  return {
    type: SET_PEOPLE_DETAILS,
    peopleDetails,
  };
}

export function setTransactionDetailsSidebar(txDetails) {
  return {
    type: SET_TRANSACTION_DETAILS,
    txDetails,
  };
}
