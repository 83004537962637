import { Modal, ModalBody, ModalHeader } from 'components/common/Modal';
import FilePayout from 'components/FilePayout';
import React from 'react';
import { connectModal as reduxModal } from 'redux-modal';

export const MODAL_NAME = 'file-payout-modal';

function FilePayoutModal(props) {
  const { show, handleHide } = props;

  return (
    <Modal isOpen={show} toggle={handleHide}>
      <ModalHeader title={'Payout via File Upload'} toggle={handleHide} />
      <ModalBody width="103rem" minHeight="auto">
        <FilePayout handleHide={handleHide} />
      </ModalBody>
    </Modal>
  );
}

export default reduxModal({ name: MODAL_NAME })(FilePayoutModal);
