/**
 * Login saga
 */

import { call, put, fork, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import jwt_decode from 'jwt-decode';

import { LOGIN_USER, OPEN_SAFE, SWITCH_SAFE } from './action-types';
import { loginUserSuccess, loginUserError, setImportSafeFlag } from './actions';
import {
  setOrganisationType,
  setOwnersAndThreshold,
  getSafeInfoError,
  getSafeInfoSuccess,
} from '../global/actions';
import request from 'utils/request';
import { getSafeInfoEndpoint, loginEndpoint } from 'constants/endpoints';
import { networkId } from 'constants/networks';
import { logoutUser } from 'store/logout/actions';

export function* openSafe(action) {
  if (action.isRegister) {
    yield put(setOwnersAndThreshold(action.owners, action.threshold));
    yield put(setOrganisationType(action.organisationType));
    yield put(loginUserSuccess(action.safeAddress, action.log));
    yield put(
      getSafeInfoSuccess({
        name: action.organisationName,
        owners: action.owners,
        threshold: action.threshold,
        isOwner: true,
        organisationType: action.organisationType,
      })
    );
    yield put(push('/dashboard'));
  } else {
    const requestURL = `${getSafeInfoEndpoint}?safeAddress=${action.safeAddress}&ownerAddress=${action.account}`;

    const options = {
      method: 'GET',
    };

    try {
      const result = yield call(request, requestURL, options);
      if (!result.isOwner) {
        window.location = '/';
        yield put(logoutUser());
      } else {
        yield put(setOwnersAndThreshold(action.owners, action.threshold));
        yield put(setOrganisationType(action.organisationType));
        yield put(loginUserSuccess(action.safeAddress, action.log));
        yield put(push('/dashboard'));
      }
    } catch (err) {
      yield put(getSafeInfoError(err));
      yield put(loginUserError(err));
    }
  }
}

export function* switchSafe(action) {
  const requestURL = `${getSafeInfoEndpoint}?safeAddress=${action.safeAddress}&ownerAddress=${action.account}`;
  const options = {
    method: 'GET',
  };

  try {
    yield call(request, requestURL, options);
    yield put(setOwnersAndThreshold(action.owners, action.threshold));
    yield put(setOrganisationType(action.organisationType));
    yield put(loginUserSuccess(action.safeAddress, action.log));
    yield put(push('/dashboard'));
  } catch (err) {
    yield put(getSafeInfoError(err));
    yield put(loginUserError(err));
  }
}

export function* loginUser(action) {
  // Select username from store
  // const username = yield select(makeSelectUsername());
  const requestURL = `${loginEndpoint}?safeAddress=${action.safeAddress}&networkId=${networkId}`;
  const options = {
    method: 'GET',
  };

  try {
    const result = yield call(request, requestURL, options);
    if (result.flag === 145) {
      yield put(setImportSafeFlag(result.flag));
    } else if (result.flag !== 200) {
      // Error in payload
      yield put(loginUserError(result.log));
    } else {
      localStorage.setItem('token', result.access_token);
      let decoded;
      try {
        decoded = jwt_decode(result.access_token);
      } catch (err) {
        yield put(loginUserError(`Invalid JWT token.`));
        return;
      }

      yield put(setOwnersAndThreshold(decoded.owners, decoded.threshold));
      yield put(setOrganisationType(decoded.organisationType));
      yield put(loginUserSuccess(result.transactionHash, result.log));
      yield put(push('/dashboard'));
    }
  } catch (err) {
    yield put(loginUserError(err));
  }
}

export function* watchLogin() {
  yield takeLatest(LOGIN_USER, loginUser);
}

export function* watchOpen() {
  yield takeLatest(OPEN_SAFE, openSafe);
}

export function* watchSwitch() {
  yield takeLatest(SWITCH_SAFE, switchSafe);
}

export default function* logins() {
  yield fork(watchLogin);
  yield fork(watchOpen);
  yield fork(watchSwitch);
}
