import { useState } from 'react';
import { Modal, ModalFooter, ModalBody } from 'reactstrap';
import Button from 'components/common/Button';

import SomethingWentWrongPng from 'assets/images/Something_went_wrong_2.png';

import { WrongWallet } from './styles';
import { ModalHeader } from 'components/common/Modal';

const WalletNotSupportedModal = ({ isOpen, closeModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      centered
      contentClassName="custom-modal-content"
    >
      <ModalHeader
        style={{ justifyContent: 'flex-end', borderBottom: '0 none' }}
        toggle={closeModal}
      ></ModalHeader>
      <ModalBody toggle={closeModal} style={{ padding: '0px' }}>
        <WrongWallet>
          <div className="text-center">
            <div className="pb-3">
              <img
                src={SomethingWentWrongPng}
                alt="error"
                width="380"
                height="320"
                className="mb-4"
              />
            </div>
            <h4 className="title pb-2">
              Argent wallet is not supported at the moment.
            </h4>
            <div className="subtitle">
              Please select a different wallet to continue.
            </div>
          </div>
        </WrongWallet>
      </ModalBody>
      {/* <ModalFooter style={{ borderTop: '0 none' }}>
        <Button
          type="button"
          width="12rem"
          loading={false}
          style={{
            background: '#7367F0',
            color: '#FFFFFF',
            marginLeft: '10px',
            fontWeight: 600,
          }}
          onClick={() => closeModal()}
        >
          Continue
        </Button>
      </ModalFooter> */}
    </Modal>
  );
};

export default WalletNotSupportedModal;
