import styled from 'styled-components/macro';

export default styled.div`
  justify-content: space-between;
  width: 100%;
  font-size: 1.4rem;
  color: #373737;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 1.6rem 0 0;
  z-index: -1;

  span: {
    margin-right: 1.6rem;
  }

  .right {
    font-weight: normal;
    color: #8d8e91;

    .team {
      margin-right: 2.4rem;
    }
  }
`;
