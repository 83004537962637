import React from 'react';
import animationData from 'assets/videos/LoaderGrey.json';
import Lottie from 'react-lottie';
import successSVG from 'assets/images/success_big.svg';
import errorSVG from 'assets/images/error_big.svg';

import ParcelLoaderContainer from './styles';
import Img from '../Img';

const ParcelLoader = ({
  size = 200,
  success = false,
  error = false,
  children,
}) => {
  let title = 'Waiting for wallet confirmation...';
  let subtitle = 'Please confirm this transaction from your wallet.';

  if (error) {
    title = 'Transaction could not be created';
    subtitle = 'Your wallet did not approve the transaction.';
  }
  if (success) {
    title = 'Transaction Created';
    subtitle = '';
  }

  const topRender = () => {
    if (success) {
      return (
        <div style={{ width: size, height: size }}>
          <Img src={successSVG} alt="success" />
        </div>
      );
    } else if (error) {
      return (
        <div style={{ width: size, height: size }}>
          <Img src={errorSVG} alt="error" />
        </div>
      );
    } else {
      return (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          isClickToPauseDisabled={true}
          height={size}
          width={size}
        />
      );
    }
  };

  return (
    <ParcelLoaderContainer>
      <div className="loader">{topRender()}</div>
      {title && <div className="loader-title">{title}</div>}
      {subtitle && <div className="loader-subtitle">{subtitle}</div>}
      {children && <div className="children-container">{children}</div>}
    </ParcelLoaderContainer>
  );
};

export default ParcelLoader;
