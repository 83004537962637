import { frequencies } from 'components/Automations';
import Avatar from 'components/common/Avatar';
import Button from 'components/common/Button';
import Loading from 'components/common/Loading';
import { Table, TableBody, TableHead } from 'components/common/Table';
import TokenImg from 'components/common/TokenImg';
import { minifyAddress } from 'components/common/Web3Utils';
import { TRANSACTION_MODES } from 'constants/transactions';
import { useActiveWeb3React, useMassPayout } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPayeesforDeleteion } from 'store/automations/actions';
import { makeSelectToBeRemovedAutomations } from 'store/automations/selectors';
import {
  makeSelectIsMultiOwner,
  makeSelectOwnerSafeAddress,
  makeSelectIsMetaTxEnabled,
} from 'store/global/selectors';
import { createMultisigTransaction } from 'store/multisig/actions';
import multisigReducer from 'store/multisig/reducer';
import { getNonce } from 'store/safe/actions';
import safeReducer from 'store/safe/reducer';
import { makeSelectNonce } from 'store/safe/selectors';
import { getTokens } from 'store/tokens/actions';
import {
  addTransaction,
  clearTransactionHash,
} from 'store/transactions/actions';
import transactionsReducer from 'store/transactions/reducer';
import metaTxReducer from 'store/metatx/reducer';
import metaTxSaga from 'store/metatx/saga';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { formatNumber } from 'utils/number-helpers';
import { RemoveAutomationContainer } from './styles';
import getTransactionReceipts from 'utils/getTransactionReceipts';
import { getFinalMetaTransactionHash } from 'utils/meta-tx-helpers';
import { getMetaTxEnabled } from 'store/metatx/actions';
import { makeSelectIsMetaTxEnabled as makeSelectIsMetaTxLimitAllowed } from 'store/metatx/selectors';
import { makeSelectMetaTransactionHash } from 'store/transactions/selectors';
import { networkId } from 'constants/networks';

const multisigKey = 'multisig';
const transactionsKey = 'transactions';
const safeKey = 'safe';
const metaTxKey = 'metatx';

export default function RemoveAutomation({ handleHide, closeEditAutomations }) {
  const { library, account } = useActiveWeb3React();
  const [errorState, setErrorState] = useState(false);

  const payeesToBeRemovedList = useSelector(makeSelectToBeRemovedAutomations());
  const nonce = useSelector(makeSelectNonce());
  const isMultiOwner = useSelector(makeSelectIsMultiOwner());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const isMetaEnabled = useSelector(makeSelectIsMetaTxEnabled());
  const txHashFromMetaTx = useSelector(makeSelectMetaTransactionHash());
  const isMetaTxLimitAllowed = useSelector(makeSelectIsMetaTxLimitAllowed());

  const dispatch = useDispatch();

  const {
    txHash,
    loadingTx,
    txData,
    setTxData,
    deleteAutomatedPayouts,
    loadingMetaTx,
    setLoadingMetaTx,
  } = useMassPayout();

  useInjectReducer({ key: transactionsKey, reducer: transactionsReducer });
  useInjectReducer({ key: multisigKey, reducer: multisigReducer });
  useInjectReducer({ key: safeKey, reducer: safeReducer });
  useInjectReducer({ key: metaTxKey, reducer: metaTxReducer });

  useInjectSaga({ key: metaTxKey, saga: metaTxSaga });

  useEffect(() => {
    if (ownerSafeAddress) {
      dispatch(getNonce(ownerSafeAddress));
      dispatch(getTokens(ownerSafeAddress));
      dispatch(getMetaTxEnabled(ownerSafeAddress));
    }
  }, [ownerSafeAddress, dispatch]);

  useEffect(() => {
    if (
      txHashFromMetaTx &&
      isMetaEnabled &&
      !isMultiOwner &&
      isMetaTxLimitAllowed
    ) {
      setLoadingMetaTx(true);
      dispatch(clearTransactionHash());
      const intervalId = setInterval(async () => {
        const newMetaHash = await getFinalMetaTransactionHash(
          txHashFromMetaTx,
          networkId
        );
        if (newMetaHash) {
          getTransactionReceipts(
            library,
            dispatch,
            ownerSafeAddress,
            '',
            newMetaHash,
            false
          );
          handleHide();
          setTimeout(() => {
            handleHide();
            closeEditAutomations();
            setLoadingMetaTx(false);
          }, 5000);
          clearInterval(intervalId);
        }
      }, 3000);
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    txHashFromMetaTx,
    isMetaEnabled,
    isMultiOwner,
    isMetaTxLimitAllowed,
  ]);

  useEffect(() => {
    const to = JSON.stringify(payeesToBeRemovedList);
    if (txHash) {
      dispatch(
        addTransaction({
          to,
          safeAddress: ownerSafeAddress,
          createdBy: account,
          transactionHash: txHash,
          tokenValue: -1,
          tokenCurrency: '_nil_',
          fiatValue: -1,
          fiatCurrency: 'USD',
          addresses: payeesToBeRemovedList.map(({ delgate }) => delgate),
          transactionMode: TRANSACTION_MODES.AUTOMATION,
          paymentType: 'removal',
          description: 'removal of select automations',
          nonce: nonce,
        })
      );
    } else if (txData) {
      if (!isMultiOwner) {
        // threshold = 1 or single owner
        dispatch(
          addTransaction({
            to,
            safeAddress: ownerSafeAddress,
            createdBy: account,
            txData,
            tokenValue: -1,
            tokenCurrency: '_nil_',
            fiatValue: -1,
            fiatCurrency: 'USD',
            addresses: payeesToBeRemovedList.map(({ delgate }) => delgate),
            transactionMode: TRANSACTION_MODES.AUTOMATION,
            paymentType: 'removal',
            description: 'removal of select automations',
            nonce: nonce,
            isMetaEnabled,
          })
        );
        setTxData(undefined);
      } else {
        // threshold > 1
        dispatch(
          createMultisigTransaction({
            to,
            safeAddress: ownerSafeAddress,
            createdBy: account,
            txData,
            tokenValue: -1,
            tokenCurrency: '_nil_',
            fiatValue: -1,
            fiatCurrency: 'USD',
            addresses: payeesToBeRemovedList.map(({ delgate }) => delgate),
            transactionMode: TRANSACTION_MODES.AUTOMATION,
            paymentType: 'removal',
            description: 'removal of select automations',
            nonce: nonce,
          })
        );
      }
    }
  }, [
    account,
    dispatch,
    isMultiOwner,
    nonce,
    ownerSafeAddress,
    payeesToBeRemovedList,
    setTxData,
    txData,
    txHash,
    isMetaEnabled,
  ]);

  const deleteAutomations = async () => {
    // get list of addresses from payeesToBeRemovedList and use that. secone value is token address
    // if we're allowing removal from multiple teams, we'll have  to change the api to accommodate

    setErrorState(false);

    const deletions =
      payeesToBeRemovedList &&
      payeesToBeRemovedList.map(payee => ({
        address: payee.delegate,
        tokenAddress: payee.tokenAddress,
      }));

    try {
      await deleteAutomatedPayouts(
        deletions,
        isMultiOwner,
        nonce,
        isMetaEnabled,
        isMetaTxLimitAllowed
      );

      if (isMetaEnabled && isMetaTxLimitAllowed) {
        setLoadingMetaTx(true);
      } else {
        setTimeout(() => {
          handleHide();
          closeEditAutomations();
        }, [400]);
      }
    } catch (error) {
      console.error(error);
      setErrorState(true);
    }
  };

  const renderTable = () => {
    return (
      <Table>
        <TableHead>
          <tr>
            <th style={{ width: '43%' }}>Name</th>
            <th style={{ width: '25%' }}>Disbursement</th>
            <th style={{ width: '27%' }}>Frequency</th>
          </tr>
        </TableHead>
        <TableBody>
          {payeesToBeRemovedList &&
            payeesToBeRemovedList.length > 0 &&
            payeesToBeRemovedList.map(
              (
                {
                  firstName,
                  lastName,
                  delegate,
                  allowanceAmount,
                  tokenName,
                  resetTimeMin,
                },
                idx
              ) => (
                <tr className={`payee-row`} key={`${firstName}-${idx}`}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div>
                        {firstName && lastName ? `${firstName} ${lastName} (${minifyAddress(
                          delegate
                        )})` : `${minifyAddress(
                          delegate
                        )}`}
                      </div>
                    </div>
                  </td>
                  <td>
                    <TokenImg token={tokenName} />
                    {formatNumber(allowanceAmount, 5)} {tokenName}
                  </td>
                  <td>{frequencies[resetTimeMin]}</td>
                </tr>
              )
            )}
        </TableBody>
      </Table>
    );
  };

  return (
    <RemoveAutomationContainer>
      {(loadingTx || loadingMetaTx) && (
        <div className="loader-container">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: '100%' }}
          >
            <Loading color="primary" width="100px" height="100px" />
          </div>
        </div>
      )}
      <div className={loadingTx || loadingMetaTx ? 'container-blur' : ''}>
        <div className="modal-title">
          The following automations will be stopped
        </div>
        {renderTable()}
        {errorState && (
          <div className="error-text">
            Something went wrong. please check the details and retry.
          </div>
        )}

        <div className="cta-wrapper">
          <Button
            type="button"
            width="20rem"
            style={{
              minWidth: '18rem',
              background: 'rgba(115, 103, 240, 0.1)',
              color: '#7367F0',
              fontWeight: 600,
            }}
            onClick={() => {
              dispatch(addPayeesforDeleteion([]));
              handleHide();
            }}
          >
            Go back
          </Button>
          <Button
            type="submit"
            width="20rem"
            style={{ minWidth: '18rem', backgroundColor: '#FF4B55' }}
            disabled={false}
            onClick={deleteAutomations}
          >
            Confirm
          </Button>
        </div>
      </div>
    </RemoveAutomationContainer>
  );
}
