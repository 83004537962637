import styled from 'styled-components/macro';

export default styled.tbody`
  position: relative;

  width: 100%;

  tr {
    font-size: 1.4rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #373737;
    background-color: #ffffff;
    padding: 1.5rem;
    border-bottom: 0.1rem solid rgba(235, 236, 239, 0.8);

    &:hover {
      background-color: rgba(115, 103, 240, 0.03) !important;
      cursor: pointer;
    }

    &:last-of-type {
      td:last-of-type {
        border-bottom-right-radius: 0.4rem;
      }
      td:first-of-type {
        border-bottom-left-radius: 0.4rem;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  td {
    height: 5rem;

    &:first-child {
      padding-left: 2rem;
    }
    &:last-child {
      padding-right: 2rem;
    }
  }

  @media (max-width: 600px) {
    td:first-child {
      padding-left: 1rem;
    }
    td:last-child {
      padding-right: 1rem;
    }
  }
`;
