/**
 * The login state selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectLoginWizard = state => state.loginWizard || initialState;

const makeSelectStep = () =>
  createSelector(selectLoginWizard, loginWizardState => loginWizardState.step);

const makeSelectFormData = () =>
  createSelector(selectLoginWizard, loginWizardState => loginWizardState.form);

const makeSelectFlow = () =>
  createSelector(selectLoginWizard, loginWizardState => loginWizardState.flow);

const makeSelectChosenSafeAddress = () =>
  createSelector(
    selectLoginWizard,
    loginWizardState => loginWizardState.chosenSafeAddress
  );

const makeSelectParcelLoading = () =>
  createSelector(
    selectLoginWizard,
    loginWizardState => loginWizardState.parcelLoading
  );

const makeSelectGnosisLoading = () =>
  createSelector(
    selectLoginWizard,
    loginWizardState => loginWizardState.gnosisLoading
  );

const makeSelectSafes = () =>
  createSelector(selectLoginWizard, loginWizardState => loginWizardState.safes);

const makeSelectGnosisSafes = () =>
  createSelector(
    selectLoginWizard,
    loginWizardState => loginWizardState.gnosisSafes
  );

const makeSelectCreatedBy = () =>
  createSelector(
    selectLoginWizard,
    loginWizardState => loginWizardState.createdBy
  );

const makeSelectGnosisSafeOwners = () =>
  createSelector(
    selectLoginWizard,
    loginWizardState => loginWizardState.gnosisSafeOwners
  );

const makeSelectGnosisSafeThreshold = () =>
  createSelector(
    selectLoginWizard,
    loginWizardState => loginWizardState.gnosisSafeThreshold
  );

const makeSelectFetchingSafeDetails = () =>
  createSelector(
    selectLoginWizard,
    loginWizardState => loginWizardState.fetchingSafeDetails
  );

const makeSelectError = () =>
  createSelector(selectLoginWizard, loginWizardState => loginWizardState.error);

export {
  selectLoginWizard,
  makeSelectStep,
  makeSelectFormData,
  makeSelectFlow,
  makeSelectChosenSafeAddress,
  makeSelectParcelLoading,
  makeSelectGnosisLoading,
  makeSelectSafes,
  makeSelectGnosisSafes,
  makeSelectCreatedBy,
  makeSelectGnosisSafeOwners,
  makeSelectGnosisSafeThreshold,
  makeSelectFetchingSafeDetails,
  makeSelectError,
};
