import styled from 'styled-components/macro';

export default styled.div`
  padding: 4rem 2.6rem;

  .desc {
    font-size: 1.3rem;
    color: #373737;
  }

  .name {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    color: #373737;
    margin-top: 4rem;
  }

  .qr-code {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
  }

  .address {
    display: flex;
    justify-content: center;
    align-items-center;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    color: #373737;
  }
`;
