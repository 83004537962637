import styled from 'styled-components/macro';

const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#dddcdc';
};

// export const Container = styled.div`
//   .upload {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;

//     .drag-text {
//       font-weight: 900;
//       line-height: 1.9rem;
//       margin: 2rem 0 0 0;
//       font-size: 1.6rem;
//       color: #7367f0;
//     }

//     button {
//       font-weight: 900;
//       font-size: 1.4rem;
//       padding: 0 3.4rem;
//     }

//     .divider {
//       font-size: 1rem;
//       line-height: 1.2rem;
//       text-align: center;
//       color: #dddcdc;
//       margin: 2rem 0;
//       position: relative;

//       &:before {
//         position: absolute;
//         background-color: #dddcdc;
//         content: '';
//         top: 50%;
//         right: 1.4rem;
//         height: 0.1rem;
//         width: 1.6rem;
//         transform: translate(-50%, -50%);
//       }
//       &:after {
//         position: absolute;
//         background-color: #dddcdc;
//         content: '';
//         top: 50%;
//         left: 3rem;
//         height: 0.1rem;
//         width: 1.6rem;
//         transform: translate(-50%, -50%);
//       }
//     }
//   }
// `;

// export const Container = styled.div.attrs(({ hasError }) => {
//   const props = {};
// })(
//   props => `
//    .upload {
//      display: flex;
//      flex-direction: column;
//      justify-content: center;
//      align-items: center;

//      .drag-text {
//        font-weight: 900;
//        line-height: 1.9rem;
//        margin: 2rem 0 0 0;
//        font-size: 1.6rem;
//        color: #7367f0;
//      }

//      button {
//        font-weight: 900;
//        font-size: 1.4rem;
//        padding: 0 3.4rem;
//      }

//      .divider {
//        font-size: 1rem;
//        line-height: 1.2rem;
//        text-align: center;
//        color: #dddcdc;
//        margin: 2rem 0;
//        position: relative;

//        &:before {
//          position: absolute;
//          background-color: #dddcdc;
//          content: '';
//          top: 50%;
//          right: 1.4rem;
//          height: 0.1rem;
//          width: 1.6rem;
//          transform: translate(-50%, -50%);
//        }
//        &:after {
//          position: absolute;
//          background-color: #dddcdc;
//          content: '';
//          top: 50%;
//          left: 3rem;
//          height: 0.1rem;
//          width: 1.6rem;
//          transform: translate(-50%, -50%);
//        }
//      }
//    }
// `
// );
export const Container = styled.div.attrs(({ hasError }) => ({
  color: hasError ? '#FF4B55' : '#7367F0',
}))`
  .upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .drag-text {
      font-weight: 900;
      line-height: 1.9rem;
      margin: 2rem 0 0 0;
      font-size: 1.6rem;
      color: ${props => props.color};
    }

    button {
      font-weight: 900;
      font-size: 1.4rem;
      padding: 0 3.4rem;
    }

    .divider {
      font-size: 1rem;
      line-height: 1.2rem;
      text-align: center;
      color: #dddcdc;
      margin: 2rem 0;
      position: relative;

      &:before {
        position: absolute;
        background-color: #dddcdc;
        content: '';
        top: 50%;
        right: 1.4rem;
        height: 0.1rem;
        width: 1.6rem;
        transform: translate(-50%, -50%);
      }
      &:after {
        position: absolute;
        background-color: #dddcdc;
        content: '';
        top: 50%;
        left: 3rem;
        height: 0.1rem;
        width: 1.6rem;
        transform: translate(-50%, -50%);
      }
    }
  }

  .upload-error-container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #ff4b55;
    padding-bottom: 1.8rem;

    img {
      width: 5.6rem;
      height: 5.6rem;
    }

    .title {
      font-size: 1.6rem;
      font-weight: 700;
      margin: 2.4rem 0 0.8rem 0;
    }

    .error-info {
      font-size: 1.2rem;
      text-align: center;
      max-width: 70%;
    }
  }
`;

export const Wrapper = styled.section.attrs(({ hasError }) => ({
  bgColor: hasError ? 'rgba(255, 75, 85, 0.02);' : 'rgba(115, 103, 240, 0.02)',
  // color: hasError ? '#7367F0' : '#FF4B55',
}))`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
  width: 44rem;
  min-height: '32rem';
  border-radius: 0.6rem;
  border: 0.1rem dashed #dddcdc;
  border-color: ${props => getColor(props)};
  background-color: ${props => props.bgColor};
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .footer-button {
    border-top: 0.1rem dashed #dddcdc;
    border-color: ${props => getColor(props)};
    width: 100%;
    height: 4.3rem;
    justify-content: center;
    margin-top: 4.2rem;
    font-weight: 900;
    font-size: 1.2rem;
    color: #7367f0;
  }
`;
