import PeopleDetailsSidebar from 'components/People/PeopleDetailsSidebar';
import SafeDetailsSidebar from 'components/People/SafeDetailsSidebar';
import TransactionDetailSidebar from 'components/Transactions/TransactionDetailSidebar';
import multisigReducer from 'store/multisig/reducer';
import multisigSaga from 'store/multisig/saga';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeSelectIsMultiOwner,
  makeSelectOwnerSafeAddress,
} from 'store/global/selectors';
import layoutReducer from 'store/layout/reducer';
import { makeSelectMultisigTransactionsDashboard } from 'store/multisig/selectors';
import { getTokenList, getTokens } from 'store/tokens/actions';
import tokensReducer from 'store/tokens/reducer';
import tokensSaga from 'store/tokens/saga';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import Sidebar from './Sidebar';
import { LayoutContainer, Main } from './styles';
import TopBar from './TopBar';
import { getMultisigTransactionsDashboard } from 'store/multisig/actions';

const layoutKey = 'layout';
const tokensKey = 'tokens';
const multisigKey = 'multisig';

export default function DashboardLayout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [transactionCount, setTransactionCount] = useState(0);

  useInjectReducer({ key: layoutKey, reducer: layoutReducer });
  useInjectReducer({ key: tokensKey, reducer: tokensReducer });
  useInjectReducer({ key: multisigKey, reducer: multisigReducer });

  useInjectSaga({ key: tokensKey, saga: tokensSaga });
  useInjectSaga({ key: multisigKey, saga: multisigSaga });

  const dispatch = useDispatch();

  const safeAddress = useSelector(makeSelectOwnerSafeAddress());
  const isMultiOwner = useSelector(makeSelectIsMultiOwner());
  const multiSigTransactions = useSelector(
    makeSelectMultisigTransactionsDashboard()
  );

  useEffect(() => {
    if (safeAddress && isMultiOwner) {
      dispatch(getMultisigTransactionsDashboard(safeAddress));
    }
  }, [dispatch, safeAddress, isMultiOwner]);

  useEffect(() => {
    if (multiSigTransactions) {
      let pendingCount = multiSigTransactions.length || 0;
      setTransactionCount(pendingCount);
    }
  }, [multiSigTransactions]);

  useEffect(() => {
    if (safeAddress) {
      dispatch(getTokenList(safeAddress));
      dispatch(getTokens(safeAddress));
    }
  }, [dispatch, safeAddress]);

  const openSidebar = () => {
    setIsSidebarOpen(state => !state);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <React.Fragment>
      <TopBar
        isSidebarOpen={isSidebarOpen}
        closeSidebar={closeSidebar}
        openSidebar={openSidebar}
      />
      <LayoutContainer>
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          closeSidebar={closeSidebar}
          transactionCount={transactionCount}
        />
        {/* <Navbar isSidebarOpen={isSidebarOpen} openSidebar={openSidebar} /> */}
        <Main>{children}</Main>
      </LayoutContainer>
      {/* <NotificationSidebar /> */}
      <PeopleDetailsSidebar />
      <SafeDetailsSidebar />
      <TransactionDetailSidebar />
    </React.Fragment>
  );
}
