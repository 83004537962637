import React from 'react';
import Button from 'components/common/Button';

function MetaTxConfirmationModal({ setOpenMetaConfirmation, executeMetaTx }) {
  return (
    <div
      style={{
        width: '500px',
        borderRadius: '0.4rem',
        boxShadow: '1rem 1rem 2rem 0 rgb(178 178 178 / 10%)',
        border: 'solid 0.1rem #dddcdc',
      }}
    >
      <div className="customDropDownEdit" style={{ width: '100%' }}>
        {/* <Img src={ParcelSvg} alt="parcel" width="160" /> */}
        <div className="title">This transaction is sponsored by Parcel</div>
        <div className="title-muted">
          Thank you for partnering with us to scale ops. <br />
        </div>

        <div className="action-buttons" style={{ justifyContent: 'flex-end' }}>
          <Button
            type="button"
            width="12rem"
            style={{
              background: 'rgba(115, 103, 240, 0.1)',
              color: '#7367F0',
              fontWeight: 600,
            }}
            onClick={e => {
              e.stopPropagation();
              setOpenMetaConfirmation(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            width="12rem"
            onClick={e => {
              e.stopPropagation();
              executeMetaTx();
              setOpenMetaConfirmation(false);
            }}
            loading={false}
            style={{
              background: '#7367F0',
              color: '#FFFFFF',
              marginLeft: '10px',
              fontWeight: 600,
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}

export default MetaTxConfirmationModal;
