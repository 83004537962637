import React, { useState, useEffect } from 'react';
import { FilterCard, Items } from './styles';
// import Img from 'components/common/Img';
import CheckBox from 'components/common/CheckBox';
import HoverActions from './HoverActions';
import DeleteTeamModal from './DeleteTeamModel';
import { Skeleton } from '@material-ui/lab';
import { ReactComponent as NoTransaction } from '../../assets/icons/no-transaction.svg';

function TeamFilters({
  allTeams,
  allPeople,
  setAllPeople,
  sortedDecrypted,
  setTempAllPeople,
  loadingTeams,
  searchText,
  filterSearch,
  selectedTeams,
  setSelectedTeams,
}) {
  const [hovered, setHovered] = useState(null);
  const [allteams, setAllTeams] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  const [shouldActive, setShouldActive] = useState(true);
  const skeletonArray = Array(3).fill('');
  //Effects
  useEffect(() => {
    if (shouldActive === false) {
      setHovered(false);
    }
  }, [shouldActive]);
  useEffect(() => {
    setAllTeams(allTeams);
  }, [allTeams]);
  useEffect(() => {
    // check if selected teams array has value
    if (selectedTeams?.length > 0) {
      //check condition to make the all select checkbox checkable and uncheckable
      if (selectedTeams?.length === allTeams?.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
      //filtering ids for teams filter
      const ids = selectedTeams.map(i => i.departmentId);

      const updatedPeoples = sortedDecrypted.filter(people => {
        //check if search text is there filter the checked team's people along with search text
        if (searchText.length > 0) {
          if (
            ids.includes(people.departmentId) &&
            `${people.firstName} ${people.lastName}`
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            return people;
          }
        } //do filter without search text
        else if (ids.includes(people.departmentId)) {
          return people;
        }
      });
      setAllPeople(updatedPeoples);
      //set temporary array with all the values of selected team's people
      setTempAllPeople(
        sortedDecrypted.filter(people => {
          if (ids.includes(people.departmentId)) {
            return people;
          }
        })
      );
    } //handle unChecks with search text condition
    else if (searchText.length > 0) {
      const updatedPeoples = filterSearch(sortedDecrypted, searchText);
      setAllPeople(updatedPeoples);
    } else {
      setAllPeople(sortedDecrypted);
      setTempAllPeople(sortedDecrypted);
    }
  }, [selectedTeams]);
  //handlers
  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedTeams(allTeams);
      setSelectAll(true);
    } else {
      setSelectedTeams([]);
      setSelectAll(false);
    }
  };
  const handleSelectOne = team => {
    if (!selectedTeams.some(el => el.departmentId === team.departmentId)) {
      setSelectedTeams([...selectedTeams, team]);
    } else {
      const updatedSelect = selectedTeams.filter(
        el => el.departmentId !== team.departmentId
      );
      setSelectedTeams(updatedSelect);
    }
  };

  return (
    <FilterCard>
      <div className="header">
        {!loadingTeams ? (
          <>
            <CheckBox
              type="checkbox"
              id={`checkbox`}
              name={`checkbox`}
              checkType="flat"
              checked={selectAll}
              disabled={allteams?.length === 0}
              onChange={handleSelectAll}
            />
            <span className="text">TEAMS</span>
          </>
        ) : (
          <>
            <Skeleton variant="rect" height={'1.4rem'} width={'16px'} />
            <Skeleton
              variant="rect"
              height="1.4rem"
              width="10rem"
              className="ml-4"
            />
          </>
        )}
      </div>
      {loadingTeams &&
        skeletonArray.map((i, index) => {
          return (
            <div className="section" key={index}>
              <Items>
                <div className="flex align-items-center">
                  <Skeleton variant="rect" height={'1.4rem'} width={'16px'} />
                  <Skeleton
                    variant="rect"
                    height="1.4rem"
                    width="10rem"
                    className="ml-4"
                  />
                </div>
              </Items>
            </div>
          );
        })}
      {!loadingTeams && allteams?.length === 0 && (
        <div
          className="section"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1.4rem',
          }}
        >
          <div
            style={{
              padding: '6.5rem 3rem',
              color: '#8D8E91',
              textAlign: 'center',
              fontWeight: '500',
            }}
          >
            <NoTransaction style={{ opacity: '0.6' }} />
            <p style={{ marginTop: '20px' }}>No Teams Found!</p>
          </div>
        </div>
      )}

      <div className="section">
        {!loadingTeams &&
          allteams?.map(team => {
            return (
              <Items
                key={team.departmentId}
                active={hovered && hovered === team.departmentId}
                onMouseEnter={() => setHovered(team.departmentId)}
                onMouseLeave={() => {
                  if (shouldActive) {
                    return;
                  } else {
                    setHovered(null);
                  }
                }}
              >
                <div className="flex align-items-center">
                  <CheckBox
                    type="checkbox"
                    id={`checkbox`}
                    name={`checkbox`}
                    checked={selectedTeams.some(
                      item => item.departmentId === team.departmentId
                    )}
                    onChange={() => handleSelectOne(team)}
                  />
                  <span className="text">
                    {team.name} ({team.employees})
                  </span>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  {hovered && hovered === team.departmentId && (
                    <HoverActions shouldActive={setShouldActive} data={team} />
                  )}
                </div>
                <DeleteTeamModal data={team} allPeople={allPeople} />
              </Items>
            );
          })}
      </div>
    </FilterCard>
  );
}

export default TeamFilters;
