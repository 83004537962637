import styled from 'styled-components/macro';

export default styled.main`
  background-color: #f7f7f7;
  overflow-y: auto;
  padding: 4rem 5rem 1.75rem 5rem;
  height: 100%;

  @media (max-width: 978px) {
    padding: 4rem 1.75rem;
  }
`;
