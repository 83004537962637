import React, { useState, useEffect, memo } from 'react';
import { Link } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/common/Loading';
import TokenImg from 'components/common/TokenImg';
import { routeTemplates } from 'constants/routes/templates';
import {
  makeSelectLoading as makeSelectLoadingTokens,
  makeSelectTokenList,
} from 'store/tokens/selectors';
import { defaultTokenDetails, tokens } from 'constants/index';
import ETHIcon from 'assets/icons/tokens/ETH-icon.png';

import { Assets } from './styles';
import { formatNumber } from 'utils/number-helpers';
import addresses from 'constants/addresses';
import { makeSelectOwnerSafeAddress } from 'store/global/selectors';
import { getTokens } from 'store/tokens/actions';

const chartColors = [
  '#F1C40F',
  '#E74C3C',
  '#9B59B6',
  '#2E86C1',
  '#17A589',
  '#212F3C',
];

function AssetsCard() {
  const [tokenDetails, setTokenDetails] = useState(defaultTokenDetails);
  const [doughnutData, setDoughnutData] = useState();

  // Selectors
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const tokenList = useSelector(makeSelectTokenList());
  const loading = useSelector(makeSelectLoadingTokens());

  const dispatch = useDispatch();

  useEffect(() => {
    if (ownerSafeAddress) {
      dispatch(getTokens(ownerSafeAddress));
    }
  }, [ownerSafeAddress, dispatch]);

  useEffect(() => {
    if (tokenList && tokenList.length > 0) {
      const list = tokenList.slice(0, 4);
      if (list.length % 2) {
        const names = list.map(item => item.name);
        if (!names.includes(tokens.ETH)) {
          list.push({
            id: 0,
            name: tokens.ETH,
            icon: ETHIcon,
            balance: '0.00',
            usd: '0.00',
            address: addresses.ZERO_ADDRESS,
            decimals: 18,
            usdConversionRate: 1,
          });
        }
      }

      setTokenDetails(list);
      const chartData = tokenList.reduce(
        (data, { name, usd }) => {
          if (name && usd) {
            data.labels.push(`${name} (in $)`);
            data.datasets[0].data.push(usd.toFixed(2));
          }
          return data;
        },
        {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: chartColors,
            },
          ],
        }
      );
      setDoughnutData(chartData);
    }
  }, [tokenList]);

  const renderAssetCard = ({ icon, name, balance, usd }) => (
    <div className="asset-card" key={name}>
      <div className="token-details">
        <TokenImg icon={icon} token={name} className="token-icon" />
        <div>
          <div className="token-name">{name}</div>
          <div className="token-amount">{formatNumber(balance)}</div>
        </div>
      </div>
      <div className="usd">${formatNumber(usd)}</div>
    </div>
  );

  return (
    <Assets>
      <div className="title-container">
        <div className="title">Assets</div>
        <Link to={routeTemplates.dashboard.assets} className="view">
          View All
        </Link>
      </div>
      {!tokenDetails?.length && <div className="no-asset-info">No funds</div>}
      {loading && (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: '30rem' }}
        >
          <Loading color="primary" width="50px" height="50px" />
        </div>
      )}
      {!loading && (
        <React.Fragment>
          {doughnutData && doughnutData.datasets[0].data?.length ? (
            <div className="chart-container">
              <Doughnut
                data={doughnutData}
                options={{ legend: { display: false } }}
                width={200}
                height={200}
              />
            </div>
          ) : (
            <div style={{ height: '6rem' }} />
          )}
          {tokenDetails?.length > 0 && (
            <div className="assets-container">
              {tokenDetails.map(detail => renderAssetCard(detail))}
            </div>
          )}
        </React.Fragment>
      )}
    </Assets>
  );
}

export default memo(AssetsCard);
