import styled from 'styled-components/macro';
import Button from 'components/common/Button';

export const AutomationsWrapper = styled.div`
  .automations-team-title {
    td {
      font-size: 1.2rem;
      line-height: 1.4rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #373737;
      opacity: 0.6;
    }
  }
  .subtitle {
    span {
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #7367f0;
    }
  }
  .red-background-button {
    background-color: #ff4b55;
    color: #ffffff;
    &:hover {
      opacity: 0.8;
      background-color: #ff4b55;
    }
  }
`;

export const PaleBlueButton = styled(Button)`
  min-height: 4rem;
  padding: 0 2.4rem;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  /* min-width: 8rem; */
  background-color: #f1f0fd;
  font-size: 1.4rem;
  color: #373737;
  font-weight: 400;
  span {
    font-weight: 700;
    margin-left: 5px;
  }
  &:hover {
    opacity: 0.8;
    background-color: #f1f0fd;
  }
`;

export const GasPriceModalContainer = styled.div`
  padding: 4rem;
  input {
    margin: 2rem 0;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.4rem;
    padding: 1.4rem 0 1rem 1rem;
  }
  .title {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #373737;
    margin-bottom: 2rem;
  }
  .cta-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 4rem;
    button {
      margin: 0 1rem;
    }
  }
  p {
    font-size: 1.2rem;
    color: #373737;
  }
`;

export const GasLink = styled.p`
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  color: #7367f0;
  cursor: pointer;
`;

export const NotificationArea = styled.div`
  border: 1px solid #ebecef;
  border-radius: 6px;
  background: rgba(115, 103, 240, 0.03);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  overflow: hidden;
`;

export const NotifyArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  padding: 1.5rem 2rem;
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #7367f0;
  margin-right: 1rem;
`;

export const ActionButton = styled.div`
  color: #7367f0;
  font-weight: 900;
  font-size: 1.2rem;
  line-height: 1.4rem;
  border-left: 0.2rem solid #ebecef;
  padding: 1.5rem 2rem;
  cursor: pointer;
  display: flex;
  justify-content: between;
  align-items: center;

  &.initiate-payout-btn {
    background: #7367f0;
    color: #fff;
    border: none;

    .icons {
      cursor: pointer;
      margin-right: 0.8rem;
      fill: #ffffff;
    }
  }
`;

export const AutomationsGuideContainer = styled.div`
  padding: 4rem;
  .wrapper {
    margin-top: 5.2rem;
    img {
      width: 2rem;
      height: 2rem;
      margin-right: 1.8rem;
    }
  }
  .header {
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    color: #373737;
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    color: #373737;
  }
  .list-container {
    margin-top: 1.6rem;
    margin-left: 3.7rem;
    .item:not(:first-child) {
      margin-top: 0.8rem;
    }
  }
  .divider {
    background: #dcdcdc;
    opacity: 0.8;
    margin: 4.8rem 0 4rem 0;
    width: calc(100% + 8rem);
    height: 0.1rem;
    margin-left: -4rem;
  }
  .faq-wrapper {
    margin-top: 3.2rem;
    .question {
      font-weight: bold;
      font-size: 13px;
      color: #373737;
      margin-bottom: 0.8rem;
    }
  }
  .text {
    font-size: 13px;
    line-height: 150%;
    color: #373737;
  }
  .contact-us {
    margin-top: 3.2rem;
    font-weight: 800;
    font-size: 13px;
    span {
      color: #7367f0;
      cursor: pointer;
    }

    a {
      color: #7367f0;
    }
  }
  .header-wrapper {
    justify-content: space-between;
    .close-btn {
      cursor: pointer;
    }
  }
`;
