import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from '@material-ui/lab';
import {
  Table,
  TableBody,
  TableDivider,
  TableHead,
  TableInfo,
} from 'components/common/Table';
import TokenImg from 'components/common/TokenImg';
import { minifyAddress } from 'components/common/Web3Utils';
import { format } from 'date-fns';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  setTransactionDetailsSidebar,
  toggleTransactionDetails,
} from 'store/layout/actions';
import { makeSelectTokenList } from 'store/tokens/selectors';
import { TableContainer, TransactionStatus } from './styles';
import { ReactComponent as NoTransaction } from 'assets/icons/no-transaction.svg';
import defaultTokenIcon from 'assets/icons/tokens/Default-icon.jpg';

function isObjEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const AutomatedTransactionsTable = ({ transactions, loading, threshold }) => {
  const dispatch = useDispatch();

  const [automatedTransaction, setAutomatedTransaction] = useState({});

  const tokenList = useSelector(makeSelectTokenList());

  useEffect(() => {
    if (!isObjEmpty(automatedTransaction)) return;
    const sortedTransaction = {};
    Object.values(transactions).forEach(txDetails => {
      const lastPaidOn = format(new Date(txDetails.lastPaidOn), 'dd MMM yyyy');
      if (sortedTransaction[lastPaidOn]) {
        sortedTransaction[lastPaidOn].push(txDetails);
      } else {
        sortedTransaction[lastPaidOn] = [txDetails];
      }
    });

    setAutomatedTransaction(sortedTransaction);
  }, [transactions]);

  const openSidebar = params => {
    document.querySelector('body').style.overflowY = 'hidden';
    dispatch(toggleTransactionDetails(true));
    dispatch(setTransactionDetailsSidebar(params));
  };

  const renderTableContent = () => {
    const skeletonArray = Array(3).fill('');

    if (loading) {
      return skeletonArray.map((item, index) => (
        <tr key={index}>
          <td>
            <CustomShimmer height={'18px'} width={'16px'} />{' '}
          </td>
          <td>
            <div className="d-flex align-items-center">
              <CustomShimmer
                width={'2.8rem'}
                height={'2.8rem'}
                className="mr-2"
                variant="circle"
                style={{
                  flex: '0 0 2.8rem',
                }}
              />
              <div
                className="d-flex "
                style={{ width: '100%', flexDirection: 'column' }}
              >
                <CustomShimmer height="1.4rem" className="mb-2" />
                <CustomShimmer
                  height="1.2rem"
                  width="14rem"
                  className="mb-2"
                  style={{
                    background:
                      'linear-gradient(90deg, rgba(141, 142, 145, 0.1) 0%, rgba(141, 142, 145, 0.2) 49.31%, rgba(141, 142, 145, 0) 100%, rgba(141, 142, 145, 0.1) 100%, rgba(141, 142, 145, 0.1) 100%)',
                  }}
                />
              </div>
            </div>
          </td>
          <td>
            <div className="d-flex align-items-center">
              <CustomShimmer
                variant="circle"
                width={'2.8rem'}
                height={'2.8rem'}
                className="mr-2"
                style={{ flex: '0 0 2.8rem' }}
              />
              <div
                className="d-flex "
                style={{ width: '100%', flexDirection: 'column' }}
              >
                <CustomShimmer className="mb-2" />
                <CustomShimmer className="mb-2" height="1.2rem" width="14rem" />
              </div>
            </div>
          </td>
          <td align="right">
            <CustomShimmer className="mb-2" width="100%" />
          </td>
          <td></td>
        </tr>
      ));
    }
    if (!isObjEmpty(automatedTransaction)) {
      return (
        <>
          {Object.entries(automatedTransaction).map(
            ([createdOn, personDetails], id) => {
              // const [date, month, year] = createdOn.split(' ');
              // const todayDate = format(new Date(), 'dd MMM yyyy');

              return (
                <Fragment key={`${createdOn}${id}`}>
                  {/* <TableDivider className="flex align-items-center">
                    <td>{todayDate === createdOn ? 'today' : `${year}`}</td>
                  </TableDivider> */}

                  {personDetails &&
                    personDetails.map(
                      (
                        {
                          paidTo,
                          paidIn,
                          lastPaidOn,
                          personInfo,
                          transactions,
                        },
                        idx
                      ) => {
                        const lastPaidOnDate = format(
                          new Date(lastPaidOn),
                          'dd MMM'
                        );

                        const tokenLogoUrls = [];

                        let personInfoDetails = {};
                        if (personInfo) {
                          personInfoDetails = { ...personInfo };
                        }

                        paidIn.forEach(token => {
                          if (
                            token ===
                            '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
                          ) {
                            const logoUrl = tokenList.find(
                              item => item.address === 'ETH'
                            );
                            if (logoUrl) {
                              tokenLogoUrls.push(logoUrl?.icon);
                            }
                          } else {
                            const logoUrl = tokenList.find(
                              item => item.address === token
                            );
                            if (logoUrl) {
                              tokenLogoUrls.push(logoUrl?.icon);
                            }
                          }
                        });

                        return (
                          <tr
                            key={`${paidTo}-${idx}`}
                            onClick={() =>
                              openSidebar({
                                paidTo,
                                paidIn,
                                lastPaidOn,
                                personInfoDetails,
                                transactions,
                              })
                            }
                          >
                            <td>{lastPaidOnDate}</td>
                            <td>
                              {personInfoDetails.address
                                ? `${personInfoDetails.firstName} ${
                                    personInfoDetails.lastName
                                  } (${minifyAddress(
                                    personInfoDetails.address
                                  )})`
                                : '---'}
                            </td>
                            <td>
                              {tokenLogoUrls && tokenLogoUrls.length ? (
                                tokenLogoUrls.map(icon => (
                                  <TokenImg width="24" icon={icon} />
                                ))
                              ) : (
                                <TokenImg width="24" icon={defaultTokenIcon} />
                              )}
                            </td>
                            <td className="expandLogo">
                              <FontAwesomeIcon
                                className="dropdown"
                                icon={faAngleDown}
                              />
                            </td>
                          </tr>
                        );
                      }
                    )}
                </Fragment>
              );
            }
          )}
        </>
      );
    } else {
      return (
        <>
          <TableDivider className="flex align-items-center">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </TableDivider>
          <TableInfo
            style={{
              fontSize: '1.4rem',
              fontWeight: '500',
              textAlign: 'center',
              height: '10rem',
            }}
          >
            <td
              colSpan={6}
              style={{
                padding: '6.5rem 3rem',
                color: '#8D8E91',
                fontWeight: 'normal',
              }}
            >
              <NoTransaction style={{ opacity: '.6' }} />
              <p style={{ marginTop: '20px' }}>No Transactions Found!</p>
            </td>
          </TableInfo>
        </>
      );
    }
  };
  return (
    <TableContainer>
      <Table style={{ marginTop: '1.6rem' }}>
        <TableHead>
          <tr>
            <th style={{ width: '17%' }}>Last Paid On</th>
            <th style={{ width: '40%' }}>Paid To</th>
            <th style={{ width: '23%' }}>Paid In</th>
            <th style={{ width: '20%' }}></th>
          </tr>
        </TableHead>
        <TableBody>
          {loading && (
            <TableDivider className="flex align-items-center">
              <td /> <td /> <td /> <td /> <td />
            </TableDivider>
          )}

          {renderTableContent()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AutomatedTransactionsTable;

const CustomShimmer = ({
  variant = 'rect',
  height = '1.4rem',
  width = '21rem',
  style = {},
  className,
}) => {
  return (
    <Skeleton
      animation="wave"
      variant={variant}
      height={height}
      width={width}
      style={{
        ...style,
        borderRadius: `${variant === 'circle' ? '50%' : '.2rem'}`,
        background:
          'linear-gradient(90deg, rgba(141, 142, 145, 0.1) 0%, rgba(141, 142, 145, 0.2) 49.31%, rgba(141, 142, 145, 0) 100%, rgba(141, 142, 145, 0.1) 100%, rgba(141, 142, 145, 0.1) 100%)',
      }}
      className={className}
    />
  );
};
