export const UPDATE_FORM = 'parcel/addPeople/UPDATE_FORM';

export const ADD_PEOPLE = 'parcel/addPeople/ADD_PEOPLE';
export const ADD_PEOPLE_SUCCESS = 'parcel/addPeople/ADD_PEOPLE_SUCCESS';
export const ADD_PEOPLE_ERROR = 'parcel/addPeople/ADD_PEOPLE_ERROR';

export const ADD_BULK_PEOPLE = 'parcel/addPeople/ADD_BULK_PEOPLE';
export const ADD_BULK_PEOPLE_SUCCESS =
  'parcel/addPeople/ADD_BULK_PEOPLE_SUCCESS';
export const ADD_BULK_PEOPLE_ERROR = 'parcel/addPeople/ADD_BULK_PEOPLE_ERROR';
