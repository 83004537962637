import produce from 'immer';
import { DELETE_AUTOMATIONS_PAYEES_LIST } from './action-types';

export const initialState = {
  payeesToBeRemovedList: [],
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DELETE_AUTOMATIONS_PAYEES_LIST:
        draft.payeesToBeRemovedList = action.payeesToBeRemovedList;
        break;
    }
  });

export default reducer;
