import dropdownIcon from 'assets/icons/dropdown.svg';
import ErrorLogo from 'assets/icons/warning-red.svg';
import Avatar from 'components/common/Avatar';
import Img from 'components/common/Img';
import TokenImg from 'components/common/TokenImg';
import { getClickable } from 'components/common/Web3Utils';
import { useActiveWeb3React } from 'hooks';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Collapse from 'reactstrap/lib/Collapse';
import { makeSelectPrices } from 'store/tokens/selectors';
import { formatNumber } from 'utils/number-helpers';

const getFormattedAmount = (amount = '') => {
  let formattedAmount;
  formattedAmount = `$${formatNumber(amount.split('.')[0], 2)}.${
    amount.split('.')[1]
  }`;

  if (isNaN(amount)) {
    return 'Invalid Amount';
  }

  return formattedAmount || '';
};

const renderCsvRow = ({
  isAddressInvalid = [],
  salaryToken,
  idx,
  name,
  address,
  salaryAmount,
  prices,
  firstName,
  lastName,
  showNoError,
  chainId,
}) => {
  const addressError = isAddressInvalid.includes(address);

  const fullName =
    (firstName ? firstName : '') +
    (lastName ? '  ' : '') +
    (lastName ? lastName : '');

  const renderName = !!fullName ? fullName : name;

  return (
    <div
      key={`${address}-${idx}`}
      className={`payee-table-row ${
        addressError && 'error'
      } d-flex align-items-center`}
    >
      <div className="name d-flex align-items-center">
        {isAddressInvalid.includes(address) && !showNoError ? (
          <div style={{ position: 'relative' }}>
            <Img
              src={ErrorLogo}
              alt="error-logo"
              className="error-logo"
              id="error-logo"
              data-for={'error-logo'}
              data-tip={'Address is invalid'}
            />
            <ReactTooltip
              id={'error-logo'}
              place={'bottom'}
              type={'dark'}
              effect={'solid'}
              getContent={dataTip => dataTip}
            />
          </div>
        ) : (
          <Avatar className="avatar" firstName={renderName} />
        )}
        {renderName}
        {'   '}( {getClickable(address, chainId, 'address', true)} )
      </div>

      <div className="token d-flex justify-content-end align-item-center">
        <TokenImg token={salaryToken} /> {salaryAmount}
        {'  '} {salaryToken}
      </div>
      <div>
        {prices && prices[salaryToken]
          ? getFormattedAmount((prices[salaryToken] * salaryAmount).toFixed(2))
          : '--'}
      </div>
    </div>
  );
};

const TransactionsTableByTokens = ({
  token,
  idx,
  isInsufficientBalance = {},
  errorCount = 0,
  payees,
  isAddressInvalid,
  showNoError,
}) => {
  const [open, setopen] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const prices = useSelector(makeSelectPrices());

  const { chainId } = useActiveWeb3React();

  const getTotalAmount = (payees = []) => {
    return payees.reduce((total, { salaryAmount, salaryToken }) => {
      if (salaryToken === 'USD') {
        total += Number(salaryAmount);
      } else {
        total += prices[salaryToken] ? prices[salaryToken] * salaryAmount : 0;
      }
      return total;
    }, 0);
  };

  const getTotalTokens = (payees = []) => {
    return payees.reduce((total, { salaryAmount }) => {
      total += Number(salaryAmount);
      return total;
    }, 0);
  };

  const handleShowPayeeDetails = id => {
    setSelectedToken(id + 1);
    setopen(!open);
  };

  return (
    <div
      key={`${token}-${idx}`}
      style={{
        borderBottom: '0.1rem solid rgba(221,220,220,0.8)',
        backgroundColor:
          !showNoError &&
          (isInsufficientBalance.insufficientBalanceFlag || errorCount > 0)
            ? 'rgba(255, 75, 85, 0.04)'
            : '#ffffff',
      }}
      className="table-row-container"
    >
      <div
        onClick={() => handleShowPayeeDetails(idx)}
        className="table-row d-flex align-items-center"
      >
        <div className="token d-flex align-items-center">
          <TokenImg token={token} />
          {formatNumber(getTotalTokens(payees), 5)}
          {'   '}
          {token}
          {!showNoError &&
            (errorCount > 0 ? (
              <div className="error-text">{errorCount} errors</div>
            ) : !isInsufficientBalance.tokenExists && token !== 'ETH' ? (
              <div className="error-text">Token Not Found</div>
            ) : (
              isInsufficientBalance.insufficientBalanceFlag && (
                <div className="error-text">
                  Insufficient balance ( {isInsufficientBalance.balance} {token}{' '}
                  )
                </div>
              )
            ))}
        </div>
        <div>{payees && payees.length}</div>
        <div>
          {prices && prices[token]
            ? getFormattedAmount(prices[token].toFixed(2))
            : '--'}
        </div>
        <div className="amount">
          {prices && prices[token]
            ? getFormattedAmount(getTotalAmount(payees).toFixed(2))
            : '--'}
          <Img src={dropdownIcon} />
        </div>
      </div>
      <Collapse isOpen={open && selectedToken === idx + 1}>
        <div className="payee-table">
          <div className="d-flex align-items-center payee-table-head">
            <div>Payee</div>
            <div>Disbursement</div>
            <div>Amount in usd</div>
          </div>

          <div className="payee-table-body">
            {payees &&
              payees.length > 0 &&
              payees.map(
                (
                  {
                    salaryToken,
                    firstName,
                    lastName,
                    name,
                    address,
                    salaryAmount,
                  },
                  vdx
                ) =>
                  renderCsvRow({
                    isAddressInvalid,
                    salaryToken,
                    idx,
                    name,
                    firstName,
                    lastName,
                    address,
                    salaryAmount,
                    prices,
                    showNoError,
                    chainId,
                  })
              )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default TransactionsTableByTokens;
