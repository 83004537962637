import styled from 'styled-components/macro';

export default styled.div`
  padding-bottom: 1.2rem;
  border-bottom: 1px solid #ebecef;

  .title {
    font-style: normal;
    font-weight: 900;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #373737;
  }
`;
