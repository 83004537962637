import React from 'react';

import { Label } from './styles';

export default function CheckBox({ label, checkType, ...rest }) {
  return (
    <Label>
      <span>{label}</span>
      <input type="checkbox" {...rest} />
      <span
        className={
          checkType && checkType === 'flat'
            ? 'custom-checkbox-flat'
            : 'custom-checkbox'
        }
      ></span>
    </Label>
  );
}
