import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectAutomations = state => state.automations || initialState;

const makeSelectToBeRemovedAutomations = () =>
  createSelector(
    selectAutomations,
    automationsState => automationsState.payeesToBeRemovedList
  );

export { selectAutomations, makeSelectToBeRemovedAutomations };
