export const EDIT_PEOPLE = 'parcel/modifyPeople/EDIT_PEOPLE';
export const EDIT_PEOPLE_SUCCESS = 'parcel/modifyPeople/EDIT_PEOPLE_SUCCESS';
export const EDIT_PEOPLE_ERROR = 'parcel/modifyPeople/EDIT_PEOPLE_ERROR';

export const DELETE_PEOPLE = 'parcel/modifyPeople/DELETE_PEOPLE';
export const DELETE_PEOPLE_SUCCESS =
  'parcel/modifyPeople/DELETE_PEOPLE_SUCCESS';
export const DELETE_PEOPLE_ERROR = 'parcel/modifyPeople/DELETE_PEOPLE_ERROR';

export const DELETE_PEOPLE_BULK_LIST =
  'parcel/modifyPeople/DELETE_PEOPLE_BULK_LIST';
export const DELETE_PEOPLE_BULK = 'parcel/modifyPeople/DELETE_PEOPLE_BULK';
export const DELETE_PEOPLE_BULK_SUCCESS =
  'parcel/modifyPeople/DELETE_PEOPLE_BULK_SUCCESS';
export const DELETE_PEOPLE_BULK_ERROR =
  'parcel/modifyPeople/DELETE_PEOPLE_BULK_ERROR';
