import { Input } from 'components/common/Form';
import Radio from 'components/common/Radio';
import React, { useEffect, useState } from 'react';
import { PaymentScheduleWrapper } from './styles';
import AutomationModuleABI from 'constants/abis/AutomationModule.json';
import addresses from 'constants/addresses';
import { useContract } from 'hooks';
import { useSelector } from 'react-redux';
import { makeSelectOwnerSafeAddress } from 'store/global/selectors';
import { convertBigNumToNumber } from 'utils/number-helpers';
import { repeatPattern } from 'constants/index';

// const scheduleEndsOn = ['Never', 'After'];

const { AUTOMATION_MODULE_ADDRESS } = addresses;

const PaymentSchedule = ({ register }) => {
  // const renderPaymentEndsOnLabel = val => {
  //   if (val === 'After') {
  //     return (
  //       <div className="d-flex align-items-center">
  //         After
  //         <Input
  //           type="number"
  //           name="endsOnInput"
  //           className="endsOnInput"
  //           register={register}
  //           placeholder="10"
  //           defaultValue={10}
  //         />
  //         Payments
  //       </div>
  //     );
  //   } else {
  //     return val;
  //   }
  // };

  const automationModule = useContract(
    AUTOMATION_MODULE_ADDRESS,
    AutomationModuleABI
  );

  const [gasPrice, setGasPrice] = useState();

  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());

  useEffect(() => {
    const getGasPriceValue = async () => {
      if (ownerSafeAddress && automationModule) {
        try {
          const gasPrice = await automationModule.maxGasPrice(ownerSafeAddress);
          setGasPrice(convertBigNumToNumber(gasPrice));
        } catch (err) {
          console.error(err);
        }
      }
    };

    getGasPriceValue();
  }, [ownerSafeAddress, automationModule]);

  const showGasPriceInput = gasPrice === 0;

  return (
    <PaymentScheduleWrapper>
      <div className="payment-schedule-grid">
        <div>
          <div className="title">Schedule</div>
          {/* <div className="payment-schedule-items-grid">
            <div className="subtitle">First payment on</div>
            <input
              type="date"
              ref={register}
              placeholder="dd-mm-yyyy"
              id="birthday"
              name="firstPaymentDate"
            />
          </div> */}
          <div className="payment-schedule-items-grid">
            <div className="subtitle">Repeat Every</div>
            <div className="flex">
              {repeatPattern.map((label, i) => (
                <Radio
                  register={register}
                  key={i}
                  style={{ marginLeft: i !== 0 && '5rem' }}
                  id={`radio-${label}${i}`}
                  name="repeatPattern"
                  label={label}
                  value={label}
                  defaultChecked={label === 'Month'}
                />
              ))}
            </div>
          </div>
          {/* <div className="payment-schedule-items-grid">
            <div className="subtitle">Ends on</div>
            <div className="flex center">
              {scheduleEndsOn.map((label, i) => (
                <div className="flex" key={i}>
                  <Radio
                    register={register}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: i !== 0 && '3.8rem',
                    }}
                    id={`radio-${label}${i}`}
                    name="scheduleEndsOn"
                    label={() => renderPaymentEndsOnLabel(label)}
                    value={label}
                    defaultChecked={label === 'Never'}
                  />
                </div>
              ))}
            </div>
          </div> */}
        </div>
        {showGasPriceInput && (
          <div className="gas-price-container">
            <div className="title" style={{ margin: 0 }}>
              Maximum Gas Price for Automations (GWEI)
            </div>
            <Input
              type="number"
              className="gas-price-input"
              name="gasPrice"
              register={register}
              placeholder="221"
              required
              min={40}
            />
            <p>
              This will change the maximum GAS price across all automations.
              Your automations will only be executed if the GAS price is lower
              or equal to this number
            </p>
          </div>
        )}
      </div>
    </PaymentScheduleWrapper>
  );
};

export default PaymentSchedule;
