import styled from 'styled-components/macro';

export default styled.label`
  display: block;
  position: relative;
  padding: 0 1rem;
  cursor: pointer;
  margin-bottom: 0;
  height: 100%;
  margin-left: -1rem;

  input[type='checkbox'] {
    visibility: hidden;
  }

  span {
    padding-left: 1rem;
  }

  .custom-checkbox {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.4rem;
    width: 1.4rem;
    background-color: #fff;
    border: 0.12rem solid #dddcdc;
    transform: translate(-50%, -50%);
    border-radius: 2px;
  }

  &:hover input ~ .custom-checkbox {
    background-color: #fff;
  }

  input:active ~ .custom-checkbox {
    background-color: #fff;
  }
  input:checked ~ .custom-checkbox {
    background-color: #7367f0;
    border: 0.1rem solid #7367f0;
  }

  .custom-checkbox:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .custom-checkbox:after {
    display: block;
  }

  .custom-checkbox:after {
    left: 0.4rem;
    top: 0.1rem;
    bottom: 5px;
    width: 0.4rem;
    height: 0.8rem;
    border: solid white;
    border-width: 0 0.15rem 0.15rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  //flat checkbox
  .custom-checkbox-flat {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.4rem;
    width: 1.4rem;
    background-color: #fff;
    border: 0.1rem solid #dddcdc;
    transform: translate(-50%, -50%);
  }

  &:hover input ~ .custom-checkbox-flat {
    background-color: #fff;
  }
  input:active ~ .custom-checkbox-flat {
    background-color: #fff;
  }
  input:checked ~ .custom-checkbox-flat {
    background-color: #7367f0;
    border: 0.1rem solid #7367f0;
  }
  .custom-checkbox-flat:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .custom-checkbox-flat:after {
    display: block;
  }

  .custom-checkbox-flat:after {
    left: 0.4rem;
    top: 0.1rem;
    bottom: 5px;
    width: 0.4rem;
    height: 0.8rem;
    border: solid white;
    border-width: 0 0.15rem 0 0;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
`;
