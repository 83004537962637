import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FixedSizeList as List } from 'react-window';
import Avatar from '../Avatar';
import { minifyAddress } from '../Web3Utils';
import { LabelStyles } from './styles';

const inputStyles = {
  control: (styles, state) => ({
    ...styles,
    width: state.selectProps.width || '100%',
    minHeight: '4rem',
    borderRadius: '0.5rem',
    backgroundColor: '#ffffff',
    fontSize: '1.4rem',
    color: '#373737',
    border: `solid 0.1rem ${state.isFocused ? '#7367f0' : '#dddcdc'}`,
    '&:hover': {
      borderColor: '#7367f0',
    },
    boxShadow: 'none',
  }),
  option: (styles, state) => {
    return {
      ...styles,
      width: '100%',
      overflow: 'hidden',
      height: '5.3rem',
      backgroundColor: 'transparent !important',
      fontSize: '1.4rem',
      color: '#373737',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '0 1.6rem',

      '& + div': {
        '&::after': {
          position: 'absolute',
          content: "''",
          width: '100%',
          height: '100%',
          backgroundColor: state.isFocused ? '#f1f0fd' : '#ffffff',
          zIndex: -1,
          left: 0,
        },
      },

      p: {
        opacity: 0,
      },
    };
  },
  menu: (styles, state) => {
    return {
      ...styles,
      width: state.selectProps.width || '100%',
      fontSize: '1.2rem',
      fontWeight: 500,
      color: '#373737',
      cursor: 'pointer',
      backgroundColor: state.isFocused ? '#f1f0fd' : '#ffffff',
      overflow: 'hidden',

      '.menu-item': {
        position: 'absolute',
        left: '0',
        top: '0',
        justifyContent: 'space-between',
        width: '100%',
        fontSize: '1.4rem',
        color: '#373737',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        height: '5.3rem',
        padding: '0 1.6rem',
        zIndex: -1,

        span: {
          marginRight: '1.6rem',
        },

        '.right': {
          fontWeight: 'normal',
          color: '#8d8e91',

          '.team': {
            marginRight: '2.4rem',
          },
        },
      },
    };
  },
  indicatorSeparator: styles => {
    return {
      display: 'none',
      ...styles,
    };
  },
  input: styles => ({ ...styles }),
  placeholder: styles => ({ ...styles }),
  singleValue: styles => {
    return {
      ...styles,
      width: '100%',
    };
  },
};

function MenuList(props) {
  const height = 50;
  const { children, maxHeight } = props;

  return (
    <List
      height={
        children.length
          ? children.length * height > maxHeight
            ? maxHeight
            : children.length * height
          : height
      }
      itemCount={children.length || 1}
      itemSize={height}
      initialScrollOffset={0}
    >
      {({ index, style }) => {
        const {
          props: {
            data: {
              firstName,
              lastName,
              departmentName,
              address,
              __isNew__,
            } = {},
          } = {},
        } = children.length ? children[index] : {};
        return children.length ? (
          <div style={style}>
            {children[index]}
            <div className="menu-item d-flex align-items-center">
              {__isNew__ !== true ? (
                <>
                  <div className="d-flex align-items-center left">
                    <Avatar firstName={firstName} lastName={lastName} />
                    {firstName} {lastName}
                  </div>
                  <div className="d-flex align-items-center right">
                    <p className="team">{departmentName}</p>
                    {minifyAddress(address)}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : null;
      }}
    </List>
  );
}

const SelectPeople = ({
  name,
  control,
  required,
  options,
  isDisabled,
  isLoading,
  isClearable,
  isSearchable,
  width,
  placeholder,
  defaultValue,
  setValue,
  register,
  ...rest
}) => {
  const [dropDownOptions, setDropDownOptions] = useState([]);

  useEffect(() => {
    if (options && options.length > 0) {
      let peopleArr = [];
      options.forEach(({ address, firstName, lastName, departmentName }) => {
        let peopleData = {};
        peopleData.value = address;
        peopleData.firstName = firstName;
        peopleData.lastName = lastName;
        peopleData.departmentName = departmentName;
        peopleData.address = address;
        peopleData.label = (
          <LabelStyles className="menu-item">
            <div
              style={{ marginRight: '2.2rem', overflow: 'hidden' }}
              className="d-flex align-items-center left"
            >
              {firstName} {lastName}
            </div>
            <div className="d-flex align-items-center right">
              <p
                style={{ maxWidth: '10rem', overflow: 'hidden' }}
                className="team"
              >
                {departmentName}
              </p>
              {minifyAddress(address, 3, 4)}
            </div>
          </LabelStyles>
        );

        peopleArr.push(peopleData);
      });
      setDropDownOptions(peopleArr);
    }
  }, [options]);

  useEffect(() => {
    if (defaultValue) {
      setValue(
        'address',
        { value: defaultValue.address, label: defaultValue.address },
        { shouldValidate: false }
      );
    }
  }, [defaultValue]);

  const matchWalletAddressPattern = new RegExp(/^0x[a-fA-F0-9]{40}$/);

  const checkIfAddressExists = value =>
    dropDownOptions.some(item => item?.address === value);

  const constructNewAddressLabel = (value, create, people = {}) => {
    const doesAddressExist = checkIfAddressExists(value);
    const index = dropDownOptions.findIndex(item => item?.address === value);

    const inputLabel = {};

    if ((doesAddressExist && (index !== undefined || index !== -1)) || create) {
      const { address, firstName, lastName, departmentName } = create
        ? people
        : dropDownOptions[index];

      inputLabel.value = address;
      inputLabel.firstName = firstName;
      inputLabel.lastName = lastName;
      inputLabel.departmentName = departmentName;
      inputLabel.address = address;
      inputLabel.label = (
        <LabelStyles className="menu-item">
          <div className="d-flex align-items-center left">
            {firstName} {lastName}
          </div>
          <div className="d-flex align-items-center right">
            <p className="team">{departmentName}</p>
            {minifyAddress(address)}
          </div>
        </LabelStyles>
      );
    } else if (!doesAddressExist) {
      inputLabel.value = value;
      inputLabel.label = value;
      inputLabel.__isNew__ = true;
    }

    return inputLabel;
  };

  const isNewValidOption = value => {
    const doesAddressExist = checkIfAddressExists(value);
    const isValidFormat = matchWalletAddressPattern.test(value);

    return isValidFormat && !doesAddressExist;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ value, onChange }) => (
        <CreatableSelect
          name={name}
          defaultValue={
            defaultValue
              ? constructNewAddressLabel(
                  defaultValue?.address,
                  true,
                  defaultValue
                )
              : null
          }
          placeholder={placeholder}
          className="basic-single"
          classNamePrefix="select"
          isDisabled={isDisabled}
          {...register('address')}
          isLoading={isLoading}
          isClearable={isClearable}
          isSearchable={isSearchable}
          options={dropDownOptions}
          styles={inputStyles}
          width={width}
          value={value}
          filterOption={createFilter({
            ignoreAccents: true,
            ignoreCase: true,
            matchFrom: 'any',
            stringify: option =>
              `${option.data.value} ${option.data.firstName} ${option.data.lastName} ${option.data.departmentName}`,
          })}
          //value={defaultValue ? constructNewAddressLabel(defaultValue.address,true,defaultValue) : value}
          onChange={e => {
            onChange(e);
          }}
          onInputChange={e => {
            if (matchWalletAddressPattern.test(e)) {
              onChange(constructNewAddressLabel(e));
            }
          }}
          components={{ MenuList }}
          isValidNewOption={isNewValidOption}
          formatCreateLabel={val => `Pay - ${val}`}
          {...rest}
        />
      )}
    />
  );
};

export default SelectPeople;
