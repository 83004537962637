import UploadFailIcon from 'assets/icons/dashboard/upload-fail-icon.svg';
import UploadSuccessIcon from 'assets/icons/dashboard/upload-success-icon.svg';
import WarningIconSVG from 'assets/icons/warning-triangle.svg';
import Button from 'components/common/Button';
import Dropzone from 'components/common/Dropzone';
import Img from 'components/common/Img';
import { TransactionsByTokens } from 'components/Dashboard/styles';
import {
  FilePayoutContainer,
  UploadScreen,
  UploadStatus,
} from 'components/People/styles';
import TransactionsTableByTokens from 'components/Transactions/TransactionsTableByTokens';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FIELD_NAMES, isValidField } from 'store/add-people/utils';
import {
  makeSelectIsMultiOwner,
  makeSelectOrganisationType,
  makeSelectOwnerSafeAddress,
  makeSelectIsMetaTxEnabled,
} from 'store/global/selectors';
import { makeSelectIsMetaTxEnabled as makeSelectIsMetaTxLimitAllowed } from 'store/metatx/selectors';
import { makeSelectNonce } from 'store/safe/selectors';
import { useActiveWeb3React, useLocalStorage, useMassPayout } from 'hooks';
import transactionsReducer from 'store/transactions/reducer';
import transactionsSaga from 'store/transactions/saga';
import multisigReducer from 'store/multisig/reducer';

import {
  makeSelectPrices,
  makeSelectTokenList,
  makeSelectTokensDetails,
} from 'store/tokens/selectors';
import { formatNumber } from 'utils/number-helpers';
import ErrorLogo from 'assets/icons/warning-red.svg';
import ReactTooltip from 'react-tooltip';
import { TRANSACTION_MODES } from 'constants/transactions';
import { useHistory } from 'react-router-dom';
import {
  addTransaction,
  clearTransactionHash,
} from 'store/transactions/actions';
import { cryptoUtils } from 'parcel-sdk';
import { createMultisigTransaction } from 'store/multisig/actions';
import { getNonce } from 'store/safe/actions';
import { getTokens } from 'store/tokens/actions';
import { getInvitations } from 'store/invitation/actions';
import { getMetaTxEnabled } from 'store/metatx/actions';
import { useInjectSaga } from 'utils/injectSaga';
import safeReducer from 'store/safe/reducer';
import safeSaga from 'store/safe/saga';
import { useInjectReducer } from 'utils/injectReducer';
import multisigSaga from 'store/multisig/saga';
import Avatar from 'components/common/Avatar';
import { minifyAddress } from 'components/common/Web3Utils';
import TokenImg from 'components/common/TokenImg';
import { Input } from 'components/common/Form';
import { useForm } from 'react-hook-form';
import { TableDivider } from 'components/common/Table';
import { makeSelectMetaTransactionHash } from 'store/transactions/selectors';
import getTransactionReceipts from 'utils/getTransactionReceipts';
import { getFinalMetaTransactionHash } from 'utils/meta-tx-helpers';
import { networkId } from 'constants/networks';
import metaTxSaga from 'store/metatx/saga';
import metaTxReducer from 'store/metatx/reducer';

const metaTxKey = 'metatx';
const getFormattedAmount = (amount = '') => {
  let formattedAmount;
  formattedAmount = `$${formatNumber(amount.split('.')[0], 2)}.${
    amount.split('.')[1]
  }`;

  if (isNaN(amount)) {
    return 'Invalid Amount';
  }

  return formattedAmount || '';
};

const getTotalTokens = (payees = []) => {
  return payees.reduce((total, { salaryAmount }) => {
    total += Number(salaryAmount);
    return total;
  }, 0);
};

const FilePayout = ({ handleHide }) => {
  const safeKey = 'safe';
  const transactionsKey = 'transactions';
  const multisigKey = 'multisig';

  const { register, watch } = useForm({
    mode: 'onChange',
  });

  const [encryptionKey] = useLocalStorage('ENCRYPTION_KEY');
  const [csvData, setCSVData] = useState();
  const [invalidCsvData, setInvalidCsvData] = useState(false);
  const [fileName, setFileName] = useState();
  const tokenDetails = useSelector(makeSelectTokensDetails());
  const tokenList = useSelector(makeSelectTokenList());
  const prices = useSelector(makeSelectPrices());
  const nonce = useSelector(makeSelectNonce());
  const isMultiOwner = useSelector(makeSelectIsMultiOwner());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const isMetaEnabled = useSelector(makeSelectIsMetaTxEnabled());
  const isMetaTxLimitAllowed = useSelector(makeSelectIsMetaTxLimitAllowed());
  const txHashFromMetaTx = useSelector(makeSelectMetaTransactionHash());
  const {
    csvMassPayout,
    loadingTx,
    txHash,
    recievers,
    txData,
    setTxData,
    payoutRecivers,
    loadingMetaTx,
    setLoadingMetaTx,
  } = useMassPayout();
  const organisationType = useSelector(makeSelectOrganisationType());
  const dispatch = useDispatch();
  const [open, setopen] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const history = useHistory();
  const { library, account } = useActiveWeb3React();

  useInjectSaga({ key: transactionsKey, saga: transactionsSaga });
  useInjectSaga({ key: safeKey, saga: safeSaga });
  useInjectSaga({ key: multisigKey, saga: multisigSaga });
  useInjectSaga({ key: metaTxKey, saga: metaTxSaga });

  useInjectReducer({ key: safeKey, reducer: safeReducer });
  useInjectReducer({ key: transactionsKey, reducer: transactionsReducer });
  useInjectReducer({ key: multisigKey, reducer: multisigReducer });
  useInjectReducer({ key: metaTxKey, reducer: metaTxReducer });

  const desc = watch('description') && watch('description');

  useEffect(() => {
    if (ownerSafeAddress) {
      dispatch(getInvitations(ownerSafeAddress));
      dispatch(getNonce(ownerSafeAddress));
      dispatch(getTokens(ownerSafeAddress));
      dispatch(getMetaTxEnabled(ownerSafeAddress));
    }
  }, [ownerSafeAddress, dispatch]);

  const handleDrop = (data, fileName) => {
    setFileName(fileName);

    // checking for at least 6 columns in the csv
    if (!data || data.length === 0 || data.some(arr => arr.length < 4)) {
      setInvalidCsvData(true);
      return;
    }
    const formattedPayees = data.reduce((formatted, arr, i) => {
      return [
        ...formatted,
        {
          name: arr[0],
          address: arr[1],
          salaryAmount: arr[2],
          salaryToken: arr[3],
        },
      ];
    }, []);

    const tokens = {};

    formattedPayees.forEach(data => (tokens[data.salaryToken] = []));

    formattedPayees.forEach(data => {
      tokens[data.salaryToken] = [...tokens[data.salaryToken], data];
    });

    setCSVData(tokens);
  };

  const handleRemove = () => {
    setFileName('');
    setCSVData(null);
  };

  const handleGoBacktoUpload = () => {
    setFileName('');
    setCSVData(null);
    setInvalidCsvData(false);
  };

  const getTotalAmount = useCallback(
    (payees = []) => {
      return payees.reduce((total, { salaryAmount, salaryToken }) => {
        if (salaryToken === 'USD') {
          total += Number(salaryAmount);
        } else {
          total += prices[salaryToken] ? prices[salaryToken] * salaryAmount : 0;
        }
        return total;
      }, 0);
    },
    [prices]
  );

  const handleCSVPayout = async payouts => {
    await csvMassPayout(
      payouts,
      isMultiOwner,
      nonce,
      isMetaEnabled,
      isMetaTxLimitAllowed
    );
    if (isMetaEnabled && isMetaTxLimitAllowed) {
      setLoadingMetaTx(true);
    }
  };

  useEffect(() => {
    if (
      txHashFromMetaTx &&
      isMetaEnabled &&
      !isMultiOwner &&
      isMetaTxLimitAllowed
    ) {
      setLoadingMetaTx(true);
      dispatch(clearTransactionHash());
      const intervalId = setInterval(async () => {
        const newMetaHash = await getFinalMetaTransactionHash(
          txHashFromMetaTx,
          networkId
        );
        if (newMetaHash) {
          getTransactionReceipts(
            library,
            dispatch,
            ownerSafeAddress,
            '',
            newMetaHash,
            false
          );
          handleHide(true);
          clearInterval(intervalId);
        }
      }, 3000);
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    txHashFromMetaTx,
    isMetaEnabled,
    isMultiOwner,
    isMetaTxLimitAllowed,
  ]);
  useEffect(() => {
    let amt = 0;
    let selectedToken = '';

    if (csvData) {
      Object.entries(csvData).map(([_, payees], idx) => {
        return (amt += getTotalAmount(payees));
      });

      selectedToken = Object.keys(csvData).join('|');
    }

    if (txHash) {
      if (
        encryptionKey &&
        recievers &&
        ownerSafeAddress &&
        // totalAmountToPay &&
        selectedToken &&
        account
      ) {
        const description = desc || 'CSV Payout';
        const to = cryptoUtils.encryptDataUsingEncryptionKey(
          JSON.stringify(payoutRecivers),
          encryptionKey,
          organisationType
        );
        // const to = selectedTeammates;

        dispatch(
          addTransaction({
            to,
            safeAddress: ownerSafeAddress,
            createdBy: account,
            transactionHash: txHash,
            tokenValue: -1,
            tokenCurrency: selectedToken,
            fiatValue: amt.toFixed(2),
            addresses: recievers.map(({ address }) => address),
            paymentType: 'fileUpload',
            description,
            transactionMode: TRANSACTION_MODES.CSV_PAYOUT,
            nonce: nonce,
          })
        );
        handleHide(true);
      }
    } else if (txData) {
      if (
        encryptionKey &&
        recievers &&
        ownerSafeAddress &&
        // totalAmountToPay &&
        selectedToken &&
        account
      ) {
        const to = cryptoUtils.encryptDataUsingEncryptionKey(
          JSON.stringify(payoutRecivers),
          encryptionKey,
          organisationType
        );
        const description = desc || 'CSV Payout';
        if (!isMultiOwner) {
          // threshold = 1 or single owner
          dispatch(
            addTransaction({
              to,
              safeAddress: ownerSafeAddress,
              createdBy: account,
              txData,
              tokenValue: -1,
              tokenCurrency: selectedToken,
              fiatValue: amt.toFixed(2),
              addresses: recievers.map(({ address }) => address),
              paymentType: 'fileUpload',
              description,
              transactionMode: TRANSACTION_MODES.CSV_PAYOUT,
              nonce: nonce,
              isMetaEnabled,
            })
          );
          setTxData(undefined);
          if (!isMetaEnabled) handleHide(true);
        } else {
          // threshold > 1
          dispatch(
            createMultisigTransaction({
              to,
              safeAddress: ownerSafeAddress,
              createdBy: account,
              txData,
              tokenValue: -1,
              tokenCurrency: selectedToken,
              fiatValue: amt.toFixed(2),
              fiatCurrency: 'USD',
              addresses: recievers.map(({ address }) => address),
              nonce: nonce,
              transactionMode: TRANSACTION_MODES.CSV_PAYOUT,
              paymentType: 'fileUpload',
              description,
            })
          );
          handleHide(true);
        }
      }
    }
  }, [
    txHash,
    encryptionKey,
    recievers,
    dispatch,
    ownerSafeAddress,
    txData,
    setTxData,
    account,
    isMultiOwner,
    nonce,
    history,
    prices,
    organisationType,
    csvData,
    getTotalAmount,
    payoutRecivers,
    handleHide,
    desc,
    isMetaEnabled,
  ]);

  const renderCsvData = () => {
    const insufficientBalance = ({ token, payees }) => {
      const tokenExists = tokenList.filter(({ name }) => name === token)[0];
      const totalTokenAmount = getTotalTokens(payees);

      if (tokenExists && tokenExists.balance) {
        return {
          insufficientBalanceFlag:
            totalTokenAmount > parseFloat(tokenExists.balance),
          balance: parseFloat(tokenExists.balance).toFixed(2),
          tokenExists: true,
        };
      } else
        return {
          insufficientBalanceFlag: true,
          balance: '0',
          tokenExists: false,
        };
    };

    const getAmount = () => {
      let amt = 0;
      Object.entries(csvData).map(([_, payees]) => {
        return (amt += getTotalAmount(payees));
      });

      return amt.toFixed(2);
    };

    const getTotalPayees = () => {
      let payeeCount = 0;
      Object.entries(csvData).map(([_, payees]) => {
        return (payeeCount += payees.length);
      });

      return payeeCount;
    };

    const checkIfInsufficientBalance = () => {
      return Object.entries(csvData).map(([token, payees]) => {
        const isInsufficientBalance = insufficientBalance({ token, payees });

        return isInsufficientBalance;
      });
    };

    const hasBalanceIssues = checkIfInsufficientBalance().some(
      e => e.insufficientBalanceFlag === true
    );

    const tokenDoesNotExists = checkIfInsufficientBalance().some(
      e => e.tokenExists === false
    );

    const checkIfAddressValid = (payees = []) => {
      let tempArr = [];
      payees.map(({ address }) => {
        if (!isValidField(FIELD_NAMES.ADDRESS, address)) {
          tempArr.push(address);
        }
      });

      return tempArr;
    };

    const checkIfPayDetailsValid = (payees = []) => {
      return payees.filter(({ salaryToken, salaryAmount }) => {
        return (
          !isValidField(FIELD_NAMES.AMOUNT, salaryAmount) ||
          !isValidField(FIELD_NAMES.TOKEN, salaryToken, tokenDetails)
        );
      });
    };

    const getIssuesCount = payees => {
      const isAddressInvalid = checkIfAddressValid(payees);
      const isPayDetailsValid = checkIfPayDetailsValid(payees);
      let isInsufficientBalance = {};
      Object.entries(csvData).map(([token, payees], idx) => {
        isInsufficientBalance = insufficientBalance({
          token,
          payees,
        });
      });

      if (
        isInsufficientBalance.insufficientBalanceFlag ||
        isInsufficientBalance.tokenExists
      ) {
        return (
          isAddressInvalid.length +
          isPayDetailsValid.length +
          (isInsufficientBalance.insufficientBalanceFlag ? 1 : 0) +
          (!isInsufficientBalance.tokenExists ? 1 : 0)
        );
      }

      return isAddressInvalid.length + isPayDetailsValid.length;
    };

    const checkIfFileHasError = () => {
      return Object.entries(csvData).map(([_, payees]) => {
        return getIssuesCount(payees);
      });
    };
    const fileHasErrorBool = checkIfFileHasError().some(e => e > 0);

    return (
      <div>
        <UploadStatus>
          {!invalidCsvData ? (
            <div
              style={{
                backgroundColor: fileHasErrorBool ? '#ff4b55' : '#6cb44c',
              }}
              className="status d-flex align-items-center"
            >
              <Img
                src={fileHasErrorBool ? WarningIconSVG : UploadSuccessIcon}
                alt="upload-status-icon"
              />
              <div className={fileHasErrorBool ? 'fail' : 'success'}>
                {fileHasErrorBool
                  ? 'File has Issues'
                  : 'File upload successful'}
              </div>
            </div>
          ) : (
            <div className="status">
              <Img src={UploadFailIcon} alt="upload-success" />
              <div className="fail">File upload unsuccessful</div>
              <div className="file-name">{fileName}</div>
              <div className="remove-file" onClick={handleRemove}>
                Remove File
              </div>
            </div>
          )}
          <div className="file-name"> {fileName} </div>
        </UploadStatus>

        <TransactionsByTokens>
          <div className="d-flex align-items-center table-head">
            <div>Token</div>
            <div>No. of Payees</div>
            <div>Price per Token</div>
            <div>Total USD Amount</div>
          </div>

          <div className="table-divider"></div>

          <div className="table-body">
            {Object.entries(csvData).map(([token, payees], idx) => {
              const isInsufficientBalance = insufficientBalance({
                token,
                payees,
              });
              const isAddressInvalid = checkIfAddressValid(payees);
              const isPayDetailsValid = checkIfPayDetailsValid(payees);
              const errorCount =
                isAddressInvalid.length || isPayDetailsValid.length;

              return (
                <TransactionsTableByTokens
                  token={token}
                  idx={idx}
                  isInsufficientBalance={isInsufficientBalance}
                  errorCount={errorCount}
                  payees={payees}
                  isAddressInvalid={isAddressInvalid}
                  isPayDetailsValid={isPayDetailsValid}
                />
              );
            })}
            <div className="table-row d-flex align-items-center">
              <div></div>
              <div className="footer-row">
                Total <span>{getTotalPayees()}</span>
              </div>
              <div></div>
              <div className="footer-row">
                Total{' '}
                <span>
                  {tokenDoesNotExists ? '--' : getFormattedAmount(getAmount())}
                </span>
              </div>
            </div>
          </div>
        </TransactionsByTokens>

        {!invalidCsvData ? (
          <div
            className="d-flex justify-content-center"
            style={{ margin: '2rem 4rem' }}
          >
            <div style={{ width: '100%', marginRight: '2rem' }}>
              <Input
                type="text"
                name="description"
                style={{ minWidth: '30rem' }}
                register={register}
                placeholder="description (optional)"
              />
            </div>
            <Button
              type="button"
              width="15rem"
              style={{
                background: 'rgba(115, 103, 240, 0.1)',
                color: '#7367F0',
                fontWeight: 600,
                marginRight: '2rem',
              }}
              onClick={handleGoBacktoUpload}
            >
              Re-upload
            </Button>

            <Button
              type="button"
              width="15rem"
              onClick={() => handleCSVPayout(csvData)}
              disabled={
                invalidCsvData ||
                hasBalanceIssues ||
                fileHasErrorBool ||
                loadingMetaTx
              }
              loading={loadingTx || loadingMetaTx}
            >
              Confirm
            </Button>
          </div>
        ) : (
          <div style={{ margin: '3rem 2rem' }}>
            <div className="text-red" style={{ fontSize: '1.4rem' }}>
              Oops, something is not right. Please check your csv file and fix
              the issues.
            </div>
          </div>
        )}
      </div>
    );
  };

  const keyPoints = [
    'Please follow the format as per the sample file. Only .csv files are supported.',
    'Multiple tokens are supported',
    'All columns except ‘name’ are required. The name field is a tracker to save in the transaction details',
    'An address can be paid in multiple tokens with separate entries',
  ];
  const renderUploadScreen = () => {
    return (
      <UploadScreen className="d-flex">
        <div>
          <Dropzone
            sampleFileSrc="https://docs.google.com/spreadsheets/d/1mHPayaGG10gwmtbNVeDig_oNBfOwLQ6S_JaiDZo5io4/edit?usp=sharing"
            hasError={invalidCsvData}
            onDrop={handleDrop}
          />
        </div>

        <div className="points-to-remember">
          <div className="title">Things to keep in mind</div>
          <ul className="points">
            {keyPoints.map((item, idx) => (
              <li key={idx}>
                {idx + 1}
                {'.  '}
                {item}
              </li>
            ))}
          </ul>
        </div>
      </UploadScreen>
    );
  };

  const renderAddBulkPeople = () => {
    const hasCsvData = !!csvData;
    return !hasCsvData ? renderUploadScreen() : renderCsvData();
  };

  return <FilePayoutContainer>{renderAddBulkPeople()}</FilePayoutContainer>;
};

export default FilePayout;
