import React from 'react';

import { HorizontalStepper } from './styles';

export function Stepper({ children, count }) {
  return <HorizontalStepper count={count}>{children}</HorizontalStepper>;
}

export function StepCircle({
  icon,
  backgroundColor,
  title,
  subtitle,
  isInitiator,
  titleColor,
  stepStyles = {},
  ...rest
}) {
  if (Array.isArray(backgroundColor)) {
    const [left, right] = backgroundColor;
    return (
      <div className="step" style={stepStyles} {...rest}>
        <div className="step-container">
          <div className="step-bar-left" />

          <div className="step-circles">
            <div
              className="outer-step-circle"
              style={{ backgroundColor: '#cdcdcd' }}
            />
            <div className="step-circle-split">
              <div
                className="step-circle-left"
                style={{ backgroundColor: left }}
              />
              <div
                className="step-circle-right"
                style={{ backgroundColor: right }}
              />
            </div>
          </div>
          <div className="step-bar-right" />
        </div>

        <div className="step-info-text">
          <div className="step-title">{title}</div>
          <div className="step-subtitle">{subtitle}</div>
          {isInitiator && <div className="step-text">Initiator</div>}
        </div>
      </div>
    );
  } else {
    return (
      <div className="step" style={stepStyles} {...rest}>
        <div className="step-container">
          <div className="step-bar-left" />

          <div className="step-circles">
            <div
              className="outer-step-circle"
              style={{ backgroundColor: backgroundColor || '#f2f2f2' }}
            />
            <div
              className="step-circle"
              style={{ backgroundColor: backgroundColor || '#f2f2f2' }}
            />
          </div>
          <div className="step-bar-right" />
        </div>

        <div className="step-info-text">
          <div className="step-title">{title}</div>
          <div className="step-subtitle">{subtitle}</div>
          {isInitiator && <div className="step-text">Initiator</div>}
        </div>
      </div>
    );
  }
}
