import { format } from 'date-fns';
import { useContract, useLocalStorage } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import {
  makeSelectOrganisationType,
  makeSelectOwnerSafeAddress,
  makeSelectThreshold,
} from 'store/global/selectors';
import { getMultisigTransactions } from 'store/multisig/actions';
import multisigReducer from 'store/multisig/reducer';
import multisigSaga from 'store/multisig/saga';
import {
  makeSelectFetching,
  makeSelectMultisigTransactions,
} from 'store/multisig/selectors';
import { getTokens } from 'store/tokens/actions';
import tokensReducer from 'store/tokens/reducer';
import tokensSaga from 'store/tokens/saga';
import {
  makeSelectTokenIcons,
  makeSelectTokenList,
} from 'store/tokens/selectors';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { Container } from './styles';
import TransactionsTable from './TransactionsTable';
import { cryptoUtils } from 'parcel-sdk';
import { formatNumber } from 'utils/number-helpers';
import { CSVLink } from 'react-csv';
import { Export } from 'components/People/styles';
import Img from 'components/common/Img';
import ExportIcon from 'assets/icons/dashboard/export.svg';
import AutomatedTransactionsTable from './AutomatedTransactionTable';
import AutomationModuleABI from 'constants/abis/AutomationModule.json';
import addresses from 'constants/addresses';
import viewPeopleSaga from 'store/view-people/saga';
import {
  makeSelectLoadingPeopleByTeam,
  makeSelectPeople,
} from 'store/view-people/selectors';
import viewPeopleReducer from 'store/view-people/reducer';
import { getAmountFromWei } from 'utils/tx-helpers';
import { getAllPeople } from 'store/view-people/actions';
import TransactionDetailSidebar from './TransactionDetailSidebar';
import { useHistory } from 'react-router-dom';
import { useInterval } from 'hooks/useInterval';

const multisigKey = 'multisig';
const tokensKey = 'tokens';
const viewPeopleKey = 'viewPeople';

const { AUTOMATION_MODULE_ADDRESS, OTHER_ZERO_ADDRESS } = addresses;

const Transactions = () => {
  const [encryptionKey] = useLocalStorage('ENCRYPTION_KEY');
  const [activeTab, setActiveTab] = useState('1');
  const [dateSortedTransactions, setDateSortedTransactions] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [automatedTransactions, setAutomatedTransactions] = useState([]);
  const [loadingAutomated, setLoadingAutomated] = useState(false);
  const [startPolliingTransactions, setStartPolliingTransactions] =
    useState(false);
  const [people, setPeople] = useState();
  const automationModule = useContract(
    AUTOMATION_MODULE_ADDRESS,
    AutomationModuleABI
  );
  const history = useHistory();

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useInjectReducer({ key: multisigKey, reducer: multisigReducer });
  useInjectReducer({ key: tokensKey, reducer: tokensReducer });
  useInjectReducer({ key: viewPeopleKey, reducer: viewPeopleReducer });
  useInjectSaga({ key: multisigKey, saga: multisigSaga });
  useInjectSaga({ key: tokensKey, saga: tokensSaga });
  useInjectSaga({ key: viewPeopleKey, saga: viewPeopleSaga });

  const dispatch = useDispatch();

  const transactions = useSelector(makeSelectMultisigTransactions());
  const loading = useSelector(makeSelectFetching());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const icons = useSelector(makeSelectTokenIcons());
  const threshold = useSelector(makeSelectThreshold());
  const organisationType = useSelector(makeSelectOrganisationType());
  const loadingPeople = useSelector(makeSelectLoadingPeopleByTeam());
  const tokens = useSelector(makeSelectTokenList());
  const encryptedPeople = useSelector(makeSelectPeople());

  const getDecryptedDetails = useCallback(
    data => {
      if (!encryptionKey) return '';
      return JSON.parse(
        cryptoUtils.decryptDataUsingEncryptionKey(
          data,
          encryptionKey,
          organisationType
        )
      );
    },
    [encryptionKey, organisationType]
  );

  useInterval(async () => {
    if (ownerSafeAddress) {
      setStartPolliingTransactions(true);
      dispatch(
        getMultisigTransactions({
          ownerSafeAddress,
          executedTransactions: activeTab === '1' ? 'false' : 'true',
        })
      );
    }
  }, 30000);

  useEffect(() => {
    setStartPolliingTransactions(false);
  }, [activeTab]);

  useEffect(() => {
    if (ownerSafeAddress) {
      dispatch(
        getMultisigTransactions({
          ownerSafeAddress,
          executedTransactions: activeTab === '1' ? 'false' : 'true',
        })
      );
    }
  }, [dispatch, ownerSafeAddress, activeTab]);

  useEffect(() => {
    if (!encryptedPeople) dispatch(getAllPeople(ownerSafeAddress));
  }, [dispatch, encryptedPeople, ownerSafeAddress]);

  useEffect(() => {
    if (history && history.location) {
      const state = history.location.state || {};
      const activeTab = state.activeTab || '1';
      setActiveTab(activeTab);
    }
  }, []);

  useEffect(() => {
    if (encryptedPeople && encryptionKey) {
      const sortedDecryptedPeople = encryptedPeople
        .map(({ data, ...rest }) => {
          const { firstName, lastName, salaryAmount, salaryToken, address } =
            getDecryptedDetails(data, encryptionKey, organisationType);
          return {
            firstName,
            lastName,
            salaryAmount,
            salaryToken,
            address,
            ...rest,
          };
        })
        .sort((a, b) =>
          a.firstName &&
            b.firstName &&
            a.firstName.toUpperCase() > b.firstName.toUpperCase()
            ? 1
            : -1
        );

      const peopleMap = sortedDecryptedPeople.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.address]: cur,
        };
      }, {});

      setPeople(peopleMap);
    }
  }, [encryptedPeople, encryptionKey, organisationType, ownerSafeAddress]);

  useEffect(() => {
    if (
      ownerSafeAddress &&
      automationModule &&
      !loadingPeople &&
      people !== undefined
    ) {
      const getTransactions = async () => {
        setLoadingAutomated(true);
        const startTime = performance.now();

        const filter =
          automationModule.filters.ExecuteAllowanceTransfer(ownerSafeAddress);

        filter.fromBlock = 'earliest';
        filter.toBlock = 'latest';
        filter.safe = ownerSafeAddress;

        const data = await automationModule.queryFilter(filter);

        const extractedDataPromises = data.map(async item => {
          const args = item?.args;
          const block = await item.getBlock();
          const decimals =
            args.token === OTHER_ZERO_ADDRESS
              ? 18
              : tokens.find(item => item.address === args?.token)?.decimals;
          const salaryAmount = getAmountFromWei(args?.value, decimals, 2);

          return {
            delegate: args.delegate,
            salaryToken: args.token,
            salaryAmount,
            nonce: args.nonce,
            transactionHash: item.transactionHash,
            paidOn: block.timestamp * 1000,
          };
        });
        const extractedData = await Promise.all(extractedDataPromises);

        const structuredData = extractedData.reduce((acc, cur) => {
          const address = cur.delegate;
          const paidIn = acc[address]?.paidIn || [];
          if (!paidIn.includes(cur.salaryToken)) {
            paidIn.push(cur.salaryToken);
          }

          const transactions = acc[address]?.transactions || [];
          transactions.push(cur);

          let lastPaidOn = acc[address]?.lastPaidOn || 0;
          lastPaidOn = cur.paidOn > lastPaidOn ? cur.paidOn : lastPaidOn;

          acc[address] = {
            paidTo: address,
            personInfo: people[address],
            paidIn,
            transactions,
            lastPaidOn,
          };
          return acc;
        }, {});
        const endTime = performance.now();
        setAutomatedTransactions(structuredData);
        setLoadingAutomated(false);
      };

      getTransactions();
    }
  }, [tokens, people, automationModule, ownerSafeAddress, loadingPeople]);

  const getCSVData = useCallback(
    transactions => {
      let csvData = [];
      if (transactions && transactions.length > 0) {
        transactions.map(transaction => {
          if (transaction && transaction.transactionHash) {
            const {
              transactionId,
              to,
              createdOn,
              transactionFees,
              transactionHash,
              paymentType,
              tokenCurrency,
              description,
            } = transaction;

            const paidTeammates = getDecryptedDetails(to);
            for (let i = 0; i < paidTeammates.length; i++) {
              const {
                firstName,
                lastName,
                salaryAmount,
                salaryToken,
                address,
                allowanceAmount,
                allowanceToken,
              } = paidTeammates[i];

              const derivedDetails = {
                type: '-',
                amount: '-',
                token: '-',
              };

              if (paymentType === 'limit') {
                derivedDetails.amount = allowanceAmount;
                derivedDetails.token = allowanceToken;
                derivedDetails.type = 'Spending Limit';
              } else {
                if (paymentType !== 'gas' && paymentType !== 'removal') {
                  derivedDetails.amount = salaryAmount;
                  if (salaryToken === 'USD') {
                    derivedDetails.token = tokenCurrency;
                  } else {
                    derivedDetails.token = salaryToken;
                  }
                }

                switch (paymentType) {
                  case 'quick':
                    derivedDetails.type = 'Quick Payment';
                    break;
                  case 'custom':
                  case 'individual':
                  case 'team':
                    derivedDetails.type = 'Mass payout';
                    break;
                  case 'gas':
                    derivedDetails.type = 'Automation Max Gas Price Change';
                    break;
                  case 'removal':
                    derivedDetails.type = 'Automation Removal';
                    break;
                  default:
                    derivedDetails.type = '-';
                }
              }

              csvData.push({
                'Payment Type': derivedDetails.type,
                'First Name': firstName || '-',
                'Last Name': lastName || '-',
                Token: derivedDetails.token,
                Amount: derivedDetails.amount,
                Address: address,
                description,
                'Transaction Hash': transactionHash || '',
                'Created On': format(
                  new Date(createdOn),
                  'dd/MM/yyyy HH:mm:ss'
                ),
                'Transaction ID': transactionId,
                'Transaction fees': transactionFees
                  ? formatNumber(transactionFees, 5)
                  : '',
              });
            }
          }
          return csvData;
        });
      }

      return csvData;
    },
    [getDecryptedDetails]
  );

  useEffect(() => {
    if (transactions && transactions?.length) {
      setCSVData(getCSVData(transactions));
      const sortByDate = transactions
        ? transactions.reduce((accumulator, txDetails) => {
          const { createdOn } = txDetails;

          const dateStr = format(new Date(createdOn), 'dd MMM yyyy');
          accumulator[dateStr] = accumulator[dateStr] || [];
          accumulator[dateStr].push(txDetails);
          return accumulator;
        }, {})
        : {};

      setDateSortedTransactions(sortByDate);
    } else {
      setDateSortedTransactions([]);
    }
  }, [getCSVData, transactions]);

  useEffect(() => {
    if (ownerSafeAddress && !icons) {
      dispatch(getTokens(ownerSafeAddress));
    }
  }, [ownerSafeAddress, dispatch, icons]);

  return (
    <Container>
      <div className="header d-flex align-items-center">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={`tab-items ${activeTab === '1' && 'active-tab'}`}
              onClick={() => {
                toggle('1');
              }}
            >
              Pending
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`tab-items ${activeTab === '2' && 'active-tab'}`}
              onClick={() => {
                toggle('2');
              }}
            >
              Executed
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`tab-items ${activeTab === '3' && 'active-tab'}`}
              onClick={() => {
                toggle('3');
              }}
            >
              Automated
            </NavLink>
          </NavItem>
        </Nav>
        <div>
          <CSVLink
            data={csvData}
            filename={`transactions-${format(
              Date.now(),
              'dd/MM/yyyy-HH:mm:ss'
            )}.csv`}
          >
            <Export>
              <Img src={ExportIcon} alt="export" />
              <div className="text">Export</div>
            </Export>
          </CSVLink>
        </div>
      </div>
      <TabContent activeTab={activeTab}>
        {activeTab === '3' ? (
          <AutomatedTransactionsTable
            transactions={automatedTransactions}
            loading={loadingAutomated}
            threshold={threshold}
          />
        ) : (
          <TransactionsTable
            transactions={dateSortedTransactions}
            loading={startPolliingTransactions ? false : loading}
            threshold={threshold}
          />
        )}
      </TabContent>
    </Container>
  );
};

export default Transactions;
