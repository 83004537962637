export const networkNames = {
  LOCAL: 'LOCAL',
  RINKEBY: 'RINKEBY',
  ROPSTEN: 'ROPSTEN',
  KOVAN: 'KOVAN',
  MAINNET: 'MAINNET',
  POLYGON: 'POLYGON',
};

export const chainIds = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  KOVAN: 42,
  POLYGON: 137,
};

export function findNetworkNameByWeb3ChainId(chainId) {
  switch (chainId) {
    case chainIds.MAINNET:
      return networkNames.MAINNET;
    case chainIds.POLYGON:
      return networkNames.POLYGON;
    case chainIds.ROPSTEN:
      return networkNames.ROPSTEN;
    case chainIds.RINKEBY:
      return networkNames.RINKEBY;
    case chainIds.KOVAN:
      return networkNames.KOVAN;
    default:
      return 'Unknown Network';
  }
}

const requiredNetworkName = process.env.REACT_APP_NETWORK_NAME;
export const networkId = chainIds[requiredNetworkName];
