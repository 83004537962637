import React, { useState, useEffect } from 'react';
import { ReactComponent as Send } from 'assets/icons/send.svg';
import { ReactComponent as Pencil } from 'assets/icons/pencil.svg';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';
import { HoverActionIcons } from './styles';
import Button from 'components/common/Button';
import { Popover as PopOver } from 'reactstrap';

import { makeSelectTokensDetails } from 'store/tokens/selectors';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { MODAL_NAME as DELETE_TEAM_MODAL } from './DeleteTeamModel';
import { makeSelectOwnerSafeAddress } from 'store/global/selectors';
import { makeSelectUpdating } from 'store/modify-people/selectors';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import modifyPeopleReducer from 'store/modify-people/reducer';
import modifyPeopleSaga from 'store/modify-people/saga';
import { MODAL_NAME as MASS_PAYOUT_MODAL } from 'components/Payments/MassPayoutModal';
import { setSelectedTeam } from 'store/transactions/actions';
import { show } from 'redux-modal';
import AddTeam from './AddTeam';
import { deleteTeam } from 'store/modify-team/actions';
import modifyTeamReducer from 'store/modify-team/reducer';
import modifyTeamSaga from 'store/modify-team/saga';
import DeleteTeamModal from './DeleteTeamModel';

const modifyPeople = 'modifyPeople';
const modifyTeamKey = 'modifyTeam';

function HoverActions({ shouldActive, data }) {
  useInjectReducer({ key: modifyPeople, reducer: modifyPeopleReducer });
  useInjectReducer({ key: modifyTeamKey, reducer: modifyTeamReducer });

  useInjectSaga({ key: modifyTeamKey, saga: modifyTeamSaga });
  useInjectSaga({ key: modifyPeople, saga: modifyPeopleSaga });

  const dispatch = useDispatch();

  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [tokensToRender, setTokensToRender] = useState([]);
  const [dataToEdit, setDataToEdit] = useState({
    token: 'DAI',
    teamName: '',
  });
  const tokenDetails = useSelector(makeSelectTokensDetails());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const updating = useSelector(makeSelectUpdating());

  const confirmDelete = () => {
    dispatch(
      deleteTeam({
        departmentId: data.departmentId,
        safeAddress: ownerSafeAddress,
      })
    );
  };

  useEffect(() => {
    setTokensToRender(tokenDetails);
  }, [tokenDetails]);

  useEffect(() => {
    if (editOpen || deleteOpen) {
      shouldActive(true);
    } else {
      shouldActive(false);
    }
  }, [editOpen, deleteOpen]);

  const showMassPayoutModal = team => {
    dispatch(setSelectedTeam(team));
    dispatch(show(MASS_PAYOUT_MODAL));
  };
  return (
    <HoverActionIcons className="d-flex">
      <Send
        className="icons"
        onClick={e => {
          e.stopPropagation();
          showMassPayoutModal({ label: data.name, value: data.departmentId });
        }}
      />

      <div id="Popover222" type="button">
        <Pencil className="icons" onClick={e => e.stopPropagation()} />
        <PopOver
          placement="bottom"
          isOpen={editOpen}
          target="Popover222"
          trigger="legacy"
          toggle={e => {
            setEditOpen(!editOpen);
            e.stopPropagation();
          }}
        >
          <AddTeam
            tokensToRender={tokensToRender}
            setDataToEdit={setDataToEdit}
            dataToEdit={dataToEdit}
            data={data}
            editOpen={editOpen}
            departmentId={data.departmentId}
            isEditMode={true}
            setEditOpen={setEditOpen}
          />
        </PopOver>
      </div>

      <div id="Popover322" type="button" onClick={e => e.stopPropagation()}>
        <Delete className="icons" style={{ marginRight: '0px' }} />

        <PopOver
          placement="bottom"
          isOpen={deleteOpen}
          target="Popover322"
          toggle={() => setDeleteOpen(!deleteOpen)}
          trigger="legacy"
        >
          <DeleteMenu
            confirmDelete={confirmDelete}
            dispatch={dispatch}
            deleteOpen={deleteOpen}
            setDeleteOpen={setDeleteOpen}
            data={data}
          />
        </PopOver>
      </div>
    </HoverActionIcons>
    // </div>
  );
}

const DeleteMenu = ({
  confirmDelete,
  deleteOpen,
  setDeleteOpen,
  data,
  dispatch,
}) => {
  const showDeleteTeamModal = () => {
    dispatch(show(DELETE_TEAM_MODAL, { departmentId: data.departmentId }));
    setDeleteOpen(!deleteOpen);
  };

  return (
    <div>
      <div className="customDropDown">
        <div className="title">Are you sure you want to delete</div>
        <div className="title">
          <strong>“{data.name}”</strong> team with {data.employees} members?
        </div>
        <div className="title-muted">
          <div>PS: Deleting a team will delete all</div>{' '}
          <div> constituent people in it.</div>
        </div>

        <div className="action-buttons">
          <Button
            type="button"
            width="12rem"
            style={{
              background: 'rgba(115, 103, 240, 0.1)',
              color: '#7367F0',
              fontWeight: 600,
            }}
            onClick={e => {
              setDeleteOpen(!deleteOpen);
              e.stopPropagation();
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            width="12rem"
            style={{
              background: '#FF4B55',
              color: '#FFFFFF',
              marginLeft: '10px',
              fontWeight: 600,
            }}
            onClick={showDeleteTeamModal}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HoverActions;
