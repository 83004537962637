import styled from 'styled-components/macro';

export const BulkDeletePeopleContainer = styled.div`
  padding: 4rem;
  position: relative;
  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  .container-blur {
    filter: blur(16px);
    z-index: 1;
  }
  .error-text {
    width: 100%;
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.7rem;
    color: #ff4b55;
    margin-top: 2rem;
  }
  .modal-title {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #373737;
    margin-bottom: 2rem;
  }
  .cta-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 4rem;
    button {
      margin: 0 1rem;
    }
  }
  .payee-row {
    background-color: #f9f9f9;
  }
`;
