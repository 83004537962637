export const CHOOSE_STEP = 'parcel/loginWizard/CHOOSE_STEP';
export const UPDATE_FORM = 'parcel/loginWizard/UPDATE_FORM';
export const SELECT_FLOW = 'parcel/loginWizard/SELECT_FLOW';
export const CHOOSE_SAFE = 'parcel/loginWizard/CHOOSE_SAFE';

export const GET_SAFES = 'parcel/loginWizard/GET_SAFES';
export const FETCH_SAFES = 'parcel/loginWizard/FETCH_SAFES';
export const GET_SAFES_SUCCESS = 'parcel/loginWizard/GET_SAFES_SUCCESS';
export const GET_SAFES_ERROR = 'parcel/loginWizard/GET_SAFES_ERROR';

export const GET_PARCEL_SAFES = 'parcel/loginWizard/GET_PARCEL_SAFES';
export const GET_PARCEL_SAFES_SUCCESS =
  'parcel/loginWizard/GET_PARCEL_SAFES_SUCCESS';
export const GET_PARCEL_SAFES_ERROR =
  'parcel/loginWizard/GET_PARCEL_SAFES_ERROR';

export const GET_SAFE_OWNERS = 'parcel/loginWizard/GET_SAFE_OWNERS';
export const GET_SAFE_OWNERS_SUCCESS =
  'parcel/loginWizard/GET_SAFE_OWNERS_SUCCESS';
export const GET_SAFE_OWNERS_ERROR = 'parcel/loginWizard/GET_SAFE_OWNERS_ERROR';
