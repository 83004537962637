import styled from 'styled-components/macro';

export default styled.div`
  text-align: center;
  width: 100%;
  height: 460px;

  .title {
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #373737;
    margin-top: 20px;
  }

  .subtitle {
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: #373737;
    padding: 10px 0;
  }
`;
