import styled from 'styled-components/macro';

export default styled.div`
  input[type='radio'] {
    opacity: 0;
    position: absolute;
    + .radio-label {
      &:before {
        content: '';
        background: #fff;
        border-radius: 50%;
        border: 1px solid #c7c7c7;
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: -0.2em;
        margin-right: 1rem;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        font-weight: bold;
        &:before {
          background-color: #7367f0;
          box-shadow: inset 0 0 0 2px #ffffff;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
        }
      }
    }
    ${
      '' /* &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px #fff;
          border-color: darken(#fff, 25%);
          background: darken(#fff, 25%);
        }
      }
    } */
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
  label {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
  }
`;
