import React from 'react';
import { ReleaseContainer } from './styles';
import Close from 'assets/icons/close.svg';

export default function ReleaseBar({ setShowRelease }) {
  return (
    <ReleaseContainer>
      <p>Payout across people section in multiple tokens is now live! 🔥</p>
      <img alt="close" src={Close} onClick={setShowRelease}></img>
    </ReleaseContainer>
  );
}
