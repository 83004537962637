import { createGlobalStyle } from 'styled-components';
import AvenirLTProBook from 'assets/fonts/AvenirLTProBook.otf';
import AvenirLTProHeavy from 'assets/fonts/AvenirLTProHeavy.otf';
import AvenirLTProMedium from 'assets/fonts/AvenirLTProMedium.otf';

export const lightTheme = {
  primary: '#7367f0',
  secondary: '#373737',
  body: '#FFF',
  text: '#363537',
  toggleBorder: '#FFF',
  background: '#FFF',
  logo: {
    color: '#7367F0',
  },
  border: {
    color: '#eee',
  },
  card: {
    backgroundColor: '#fff',
    color: '#363537',
    inner: {
      backgroundColor: '#fff',
      borderColor: '#f2f2f2',
      disabledBackgroundColor: 'f2f2f2',
    },
  },
};
export const darkTheme = {
  primary: '#7367f0',
  secondary: '#373737',
  body: '#363537',
  text: '#FAFAFA',
  toggleBorder: '#6B8096',
  background: '#292C35',
  logo: {
    color: '#7367F0',
  },
  border: {
    color: '#535766',
  },
  card: {
    backgroundColor: '#292C35',
    color: '#fff',
    inner: {
      backgroundColor: '#f1f1f1',
      borderColor: '#333',
      disabledBackgroundColor: 'f2f2f2',
    },
  },
};

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Avenir Pro';
    src: local('Avenir Pro'), local('AvenirPro'),
    url(${AvenirLTProBook}) format('opentype');
    font-weight: normal; 
    font-style: normal;
  }

  @font-face {
    font-family: 'Avenir Pro';
    src: local('Avenir Pro'), local('AvenirPro'),
    url(${AvenirLTProHeavy}) format('opentype');
    font-style: normal;
    font-weight: bold;
  }

  @font-face {
    font-family: 'Avenir Pro';
    src: local('Avenir Pro'), local('AvenirPro'),
    url(${AvenirLTProMedium}) format('opentype');
    font-style: normal;
    font-weight: 500;
  }

  html{
    background-color: #211D4A
  }

  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    margin: 0;
    font-size: 10px;
  }

  p, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
  }

  body {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    transition: all 0.50s linear;
    font-family: 'Avenir Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: normal;
  }

  body.fontLoaded {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  a {
    &:hover, &:focus {
      text-decoration: none;
      color: inherit;
      background: none;
    }
    &:active{
      background : none;
    }

  }

  .cursor-pointer{
    cursor: pointer;
  }

  .modal {
    color: #373737;
  }

  #root {
    background-color: #fafafa;
    height: 100%;
    width: 100%;
  }

  .app, .App {
    background-color: #fafafa;
    height: 100%;
    width: 100%;
  }

  .svg-inline--fa {
    font-size: 1.6em;
  }

  // Scaling/zooming depending on screen width
  // @media (max-width: 1500px) {
  //   #root, .modal-dialog {
  //    zoom: 85%;
  //  }

  //  .tooltip {
  //     zoom: 85%;
  //   }
  // }

  .text-green {
    color: #6cb44c;
  }

  .text-orange {
    color: #fcbc04;
  }
  
  .text-red {
    color: #ff4660;
  }

  .tooltip {
    font-size: 1.2rem;
  }

  .text-primary {
    color: ${({ theme }) => theme.primary} !important;
  }

  .text-bold {
    font-weight: 900 !important;
  }

  .form-control:focus {
    border-color: ${({ theme }) => theme.primary} !important;
    box-shadow: -1px 0 0 .2rem rgba(138, 128, 242, 0.25) !important;
  }

  .customDropDown {
    font-family: 'Avenir Pro' !important;
    // width: 300px;
    overflow: hidden;
    padding: 2em 2.2em;
    background: #ffffff;
    border: 1px solid rgba(235, 236, 239, 0.8);
    box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    .title {
      font-size: 14px;
      color: #373737;
    }
    .title-muted {
      margin-top: 1.2em;
      color: #8d8e91;
      font-size: 12px;
      line-height: 18px;
    }
    .action-buttons {
      margin-top: 2em;
      display: flex;
      justify-content: space-between;
    }
  }
  .customDropDownEdit {
    font-family: 'Avenir Pro' !important;
    width: 320px;
    overflow: hidden;
    padding: 2em 2.2em;
    background: #ffffff;
    box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(235, 236, 239, 0.8);
    .title {
      font-size: 1.4rem;
      color: #373737;
      margin-bottom: 18px;
      font-weight: 600;
    }
    .title-muted {
      margin-top: 1.2em;
      color: #8d8e91;
      font-size: 12px;
      line-height: 18px;
    }
    .action-buttons {
      margin-top: 2em;
      display: flex;
      justify-content: space-between;
    }
    .select-token-wrapper {
      margin-top: 10px;
      border: 1px solid rgba(235, 236, 239, 0.8) !important;
      border-radius: 5px;
      padding-right: 10px;
      align-items: center;
      justify-content: space-between;
      .MuiFilledInput-root {
        width: 100% !important;
        border: none !important;
        background-color: white !important;
        border-radius: 4px !important;
        font-size: 14px !important;
      }
      .MuiFilledInput-root {
        .Mui-focused {
          border: none !important;
          background-color: white !important;
          border-radius: 4px !important;
          font-size: 14px;
        }
      }
    }
  }
  .custom-token-select {
    margin-bottom: 18px;
  }
  .popover {
    max-width: none !important;
    border: none;
    font-family: inherit;
  }
  
  .MuiAutocomplete-paper {
    font-size: 16px !important;
  }
  .usd-token {
    margin-right: 0.25rem;
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block;
    text-align: center;
    line-height: 1.6rem;
    border-radius: 100%;
    border: 0.1px solid black;
  }
  .token-img {
    display: flex;
    align-items: center;
    img {
      margin-right: 3px;
    }
  }
  .MuiTab-textColorPrimary.Mui-selected {
    font-size: 14px;
    outline: none;
  }
  .MuiTab-textColorPrimary {
    font-size: 14px !important;
  }
  .custom-tab {
    .MuiTab-root {
      outline: none;
    }
    .MuiTab-wrapper {
      font-size: 14px;
      color: #7367f0;
    }
    .MuiTabs-indicator {
      background: #7367f0;
    }
  }
  .MuiFilledInput-root {
    border: 1px solid rgba(235, 236, 239, 0.8) !important;
    background-color: white !important;
    border-radius: 4px !important;
    font-size: 14px !important;
  }
  .MuiFilledInput-root {
    font-family: 'Avenir Pro' !important;
    .Mui-focused {
      border: 1px solid rgba(235, 236, 239, 0.8);
      background-color: white !important;
      border-radius: 4px !important;
      font-size: 14px;
    }
  }
  
  .MuiFilledInput-underline:before {
    border: none !important;
  }
  .MuiFilledInput-underline:after {
    border: none !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #7367f0 !important;
  }
  .MuiTab-root	{
    font-family: 'Avenir Pro' !important;
  }
  .MuiTextField-root	{
    font-family: 'Avenir Pro' !important;
  }
  .clickable-links {
    color: #373737;
    &:hover {
      text-decoration: underline;
    }
  }
  td {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .custom-modal-content {
    border-radius:0.8rem !important;
  }
`;

export default GlobalStyle;
