import Button from 'components/common/Button';
import Loading from 'components/common/Loading';
import { Table, TableBody, TableHead } from 'components/common/Table';
import TokenImg from 'components/common/TokenImg';
import { minifyAddress } from 'components/common/Web3Utils';
import { useMassPayout } from 'hooks';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectOwnerSafeAddress } from 'store/global/selectors';
import {
  addPeopleToBulkDelete,
  deletePeopleBulk,
} from 'store/modify-people/actions';
import modifyPeopleReducer from 'store/modify-people/reducer';
import modifyPeopleSaga from 'store/modify-people/saga';
import { makeSelectPeopleToBeDeleted } from 'store/modify-people/selectors';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { formatNumber } from 'utils/number-helpers';
import { BulkDeletePeopleContainer } from './styles';

const modifyPeople = 'modifyPeople';

export default function BulkDeletePeople({ handleHide, setSelectedPeople }) {
  const [errorState, setErrorState] = useState(false);

  useInjectReducer({ key: modifyPeople, reducer: modifyPeopleReducer });

  useInjectSaga({ key: modifyPeople, saga: modifyPeopleSaga });

  const peopleToBeDeleted = useSelector(makeSelectPeopleToBeDeleted());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());

  const dispatch = useDispatch();
  const { loadingTx } = useMassPayout();

  const renderTable = () => {
    return (
      <Table>
        <TableHead>
          <tr>
            <th style={{ width: '43%' }}>Name</th>
            <th style={{ width: '25%' }}>Disbursement</th>
            <th style={{ width: '27%' }}>Team</th>
          </tr>
        </TableHead>
        <TableBody>
          {peopleToBeDeleted &&
            peopleToBeDeleted.length > 0 &&
            peopleToBeDeleted.map(
              (
                {
                  firstName,
                  lastName,
                  address,
                  salaryAmount,
                  salaryToken,
                  departmentName,
                },
                idx
              ) => (
                <tr className={`payee-row`} key={`${firstName}-${idx}`}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div>
                        {`${firstName} ${lastName} (${minifyAddress(address)})`}
                      </div>
                    </div>
                  </td>
                  <td>
                    <TokenImg token={salaryToken} />
                    {formatNumber(salaryAmount, 5)} {salaryToken}
                  </td>
                  <td>{departmentName}</td>
                </tr>
              )
            )}
        </TableBody>
      </Table>
    );
  };

  return (
    <BulkDeletePeopleContainer>
      {loadingTx && (
        <div className="loader-container">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: '100%' }}
          >
            <Loading color="primary" width="100px" height="100px" />
          </div>
        </div>
      )}
      <div className={loadingTx ? 'container-blur' : ''}>
        <div className="modal-title">The following people will be removed</div>
        {renderTable()}
        {errorState && (
          <div className="error-text">
            Something went wrong. please check the details and retry.
          </div>
        )}

        <div className="cta-wrapper">
          <Button
            type="button"
            width="20rem"
            style={{
              minWidth: '18rem',
              background: 'rgba(115, 103, 240, 0.1)',
              color: '#7367F0',
              fontWeight: 600,
            }}
            onClick={() => {
              // Cancel and close modal
              dispatch(addPeopleToBulkDelete([]));
              handleHide();
            }}
          >
            Go back
          </Button>
          <Button
            type="submit"
            width="20rem"
            style={{ minWidth: '18rem', backgroundColor: '#FF4B55' }}
            disabled={false}
            onClick={() => {
              const addresses =
                peopleToBeDeleted &&
                peopleToBeDeleted.map(person => person.peopleId);
              if (addresses) {
                dispatch(deletePeopleBulk(ownerSafeAddress, addresses));
                dispatch(addPeopleToBulkDelete([]));
                setSelectedPeople([]);
              }
              handleHide();
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </BulkDeletePeopleContainer>
  );
}
