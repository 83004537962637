import React, { useState, useEffect, memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { routeTemplates } from 'constants/routes/templates';
import transactionsReducer from 'store/transactions/reducer';
import transactionsSaga from 'store/transactions/saga';
import { viewTransactions } from 'store/transactions/actions';
import { makeSelectTransactions } from 'store/transactions/selectors';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { makeSelectOwnerSafeAddress } from 'store/global/selectors';
import IncomingIcon from 'assets/icons/dashboard/incoming.svg';

import { RecentTx } from './styles';
import Img from 'components/common/Img';
import { TRANSACTION_STATUS } from 'constants/transactions';
import { replaceAddresswithMinification } from 'components/common/Web3Utils';

const transactionsKey = 'transactions';

const STATES = {
  EMPTY_STATE: 'EMPTY_STATE',
  TEAMMATES_ADDED: 'TEAMMATES_ADDED',
  TRANSACTION_EXECUTED: 'TRANSACTION_EXECUTED',
};

function RecentTxCard() {
  const [state, setState] = useState(STATES.EMPTY_STATE);
  const [transactionData, setTransactionData] = useState();

  useInjectReducer({ key: transactionsKey, reducer: transactionsReducer });

  useInjectSaga({ key: transactionsKey, saga: transactionsSaga });

  const dispatch = useDispatch();

  const transactions = useSelector(makeSelectTransactions());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());

  useEffect(() => {
    if (ownerSafeAddress) {
      dispatch(viewTransactions(ownerSafeAddress));
    }
  }, [dispatch, ownerSafeAddress]);

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      setState(STATES.TRANSACTION_EXECUTED);
      setTransactionData(transactions);
    } else {
      setState(STATES.EMPTY_STATE);
    }
  }, [transactions]);

  const stepTitle = useMemo(() => {
    switch (state) {
      case STATES.EMPTY_STATE:
        return `Recent Transactions`;
      case STATES.TRANSACTION_EXECUTED:
        return `Recent Transactions`;
      default:
        return null;
    }
  }, [state]);

  const linkByState = useMemo(() => {
    switch (state) {
      case STATES.EMPTY_STATE:
        return routeTemplates.dashboard.transactions;
      case STATES.TRANSACTION_EXECUTED:
        return routeTemplates.dashboard.transactions;
      default:
        return null;
    }
  }, [state]);

  const renderStatusText = status => {
    switch (status) {
      case TRANSACTION_STATUS.COMPLETED:
        return <div className="text-green">Completed</div>;
      case TRANSACTION_STATUS.PENDING:
        return <div className="text-orange">Pending</div>;
      case TRANSACTION_STATUS.FAILED:
        return <div className="text-red">Failed</div>;
      default:
        return null;
    }
  };

  const renderTx = ({
    paidTo,
    createdOn,
    fiatValue,
    tokenCurrency,
    tokenValue,
    transactionId,
    status,
  }) => {
    let displayValue = '';

    if (!(tokenValue === -1 || fiatValue === -1 || tokenCurrency === '_nil_')) {
      displayValue = (
        <>
          <div className="top">- ${parseFloat(fiatValue).toFixed(2)}</div>
          <div className="bottom">
            {parseFloat(tokenValue).toFixed(2)} {tokenCurrency}
          </div>
        </>
      );
    }

    return (
      <div className="tx" key={transactionId}>
        <div className="tx-info">
          <Img src={IncomingIcon} alt="tx-icon" className="mr-4" />
          <div>
            <div className="top">{replaceAddresswithMinification(paidTo)}</div>
            <div className="bottom">
              {format(new Date(createdOn), 'dd MMM yyyy')}
            </div>
          </div>
        </div>
        <div className="tx-amounts">{displayValue}</div>
        <div className="tx-status">{renderStatusText(status)}</div>
      </div>
    );
  };

  return (
    <RecentTx>
      <div className="title-container">
        <div className="title">{stepTitle}</div>
        <Link to={linkByState} className="view">
          View All
        </Link>
      </div>
      <div className="tx-container">
        {state === STATES.EMPTY_STATE && (
          <div className="no-tx-info">
            You have not made any transactions yet
          </div>
        )}
        {state === STATES.TRANSACTION_EXECUTED &&
          transactionData &&
          transactionData.slice(0, 5).map(tx => renderTx(tx))}
      </div>
    </RecentTx>
  );
}

export default memo(RecentTxCard);
