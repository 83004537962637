import styled from 'styled-components/macro';

export default styled.div`
  height: 5rem;
  padding: 0 2.4rem;
  justify-content: space-between;
  border-bottom: 0.1rem solid #dddcdc;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 999;

  .nav-icon {
    display: none;
  }
  @media (max-width: 978px) {
    .nav-icon {
      grid-area: icon;
      display: inline;
      /* padding: 0 1em; */
      cursor: pointer;
      margin-right: 1.9rem;
    }
  }

  .parcel-logo {
    img {
      height: 2.8rem;
      width: 9.6rem;
    }
  }

  .support-container {
    padding: 0 2.4rem 0 1.8rem;
    border-left: 0.1rem solid #dddcdc;
    height: 100%;

    img {
      width: 2rem;
      height: 2rem;
      margin: 0 1.2rem 0.2rem 0;
    }

    div {
      font-weight: 500;
      font-size: 1.4rem;
      color: #373737;
    }
  }
  .wallet-details-container {
    position: relative;
    border-left: 0.1rem solid #dddcdc;
    padding: 0 1.7rem;
    height: 100%;

    .wallet-logo {
      width: 2.8rem;
    }

    .wallet-details {
      margin-left: 1.3rem;

      .name {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        color: #8d8e91;
      }
      .address {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #373737;
        margin-top: 0.3rem;
      }
    }
    .actions-container {
      position: relative;
      margin-left: 22px;

      .dropdown {
        margin-left: 1.3rem;
        color: #8d8e91;
        cursor: pointer;
      }

      .disconnect-dropdown {
        position: absolute;
        top: 2.5rem;
        left: -7rem;
        min-width: 15rem;
        background: #ffffff;
        border: 0.1rem solid #dddcdc;
        box-sizing: border-box;
        box-shadow: 1rem 1rem 2rem rgba(170, 170, 170, 0.2);
        border-radius: 0.4rem;
        transition: opacity 0.15s linear;
        opacity: 0;
        height: 0;
        overflow: hidden;
        visibility: hidden;

        &.show {
          visibility: visible;
          opacity: 1;
          height: auto;
          z-index: 3;
        }

        .disconnect-option {
          padding: 1.5rem;
          border-bottom: 0.1rem solid #f1f0fd;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .icon {
            margin-left: 2.5rem;
            width: 1.6rem;
          }

          .name {
            font-weight: 500;
            font-size: 14px;
            color: #373737;
          }
        }
      }
    }
  }
`;
