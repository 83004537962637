import React, { useEffect, useState, useMemo } from 'react';
import { cryptoUtils } from 'parcel-sdk';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useActiveWeb3React, useLocalStorage, useMassPayout } from 'hooks';
import Button from 'components/common/Button';
import multisigReducer from 'store/multisig/reducer';
import multisigSaga from 'store/multisig/saga';
import {
  confirmMultisigTransaction,
  submitMultisigTransaction,
  getMultisigTransactionById,
  clearMultisigTransactionHash,
} from 'store/multisig/actions';
import {
  makeSelectFetching,
  makeSelectMultisigTransactionHash,
  makeSelectConfirmed,
  makeSelectUpdating,
  makeSelectMultisigTransactionDetails,
  makeSelectMultisigExecutionAllowed,
  makeSelectAwaitingGnosisSync,
} from 'store/multisig/selectors';
import safeReducer from 'store/safe/reducer';
import safeSaga from 'store/safe/saga';
import metaTxReducer from 'store/metatx/reducer';
import metaTxSaga from 'store/metatx/saga';
import { getMetaTxEnabled } from 'store/metatx/actions';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { getDecryptedDetails } from 'utils/encryption';
import {
  makeSelectOwnerSafeAddress,
  makeSelectThreshold,
  makeSelectSafeOwners,
  makeSelectOrganisationType,
  makeSelectIsMetaTxEnabled,
} from 'store/global/selectors';
import { makeSelectIsMetaTxEnabled as makeSelectIsMetaTxLimitAllowed } from 'store/metatx/selectors';
import Loading from 'components/common/Loading';
import Img from 'components/common/Img';
import { Stepper, StepCircle } from 'components/common/Stepper';
import addresses from 'constants/addresses';
import { InfoCard } from 'components/People/styles';
import {
  ConfirmSection,
  FinalStatus,
  DescriptionCard,
  DisbursementCard,
} from './styles';
import DisbursementDetails from './DisbursementDetails';
import Summary from './Summary';
import getTransactionReceipts from 'utils/getTransactionReceipts';
import { getFinalMetaTransactionHash } from 'utils/meta-tx-helpers';
import ReactTooltip from 'react-tooltip';
import WarningIconSVG from 'assets/icons/warning.svg';
import LinkIcon from 'assets/icons/dashboard/link-icon-dark.svg';
import { networkId } from 'constants/networks';
import { viewTransactions } from 'store/transactions/actions';
import MetaTxConfirmationModal from 'components/Payments/MetaTxConfirmationModal';
import { Popover as PopOver } from 'reactstrap';

const multisigKey = 'multisig';
const safeKey = 'safe';
const metaTxKey = 'metatx';

const { MULTISEND_ADDRESS } = addresses;

export default function MultiSigTransactions() {
  const [encryptionKey] = useLocalStorage('ENCRYPTION_KEY');
  const [openMetaConfirmation, setOpenMetaConfirmation] = useState(false);
  const [confirmedMetaTx, setConfirmedMetaTx] = useState(true);
  const { account, library } = useActiveWeb3React();
  const {
    txHash,
    loadingTx,
    // submitMassPayout,
    confirmMassPayout,
    executeMassPayout,
    confirmTxData,
    setConfirmTxData,
    txData,
    setTxData,
    approving,
    setApproving,
    rejecting,
    setRejecting,
    executing,
    setExecuting,
    loadingMetaTx,
    setLoadingMetaTx,
  } = useMassPayout();

  // Reducers
  useInjectReducer({ key: multisigKey, reducer: multisigReducer });
  useInjectReducer({ key: safeKey, reducer: safeReducer });
  useInjectReducer({ key: metaTxKey, reducer: metaTxReducer });

  // Sagas
  useInjectSaga({ key: multisigKey, saga: multisigSaga });
  useInjectSaga({ key: safeKey, saga: safeSaga });
  useInjectSaga({ key: metaTxKey, saga: metaTxSaga });

  const dispatch = useDispatch();
  const params = useParams();

  const isMetaEnabled = useSelector(makeSelectIsMetaTxEnabled());
  const loading = useSelector(makeSelectFetching());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const safeOwners = useSelector(makeSelectSafeOwners());
  const threshold = useSelector(makeSelectThreshold());
  const confirmedStatus = useSelector(makeSelectConfirmed());
  const updating = useSelector(makeSelectUpdating());
  const transactionDetails = useSelector(
    makeSelectMultisigTransactionDetails()
  );
  const executionAllowed = useSelector(makeSelectMultisigExecutionAllowed());
  const organisationType = useSelector(makeSelectOrganisationType());
  const awaitingGnosisSync = useSelector(makeSelectAwaitingGnosisSync());
  const txHashFromMetaTx = useSelector(makeSelectMultisigTransactionHash());
  const isMetaTxLimitAllowed = useSelector(makeSelectIsMetaTxLimitAllowed());

  useEffect(() => {
    if (ownerSafeAddress) {
      dispatch(getMetaTxEnabled(ownerSafeAddress));
    }
  }, [dispatch, ownerSafeAddress]);

  useEffect(() => {
    const transactionId = params && params.transactionId;
    if (ownerSafeAddress && transactionId) {
      dispatch(getMultisigTransactionById(ownerSafeAddress, transactionId));
    }
  }, [dispatch, ownerSafeAddress, params]);

  useEffect(() => {
    if (txHashFromMetaTx && isMetaEnabled && isMetaTxLimitAllowed) {
      setLoadingMetaTx(true);
      setExecuting(true);
      dispatch(clearMultisigTransactionHash());
      const intervalId = setInterval(async () => {
        const newMetaHash = await getFinalMetaTransactionHash(
          txHashFromMetaTx,
          networkId
        );
        if (newMetaHash) {
          getTransactionReceipts(
            library,
            dispatch,
            ownerSafeAddress,
            transactionDetails.txDetails.transactionId,
            newMetaHash,
            true
          );
          setTimeout(() => {
            setLoadingMetaTx(false);
            setExecuting(false);
          }, 5000);

          clearInterval(intervalId);
        }
      }, 3000);
    }
    // eslint-disable-next-line
  }, [dispatch, txHashFromMetaTx, isMetaEnabled, isMetaTxLimitAllowed]);

  useEffect(() => {
    if (txData && transactionDetails && account) {
      dispatch(
        submitMultisigTransaction({
          safeAddress: ownerSafeAddress,
          fromAddress: account,
          transactionId: transactionDetails.txDetails.transactionId,
          txData: txData,
          transactionHash: txHash || '',
          isMetaEnabled,
          isMetaTxLimitAllowed,
        })
      );
      if (!isMetaEnabled || !isMetaTxLimitAllowed) {
        getTransactionReceipts(
          library,
          dispatch,
          ownerSafeAddress,
          transactionDetails.txDetails.transactionId,
          txHash,
          true
        );
      }
      setTxData('');
    }
  }, [
    dispatch,
    library,
    txHash,
    txData,
    transactionDetails,
    ownerSafeAddress,
    setTxData,
    account,
    params,
    isMetaEnabled,
  ]);

  useEffect(() => {
    if (confirmTxData && transactionDetails) {
      dispatch(
        confirmMultisigTransaction({
          safeAddress: ownerSafeAddress,
          transactionId: transactionDetails.txDetails.transactionId,
          txData: confirmTxData,
        })
      );
      setConfirmTxData('');
    }
  }, [
    dispatch,
    confirmTxData,
    transactionDetails,
    ownerSafeAddress,
    setConfirmTxData,
  ]);

  useEffect(() => {
    if (confirmedStatus) {
      const transactionId = params && params.transactionId;
      dispatch(getMultisigTransactionById(ownerSafeAddress, transactionId));
    }
  }, [confirmedStatus, ownerSafeAddress, params, dispatch]);

  const { hasApproved, hasRejected } = useMemo(() => {
    const approvals = [];
    const rejections = [];

    if (transactionDetails) {
      transactionDetails.confirmations.forEach(confirmation => {
        const { owner, approved } = confirmation;
        if (approved) {
          approvals.push(owner);
        } else {
          rejections.push(owner);
        }
      });
    }
    const hasApproved = approvals.includes(account);
    const hasRejected = rejections.includes(account);

    return { approvals, rejections, hasApproved, hasRejected };
  }, [transactionDetails, account]);

  const renderFinalStatus = (
    confirmedCount,
    rejectedCount,
    isExecuted,
    isSuccessful,
    isExecutedApproved
  ) => {
    if (
      awaitingGnosisSync &&
      (isSuccessful === null || isSuccessful === false)
    ) {
      return (
        <div className="pending">
          <Loading
            style={{ display: 'inline', marginRight: '1rem' }}
            width="1.6rem"
            height="1.6rem"
          />
          Syncing with Gnosis
        </div>
      );
    } else {
      if (confirmedCount < threshold && rejectedCount < threshold) {
        return <div className="pending">Pending</div>;
      } else if (
        confirmedCount >= threshold &&
        isSuccessful &&
        isExecutedApproved
      ) {
        return <div className="success">Success</div>;
      } else if (rejectedCount >= threshold && isSuccessful) {
        return <div className="rejected">Rejected</div>;
      } else if (
        (confirmedCount >= threshold || rejectedCount >= threshold) &&
        !isExecuted
      ) {
        return <div className="pending">Awaiting Execution</div>;
      } else {
        return <div className="failed">Failed</div>;
      }
    }
  };

  const getStatusText = (approved, rejected) => {
    if (approved && rejected) {
      return 'Approved & Rejected';
    } else if (approved) {
      return 'Approved';
    } else if (rejected) {
      return 'Rejected';
    } else {
      if (transactionDetails.isExecuted && transactionDetails.isSuccessful) {
        return 'Did not vote';
      } else {
        return 'Pending';
      }
    }
  };

  const getStatusColor = (owner, approved, rejected) => {
    // if both: green-red
    if (approved && rejected) return ['#71B353', '#FF4B55'];
    // green -> approved
    if (approved) return '#6cb44c';
    // red -> rejected
    else if (rejected) return '#ff4660';
    // purple -> pending and current account
    else if (account && owner === account) return '#7367f0';
    // yellow -> pending
    return '#fcbc04';
  };

  const renderConfirmationStatus = confirmations => {
    if (!confirmations || !confirmations.length) return;

    const statuses =
      safeOwners &&
      safeOwners.map(safeOwner => {
        let confirmedOwner = {};
        const confirmedOwners = confirmations.filter(
          c => c.owner === safeOwner.owner
        );
        // has both approved and rejected, merging both confirmations
        if (confirmedOwners.length > 1) {
          confirmedOwner = {
            ...confirmedOwners[0],
            approved: true,
            rejected: true,
          };
        } else {
          confirmedOwner = confirmedOwners[0];
        }

        if (confirmedOwner)
          return {
            ...confirmedOwner,
            title: cryptoUtils.decryptDataUsingEncryptionKey(
              confirmedOwner.ownerInfo.name,
              encryptionKey,
              organisationType
            ),
            subtitle: getStatusText(
              confirmedOwner.approved,
              confirmedOwner.rejected
            ),
            backgroundColor: getStatusColor(
              confirmedOwner.ownerInfo.owner,
              confirmedOwner.approved,
              confirmedOwner.rejected
            ),
          };
        return {
          ownerInfo: safeOwner,
          title: cryptoUtils.decryptDataUsingEncryptionKey(
            safeOwner.name,
            encryptionKey,
            organisationType
          ),
          subtitle: getStatusText(safeOwner.approved, safeOwner.rejected),
          backgroundColor: getStatusColor(
            safeOwner.owner,
            safeOwner.approved,
            safeOwner.rejected
          ),
        };
      });

    return statuses.map(
      ({ ownerInfo, title, subtitle, backgroundColor }, idx) => (
        <StepCircle
          key={`${ownerInfo.owner}-${idx}`}
          title={title}
          subtitle={subtitle}
          backgroundColor={backgroundColor}
        />
      )
    );
  };

  const openMetaTxModal = approval => {
    setOpenMetaConfirmation(true);
    setConfirmedMetaTx(approval);
  };
  const approveTransaction = async () => {
    const {
      // safe,
      // to,
      value,
      data,
      // operation,
      gasToken,
      safeTxGas,
      baseGas,
      gasPrice,
      refundReceiver,
      nonce,
      safeTxHash,
      executor,
      origin,
      // confirmedCount,
      confirmations,
      // txDetails,
    } = transactionDetails;

    try {
      setApproving(true);
      // call confirm api
      await confirmMassPayout({
        safe: ownerSafeAddress,
        to: MULTISEND_ADDRESS,
        value,
        data,
        operation: 1,
        gasToken,
        safeTxGas,
        baseGas,
        gasPrice,
        refundReceiver,
        nonce,
        safeTxHash,
        executor,
        origin,
        confirmations,
      });
      setApproving(false);
    } catch (error) {
      setApproving(false);
    }
  };

  const rejectTransaction = async () => {
    const {
      safe,
      // to,
      value,
      // data,
      // operation,
      gasToken,
      safeTxGas,
      baseGas,
      gasPrice,
      refundReceiver,
      nonce,
      // executionDate,
      // submissionDate,
      // modified, //date
      // blockNumber,
      // transactionHash,
      safeTxHash,
      executor,
      // isExecuted,
      // isSuccessful,
      // ethGasPrice,
      // gasUsed,
      // fee,
      origin,
      // confirmationsRequired,
      // signatures,
      // rejectedCount,
      confirmations,
      // txDetails,
    } = transactionDetails;

    try {
      setRejecting(true);
      // call confirm api with reject params
      await confirmMassPayout({
        safe,
        to: safe,
        value,
        data: '0x',
        operation: 0,
        gasToken,
        safeTxGas,
        baseGas,
        gasPrice,
        refundReceiver,
        nonce,
        safeTxHash,
        executor,
        origin,
        confirmations,
      });
      setRejecting(false);
    } catch (error) {
      setRejecting(false);
    }
  };
  const executeMetaTx = () => {
    executeTransaction(confirmedMetaTx)();
  };
  const executeTransaction = isApproval => async () => {
    const {
      // safe,
      // to,
      value,
      data,
      // operation,
      gasToken,
      safeTxGas,
      baseGas,
      gasPrice,
      refundReceiver,
      nonce,
      safeTxHash,
      executor,
      origin,
      // confirmedCount,
      // rejectedCount,
      confirmations,
      // txDetails,
    } = transactionDetails;

    try {
      setExecuting(true);
      await executeMassPayout(
        {
          safe: ownerSafeAddress,
          to: MULTISEND_ADDRESS,
          value,
          data,
          operation: 1,
          gasToken,
          safeTxGas,
          baseGas,
          gasPrice,
          refundReceiver,
          nonce,
          safeTxHash,
          executor,
          origin,
          confirmations,
        },
        isApproval,
        threshold,
        isMetaEnabled,
        isMetaTxLimitAllowed
      );
      dispatch(viewTransactions(ownerSafeAddress));
      setExecuting(false);
    } catch (error) {
      setExecuting(false);
    }
  };

  const renderConfirmSection = () => {
    const { isExecuted, confirmedCount, rejectedCount } = transactionDetails;

    let showConfirmSection = false;

    if (isExecuted || awaitingGnosisSync) {
      showConfirmSection = false;
    } else {
      showConfirmSection = true;
    }

    const redirectTo = () => {
      return networkId === 4
        ? `https://rinkeby.gnosis-safe.io/app/#/safes/${ownerSafeAddress}/transactions`
        : networkId === 137
        ? `https://polygon.gnosis-safe.io/app/#/safes/${ownerSafeAddress}/transactions`
        : `https://gnosis-safe.io/app/#/safes/${ownerSafeAddress}/transactions`;
    };

    if (showConfirmSection) {
      return (
        <ConfirmSection>
          <div>
            <ReactTooltip place="bottom" multiline={true} />
            <div className="section">
              <div className="left">
                <div className="title">Approval Status</div>
                <div className="subtitle">
                  {confirmedCount}/{threshold} approved
                </div>
              </div>
              <div className="right">
                <div className="buttons">
                  {confirmedCount < threshold && (
                    <div className="approve-button">
                      <Button
                        type="button"
                        width="15rem"
                        onClick={approveTransaction}
                        disabled={
                          loadingTx || updating || hasApproved || loadingMetaTx
                        }
                        loading={approving}
                      >
                        Approve
                      </Button>
                    </div>
                  )}
                  {confirmedCount >= threshold && (
                    <div>
                      <div className="approve-button">
                        {!executionAllowed && (
                          <div className="warning">
                            <Img
                              src={WarningIconSVG}
                              alt="warning"
                              data-tip="You have not executed your older transactions.<br />
                          You need to execute transactions in order of creation."
                            />
                          </div>
                        )}
                        <Button
                          type="button"
                          width="15rem"
                          onClick={() => {
                            isMetaTxLimitAllowed && isMetaEnabled
                              ? openMetaTxModal(true)
                              : executeTransaction(true)();
                          }}
                          disabled={
                            loadingTx ||
                            updating ||
                            !executionAllowed ||
                            loadingMetaTx
                          }
                          loading={executing}
                        >
                          Execute
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="section">
              <div className="left">
                <div className="title">Rejection Status</div>
                <div className="subtitle">
                  {rejectedCount}/{threshold} rejected
                </div>
              </div>
              <div className="right">
                <div className="buttons">
                  {rejectedCount < threshold && (
                    <div className="reject-button">
                      <Button
                        type="button"
                        width="15rem"
                        onClick={rejectTransaction}
                        disabled={
                          loadingTx || updating || hasRejected || loadingMetaTx
                        }
                        loading={rejecting}
                      >
                        Reject
                      </Button>
                    </div>
                  )}
                  {rejectedCount >= threshold && (
                    <div className="reject-button">
                      {!executionAllowed && (
                        <div className="warning">
                          {/* <Tooltip
                          style={{
                            background: '#F8F8F8',
                            color: '#373737',
                            border: '1px solid #DDDCDC',
                            borderRadius: '4px',
                          }}
                          isOpen={isWarningRejectionOpen}
                          toggle={toggleWarningRejectionOpen}
                          placement="bottom"
                          target="warningRejection"
                        >
                          You have not executed your older transactions. You
                          need to execute transactions in order of creation.
                        </Tooltip> */}
                          <Img
                            src={WarningIconSVG}
                            alt="warning"
                            data-tip="You have not executed your older transactions.<br />
                          You need to execute transactions in order of creation."
                          />
                        </div>
                      )}
                      <Button
                        type="button"
                        width="15rem"
                        onClick={() => {
                          isMetaTxLimitAllowed && isMetaEnabled
                            ? openMetaTxModal(false)
                            : executeTransaction(false)();
                        }}
                        disabled={
                          loadingTx ||
                          updating ||
                          !executionAllowed ||
                          loadingMetaTx
                        }
                        loading={executing}
                      >
                        Execute
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {!executionAllowed && (
            <div className="note-container d-flex align-items-center">
              <div className="note d-flex align-items-center">
                <div className="status-indicator"></div>
                Some of your previously created transactions are pending.
                Transactions need to be executed in order of creation.
              </div>
              <a
                href={redirectTo()}
                target="_blank"
                rel="noopenner noreferrer"
                className="cta d-flex align-items-center"
              >
                <Img src={LinkIcon} alt="link-icon" />
                Check on Gnosis
              </a>
            </div>
          )}
        </ConfirmSection>
      );
    } else {
      return null;
    }
  };

  const renderTransactionDetails = () => {
    if (!transactionDetails || !encryptionKey) return null;

    const {
      // transactionHash,
      // executor,
      isExecuted,
      isSuccessful,
      rejectedCount,
      confirmedCount,
      confirmations,
      txDetails,
      isExecutedApproved,
    } = transactionDetails;

    const {
      // transactionId,
      // addresses,
      transactionHash: txDetailsHash,
      // safeAddress,
      tokenCurrency,
      to,
      transactionMode,
      description,
      // createdBy,
      paymentType,
    } = txDetails;

    if (loading || updating)
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: '40rem' }}
        >
          <Loading color="primary" width="3rem" height="3rem" />
        </div>
      );

    if (!transactionDetails || !encryptionKey) return null;

    const paidTeammates = getDecryptedDetails(
      to,
      encryptionKey,
      organisationType
    );

    const isTxSubmitted =
      confirmedCount >= threshold || rejectedCount >= threshold;
    const isTxForGasChange = paymentType === 'gas';
    const isTxForPayeeRemoval = paymentType === 'removal';

    return (
      <div>
        <InfoCard style={{ minHeight: '0' }}>
          <div>
            <div className="title mb-0">Transaction Status</div>
            {!isTxSubmitted && (
              <div className="subtitle mt-2">
                Transaction requires the confirmation of{' '}
                <span className="text-bold">
                  {threshold} out of {safeOwners.length}
                </span>{' '}
                owners
              </div>
            )}
          </div>
          <FinalStatus>
            {renderFinalStatus(
              confirmedCount,
              rejectedCount,
              isExecuted,
              isSuccessful,
              isExecutedApproved
            )}
          </FinalStatus>
        </InfoCard>
        {isMetaTxLimitAllowed && isMetaEnabled && openMetaConfirmation && (
          <div
            style={{
              position: 'absolute',
              zIndex: 1,
              left: '40%',
              top: '40%',
              marginRight: '40%',
              width: '100%',
            }}
          >
            <MetaTxConfirmationModal
              setOpenMetaConfirmation={setOpenMetaConfirmation}
              executeMetaTx={executeMetaTx}
            />
          </div>
        )}
        <InfoCard className="d-flex justify-content-center align-items-center mt-3">
          <Stepper count={safeOwners.length}>
            {renderConfirmationStatus(confirmations)}
          </Stepper>
        </InfoCard>

        {renderConfirmSection()}

        <DescriptionCard>
          <div className="title">Description</div>
          <div className="subtitle">
            {description ? description : `No description given...`}
          </div>
        </DescriptionCard>

        {!isTxForGasChange && (
          <DisbursementCard>
            <div className="title">
              {isTxForPayeeRemoval
                ? 'The following automations will be deleted'
                : 'Disbursement Details'}
            </div>
            <DisbursementDetails
              paidTeammates={paidTeammates}
              transactionMode={transactionMode}
              tokenCurrency={tokenCurrency}
              paymentType={paymentType}
            />
          </DisbursementCard>
        )}
        {txDetailsHash && (
          <Summary txDetails={txDetails} paidTeammates={paidTeammates} />
        )}
      </div>
    );
  };

  return renderTransactionDetails();
}
