import React from 'react';
import Img from 'components/common/Img';

import maintenancePNG from 'assets/images/NewLogin/maintenance.png';
import parcelLogoSVG from 'assets/images/NewLogin/parcel-logo.svg';

import { LoginPage } from './styles';

const Login = () => {
  return (
    <LoginPage>
      <div className="login-container">
        <div>
          <div className="logo-container">
            <Img className="logo" src={parcelLogoSVG} alt="parcel" />
          </div>

          <div className="container-small">
            <div className="title">
              <div className="text">We are undergoing server maintainance</div>
              <div className="light-text">Duration - apx. 2 hours</div>
            </div>
            <div className="content">
              <div className="maintenance-image">
                <Img src={maintenancePNG} alt="maintenance" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginPage>
  );
};

export default Login;
