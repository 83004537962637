import styled from 'styled-components/macro';

export default styled.div`
  .status {
    height: 2.8rem;
    padding: 2rem 0;
    border-radius: 0.4rem 0.4rem 0 0;
    background-color: #6cb44c;
    justify-content: center;

    img {
      margin-bottom: 0.2rem;
    }

    .success,
    .fail {
      img {
        width: 1.6rem;
        height: 1.6rem;
      }
      font-weight: 700;
      font-size: 1.2rem;
      text-align: center;
      margin: 0 0 0 1rem;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #ffffff;
    }

    .remove-file {
      font-size: 1.2rem;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #aaaaaa;
      cursor: pointer;
    }
  }

  .file-name {
    font-size: 2.2rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #373737;
    padding: 4rem 2rem 2rem 2rem;
  }
`;
