/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as InfoIcon } from 'assets/icons/dashboard/info-icon.svg';
import GelatoLogo from 'assets/icons/gelato.svg';
import { ReactComponent as Pencil } from 'assets/icons/pencil.svg';
import Button from 'components/common/Button';
import CheckBox from 'components/common/CheckBox';
import ErrorText from 'components/common/ErrorText';
import { Input, Select, SelectToken } from 'components/common/Form';
import ParcelLoader from 'components/common/ParcelLoader';
import Radio from 'components/common/Radio';
import {
  Table,
  TableBody,
  TableDivider,
  TableHead,
  TableInfo,
  TableLoader,
} from 'components/common/Table';
import TokenImg from 'components/common/TokenImg';
import { getEtherscanLink } from 'components/common/Web3Utils';
import PaymentSchedule from 'components/PaymentSchedule';
import ScheduleFooter from 'components/ScheduleFooter';
import AutomationModuleABI from 'constants/abis/AutomationModule.json';
import ERC20ABI from 'constants/abis/ERC20.json';
import addresses from 'constants/addresses';
import { isPolygonMainnet, resetTimes } from 'constants/index';
import { networkId } from 'constants/networks';
import { TRANSACTION_MODES } from 'constants/transactions';
import {
  useActiveWeb3React,
  useContract,
  useLocalStorage,
  useMassPayout,
} from 'hooks';
import debounce from 'lodash.debounce';
import { cryptoUtils } from 'parcel-sdk';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  makeSelectIsMultiOwner,
  makeSelectOrganisationType,
  makeSelectOwnerSafeAddress,
  makeSelectThreshold,
  makeSelectIsMetaTxEnabled,
} from 'store/global/selectors';
import { getInvitations } from 'store/invitation/actions';
import invitationReducer from 'store/invitation/reducer';
import invitationSaga from 'store/invitation/saga';
import { getMetaTxEnabled } from 'store/metatx/actions';
import metaTxReducer from 'store/metatx/reducer';
import metaTxSaga from 'store/metatx/saga';
// import { makeSelectIsMetaTxEnabled } from 'store/metatx/selectors';
import modifyPeopleReducer from 'store/modify-people/reducer';
import modifyPeopleSaga from 'store/modify-people/saga';

import viewTeamsReducer from 'store/view-teams/reducer';
import { getTeams } from 'store/view-teams/actions';
import viewTeamsSaga from 'store/view-teams/saga';
import viewPeopleSaga from 'store/view-people/saga';
import viewPeopleReducer from 'store/view-people/reducer';
import { getPeopleByTeam } from 'store/view-people/actions';
import {
  makeSelectTeams,
  makeSelectLoading as makeSelectTeamsLoading,
  makeSelectTeamIdToDetailsMap,
} from 'store/view-teams/selectors';
import {
  makeSelectPeopleByTeam,
  makeSelectLoadingPeopleByTeam,
} from 'store/view-people/selectors';
import transactionsReducer from 'store/transactions/reducer';
import transactionsSaga from 'store/transactions/saga';
import {
  makeSelectError as makeSelectErrorInCreateTx,
  makeSelectMetaTransactionHash,
  makeSelectSelectedTeam,
} from 'store/transactions/selectors';
import {
  addTransaction,
  clearTransactionHash,
} from 'store/transactions/actions';
import { getNonce } from 'store/safe/actions';

import safeReducer from 'store/safe/reducer';
import safeSaga from 'store/safe/saga';
import {
  makeSelectLoading as makeSelectLoadingSafeDetails,
  makeSelectNonce,
} from 'store/safe/selectors';

import { createMultisigTransaction } from 'store/multisig/actions';
import multisigSaga from 'store/multisig/saga';
import multisigReducer from 'store/multisig/reducer';
import { makeSelectUpdating as makeSelectAddTxLoading } from 'store/multisig/selectors';

// import { getMetaTxEnabled } from 'store/metatx/actions';
import { makeSelectIsMetaTxEnabled as makeSelectIsMetaTxLimitAllowed } from 'store/metatx/selectors';

import { getTokens } from 'store/tokens/actions';

import {
  makeSelectLoading as makeSelectLoadingTokens,
  makeSelectPrices,
  makeSelectTokenList,
} from 'store/tokens/selectors';

import { getDecryptedDetails } from 'utils/encryption';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { formatNumber } from 'utils/number-helpers';
import { constructLabel } from 'utils/tokens';
import { getAmountFromWei } from 'utils/tx-helpers';
import groupall from '../../assets/icons/groupadd.svg';

import getTransactionReceipts from 'utils/getTransactionReceipts';
import { getFinalMetaTransactionHash } from 'utils/meta-tx-helpers';

import {
  EditConfirmation,
  MassPayoutContainer,
  PaymentSummary,
} from './styles';

// reducer/saga keys
const viewPeopleKey = 'viewPeople';
const viewTeamsKey = 'viewTeams';
const transactionsKey = 'transactions';
const safeKey = 'safe';
const multisigKey = 'multisig';
const invitationKey = 'invitation';
const metaTxKey = 'metatx';
const modifyPeopleKey = 'modifyPeople';

const payoutType = ['One Time', 'Recurring'];

const renderPayoutTypeLabel = val => {
  if (val === 'Recurring') {
    return (
      <div className="d-flex align-items-center gelato-wrapper">
        {val} <span>-</span>
        <img src={GelatoLogo} alt="gelato" />
      </div>
    );
  } else {
    return val;
  }
};

const { AUTOMATION_MODULE_ADDRESS, ZERO_ADDRESS } = addresses;

export default function Payments(props) {
  const [encryptionKey] = useLocalStorage('ENCRYPTION_KEY');
  const { register, handleSubmit, control, setValue, watch } = useForm({
    mode: 'onChange',
  });
  const { handleHide } = props;

  const selectedTeamId = watch('team') && watch('team').value;
  const selectedToken = watch('token') && watch('token').value;
  const selectedTeamName = watch('team') && watch('team').label;
  const paymentRepeatPattern =
    watch('repeatPattern') && watch('repeatPattern').toLowerCase();
  // const scheduleEndsOn = watch('scheduleEndsOn') && watch('scheduleEndsOn');
  // const scheduleEndsOnAfter = watch('endsOnInput') && watch('endsOnInput');
  // const firstPaymentDate =
  //   watch('firstPaymentDate') && watch('firstPaymentDate');
  const selectedPayoutType = (watch('payoutType') && watch('payoutType')) || '';

  const { library, account } = useActiveWeb3React();

  const automationModule = useContract(
    AUTOMATION_MODULE_ADDRESS,
    AutomationModuleABI
  );
  const erc20Contract = useContract(ZERO_ADDRESS, ERC20ABI);

  const [checked, setChecked] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [people, setPeople] = useState([]);
  const [temPeople, setTempPeople] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [submittedTx, setSubmittedTx] = useState(false);
  const [selectedTokenDetails, setSelectedTokenDetails] = useState();
  const [isSelectedTokenUSD, setIsSelectedTokenUSD] = useState();
  const [existingTokenDetails, setExistingTokenDetails] = useState();
  const [tokensDropdown, setTokensDropdown] = useState([]);
  const [teamsDropdown, setTeamsDropdown] = useState();
  const [tokenError, setTokenError] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [successState, setSuccessState] = useState(false);
  const [allAutomations, setAllAutomations] = useState({});
  const [loadingAutomatedTransactions, setLoadingAutomatedTransactions] =
    useState(false);
  const [editOn, setEditOn] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [copyAllIdx, setCopyAllIdx] = useState();
  const [metaTxHash, setMetaTxHash] = useState('');

  const {
    loadingTx,
    txHash,
    recievers,
    massPayout,
    teamsMassPayout,
    txData,
    setTxData,
    createAutomatedPayout,
    loadingMetaTx,
    setLoadingMetaTx,
  } = useMassPayout({ tokenDetails: selectedTokenDetails });

  // Reducers
  useInjectReducer({ key: viewPeopleKey, reducer: viewPeopleReducer });
  useInjectReducer({
    key: viewTeamsKey,
    reducer: viewTeamsReducer,
  });
  useInjectReducer({ key: transactionsKey, reducer: transactionsReducer });
  useInjectReducer({ key: safeKey, reducer: safeReducer });
  useInjectReducer({ key: multisigKey, reducer: multisigReducer });
  useInjectReducer({ key: invitationKey, reducer: invitationReducer });
  useInjectReducer({ key: metaTxKey, reducer: metaTxReducer });
  useInjectReducer({ key: modifyPeopleKey, reducer: modifyPeopleReducer });

  // Sagas
  useInjectSaga({ key: viewPeopleKey, saga: viewPeopleSaga });
  useInjectSaga({ key: viewTeamsKey, saga: viewTeamsSaga });
  useInjectSaga({ key: transactionsKey, saga: transactionsSaga });
  useInjectSaga({ key: safeKey, saga: safeSaga });
  useInjectSaga({ key: multisigKey, saga: multisigSaga });
  useInjectSaga({ key: invitationKey, saga: invitationSaga });
  useInjectSaga({ key: metaTxKey, saga: metaTxSaga });
  useInjectSaga({ key: modifyPeopleKey, saga: modifyPeopleSaga });

  const dispatch = useDispatch();
  const history = useHistory();

  // Selectors
  const allTeams = useSelector(makeSelectTeams());
  const loadingTeams = useSelector(makeSelectTeamsLoading());
  const loadingTeammates = useSelector(makeSelectLoadingPeopleByTeam());
  const teammates = useSelector(makeSelectPeopleByTeam());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const prices = useSelector(makeSelectPrices());
  const errorFromMetaTx = useSelector(makeSelectErrorInCreateTx());
  const addingTx = useSelector(makeSelectAddTxLoading());
  const nonce = useSelector(makeSelectNonce());
  const threshold = useSelector(makeSelectThreshold());
  const isMultiOwner = useSelector(makeSelectIsMultiOwner());
  const loadingSafeDetails = useSelector(makeSelectLoadingSafeDetails());
  const tokenList = useSelector(makeSelectTokenList());
  const loadingTokens = useSelector(makeSelectLoadingTokens());
  const organisationType = useSelector(makeSelectOrganisationType());
  const isMetaEnabled = useSelector(makeSelectIsMetaTxEnabled());
  const teamIdToDetailsMap = useSelector(makeSelectTeamIdToDetailsMap());
  const selectedTeam = useSelector(makeSelectSelectedTeam());
  const txHashFromMetaTx = useSelector(makeSelectMetaTransactionHash());
  const isMetaTxLimitAllowed = useSelector(makeSelectIsMetaTxLimitAllowed());
  // const peoples = useSelector(makeSelectPeople());

  const getERC20Contract = useCallback(
    contractAddress => {
      if (contractAddress) return erc20Contract.attach(contractAddress);
      return erc20Contract;
    },
    [erc20Contract]
  );

  const minFieldsForTransaction =
    people && selectedToken && selectedTokenDetails && selectedRows.length;

  useEffect(() => {
    if (ownerSafeAddress) {
      dispatch(getInvitations(ownerSafeAddress));
      dispatch(getNonce(ownerSafeAddress));
      dispatch(getTokens(ownerSafeAddress));
      dispatch(getMetaTxEnabled(ownerSafeAddress));
      handleCheckAll();
    }
  }, [ownerSafeAddress, dispatch]);

  useEffect(() => {
    let dropdownList = [];
    if (allTeams && allTeams.length > 0 && !teamsDropdown) {
      dropdownList = allTeams.map(({ departmentId, name }) => ({
        value: departmentId,
        label: name,
      }));

      setTeamsDropdown(dropdownList);
    }
  }, [allTeams, existingTokenDetails, teamsDropdown]);

  useEffect(() => {
    if (
      selectedTeamId &&
      teamIdToDetailsMap &&
      teamIdToDetailsMap[selectedTeamId]
    ) {
      setTokenError(false);
      const tokenInfo = teamIdToDetailsMap[selectedTeamId]?.tokenInfo;

      if (tokenInfo) {
        const existingToken =
          existingTokenDetails?.length &&
          existingTokenDetails.filter(
            ({ name }) => name === tokenInfo.symbol
          )[0];
        if (tokenInfo.symbol === 'USD') {
          setIsSelectedTokenUSD(true);
        } else {
          setIsSelectedTokenUSD(false);
        }

        if (existingToken) {
          setValue('token', {
            value: existingToken.name,
            label: constructLabel({
              token: existingToken.name,
              component: (
                <div>
                  {formatNumber(existingToken.balance)} {existingToken.name}
                </div>
              ),
              imgUrl: existingToken.icon,
            }),
          });
        } else {
          if (tokenInfo.symbol === 'USD') {
            setValue('token', {
              value: existingTokenDetails[0].name,
              label: constructLabel({
                token: existingTokenDetails[0].name,
                component: (
                  <div>
                    {formatNumber(existingTokenDetails[0].balance)}{' '}
                    {existingTokenDetails[0].name}
                  </div>
                ),
                imgUrl: existingTokenDetails[0].icon,
              }),
            });
          } else {
            setTokenError(
              `Please deposit ${tokenInfo.symbol} tokens into your safe and try again`
            );
            setValue('token', {
              value: tokenInfo.symbol,
              label: `0.00 ${tokenInfo.symbol}`,
            });
          }
        }
      } else {
        setTokenError(
          `Please deposit some tokens into your safe and try again`
        );
      }
    }
  }, [selectedTeamId, existingTokenDetails, setValue]); // eslint-disable-line

  useEffect(() => {
    if (selectedTeamId) {
      dispatch(getPeopleByTeam(ownerSafeAddress, selectedTeamId));
    }
  }, [selectedTeamId, dispatch, ownerSafeAddress]);

  useEffect(() => {
    if (tokenList && tokenList.length > 0 && !tokensDropdown.length) {
      setExistingTokenDetails(tokenList);
      setTokensDropdown(
        tokenList.map(details => ({
          metaValue: details.usd,
          value: details.name,
          label: constructLabel({
            token: details.name,
            component: (
              <div>
                {formatNumber(details.balance)} {details.name}
              </div>
            ),
            imgUrl: details.icon,
          }),
        }))
      );
    }
  }, [tokenList, tokensDropdown]);

  useEffect(() => {
    if (!allTeams) {
      dispatch(getTeams(ownerSafeAddress));
    }
  }, [dispatch, ownerSafeAddress, allTeams]);

  useEffect(() => {
    // reset to initial state
    setSelectedRows([]);
    setChecked([]);
    setIsCheckedAll(false);
    handleCheckAll();
  }, [selectedToken, selectedTeamId]);

  useEffect(() => {
    if (selectedTeamId && teammates) {
      const allRows = teammates.map(({ data, ...rest }) => ({
        ...getDecryptedDetails(data, encryptionKey, organisationType),
        ...rest,
      }));

      setPeople(allRows);
      setTempPeople(allRows);
    } else {
      setPeople([]);
      setTempPeople([]);
    }
  }, [selectedTeamId, teammates, encryptionKey, organisationType]);

  useEffect(() => {
    if (selectedToken && existingTokenDetails) {
      setSelectedTokenDetails(
        existingTokenDetails.filter(({ name }) => name === selectedToken)[0]
      );
    }
  }, [selectedToken, existingTokenDetails]);

  useEffect(() => {
    if (
      txHashFromMetaTx &&
      isMetaEnabled &&
      !isMultiOwner &&
      isMetaTxLimitAllowed
    ) {
      setLoadingMetaTx(true);
      dispatch(clearTransactionHash());
      const intervalId = setInterval(async () => {
        const newMetaHash = await getFinalMetaTransactionHash(
          txHashFromMetaTx,
          networkId
        );
        if (newMetaHash) {
          getTransactionReceipts(
            library,
            dispatch,
            ownerSafeAddress,
            '',
            newMetaHash,
            false
          );
          setMetaTxHash(newMetaHash);
          setLoadingMetaTx(false);

          clearInterval(intervalId);
        }
      }, 3000);
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    txHashFromMetaTx,
    isMetaEnabled,
    isMultiOwner,
    isMetaTxLimitAllowed,
  ]);

  const totalAmountToPay = useMemo(() => {
    if (!selectedRows.length) return 0;
    if (prices && teammates) {
      return selectedRows.reduce((total, { salaryAmount, salaryToken }) => {
        // TODO use Big.js to fix precision errors
        if (salaryToken === 'USD') {
          total += Number(salaryAmount);
        } else {
          total += prices[salaryToken] * salaryAmount;
        }

        return total;
      }, 0);
    }
  }, [prices, selectedRows, teammates, people]);

  const totalAmountInToken = useMemo(() => {
    if (!selectedRows.length) return 0;
    if (prices) {
      return selectedRows.reduce((total, { salaryAmount, salaryToken }) => {
        // TODO use Big.js to fix precision errors
        if (salaryToken === 'USD' && selectedTokenDetails) {
          total += salaryAmount / prices[selectedTokenDetails.name];
        } else {
          total += Number(salaryAmount);
        }
        return total;
      }, 0);
    }
  }, [prices, selectedRows, selectedTokenDetails]);

  useEffect(() => {
    if (txHash) {
      setSubmittedTx(true);
      if (
        encryptionKey &&
        recievers &&
        ownerSafeAddress &&
        // totalAmountToPay &&
        selectedTokenDetails &&
        account
      ) {
        let description = '';
        if (recievers?.length && recievers[0].description) {
          description = recievers[0].description;
        }
        const to = cryptoUtils.encryptDataUsingEncryptionKey(
          JSON.stringify(recievers),
          encryptionKey,
          organisationType
        );
        // const to = selectedTeammates;

        dispatch(
          addTransaction({
            to,
            safeAddress: ownerSafeAddress,
            createdBy: account,
            transactionHash: txHash,
            tokenValue: recievers.reduce(
              (total, { salaryAmount }) => (total += parseFloat(salaryAmount)),
              0
            ),
            tokenCurrency: selectedTokenDetails.name,
            fiatValue: totalAmountToPay,
            addresses: recievers.map(({ address }) => address),
            paymentType: recievers.length > 1 ? 'team' : 'individual',
            description,
            nonce: nonce,
          })
        );
      }
    } else if (txData) {
      if (
        encryptionKey &&
        recievers &&
        ownerSafeAddress &&
        // totalAmountToPay &&
        selectedTokenDetails &&
        account
      ) {
        const to = cryptoUtils.encryptDataUsingEncryptionKey(
          JSON.stringify(recievers),
          encryptionKey,
          organisationType
        );
        let description = '';
        if (recievers?.length && recievers[0].description) {
          description = recievers[0].description;
        }
        if (!isMultiOwner) {
          // threshold = 1 or single owner
          dispatch(
            addTransaction({
              to,
              safeAddress: ownerSafeAddress,
              createdBy: account,
              txData,
              tokenValue: recievers.reduce(
                (total, { salaryAmount }) =>
                  (total += parseFloat(salaryAmount)),
                0
              ),
              tokenCurrency: selectedTokenDetails.name,
              fiatValue: totalAmountToPay,
              addresses: recievers.map(({ address }) => address),
              paymentType: recievers.length > 1 ? 'team' : 'individual',
              description,
              nonce: nonce,
              isMetaEnabled,
            })
          );
          setTxData(undefined);
        } else {
          // threshold > 1
          dispatch(
            createMultisigTransaction({
              to,
              safeAddress: ownerSafeAddress,
              createdBy: account,
              txData,
              tokenValue: recievers.reduce(
                (total, { salaryAmount }) =>
                  (total += parseFloat(salaryAmount)),
                0
              ),
              tokenCurrency: selectedTokenDetails.name,
              fiatValue: totalAmountToPay,
              fiatCurrency: 'USD',
              addresses: recievers.map(({ address }) => address),
              nonce: nonce,
              transactionMode: TRANSACTION_MODES.MASS_PAYOUT,
              paymentType: recievers.length > 1 ? 'team' : 'individual',
              description,
            })
          );
        }
      }
    }
  }, [
    txHash,
    encryptionKey,
    recievers,
    dispatch,
    ownerSafeAddress,
    totalAmountToPay,
    selectedTokenDetails,
    txData,
    setTxData,
    account,
    isMultiOwner,
    nonce,
    history,
    prices,
    organisationType,
  ]);

  const selectedCount = useMemo(() => {
    return checked.filter(Boolean).length;
  }, [checked]);

  useEffect(() => {
    if ((submittedTx || metaTxHash) && selectedCount > 0) {
      // handleHide();
      // dispatch(
      //   show(TX_SUBMITTED_MODAL, {
      //     txHash: txHash ? txHash : metaTxHash,
      //     selectedCount,
      //     transactionId: singleOwnerTransactionId,
      //   })
      // );
      setSuccessState(true);
      setTimeout(() => {
        setSuccessState(false);
        handleHide();
      }, 3000);
    }
  }, [
    // dispatch,
    // metaTxHash,
    // singleOwnerTransactionId,
    // txHash,
    selectedCount,
    submittedTx,
    handleHide,
    metaTxHash,
  ]);

  useEffect(() => {
    const getAllAutomations = async () => {
      if (ownerSafeAddress && automationModule) {
        setLoadingAutomatedTransactions(true);
        try {
          const start = 0;
          const pageSize = 255; // max uint8 value
          const allDelegates = await automationModule.getDelegates(
            ownerSafeAddress,
            start,
            pageSize
          );
          const { results: delegates } = allDelegates;
          const automations = {};

          const delegatesPromises = delegates.map(async delegate => {
            const tokens = await automationModule.getTokens(
              ownerSafeAddress,
              delegate
            );

            if (tokens && tokens.length > 0) {
              const tokensPromises = tokens.map(async token => {
                const tokenAllowance = await automationModule.getTokenAllowance(
                  ownerSafeAddress,
                  delegate,
                  token
                );

                let tokenName;
                let tokenAddress = token;
                let decimals = 18;

                try {
                  const amount = getAmountFromWei(tokenAllowance[0], decimals);
                  if (amount !== 0) {
                    if (
                      tokenAddress !== addresses.ZERO_ADDRESS &&
                      tokenAddress !== addresses.OTHER_ZERO_ADDRESS
                    ) {
                      const erc20 = getERC20Contract(tokenAddress);
                      decimals = await erc20.decimals();
                      tokenName = await erc20.symbol();
                      automations[delegate][tokenName] = {
                        delegate: delegate,
                        allowanceAmount: getAmountFromWei(
                          tokenAllowance[0],
                          decimals
                        ),
                        spentAmount: getAmountFromWei(
                          tokenAllowance[1],
                          decimals
                        ),
                        resetTimeMin: tokenAllowance[2].toNumber(),
                        lastResetMin: tokenAllowance[3].toNumber(),
                        tokenName,
                        tokenAddress,
                      };
                    } else {
                      automations[delegate][tokenName] = {
                        delegate: delegate,
                        allowanceAmount: getAmountFromWei(
                          tokenAllowance[0],
                          decimals
                        ),
                        spentAmount: getAmountFromWei(
                          tokenAllowance[1],
                          decimals
                        ),
                        resetTimeMin: tokenAllowance[2].toNumber(),
                        lastResetMin: tokenAllowance[3].toNumber(),
                        tokenName: tokenName,
                        tokenAddress: addresses.ZERO_ADDRESS,
                      };
                    }
                  }
                } catch (error) {
                  console.error('error with token: ', tokenAddress, error);
                }
              });

              await Promise.all(tokensPromises);
            }
          });

          await Promise.all(delegatesPromises);

          setLoadingAutomatedTransactions(false);
          setAllAutomations(automations);
        } catch (err) {
          console.error(err);
          setLoadingAutomatedTransactions(false);
          return {};
        }
      }
    };

    getAllAutomations();
  }, [automationModule, getERC20Contract, ownerSafeAddress]);

  const handleMassPayout = async (selectedTeammates, values) => {
    try {
      if (values.payoutType === 'Recurring') {
        await createAutomatedPayout(
          selectedTeammates,
          selectedTokenDetails.name,
          resetTimes[values.repeatPattern],
          values.gasPrice,
          nonce,
          isMultiOwner,
          isMetaEnabled,
          isMetaTxLimitAllowed
        );
      } else {
        await massPayout(
          selectedTeammates,
          selectedTokenDetails.name,
          isMultiOwner,
          nonce,
          isMetaEnabled,
          isMetaTxLimitAllowed
        );
      }
    } catch (error) {
      console.error(error);
      setErrorState(true);
      setTimeout(() => setErrorState(false), 3000);
    }
  };

  const onSubmit = async values => {
    const selectedTeammates = selectedRows.map(
      ({ address, salaryAmount, salaryToken, ...rest }) => {
        const amount =
          salaryToken === 'USD'
            ? String(salaryAmount / selectedTokenDetails.usdConversionRate)
            : salaryAmount;
        return {
          address,
          salaryAmount: amount,
          salaryToken,
          description: values.description || '',
          usd: selectedTokenDetails.usdConversionRate * amount,
          ...rest,
        };
      }
    );

    await handleMassPayout(selectedTeammates, values);
    if (isMetaEnabled && isMetaTxLimitAllowed) {
      setLoadingMetaTx(true);
    }
  };

  const handleCheckAll = e => {
    if (checked.length === people.length && checked.every(check => check)) {
      // if all are checked, deselect all
      setIsCheckedAll(false);
      setSelectedRows([]);
      setChecked([]);
    } else {
      // select all
      setChecked(new Array(people.length).fill(true));
      setIsCheckedAll(true);
      if (people && people.length > 0) {
        // const allRows = people.map(({ data, ...rest }) => ({
        //   ...getDecryptedDetails(data, encryptionKey, organisationType),
        //   ...rest,
        // }));
        setSelectedRows(people);
      }
    }
  };

  const handleChecked = (teammateDetails, index) => {
    const newChecked = [...checked];
    newChecked[index] = !checked[index];
    if (
      newChecked.length === people.length &&
      newChecked.every(check => check)
    ) {
      setIsCheckedAll(true);
    } else {
      setIsCheckedAll(false);
    }
    setChecked(newChecked);
    // if checked, push the details, provided it doesn't already exist in the array
    // else remove the unselected details from the array
    if (
      newChecked[index] &&
      !selectedRows.some(row => row.peopleId === teammateDetails.peopleId)
    ) {
      setSelectedRows([...selectedRows, teammateDetails]);
    } else {
      setSelectedRows(
        selectedRows.filter(row => row.peopleId !== teammateDetails.peopleId)
      );
    }
  };
  const handleAmountEdit = async (teammateDetails, value) => {
    if (value < 0) {
      return;
    }
    const { peopleId } = teammateDetails;
    //editing the temporary list's amount for the edited team mate to create  a draft
    let editedTeammates = temPeople.map(teammates => {
      if (teammates.peopleId === peopleId) {
        return {
          ...teammates,
          salaryAmount: value,
        };
      }
      return teammates;
    });
    let editedSelected = selectedRows.map(teammates => {
      if (teammates.peopleId === peopleId) {
        return {
          ...teammates,
          salaryAmount: value,
        };
      }
      return teammates;
    });
    setSelectedRows(editedSelected);
    setTempPeople(editedTeammates);
  };

  const debouncedEditAmount = useMemo(
    () =>
      debounce(
        (teammateDetails, value) => handleAmountEdit(teammateDetails, value),
        1000
      ),
    [temPeople]
  );

  const handleFinishEditing = type => {
    //managing the draft state and edited state
    if (type === 'finish') {
      setPeople(temPeople);
      setEditOn(false);
      return;
    }
    if (type === 'cancel') {
      setEditOn(false);
      setTempPeople(people);
    }
  };

  //Copy all handlers
  const handleCopyAll = (e, teammateDetails) => {
    e.stopPropagation();
    const { salaryAmount } = teammateDetails;
    let editedTeammates = temPeople.map(teammates => {
      return {
        ...teammates,
        salaryAmount: salaryAmount,
      };
    });
    let editedSelected = selectedRows.map(teammates => {
      return {
        ...teammates,
        salaryAmount: salaryAmount,
      };
    });

    setSelectedRows(editedSelected);
    setTempPeople(editedTeammates);
  };

  const renderNoPeopleFound = () => (
    <TableInfo
      style={{
        fontSize: '1.4rem',
        fontWeight: '500',
        textAlign: 'center',
        height: '20rem',
      }}
    >
      <td colSpan={4}>No people found!</td>
    </TableInfo>
  );

  const renderCopyToAll = (teammateDetails, idx, peopleId) => {
    return (
      <>
        <span
          className="copytoall"
          onClick={e => handleCopyAll(e, teammateDetails)}
        >
          <img
            src={groupall}
            alt="copyall"
            id="copy-all"
            data-for={'copy-all'}
            data-tip={'Copy to All'}
          />
        </span>
        <ReactTooltip
          id={'copy-all'}
          place={'bottom'}
          type={'dark'}
          effect={'solid'}
          getContent={dataTip => dataTip}
        />
      </>
    );
  };

  const renderPayTable = () => {
    if (!people || !selectedToken) return;

    const checkIfAutomationExists = (tokenAddress, delegate) => {
      if (delegate in allAutomations) {
        if (
          allAutomations[delegate].tokenAddress.toLowerCase() ===
          tokenAddress.toLowerCase()
        ) {
          return true;
        }
      }
      return false;
    };

    const automationsExistsIndicator = (tokenAddress = '', delegate) => {
      if (
        selectedPayoutType.toLowerCase() === 'recurring' &&
        checkIfAutomationExists(tokenAddress, delegate)
      ) {
        return <InfoIcon fill="#fcbc04" className="warning-icon" />;
      }

      return null;
    };

    const loading = loadingTeammates && loadingAutomatedTransactions;

    // This is a hack that's bad enough that we might to remove it even from this codebase
    let automationExistsForPayees = false;

    if (!loading && selectedTokenDetails && people.length > 0) {
      automationExistsForPayees = people.reduce((acc, data) => {
        const flag = checkIfAutomationExists(
          selectedTokenDetails.address,
          data.address
        );
        return acc || flag;
      }, false);
    }

    return (
      <div className="team-details-table">
        <div className="outer-flex mt-5">
          <div className="d-flex">
            <div className="title">Team Details</div>
            {editOn && (
              <div className="ml-3 editing">
                <Pencil className="icon" /> Editing
              </div>
            )}
          </div>

          {!loading && people?.length > 0 && (
            <div className="select-all">
              {!editOn && (
                <div
                  className="edit-amount"
                  onClick={() => (isEditing ? null : setEditOn(true))}
                >
                  <Pencil className="icon" />
                  <div className="title">Edit amount</div>
                </div>
              )}
            </div>
          )}
        </div>
        <div
          style={{
            minHeight: '10rem',
            maxHeight: '25rem',
            overflow: 'auto',
          }}
        >
          <Table style={{ border: '1px solid rgba(235, 236, 239, 0.8)' }}>
            <TableHead>
              <tr>
                <th style={{ width: '5%' }}>
                  <CheckBox
                    type="checkbox"
                    id="allCheckbox"
                    checked={isCheckedAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th style={{ width: '30%' }}>Teammates</th>
                <th style={{ width: '23%' }}>Disbursement</th>
                <th style={{ width: '40%' }}>Address</th>
              </tr>
            </TableHead>
            <TableBody>
              <TableDivider>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </TableDivider>
              {loadingTeammates && <TableLoader colSpan={3} height="10rem" />}
              {!loading &&
                !loadingTeammates &&
                !people.length &&
                renderNoPeopleFound()}

              {!loading &&
                !loadingTeammates &&
                people.length > 0 &&
                (editOn ? temPeople : people).map((eachTeamMate, idx) => {
                  const {
                    firstName,
                    lastName,
                    salaryAmount,
                    salaryToken,
                    address,
                    peopleId,
                    ...rest
                  } = eachTeamMate;

                  const teammateDetails = {
                    firstName,
                    lastName,
                    salaryToken,
                    salaryAmount,
                    address,
                    peopleId,
                    ...rest,
                  };
                  return (
                    <tr
                      key={`${address}-${idx}`}
                      onClick={e => {
                        e.preventDefault();
                        handleChecked(teammateDetails, idx);
                      }}
                      style={{
                        backgroundColor: checked[idx]
                          ? 'rgba(115, 103, 240, 0.03)'
                          : 'transparent',
                      }}
                    >
                      <td>
                        {' '}
                        <CheckBox
                          type="checkbox"
                          id={`checkbox${idx}`}
                          name={`checkbox${idx}`}
                          checked={checked[idx] || false}
                          onChange={() => handleChecked(teammateDetails, idx)}
                        />
                      </td>
                      <td className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          {firstName} {lastName}
                        </div>
                      </td>
                      {editOn === true ? (
                        <td>
                          <TokenImg token={salaryToken} />
                          <input
                            key={idx}
                            value={salaryAmount}
                            onClick={e => {
                              e.stopPropagation();
                              setCopyAllIdx(idx);
                            }}
                            type="number"
                            className="editamount"
                            disabled={isEditing}
                            onChange={e =>
                              handleAmountEdit(teammateDetails, e.target.value)
                            }
                          />

                          {!copyAllIdx && idx === 0 ? (
                            renderCopyToAll(teammateDetails)
                          ) : copyAllIdx === idx ? (
                            renderCopyToAll(teammateDetails)
                          ) : (
                            <></>
                          )}
                        </td>
                      ) : (
                        <td>
                          <TokenImg token={salaryToken} />
                          <span>
                            {salaryAmount ? salaryAmount : 0} {salaryToken}{' '}
                          </span>
                        </td>
                      )}
                      <td>
                        {address}{' '}
                        {automationsExistsIndicator(
                          selectedTokenDetails && selectedTokenDetails.address,
                          address
                        )}
                      </td>
                    </tr>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {automationExistsForPayees &&
          selectedPayoutType.toLowerCase() === 'recurring' && (
            <div className="table-footer">
              <InfoIcon fill={'white'} />
              <div className="text">
                These payees will be modified as they have an existing
                automation associated with this team.
              </div>
            </div>
          )}
      </div>
    );
  };

  const renderEditConfirmation = () => {
    return (
      <EditConfirmation>
        <Button
          type="button"
          width="20rem"
          style={{
            backgroundColor: 'rgba(115, 103, 240, 0.1)',
            color: '#7367F0',
            marginRight: '20px',
          }}
          loading={isEditing}
          disabled={isEditing}
          onClick={() => handleFinishEditing('cancel')}
        >
          Cancel Editing
        </Button>
        <Button
          type="button"
          width="20rem"
          loading={isEditing}
          disabled={isEditing}
          onClick={() => handleFinishEditing('finish')}
        >
          Finish Editing
        </Button>
      </EditConfirmation>
    );
  };

  const renderPaymentSummary = () => {
    if (!minFieldsForTransaction) return null;
    if (tokenError) return <ErrorText>{tokenError}</ErrorText>;

    // if (!people || !selectedToken || !selectedTokenDetails) return null;
    const insufficientBalance =
      selectedTokenDetails.usd - totalAmountToPay >= 0 ? false : true;
    const totalTokenAmount = !isNaN(totalAmountInToken)
      ? `${formatNumber(totalAmountInToken)}`
      : `0`;

    const getPaidToText = () => {
      if (!selectedRows.length) return '';
      if (selectedRows.length === 1)
        return selectedRows[0].firstName + '  ' + selectedRows[0].lastName;
      else if (selectedRows.length > 1)
        return `${selectedRows.length} members of ${selectedTeamName}`;
    };

    return selectedPayoutType.toLowerCase() === 'recurring' ? (
      <ScheduleFooter
        btnLoading={loadingTx || addingTx || loadingMetaTx}
        btnDisabled={
          loadingTx ||
          loadingMetaTx ||
          insufficientBalance ||
          addingTx ||
          loadingSafeDetails ||
          loadingTokens ||
          !selectedCount
        }
        selectedToken={selectedToken}
        tokenValue={totalTokenAmount}
        paidTo={getPaidToText()}
        repeatPattern={
          paymentRepeatPattern && paymentRepeatPattern.toLowerCase()
        }
        safeBalance={
          selectedTokenDetails &&
          formatNumber(selectedTokenDetails.balance, 5) +
            ' ' +
            selectedTokenDetails.name
        }
        minFieldsForTransaction={minFieldsForTransaction}
      />
    ) : (
      <PaymentSummary>
        <div className="payment-info">
          <div>
            <div className="payment-title">Current Balance</div>
            <div className="payment-subtitle text-bold">
              {`${formatNumber(selectedTokenDetails.balance)} ${
                selectedTokenDetails.name
              }`}
            </div>
            <div className="payment-subtitle">{`US$ ${formatNumber(
              selectedTokenDetails.usd
            )}`}</div>
          </div>
          <div>
            <div className="payment-title">Balance after payment</div>
            <div className="payment-subtitle text-bold">
              {!insufficientBalance
                ? `${formatNumber(
                    selectedTokenDetails.balance - totalAmountInToken
                  )} ${selectedTokenDetails.name}`
                : `Insufficient Balance`}
            </div>
            <div className="payment-subtitle">
              {!insufficientBalance
                ? `US$ ${formatNumber(
                    selectedTokenDetails.usd - totalAmountToPay
                  )}`
                : `Insufficient Balance`}
            </div>
          </div>
          <div>
            <div className="payment-title">Total Selected</div>
            <div className="payment-subtitle">{selectedCount} people</div>
          </div>
          <div>
            <div className="payment-title">Total Amount</div>
            <div className="payment-subtitle text-bold">
              {!isNaN(totalAmountInToken)
                ? `${formatNumber(totalAmountInToken)} ${
                    selectedTokenDetails.name
                  }`
                : `0`}
            </div>
            <div className="payment-subtitle">
              {!isNaN(totalAmountToPay)
                ? `US$ ${formatNumber(totalAmountToPay)}`
                : `0`}
            </div>
          </div>
        </div>

        <div>
          <Button
            type="submit"
            width="20rem"
            loading={loadingTx || addingTx || loadingMetaTx}
            disabled={
              loadingTx ||
              loadingMetaTx ||
              insufficientBalance ||
              addingTx ||
              loadingSafeDetails ||
              loadingTokens ||
              !selectedCount
            }
          >
            {threshold > 1 ? `Create Transaction` : `Pay Now`}
          </Button>
        </div>
      </PaymentSummary>
    );
  };

  const redirectToEtherscan = () => {
    if (txHash) {
      const url = getEtherscanLink({ chainId: networkId, hash: txHash });
      window.open(url, '_blank');
    }

    if (metaTxHash) {
      const url = getEtherscanLink({
        chainId: networkId,
        hash: metaTxHash,
      });
      window.open(url, '_blank');
    }
  };

  const redirectToDashboard = () => {
    setSuccessState(false);
    handleHide();
  };

  const goBackToReviewDetails = () => {
    setErrorState(false);
  };

  const showLoader = loadingTx || errorState || successState;

  return (
    <MassPayoutContainer
      style={{ padding: minFieldsForTransaction ? '4rem 4rem 0 4rem' : '4rem' }}
    >
      {showLoader && (
        <div className="loader-container">
          <ParcelLoader size={200} error={errorState} success={successState}>
            {successState && (
              <>
                <Button type="button" onClick={redirectToDashboard}>
                  Go back to dashboard
                </Button>
                <Button type="button" onClick={redirectToEtherscan}>
                  View Transaction on{' '}
                  {isPolygonMainnet ? 'Polygonscan' : 'Etherscan'}
                </Button>
              </>
            )}
            {errorState && (
              <Button type="button" onClick={goBackToReviewDetails}>
                Go back to Review Transaction
              </Button>
            )}
          </ParcelLoader>
        </div>
      )}
      <div className={showLoader ? 'container-blur' : ''}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="outer-flex">
            <div className="inner-flex">
              <div className="mr-3">
                {' '}
                <div className="title">Payout Type</div>
                <div className="d-flex align-items-center">
                  {payoutType.map((label, i) => (
                    <Radio
                      register={register}
                      key={i}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: i !== 0 && '5rem',
                      }}
                      id={`${label}-${i}`}
                      name="payoutType"
                      label={() => renderPayoutTypeLabel(label)}
                      value={label}
                      defaultChecked={label === 'One Time'}
                    />
                  ))}
                </div>
                <div className="mt-5 payment-wrapper">
                  <div>
                    <div className="title">Paying To</div>
                    <Select
                      name="team"
                      control={control}
                      required={`Team is required`}
                      options={teamsDropdown}
                      isLoading={loadingTeams}
                      placeholder={`Select Team...`}
                      defaultValue={selectedTeam}
                    />
                  </div>
                  {selectedTeamId && (
                    <div>
                      <div className="title">Paying From</div>
                      <SelectToken
                        name="token"
                        control={control}
                        required={`Token is required`}
                        options={tokensDropdown}
                        isSearchable
                        isLoading={loadingTeams}
                        placeholder={`Select Currency...`}
                        isDisabled={!isSelectedTokenUSD}
                        defaultValue={null}
                      />
                    </div>
                  )}
                  <div>
                    <div className="title">Description</div>
                    <Input
                      type="text"
                      name="description"
                      style={{ width: '30rem' }}
                      register={register}
                      placeholder="description"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {selectedPayoutType.toLowerCase() === 'recurring' && (
            <div className="mt-5">
              <PaymentSchedule register={register} />
            </div>
          )}
          {renderPayTable()}

          <div>
            {!loadingTx && errorFromMetaTx && (
              <div className="text-danger mt-3">{errorFromMetaTx}</div>
            )}
          </div>
          {editOn ? renderEditConfirmation() : renderPaymentSummary()}
        </form>
      </div>
    </MassPayoutContainer>
  );
}
