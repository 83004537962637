import React, { useEffect, useState } from 'react';
import { connectModal as reduxModal } from 'redux-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableHead,
  TableBody,
  TableDivider,
} from 'components/common/Table';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'components/common/Modal/SimpleModal';
import TokenImg from 'components/common/TokenImg';
import Button from 'components/common/Button';
import { deleteTeam } from 'store/modify-team/actions';
import { makeSelectOwnerSafeAddress } from 'store/global/selectors';
import { makeSelectUpdating } from 'store/modify-team/selectors';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import modifyTeamReducer from 'store/modify-team/reducer';
import modifyTeamSaga from 'store/modify-team/saga';

export const MODAL_NAME = 'delete-team-modal';
const modifyTeamKey = 'modifyTeam';

function DeleteTeamModal(props) {
  const { show, handleHide, departmentId, allPeople } = props;
  const [peopleToShow, setPeopleToShow] = useState([]);

  useInjectReducer({ key: modifyTeamKey, reducer: modifyTeamReducer });

  useInjectSaga({ key: modifyTeamKey, saga: modifyTeamSaga });

  const dispatch = useDispatch();

  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const updating = useSelector(makeSelectUpdating());

  const confirmDelete = () => {
    dispatch(deleteTeam({ departmentId, safeAddress: ownerSafeAddress }));
  };

  useEffect(() => {
    setPeopleToShow(allPeople.filter(el => el.departmentId === departmentId));
  }, [allPeople, departmentId]);

  return (
    <Modal toggle={handleHide} isOpen={show}>
      <ModalHeader toggle={handleHide} />
      <ModalBody>
        <div className="subtitle">The following team will be removed</div>
        <Table>
          <TableHead>
            <tr style={{ background: '#F8F9FA' }}>
              <th className="d-flex align-items-center">NAME</th>
              <th>DISBURSEMENT</th>
              <th>TEAM</th>
            </tr>
          </TableHead>
          <TableBody>
            {peopleToShow?.map(item => {
              return (
                <tr>
                  <td>
                    {item.firstName}
                    {item.lastName}
                  </td>
                  <td>
                    <TokenImg token={item.salaryToken} />
                    <span>{item.salaryAmount}</span>
                  </td>
                  <td>{item.departmentName}</td>
                </tr>
              );
            })}
          </TableBody>
        </Table>
        <div className="d-flex justify-content-center align-items-center mt-4">
          <div>
            <Button
              width="16rem"
              onClick={handleHide}
              className="secondary mr-4"
              style={{
                color: '#7367F0',
                background: 'rgba(115, 103, 240, 0.1)',
                border: 'none',
              }}
            >
              Go back
            </Button>
          </div>
          <div>
            <Button
              width="16rem"
              onClick={confirmDelete}
              loading={updating}
              disabled={updating}
              style={{
                color: 'white',
                background: '#FF4B55',
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default reduxModal({ name: MODAL_NAME })(DeleteTeamModal);
