import styled from 'styled-components/macro';
import { slide as Menu } from 'react-burger-menu';

export default styled(Menu)`
  position: relative;

  .container {
    padding: 2.4rem;
    flex-direction: column;

    .close {
      cursor: pointer;
      width: 2rem;
      height: 2rem;
      float: left;
    }

    .title {
      font-weight: 900;
      font-size: 18px;
      line-height: 22px;
      color: #373737;
      margin-top: 1.6rem;
    }

    .list {
      margin-top: 2rem;
    }

    .safe {
      padding: 2.3rem 0;
      justify-content: space-between;
      position: relative;
      cursor: pointer;

      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        border-bottom: 0.1rem solid #dddcdc;
        width: calc(100% + 2rem);
      }

      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #232427;
      }

      .address {
        font-size: 12px;
        line-height: 14px;
        color: #8d8e91;
        margin-top: 0.6rem;
      }
    }
  }
`;
