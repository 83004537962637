import Button from 'components/common/Button';
import React from 'react';
import { ScheduleFooterWrapper } from './styles';

const Summary = ({
  props: {
    btnDisabled,
    btnLoading,
    paidTo,
    tokenValue = '0',
    repeatPattern,
    selectedToken,
  } = {},
}) => {
  return (
    <div className="summary-wrapper">
      <div>
        <div className="title mb-8">Summary</div>
        <div className="summary">
          {`Payment of ${tokenValue} ${selectedToken} paid to ${paidTo} every ${repeatPattern} starting today.`}
        </div>
      </div>

      <Button
        type="submit"
        width="20rem"
        style={{ minWidth: '18rem' }}
        loading={btnLoading}
        disabled={btnDisabled}
      >
        Create Transaction
      </Button>
    </div>
  );
};

const Footer = ({ props: { safeBalance = '0' } = {} }) => {
  return (
    <div className="footer">
      Gnosis Safe Balance: {safeBalance}. You need to have sufficient balance in
      your gnosis safe wallet for future automations to execute
    </div>
  );
};

export default function ScheduleFooter(props) {
  if (!props.minFieldsForTransaction) return null;

  return (
    <ScheduleFooterWrapper>
      <Summary props={props} />
      <Footer props={props} />
    </ScheduleFooterWrapper>
  );
}
