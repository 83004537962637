import styled from 'styled-components/macro';

export default styled.table`
  position: relative;
  width: 100%;
  table-layout: fixed;
  border-radius: 0.4rem;
  box-shadow: 0 0 0 0.1rem rgba(235, 236, 239, 0.8);

  // overflow-x: visible !important;
  // overflow-y: visible !important;
  td {
    white-space: nowrap;

    text-overflow: ellipsis;
    padding-right: 1rem;
  }

  @media (max-width: 600px) {
    td,
    th {
      font-size: 1.1rem !important;
    }
  }
`;
