import React, { useCallback } from 'react';
import csv from 'csv';
import { useDropzone } from 'react-dropzone';

import UploadIcon from 'assets/icons/dashboard/upload-icon.svg';
import { Container, Wrapper } from './styles';
import Img from '../Img';
import Button from 'components/common/Button';
import WarningIconSVG from 'assets/icons/invalid.svg';

export default function Basic(props) {
  const onDrop = useCallback(
    acceptedFiles => {
      try {
        // Do something with the files
        const reader = new FileReader();
        reader.onload = () => {
          csv.parse(reader.result, (err, data) => {
            const fileName = acceptedFiles[0].path;
            props.onDrop(data ? data.slice(1) : undefined, fileName);
          });
        };

        reader.readAsBinaryString(acceptedFiles[0]);
      } catch (err) {
        console.error(err);
        props.onDrop(undefined, 'Invalid file');
      }
    },
    [props]
  );

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ maxFiles: 1, accept: '.csv', onDrop });

  const files = acceptedFiles.map(file => (
    <span key={file.path}>
      {file.path} - {file.size} bytes
    </span>
  ));

  const { hasError, sampleFileSrc = '' } = props;
  return (
    <Wrapper hasError={hasError}>
      <Container
        hasError={hasError}
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
        style={props.style}
      >
        <div>
          <input {...getInputProps()} />
          {hasError ? (
            <div className="upload-error-container d-flex">
              <Img src={WarningIconSVG} alt="error" />
              <div className="title">Upload Failed</div>
              <div className="error-info">File upload was unsuccessful</div>
            </div>
          ) : files && files.length > 0 ? (
            <div className="upload">
              <div className="drag-text">Uploaded - {files}</div>
            </div>
          ) : (
            <div className="upload">
              <Img src={UploadIcon} alt="upload" className="upload-icon" />
              <p className="drag-text">Drag & drop your file here</p>
              <div className="divider">OR</div>
              <Button>Browse Files</Button>
            </div>
          )}
        </div>
      </Container>
      {hasError ? (
        <div
          style={{ width: '100%' }}
          {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
        >
          <input {...getInputProps()} />
          <div className="footer-button d-flex align-items-center">
            Re-Upload File
          </div>
        </div>
      ) : (
        <div className="footer-button d-flex align-items-center">
          <a
            href={sampleFileSrc}
            rel="noreferrer noopener"
            target="_blank"
            className="format-csv"
          >
            Download Sample File
          </a>
        </div>
      )}
    </Wrapper>
  );
}
