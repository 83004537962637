import styled from 'styled-components/macro';

export default styled.div`
  .static-text {
    position: absolute;
    left: 1rem;
    font-size: 1.2rem;
    color: #aaaaaa;
    top: 1.1rem;
  }
`;
