import styled from 'styled-components/macro';

export default styled.div`
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
  // border: solid 1px #f2f2f2;
  background-color: #f2f2f2;
  width: 488px;
  margin: 0 auto;
  border-radius: 16px;
`;
