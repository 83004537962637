import styled from 'styled-components/macro';

export default styled.div`
  min-height: 10rem;
  overflow: auto;

  .error-text {
    text-align: center;
    width: 100%;
    white-space: nowrap;
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.7rem;
    color: #ff4b55;
    margin-left: 2rem;
  }

  .table-head {
    justify-content: space-between;
    background-color: #ffffff;
    width: 100%;
    height: 5rem;
    padding: 0 8rem 0 4rem;
    border-top: 0.1rem solid rgba(235, 236, 239, 0.8);

    div {
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.7rem;
      color: #373737;
      opacity: 0.6;
      text-transform: uppercase;
      letter-spacing: 0.08em;
    }
    div:nth-child(1) {
      width: 30%;
    }
    div:not(:first-child) {
      width: 20%;
      text-align: end;
    }
  }

  .table-divider {
    background-color: #f8f9fa;
    height: 3.2rem;
    border-top: 0.1rem solid rgba(235, 236, 239, 0.8);
    border-bottom: 0.1rem solid rgba(235, 236, 239, 0.8);
  }

  .table-body {
    width: 100%;

    .table-row-container {
      &:hover {
        background-color: #f5f3fe !important;
      }
    }

    .table-row {
      justify-content: space-between;
      font-size: 1.4rem;
      text-align: left;
      color: #373737;
      padding: 0 8rem 0 4rem;
      min-height: 5rem;
      cursor: pointer;

      .amount {
        position: relative;

        img {
          position: absolute;
          right: -3.4rem;
          width: 1.6rem;
          height: 1.6rem;
        }
      }

      > div:nth-child(1) {
        width: 30%;
        font-weight: 900;
        img {
          width: 2rem;
          height: 2rem;
          margin-right: 1.6rem !important;
        }
      }
      > div:not(:first-child) {
        width: 20%;
        text-align: end;
      }

      .select-wrapper {
        width: 12.6rem;
        height: 3rem;
        margin-left: 3.2rem;

        .select__placeholder {
          font-size: 1.2rem;
          line-height: 1.4rem;
          color: #cbcccb;
        }

        .select__control {
          width: 12.6rem;
          height: 3rem;
          min-height: 3rem;
        }

        .select__value-container,
        .select__dropdown-indicator {
          padding: 0 0.7rem;
        }

        .select__single-value,
        .select__option {
          font-size: 1.2rem;
          line-height: 1.4rem;
          font-weight: normal;
          color: #373737;

          div {
            margin: 0 !important;
          }

          img {
            margin: 0 0.5rem 0 0 !important;
            width: 1.6rem;
            height: 1.6rem;
          }
        }

        .select__option {
          padding: 1rem 0.7rem;
        }
      }

      .footer-row {
        color: rgb(55 55 55 / 60%);
        font-weight: normal;

        span {
          color: #373737;
          font-weight: 900;
          font-size: 1.4rem;
          line-height: 1.7rem;
          margin-left: 1.6rem;
        }
      }
    }
  }

  .payee-table {
    width: calc(100% - 15.2rem);
    margin: 0 auto 2.4rem auto;
    border: 0.1rem solid rgba(221, 220, 220, 0.5);
    border-radius: 0.8rem;

    &-head {
      justify-content: space-between;
      width: 100%;
      height: 4rem;
      padding: 0 2.4rem;
      background: #f8f9fa;
      border-radius: 0.8rem 0.8rem 0 0;

      div {
        font-size: 1.2rem;
        line-height: 1.4rem;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #373737;
        opacity: 0.6;
      }

      div:nth-child(1) {
        width: 54%;
      }
      div:not(:first-child) {
        width: 20%;
        text-align: end;
      }
    }

    &-body {
      width: 100%;

      .payee-table-row {
        width: 100%;
        height: 4rem;
        justify-content: space-between;
        font-size: 1.4rem;
        text-align: left;
        color: #373737;
        padding: 0 2.4rem;

        &.error {
          position: relative;
          &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 50%;
            height: calc(100% - 1.6rem);
            width: 0.3rem;
            background-color: red;
            transform: translateY(-50%);
          }
        }

        .name {
          .avatar {
            width: 2rem;
            height: 2rem;
            margin-right: 1.6rem;
          }
          .error-logo {
            width: 2rem;
            height: 2rem;
            margin-right: 1.6rem;
          }
        }

        .token {
          img {
            margin-right: 0.9rem !important;
            width: 2rem;
            height: 2rem;
          }
        }
        &:last-child {
          border-radius: 0 0 0.8rem 0.8rem;
        }
        &:nth-child(odd) {
          background: #fff;
        }
        &:nth-child(even) {
          background: #f8f9fa;
        }

        > div:nth-child(1) {
          width: 54%;
        }

        > div:not(:first-child) {
          width: 20%;
          text-align: end;
        }
      }
    }
  }
`;
