import styled from 'styled-components/macro';

export default styled.div`
  width: 100%;
  min-height: 8rem;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  border-bottom: 0.5px solid #dddcdc;
  border-radius: 0.4rem;

  & > div:first-child {
    display: flex;
  }

  .note-container {
    border-left: 0.5px solid #dddcdc;
    border-right: 0.5px solid #dddcdc;
    background: rgba(252, 188, 4, 0.1);
    padding: 0rem 3rem;
    min-height: 3.4rem;
    border-radius: 0 0 0.4rem 0.4rem;

    .status-indicator {
      background: #fcbc04;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      margin-right: 1.6rem;
    }

    .note {
      color: #373737;
      font-weight: normal;
      font-size: 12px;
    }

    justify-content: space-between;
    .cta {
      color: #373737;
      text-decoration: none;
      cursor: pointer;
      font-size: 12px;

      img {
        width: 1.3rem;
        margin-right: 0.8rem;
        margin-bottom: 0.2rem;
      }
    }
  }
  .section {
    width: 50%;
    border: 0.5px solid #dddcdc;
    border-bottom: none;
    padding: 3rem;
    border-radius: 0.4rem 0.4rem 0 0;

    .left {
      float: left;

      .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
      }

      .subtitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;

        margin-top: 0.8rem;
      }
    }

    .right {
      float: right;

      .buttons {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .approve-button {
          display: flex;
          margin-right: 2rem;
          button {
            box-shadow: 1rem 1rem 2rem 0 rgba(35, 30, 79, 0.15);
          }
        }

        .reject-button {
          display: flex;
          button {
            box-shadow: 1rem 1rem 2rem 0 rgba(35, 30, 79, 0.15);
            background-color: #ff4660;
          }
        }

        .warning {
          margin: auto 0.8rem;
        }
      }
    }
  }
`;
