import React, { useEffect, useState } from 'react';

import {
  Table,
  TableHead,
  TableBody,
  TableDivider,
} from 'components/common/Table';
import { TRANSACTION_MODES } from 'constants/transactions';
import TokenImg from 'components/common/TokenImg';
import { formatNumber } from 'utils/number-helpers';
import TransactionsTableByTokens from './TransactionsTableByTokens';
import { TransactionsByTokens } from 'components/Dashboard/styles';
import Avatar from 'components/common/Avatar';
import { getClickable } from 'components/common/Web3Utils';
import { useActiveWeb3React } from 'hooks';
import TransactionsTableByTeams from 'components/Transactions/TransactionsTableByTeams';
import { TransactionsByTeams } from 'components/Dashboard/styles';

export default function DisbursementDetails({
  paidTeammates,
  transactionMode,
  tokenCurrency,
  paymentType,
}) {
  const [txDetails, setTxDetails] = useState({});
  const [txByTeams, setTxByTeams] = useState({});
  const { chainId } = useActiveWeb3React();

  useEffect(() => {
    if (transactionMode === TRANSACTION_MODES.CSV_PAYOUT) {
      const tokens = {};

      paidTeammates.forEach(data => (tokens[data.salaryToken] = []));

      paidTeammates.forEach(data => {
        tokens[data.salaryToken] = [...tokens[data.salaryToken], data];
      });

      setTxDetails(tokens);
    } else if (transactionMode === TRANSACTION_MODES.TEAMS_MASS_PAYOUT) {
      const groupingByTeams = {};

      paidTeammates.forEach(
        data => (groupingByTeams[data.departmentName] = [])
      );

      paidTeammates.forEach(data => {
        groupingByTeams[data.departmentName] = [
          ...groupingByTeams[data.departmentName],
          data,
        ];
      });
      setTxByTeams(groupingByTeams);
    }
  }, [paidTeammates, transactionMode]);

  if (!paidTeammates || !Array.isArray(paidTeammates)) return null;
  const isMassPayout = transactionMode === TRANSACTION_MODES.MASS_PAYOUT;
  let isQuickTransfer = transactionMode === TRANSACTION_MODES.QUICK_TRANSFER;
  const isSpendingLimit = transactionMode === TRANSACTION_MODES.SPENDING_LIMITS;
  const isCSVPayout = transactionMode === TRANSACTION_MODES.CSV_PAYOUT;
  const isAutomationPayout = transactionMode === TRANSACTION_MODES.AUTOMATION;
  const isTeamsMassPayout =
    transactionMode === TRANSACTION_MODES.TEAMS_MASS_PAYOUT;

  const isAutomationRemoval = paymentType === 'removal';

  if (isAutomationRemoval || isAutomationPayout) {
    return (
      <Table>
        <TableHead>
          <tr>
            <th style={{ width: '19%' }}>Name</th>
            <th style={{ width: '12%' }}>Amount</th>
            <th style={{ width: '37%' }}>Address</th>
          </tr>
        </TableHead>
        <TableBody style={{ maxHeight: '30rem', overflow: 'auto' }}>
          {paidTeammates.map(
            (
              { firstName, lastName, delegate, tokenName, allowanceAmount },
              idx
            ) => (
              <tr key={`${idx}-${delegate}`}>
                <td style={{ width: '30%' }}>
                  <div className="d-flex align-items-center">
                    {firstName ? (
                      <>
                        <Avatar
                          className="mr-3"
                          firstName={firstName}
                          lastName={lastName}
                        />
                        {firstName} {lastName}
                      </>
                    ) : (
                      getClickable(delegate, chainId, 'address', true)
                    )}
                  </div>
                </td>
                <td style={{ width: '30%' }}>
                  <TokenImg token={tokenName} />
                  {formatNumber(allowanceAmount, 5)} {tokenName}
                </td>
                <td style={{ width: '40%' }}>{delegate}</td>
              </tr>
            )
          )}
        </TableBody>
      </Table>
    );
  } else if (isMassPayout) {
    return (
      <Table>
        <TableHead>
          <tr>
            <th style={{ width: '20%' }}>Name</th>
            <th style={{ width: '20%' }}>Team</th>
            <th style={{ width: '15%' }}>Disbursement</th>
            <th style={{ width: '40%' }}>Address</th>
          </tr>
        </TableHead>
        <TableBody style={{ maxHeight: '30rem', overflow: 'auto' }}>
          <TableDivider>
            <td /> <td /> <td /> <td />
          </TableDivider>
          {paidTeammates.map(
            (
              {
                firstName,
                lastName,
                address,
                salaryAmount,
                salaryToken,
                usd,
                departmentName,
              },
              idx
            ) => (
              <tr key={`${idx}-${address}`}>
                <td style={{ width: '30%' }}>
                  {firstName} {lastName}
                </td>
                <td style={{ width: '30%' }}>{departmentName}</td>
                <td style={{ width: '30%' }}>
                  <TokenImg token={salaryToken} />

                  {salaryToken === 'USD'
                    ? `${formatNumber(usd)} USD (${formatNumber(
                        salaryAmount,
                        5
                      )} ${tokenCurrency})`
                    : `${formatNumber(salaryAmount, 5)} ${salaryToken}`}
                </td>
                <td style={{ width: '40%' }}>
                  {getClickable(address, chainId, 'address', false)}
                </td>
              </tr>
            )
          )}
        </TableBody>
      </Table>
    );
  } else if (isQuickTransfer) {
    return (
      <Table>
        <TableHead>
          <tr>
            <th>Paid To</th>
            <th>Disbursement</th>
          </tr>
        </TableHead>
        <TableBody style={{ maxHeight: '30rem', overflow: 'auto' }}>
          <TableDivider>
            <td /> <td />
          </TableDivider>
          {paidTeammates.map(
            ({ address, salaryAmount, salaryToken, usd }, idx) => (
              <tr key={`${idx}-${address}`}>
                <td>{getClickable(address, chainId, 'address', false)}</td>
                <td>
                  <TokenImg token={salaryToken} />
                  {salaryToken === 'USD'
                    ? `${usd} USD`
                    : `${formatNumber(salaryAmount, 5)} ${salaryToken}`}
                </td>
              </tr>
            )
          )}
        </TableBody>
      </Table>
    );
  } else if (isSpendingLimit) {
    return (
      <Table>
        <TableHead>
          <tr>
            <th>Beneficiary</th>
            <th>Allowance</th>
          </tr>
        </TableHead>
        <TableBody style={{ maxHeight: '30rem', overflow: 'auto' }}>
          <TableDivider>
            <td /> <td />
          </TableDivider>
          {paidTeammates.map(
            ({ address, allowanceAmount, allowanceToken }, idx) => (
              <tr key={`${idx}-${address}`}>
                <td>{getClickable(address, chainId, 'address', false)}</td>
                <td>
                  <TokenImg token={allowanceToken} />
                  {formatNumber(allowanceAmount, 5)} {allowanceToken}
                </td>
              </tr>
            )
          )}
        </TableBody>
      </Table>
    );
  } else if (isCSVPayout) {
    return (
      <TransactionsByTokens>
        <div className="d-flex align-items-center table-head">
          <div>Token</div>
          <div>No. of Payees</div>
          <div>Price per Token</div>
          <div>Total USD Amount</div>
        </div>
        <div className="table-divider"></div>
        <div className="table-body">
          {Object.entries(txDetails).map(([token, payees], idx) => {
            return (
              <TransactionsTableByTokens
                token={token}
                idx={idx}
                payees={payees}
                showNoError
              />
            );
          })}
        </div>
      </TransactionsByTokens>
    );
  } else if (isTeamsMassPayout) {
    return (
      <TransactionsByTeams>
        <div className="d-flex align-items-center table-head">
          <div>Teams</div>
          <div>No. of Payees</div>
          <div>Total Token Amount</div>
          <div>Total USD Amount</div>
        </div>

        <div className="table-divider"></div>

        <div className="table-body">
          {Object.entries(txByTeams).map(([teamName, payees], idx) => {
            const tokenPerTeam = payees[0].salaryToken;
            return (
              <TransactionsTableByTeams
                key={teamName}
                team={teamName}
                token={tokenPerTeam}
                idx={idx}
                payees={payees}
                teamsChangedToken={[]}
              />
            );
          })}
        </div>
      </TransactionsByTeams>
    );
  }
}
