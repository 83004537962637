import styled from 'styled-components/macro';

import backgroundImage from 'assets/images/NewLogin/background.svg';

export default styled.div`
  background: url(${backgroundImage});
  width: 100%;
  height: 100%;
  opacity: 1;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;

  .wallet-info-wrapper{
    position: absolute;
    top: 4.8rem;
    right: 8rem;
    width: 16rem;

  }
  .network-selector-container{
    margin-bottom: 1.6rem;
  }
  
  .wallet-info-container {
    display: grid;
    background: white;
    height: 6.8rem;
    border-radius: 0.4rem;
    
    .wallet-details {
      display: flex;
      flex-direction: row;
      justify-self: start;
      height: 3.8rem;

      .wallet-image {
        height: 2.4rem;
        width: 2.4rem;
        margin: auto 0 auto 0.8rem;
      }
    
      .wallet-address {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;

        margin: auto 0 auto 0.6rem;
      }
    }

    .disconnect-button {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;
      
      height: 3rem;
      width: 100%;
      justify-self: end;
      border-radius: 0 0 0.4rem 0.4rem;
      min-height: 0;
    }
  }

  .login-container {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
  }

  .logo-container {
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: end;
    flex-direction: column;
    margin-bottom: 4rem;
  }

  .logo {
    height: 4rem;
  }

  .onboard-title {
    color: white;
    width: 16rem;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 76px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .container-small {
    display: flex;
    flex-direction: column;
    height: 50rem;
    width: 40rem;
    margin: 0 auto;
    background: white;
    border-radius: 0.8rem;
    padding: 4rem;

    .maintenance-image {
      margin: 0 auto;
    }

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;

      .light-text {
        margin-top: 0.4rem;
        color: #8d8e91;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      margin-top: 4.8rem;
      height: 100%;

      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;
      }

      .onboarding-illustration {
        height: 19rem;
        margin: 0 auto;
      }

      .auth-image {
        height: 20rem;
        margin: 0 auto;
      }

      .bottom-button {
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        
        position: relative;
        bottom: -4rem;
        width: 100%;
        height: 4.8rem;
      }

      .connect-bottom-button {
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        
        position: relative;
        bottom: -5rem;
        width: 100%;
        height: 4.8rem;
      }

      .buttons {
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        
        margin-top: 2.4rem;
        height: 4.8rem;
      }

      .safe-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 4.2rem;
        cursor: pointer;
        justify-content: space-between;

        .safe-details-wrapper {
          display: flex;
        }

        .safe-icon {
          width: 4rem;
          margin-right: 2.4rem;
        }

        .safe-right {
          position: relative;
          right: 0;
          height: 1.4rem;
          margin: auto 0;
        }

        .safe-details {
          display: flex;
          flex-direction: column;
          max-width: 20rem;
          overflow-x: hidden;
          margin: auto 0;

          .safe-name {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
          }

          .safe-address {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            margin-top: 0.6rem;
          }
        }
      }
    }

    .wallet-connected {
      position: relative;
      top: -4rem;
      left: -4rem;
      width: 40rem;
    }

    .back-button-container {
      position: relative;
      top: -1rem;
      left: 0;
      width: 0;
      height: 0;

      .back-button {
        height: 4.8rem;
        width: 4.8rem;
        cursor: pointer;
      }
    }

    .bottom-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: #7367f0;
      cursor: pointer;
      margin-top: 2rem;
    }
  }

  .container-medium {
    display: flex;
    flex-direction: column;
    height: 54rem;
    width: 76rem;
    margin: 0 auto;
    background: white;
    border-radius: 0.8rem;
    padding: 4rem;

    .title-container {
      margin-left: 7.2rem;
      text-align: left;
      margin-top: -1.3rem;

      .text {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
      }

      .light-text {
        color: #8d8e91;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        margin-top: 0.4rem;
      }
    }

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      margin-left: 7.2rem;
    }

    .light-text {
      color: #8d8e91;
      margin-top: 1rem;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
    }

    .content {
      display: flex;
      flex-direction: column;
      margin-top: 4.8rem;
      height: 100%;

      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      .text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 3.8rem;
      }

      ::-webkit-scrollbar {
        display: none;
      }

      .bottom-button {
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        
        position: relative;
        bottom: -4rem;
        width: 100%;
        height: 4.8rem;
      }

      .safe-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 4.2rem;

        .safe-icon {
          width: 4rem;
          margin-right: 2.4rem;
        }

        .safe-right {
          position: relative;
          right: 0;
          height: 1.4rem;
          margin: auto 0;
        }

        .safe-details {
          display: flex;
          flex-direction: column;
          max-width: 20rem;
          overflow-x: hidden;
          margin: auto 0;

          .safe-name {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
          }

          .safe-address {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            margin-top: 0.6rem;
          }
        }
      }

      .organisation-name {
        font-size: 16px;
        min-height: 4.8rem;
      }

      .organisation-types {
        display: grid;
        margin-top: 1.6rem;
        grid-auto-flow: column;
        grid-gap: 12px;

        .organisation-type {
          cursor: pointer;
        }
      }

      .owner-container {
        margin: 0.8rem 0;

        .owner-name {
          font-size: 16px;
          display: inline;
          max-width: 22rem;
          min-height: 4.8rem;
          border-radius: 0.25rem 0 0 0.25rem;
        }

        .owner-address {
          font-size: 16px;
          display: inline;
          max-width: 42rem;
          min-height: 4.8rem;
          border-radius: 0 0.25rem 0.25rem 0;
          margin-left: -0.1rem;
        }

        .owner-delete {
          margin: auto 0 0.5rem 2.25rem;
        }
      }

      .add-owner {
        cursor: pointer;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 1.6rem;
        color: #7367f0;
      }

      .threshold-container {
        display: grid;
        grid-gap: 1.5em;
        justify-content: flex-start;
        grid-template-columns: repeat(5, 1fr);

        .box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 12.4rem;
          height: 12.4rem;
          text-align: center;
          border: 0.5px solid #81838780;
          cursor: pointer;
          border-radius: 0.4rem;
          user-select: none;

          font-size: 56px;
          font-style: normal;
          font-weight: 400;
          line-height: 67px;
          letter-spacing: 0em;
          text-align: center;
          color: #8d8e91;
        }

        .box--selected {
          border: 1.5px solid #7367f0;
          color: #232427;
          background: #7367f014;
        }
      }

      .warning-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #e88401;

        max-width: 48rem;
        margin-top: 1.6rem;
      }

      .review-organisation-name {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #232427;
      }

      .review-light-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #8d8e91;
        margin-top: 0.6rem;
      }

      .review-owner-list {
        display: flex;
        flex-direction: column;
        margin-top: 4rem;

        .review-owner-container {
          display: flex;
          flex-direction: row;
          margin-bottom: 3.4rem;

          .review-owner-index {
            font-size: 24px;
            font-style: normal;
            font-weight: 800;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: center;
            color: white;

            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 4rem;
            width: 4rem;
            border-radius: 100%;
            margin-right: 2.4rem;
            background: #7367f03d;
          }

          .review-owner-details {
            display: flex;
            flex-direction: column;
            max-width: 40rem;
            overflow-x: hidden;
            margin: auto 0;

            .review-owner-name {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 19px;
              letter-spacing: 0em;
              text-align: left;
              color: #232427;
            }

            .review-owner-address {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 17px;
              letter-spacing: 0em;
              text-align: left;
              color: #8d8e91;
              margin-top: 0.6rem;
            }
          }
        }
      }
    }

    .review-ribbon-container {
      position: relative;
      top: -4rem;
      right: -48.2rem;
      width: 0;
      height: 0;

      .back-button {
        width: width: 23.8rem;
        cursor: pointer;
      }
    }

    .back-button-container {
      position: relative;
      top: -1rem;
      left: 0;
      width: 0;
      height: 0;

      .back-button {
        height: 4.8rem;
        width: 4.8rem;
        cursor: pointer;
      }
    }

    .bottom-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: #7367f0;
    }
  }

  .container-empty {
    color: white;

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;

      margin-top: 3.2rem;
    }

    .subtitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;

      margin-top: 1.6rem;
    }

    .view-transaction-button {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      
      height: 4.8rem;
      background: white;
      color: #7367f0;
      margin: 4.8rem auto 0 auto;
    }

    .goto-review-button {
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      
      height: 4.8rem;
      margin: 1.6rem auto 0 auto;
    }

    .failure-image-container {
      display: flex;
      justify-content: center;

      .failure-image {
        width: 20rem;
        height: 20rem;
      }
    }
  }
`;
