import styled from 'styled-components/macro';

export default styled.div`
  padding: 4rem;

  .format-csv {
    font-size: 1.2rem;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #7367f0;
  }

  .text {
    font-size: 1.4rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #373737;
  }

  .points-to-remember {
    margin-left: 6.4rem;

    .title {
      font-size: 1.6rem;
      font-weight: 900;
      text-align: left;
      color: #373737;
      margin: 2rem 0;
    }

    .points {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 1.4rem;
        font-weight: normal;
        text-align: left;
        color: #8d8e91;
        padding-bottom: 1.2rem;
        display: flex;
        align-items: center;
      }
    }
  }
`;
