import styled from 'styled-components/macro';

export default styled.div.attrs(({ status }) => {
  const props = {
    borderColor:
      status === 'Failed'
        ? '#FF4B55'
        : status === 'Awaiting Signatures'
        ? '#fcbc04'
        : status === 'Pending Execution'
        ? '#7367F0'
        : status === 'Success'
        ? '#6CB44C'
        : status === 'Rejected'
        ? '#FF4B55'
        : 'transparent',
  };

  return props;
})(
  props => `
  padding: 1.8rem 0;

  .transactionStatus {
    margin-right: 1.6rem;
    background: #f8f9fa;
    border: 0.1rem solid ${props.borderColor};
    border-radius: 50%;
    padding: 0.7rem 0.45rem 0.7rem 0.7rem;
    position:relative;

    .TxStatusIndicator{
      position:absolute;
      width:1.4rem;
      height:1.4rem;
      right: -.4rem;
      top: -0.2rem;
    }
  }

  .payeeName{
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #373737;
  }

 .status{
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #8D8E91;
    margin-top:.4rem
  }
`
);
