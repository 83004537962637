/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as modal } from 'redux-modal';

import globalReducer from './global/reducer';
import themeReducer from './theme/reducer';
import history from 'utils/history';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: globalReducer,
    router: connectRouter(history),
    theme: themeReducer,
    modal,
    ...injectedReducers,
  });

  return rootReducer;
}
