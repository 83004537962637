import styled from 'styled-components/macro';

export const ReleaseContainer = styled.div`
  padding: 4px 24px;
  font-size: 14px;
  background: #7366f0;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }

  p {
    margin: auto;
  }
`;
