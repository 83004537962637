import React from 'react';
import { Label } from './styles';

const Radio = ({
  id,
  register,
  required,
  pattern,
  label,
  name,
  style,
  ...rest
}) => {
  const renderLabel = () => {
    if (typeof label === 'function') {
      return label();
    } else {
      return label;
    }
  };

  return (
    <Label>
      <input
        type="radio"
        id={id || name}
        name={name}
        ref={register}
        {...rest}
      />
      <label className="radio-label" htmlFor={id || name} style={style}>
        {renderLabel()}
      </label>
    </Label>
  );
};

export default Radio;
