import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uniqueId } from 'lodash';
import produce from 'immer';
import axios from 'axios';
import { Input } from 'reactstrap';
import Lottie from 'react-lottie';

import { useActiveWeb3React, useContract, useLocalStorage } from 'hooks';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import Button from 'components/common/Button';
import CopyButton from 'components/common/Copy';
import Img from 'components/common/Img';
import { getEtherscanLink, minifyAddress } from 'components/common/Web3Utils';
import Loading from 'components/common/Loading';

import GnosisSafeABI from 'constants/abis/GnosisSafe.json';
import ProxyFactoryABI from 'constants/abis/ProxyFactory.json';
import { AUTH_MSG, DEFAULT_GAS_PRICE, isPolygonMainnet } from 'constants/index';
import addresses from 'constants/addresses';
import { newloginEndpoint } from 'constants/endpoints';
import { networkId } from 'constants/networks';

import gasPriceReducer from 'store/gas/reducer';
import loginReducer from 'store/login/reducer';
import registerReducer from 'store/register/reducer';
import loginWizardReducer from 'store/loginWizard/reducer';
import transactionsReducer from 'store/transactions/reducer';
import overviewReducer from 'store/overview/reducer';

import loginSaga from 'store/login/saga';
import gasPriceSaga from 'store/gas/saga';
import registerSaga from 'store/register/saga';
import loginWizardSaga from 'store/loginWizard/saga';
import transactionsSaga from 'store/transactions/saga';
import overviewSaga from 'store/overview/saga';

import {
  makeSelectSafes,
  makeSelectParcelLoading,
  makeSelectGnosisLoading,
  makeSelectGnosisSafeOwners,
  makeSelectGnosisSafeThreshold,
  makeSelectFetchingSafeDetails,
  makeSelectGnosisSafes,
} from 'store/loginWizard/selectors';
import { makeSelectAverageGasPrice } from 'store/gas/selectors';

import { openSafe } from 'store/login/actions';
import { registerUser } from 'store/register/actions';
import {
  setOwnerDetails,
  setOwnersAndThreshold,
  setOrganisationType,
} from 'store/global/actions';
import {
  getSafes,
  getParcelSafes,
  chooseSafe,
  getSafeOwners,
} from 'store/loginWizard/actions';
import { getGasPrice } from 'store/gas/actions';
import { viewTransactions } from 'store/transactions/actions';
import { getOverview } from 'store/overview/actions';
import { getTokens } from 'store/tokens/actions';

// import onboardingBackgroundPNG from 'assets/images/NewLogin/onboarding-background.png';
// import backgroundSVG from 'assets/images/NewLogin/background.svg';
import onboardingIllustrationSVG from 'assets/images/NewLogin/onboarding_illustration.png';
import authPNG from 'assets/images/NewLogin/auth.png';
import backButtonSVG from 'assets/images/NewLogin/back-button.svg';
import caretRightSVG from 'assets/images/NewLogin/caret-right.svg';
import companySVG from 'assets/images/NewLogin/company.svg';
import companySelectedSVG from 'assets/images/NewLogin/company-selected.svg';
import daoSVG from 'assets/images/NewLogin/dao.svg';
import daoSelectedSVG from 'assets/images/NewLogin/dao-selected.svg';
import deleteSVG from 'assets/images/NewLogin/delete.svg';
import failedSVG from 'assets/images/NewLogin/failed.svg';
import individualSVG from 'assets/images/NewLogin/individual.svg';
import individualSelectedSVG from 'assets/images/NewLogin/individual-selected.svg';
import parcelLogoSVG from 'assets/images/NewLogin/parcel-logo.svg';
import ribbonSVG from 'assets/images/NewLogin/ribbon.svg';
import safeSVG from 'assets/images/NewLogin/safe.svg';
import walletConnectedSVG from 'assets/images/NewLogin/wallet-connected.svg';
import walletSVG from 'assets/images/NewLogin/wallet.svg';
import animationData from 'assets/videos/Loader.json';

import { LoginPage } from './styles';
import NetworkSelector from './NetworkSelector';
import WalletNotSupportedModal from './WalletNotSupportedModal';

const { GNOSIS_SAFE_ADDRESS, PROXY_FACTORY_ADDRESS, ZERO_ADDRESS } = addresses;

const loginKey = 'login';
const loginWizardKey = 'loginWizard';
const registerKey = 'register';
const gasPriceKey = 'gas';
const transactionsKey = 'transactions';
const overviewKey = 'overview';

const authenticateWallet = async (signedMessage, ownerAddress) => {
  try {
    const result = await axios.post(newloginEndpoint, {
      ownerAddress,
      signedMessage,
      networkId,
    });

    if (result.data.flag !== 200) {
      throw new Error('Signature did not match');
    } else {
      return result.data.access_token;
    }
  } catch {
    throw new Error('Login request failed, try again');
  }
};

const LoginLoader = () => (
  <div className="loader">
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      isClickToPauseDisabled={true}
      height={200}
      width={200}
    />
  </div>
);

const imgUrls = {
  // onboardingBackground: onboardingBackgroundPNG,
  // background: backgroundSVG,
  onboardingIllustration: onboardingIllustrationSVG,
  auth: authPNG,
  backButton: backButtonSVG,
  caretRight: caretRightSVG,
  delete: deleteSVG,
  organisationTypes: {
    Individual: {
      normal: individualSVG,
      selected: individualSelectedSVG,
    },
    Company: {
      normal: companySVG,
      selected: companySelectedSVG,
    },
    DAO: {
      normal: daoSVG,
      selected: daoSelectedSVG,
    },
  },
  logo: parcelLogoSVG,
  safe: safeSVG,
  ribbon: ribbonSVG,
  walletConnected: walletConnectedSVG,
  wallet: walletSVG,
  failed: failedSVG,
};

const organisationTypes = [
  {
    name: 'Individual',
    value: 'individual',
    type: 1,
    isPrivate: true,
  },
  {
    name: 'Company',
    value: 'company',
    type: 1,
    isPrivate: true,
  },
  {
    name: 'DAO',
    value: 'dao',
    type: 1,
    isPrivate: false,
  },
];

const initialPageState = {
  organisationName: '',
  organisation: organisationTypes[2],
  owners: [],
  threshold: 1,
  safeAddress: '',
  transactionHash: '',
};

const views = {
  'onboard.connect': 0,
  'onboard.authenticate': 1,
  'onboard.select': 2,
  'login.chooseSafe': 3,
  'login.safeSelected': 4,
  'create.org': 5,
  'create.owners': 6,
  'create.threshold': 7,
  'create.review': 8,
  'create.confirm': 9,
  'create.failed': 10,
  'create.loading': 11,
  'import.chooseSafe': 12,
  'import.org': 13,
  'import.owners': 14,
  'import.review': 15,
  'import.loading': 16,
};

const Login = () => {
  const setSign = useLocalStorage('SIGNATURE')[1];
  const [pageState, setPageState] = useState(initialPageState);
  const [view, setView] = useState(views['onboard.connect']);
  const [loadingConnect, setLoadingConnect] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [showWalletNotSupported, setShowWalletNotSupported] = useState(false);

  // Reducers
  useInjectReducer({ key: loginKey, reducer: loginReducer });
  useInjectReducer({ key: registerKey, reducer: registerReducer });
  useInjectReducer({ key: gasPriceKey, reducer: gasPriceReducer });
  useInjectReducer({ key: loginWizardKey, reducer: loginWizardReducer });
  useInjectReducer({ key: overviewKey, reducer: overviewReducer });
  useInjectReducer({ key: transactionsKey, reducer: transactionsReducer });

  // Sagas
  useInjectSaga({ key: loginKey, saga: loginSaga });
  useInjectSaga({ key: registerKey, saga: registerSaga });
  useInjectSaga({ key: gasPriceKey, saga: gasPriceSaga });
  useInjectSaga({ key: loginWizardKey, saga: loginWizardSaga });
  useInjectSaga({ key: overviewKey, saga: overviewSaga });
  useInjectSaga({ key: transactionsKey, saga: transactionsSaga });

  // Selectors
  const safes = useSelector(makeSelectSafes());
  const gnosisSafes = useSelector(makeSelectGnosisSafes());
  const averageGasPrice = useSelector(makeSelectAverageGasPrice());
  const gnosisSafeOwners = useSelector(makeSelectGnosisSafeOwners());
  const gnosisSafeThreshold = useSelector(makeSelectGnosisSafeThreshold());
  const getParcelSafesLoading = useSelector(makeSelectParcelLoading());
  const getGnosisSafesLoading = useSelector(makeSelectGnosisLoading());
  const fetchingSafeDetails = useSelector(makeSelectFetchingSafeDetails());

  const dispatch = useDispatch();

  const { chainId, account, library, connector, onboard } =
    useActiveWeb3React();

  // Contracts
  const gnosisSafeMasterContract = useContract(
    GNOSIS_SAFE_ADDRESS,
    GnosisSafeABI,
    true
  );

  const proxyFactory = useContract(
    PROXY_FACTORY_ADDRESS,
    ProxyFactoryABI,
    true
  );

  const hideWalletNotSupportedModal = () => {
    setShowWalletNotSupported(false);
    connectWallet();
  };
  useEffect(() => {
    if (!averageGasPrice) {
      dispatch(getGasPrice());
    }
  }, [dispatch, averageGasPrice]);

  useEffect(() => {
    const connectedWalletName = connector?.provider?.walletMeta?.name;
    if (connectedWalletName === 'Argent') {
      disconnectWallet();
      setShowWalletNotSupported(true);
    }
    //eslint-disable-next-line
  }, [connector]);

  useEffect(() => {
    setPageState(p =>
      produce(p, draft => {
        if (gnosisSafeOwners.length) {
          draft.owners = gnosisSafeOwners.map(item => ({
            id: String(uniqueId()),
            name: '',
            address: item,
          }));
        } else {
          draft.owners = [
            {
              id: String(uniqueId()),
              name: '',
              address: account,
            },
          ];
        }
      })
    );
  }, [gnosisSafeOwners, account]);

  const {
    organisationName,
    organisation,
    owners,
    threshold,
    selectedSafe,
    transactionHash,
  } = pageState;

  const gotoView = view => () => {
    setView(view);
  };

  const connectWallet = async () => {
    if (account) {
      gotoView(views['onboard.authenticate'])();
    } else {
      if (onboard) {
        setLoadingConnect(true);
        try {
          const walletSelected = await onboard.walletSelect();
          if (walletSelected) {
            const ready = await onboard.walletCheck();
            if (ready) {
              gotoView(views['onboard.authenticate'])();
            } else {
              setLoadingConnect(false);
            }
          } else {
            setLoadingConnect(false);
          }
        } catch (err) {
          console.error(err);
          setLoadingConnect(false);
        }
      }
    }
  };

  const disconnectWallet = async () => {
    if (onboard) {
      gotoView(views['onboard.connect'])();
      onboard.walletReset();
      setLoadingConnect(false);
    }
  };

  const authenticate = async () => {
    if (!!account && !!library) {
      setIsAuthenticating(true);
      try {
        if (connector?.name === 'WalletConnect') {
          await connector?.provider?.wc
            ?.signPersonalMessage([AUTH_MSG, account])
            .then(signature => {
              authenticateWallet(signature, account)
                .then(result => {
                  authenticationSuccess(signature, result);
                })
                .catch(error => {
                  authenticationFailure(error);
                });
            })
            .catch(error => {
              authenticationFailure(error);
            });
        } else {
          await library
            .getSigner(account)
            .signMessage(AUTH_MSG)
            .then(signature => {
              authenticateWallet(signature, account)
                .then(result => {
                  authenticationSuccess(signature, result);
                })
                .catch(error => {
                  authenticationFailure(error);
                });
            })
            .catch(error => {
              authenticationFailure(error);
            });
        }
      } catch (error) {
        authenticationFailure(error);
      }
    }
  };

  const authenticationSuccess = (signature, accessToken) => {
    setIsAuthenticating(false);
    setSign(signature);
    localStorage.setItem('token', accessToken);
    refreshGnosisSafes();
    refreshSafes();
    gotoView(views['onboard.select'])();
  };

  const authenticationFailure = error => {
    setIsAuthenticating(false);
    console.error(error);
    setSign(false);
  };

  const refreshSafes = () => {
    dispatch(getParcelSafes(account));
  };

  const refreshGnosisSafes = () => {
    dispatch(getSafes(account, 1));
  };

  const selectSafe = safeAddress => () => {
    if (safeAddress) {
      gotoView(views['login.safeSelected'])();

      const ownerName = safes
        ?.find(
          safe =>
            String(safe.safeAddress).toLowerCase() ===
            String(safeAddress).toLowerCase()
        )
        ?.owners?.find(
          owner =>
            String(owner.owner).toLowerCase() === String(account).toLowerCase()
        )?.name;

      dispatch(setOwnerDetails(ownerName, safeAddress, account));
      dispatch(chooseSafe(safeAddress));
      dispatch(viewTransactions(safeAddress));
      dispatch(getOverview(safeAddress));
      dispatch(getTokens(safeAddress));
      dispatch(
        openSafe({
          owners,
          threshold,
          organisationType: organisation.type,
          safeAddress,
          log: '',
          account,
        })
      );
    }
  };

  const selectGnosisSafe = safeAddress => () => {
    setPageState(
      produce(pageState, draft => {
        draft.selectedSafe = safeAddress;
      })
    );

    dispatch(getSafeOwners(safeAddress));
    gotoView(views['import.org'])();
  };

  const onOrganisationNameChange = event => {
    setPageState(
      produce(pageState, draft => {
        draft.organisationName = event.target.value;
      })
    );
  };

  const selectOrganisationType = organisationTypeName => () => {
    setPageState(
      produce(pageState, draft => {
        draft.organisation = organisationTypes.find(
          item => item.name === organisationTypeName
        );
      })
    );
  };

  const selectThreshold = threshold => () => {
    setPageState(
      produce(pageState, draft => {
        draft.threshold = threshold;
      })
    );
  };

  const onOwnerAddressChange = ownerId => event => {
    setPageState(
      produce(pageState, draft => {
        const owner = draft.owners.find(item => item.id === ownerId);
        owner.address = event.target.value;
      })
    );
  };

  const onOwnerNameChange = ownerId => event => {
    setPageState(
      produce(pageState, draft => {
        const owner = draft.owners.find(item => item.id === ownerId);
        owner.name = event.target.value;
      })
    );
  };

  const removeOwner = id => () => {
    setPageState(
      produce(pageState, draft => {
        draft.owners = draft.owners.filter(owner => owner.id !== id);
      })
    );
  };

  const addOwner = () => {
    setPageState(
      produce(pageState, draft => {
        draft.owners.push({
          id: String(uniqueId()),
          name: '',
          address: '',
        });
      })
    );
  };

  const redirectToEtherscan = () => {
    if (transactionHash) {
      const url = getEtherscanLink({ chainId, hash: transactionHash });
      window.open(url, '_blank');
    }
  };

  const handleGotoReview = () => {
    setPageState(
      produce(pageState, draft => {
        draft.creationData = null;
        draft.createdSafeAddress = '';
        draft.transactionHash = '';
      })
    );
    gotoView(views['create.review'])();
  };

  const createSafe = async () => {
    if (gnosisSafeMasterContract && proxyFactory && account) {
      try {
        const ownerAddresses = owners.length
          ? owners.map(item => item.address)
          : [account];
        const formattedThreshold = threshold ? parseInt(threshold) : 1;
        const creationData =
          gnosisSafeMasterContract.interface.encodeFunctionData('setup', [
            ownerAddresses,
            formattedThreshold,
            ZERO_ADDRESS,
            ZERO_ADDRESS,
            ZERO_ADDRESS,
            ZERO_ADDRESS,
            0,
            ZERO_ADDRESS,
          ]);

        // Execute normal transaction
        // Create Proxy
        const estimateGas = await proxyFactory.estimateGas.createProxy(
          GNOSIS_SAFE_ADDRESS,
          creationData
        );

        const tx = await proxyFactory.createProxy(
          GNOSIS_SAFE_ADDRESS,
          creationData,
          {
            gasLimit: estimateGas,
            gasPrice: averageGasPrice || DEFAULT_GAS_PRICE,
          }
        );

        setPageState(
          produce(pageState, draft => {
            draft.transactionHash = tx.hash;
          })
        );

        gotoView(views['create.loading'])();

        const result = await tx.wait();
        setPageState(
          produce(pageState, draft => {
            //if polygon network => safeAddress fields are different
            if (networkId === 137)
              draft.createdSafeAddress = result.events[0].address;
            else draft.createdSafeAddress = result.events[0].args[0];
            draft.creationData = creationData;
          })
        );
        if (networkId === 137) signup(creationData, result.events[0].address);
        else signup(creationData, result.events[0].args[0]);

        console.info('tx success', result);
      } catch (error) {
        gotoView(views['create.failed'])();
        console.error('create safe failed: ', error);
      }
    } else {
      gotoView(views['create.failed'])();
      console.error('create safe failed.');
    }
  };

  const signup = async (creationData, createdSafeAddress) => {
    gotoView(views['create.loading'])();

    const body = {
      name: organisationName,
      organisationType: organisation?.type,
      safeAddress: createdSafeAddress,
      createdBy: account,
      proxyData: {
        from: account,
        params: [GNOSIS_SAFE_ADDRESS, creationData],
      },
      owners: owners.map(item => ({
        owner: item.address,
        name: item.name,
      })),
      threshold,
      account,
      isPrivate: organisation?.isPrivate,
      organisationKind: organisation?.value,
    };

    const ownerName = owners.find(owner => owner.address === account)?.name;
    dispatch(setOwnerDetails(ownerName, createdSafeAddress, account));
    dispatch(setOwnersAndThreshold(owners, threshold));
    dispatch(setOrganisationType(organisation.type));

    dispatch(registerUser(body, true));
  };

  const handleSignup = async () => {
    gotoView(views['create.confirm'])();
    await createSafe();
  };

  const handleImport = () => {
    gotoView(views['import.loading'])();

    const body = {
      name: organisationName,
      organisationType: organisation?.type,
      safeAddress: selectedSafe,
      createdBy: account,
      owners: owners.map(item => ({
        owner: item.address,
        name: item.name,
      })),
      threshold: gnosisSafeThreshold,
      account,
      isPrivate: organisation?.isPrivate,
      organisationKind: organisation?.value,
    };

    const ownerName = owners.find(owner => owner.address === account).name;
    dispatch(setOwnerDetails(ownerName, selectedSafe, account));
    dispatch(setOwnersAndThreshold(owners, gnosisSafeThreshold));
    dispatch(setOrganisationType(organisation.type));

    dispatch(registerUser(body));
  };

  const renderView = () => {
    switch (view) {
      case views['onboard.connect']:
        return (
          <div className="container-small">
            <div className="title">
              <div className="text">
                {account ? 'Wallet connected' : 'Connect your wallet'}
              </div>
              <div className="light-text">
                This is required to login, create or import your safes
              </div>
            </div>
            <div className="content">
              <div className="onboarding-illustration">
                <Img src={imgUrls.onboardingIllustration} width={200} alt="" />
              </div>
              <Button
                className="connect-bottom-button"
                type="button"
                onClick={connectWallet}
                loading={loadingConnect}
                disabled={loadingConnect}
              >
                {account ? 'Get Started' : 'Connect Wallet'}
              </Button>
            </div>
          </div>
        );

      case views['onboard.authenticate']:
        if (account) {
          return (
            <div className="container-small">
              <div className="title">
                <div className="text">Authenticate your wallet</div>
                <div className="light-text">
                  This is required to login, create or import your safes
                </div>
              </div>
              <div className="content">
                <div className="auth-image">
                  <Img src={imgUrls.auth} width={200} alt="" />
                </div>

                <Button
                  className="bottom-button"
                  type="button"
                  onClick={authenticate}
                  loading={isAuthenticating}
                >
                  Authenticate
                </Button>
              </div>
            </div>
          );
        } else {
          gotoView(views['onboard.connect'])();
          setLoadingConnect(false);
          break;
        }

      case views['onboard.select']:
        return (
          <div className="container-small">
            <Img
              className="wallet-connected"
              src={imgUrls.walletConnected}
              alt=""
            />
            <div className="title">How would you like to proceed?</div>
            <div className="content">
              <Button
                className="buttons"
                type="button"
                onClick={gotoView(views['login.chooseSafe'])}
              >
                Login
              </Button>
              <Button
                className="buttons"
                type="button"
                onClick={gotoView(views['create.org'])}
              >
                Create Safe
              </Button>
              <Button
                className="buttons"
                style={{
                  background: 'white',
                  border: '1.5px solid #7367f0',
                  color: '#7367f0',
                }}
                type="button"
                onClick={gotoView(views['import.chooseSafe'])}
              >
                Import Existing Safe
              </Button>
            </div>
          </div>
        );

      case views['login.chooseSafe']:
        return (
          <div className="container-small">
            <div
              className="back-button-container"
              onClick={gotoView(views['onboard.select'])}
            >
              <Img
                className="back-button"
                src={imgUrls.backButton}
                alt="go back"
              />
            </div>
            <div className="title">
              <div className="text">Choose Safe</div>
            </div>
            <div className="content">
              {getParcelSafesLoading ? (
                <div className="d-flex align-items-center justify-content-center mt-5">
                  <Loading color="primary" width="50px" height="50px" />
                </div>
              ) : (
                safes?.length > 0 &&
                safes.map(safe => (
                  <div
                    key={safe?.safeAddress}
                    className="safe-container"
                    onClick={selectSafe(safe?.safeAddress)}
                  >
                    <div className="safe-details-wrapper">
                      <Img
                        className="safe-icon"
                        src={imgUrls.safe}
                        alt={safe?.name}
                      />
                      <div className="safe-details">
                        <div className="safe-name">{safe.name}</div>
                        <div className="safe-address">
                          {minifyAddress(safe?.safeAddress)}
                        </div>
                      </div>
                    </div>
                    <Img
                      className="safe-right"
                      src={imgUrls.caretRight}
                      alt=""
                    />
                  </div>
                ))
              )}
            </div>
            <div className="bottom-text" onClick={refreshSafes}>
              Safe not loaded?
            </div>
          </div>
        );

      case views['login.safeSelected']:
        return (
          <div className="container-empty">
            <LoginLoader />
            <div className="title">Logging In...</div>
            <div className="subtitle">
              This process might take a few moments
            </div>
          </div>
        );

      case views['create.org']:
        return (
          <div className="container-medium">
            <div
              className="back-button-container"
              onClick={gotoView(views['onboard.select'])}
            >
              <Img
                className="back-button"
                src={imgUrls.backButton}
                alt="go back"
              />
            </div>
            <div className="title">
              <div className="text">Create a safe with Parcel</div>
            </div>
            <div className="content">
              <Input
                className="organisation-name"
                name="organisationName"
                value={organisationName}
                onChange={onOrganisationNameChange}
                placeholder="Organisation Name"
              />
              <div className="light-text">
                You will be registered on parcel with this name
              </div>
              <div className="text">What defines your organization best?</div>
              <div className="organisation-types">
                {organisationTypes.map(organisationType => {
                  const imgUrl =
                    organisationType.name === organisation.name
                      ? imgUrls.organisationTypes[organisationType.name]
                          .selected
                      : imgUrls.organisationTypes[organisationType.name].normal;
                  return (
                    <div
                      key={organisationType.name}
                      className="organisation-type"
                      onClick={selectOrganisationType(organisationType.name)}
                    >
                      <Img
                        className="organisation-type-image"
                        src={imgUrl}
                        alt={organisationType.name}
                      />
                    </div>
                  );
                })}
              </div>
              <Button
                className="bottom-button"
                type="button"
                onClick={gotoView(views['create.owners'])}
              >
                Proceed
              </Button>
            </div>
          </div>
        );

      case views['create.owners']:
        return (
          <div className="container-medium">
            <div
              className="back-button-container"
              onClick={gotoView(views['create.org'])}
            >
              <Img
                className="back-button"
                src={imgUrls.backButton}
                alt="go back"
              />
            </div>
            <div className="title-container">
              <div className="text">
                Enter the name & wallet address of the owners
              </div>
              <div className="light-text">
                To maintain maximum security, add more than one owner
              </div>
            </div>
            <div className="content">
              {owners.map(owner => (
                <div key={owner.id} className="owner-container">
                  <Input
                    className="owner-name"
                    name={`owner-name-${owner.id}`}
                    value={owner.name}
                    onChange={onOwnerNameChange(owner.id)}
                    required
                    placeholder="Name"
                  />
                  <Input
                    className="owner-address"
                    name={`owner-address-${owner.id}`}
                    value={owner.address}
                    onChange={onOwnerAddressChange(owner.id)}
                    required
                    placeholder="Address"
                  />
                  <Img
                    className="owner-delete"
                    src={imgUrls.delete}
                    alt="remove"
                    onClick={removeOwner(owner.id)}
                  />
                </div>
              ))}
              <div className="add-owner" onClick={addOwner}>
                + Add Owner
              </div>

              <Button
                className="bottom-button"
                type="button"
                onClick={gotoView(views['create.threshold'])}
              >
                Proceed
              </Button>
            </div>
          </div>
        );

      case views['create.threshold']:
        return (
          <div className="container-medium">
            <div
              className="back-button-container"
              onClick={gotoView(views['create.owners'])}
            >
              <Img
                className="back-button"
                src={imgUrls.backButton}
                alt="go back"
              />
            </div>
            <div className="title-container">
              <div className="text">
                How many people should approve a transaction?
              </div>
              <div className="light-text">
                Multiple approvals are better for maintaining security
              </div>
            </div>
            <div className="content">
              <div className="threshold-container">
                {owners.map((_, index) => (
                  <div
                    key={index}
                    className={`box ${
                      threshold === index + 1 ? 'box--selected' : ''
                    }`}
                    onClick={selectThreshold(index + 1)}
                  >
                    {index + 1}
                  </div>
                ))}
              </div>
              {threshold === 1 && (
                <div className="warning-text">
                  Having a single approval for every transaction might
                  compromise your safe's security.
                </div>
              )}
              <Button
                className="bottom-button"
                type="button"
                onClick={gotoView(views['create.review'])}
              >
                Proceed
              </Button>
            </div>
          </div>
        );

      case views['create.review']:
        return (
          <div className="container-medium">
            <div className="review-ribbon-container">
              <Img className="review-ribbon" src={imgUrls.ribbon} alt="" />
            </div>
            <div
              className="back-button-container"
              onClick={gotoView(views['create.threshold'])}
            >
              <Img
                className="back-button"
                src={imgUrls.backButton}
                alt="go back"
              />
            </div>
            <div className="title">
              <div className="text">Review Details</div>
            </div>
            <div className="content">
              <div className="review-organisation-name">{organisationName}</div>
              <div className="review-light-text">
                {organisation.name} •{' '}
                {`${threshold} out of ${owners.length} owner approvals required per transaction`}
              </div>
              <div className="review-owner-list">
                {owners.map((owner, index) => (
                  <div key={owner.id} className="review-owner-container">
                    <div className="review-owner-index">{index + 1}</div>
                    <div className="review-owner-details">
                      <div className="review-owner-name">{owner.name}</div>
                      <div className="review-owner-address">
                        {owner.address}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Button
                className="bottom-button"
                type="button"
                onClick={handleSignup}
              >
                Create Safe
              </Button>
            </div>
          </div>
        );

      case views['create.confirm']:
        return (
          <div className="container-empty">
            <LoginLoader />
            <div className="title">Waiting for confirmation</div>
            <div className="subtitle">
              Please confirm this transaction from your wallet.
              <br />
              Please do not leave this page
            </div>
          </div>
        );

      case views['create.loading']:
        return (
          <div className="container-empty">
            <LoginLoader />
            <div className="title">Creating Safe...</div>
            <div className="subtitle">
              This process might take a couple of minutes.
              <br />
              Please do not leave this page
            </div>
            <Button
              className="view-transaction-button"
              type="button"
              onClick={redirectToEtherscan}
            >
              View Transaction on{' '}
              {isPolygonMainnet ? 'Polygonscan' : 'Etherscan'}
            </Button>
          </div>
        );

      case views['create.failed']:
        return (
          <div className="container-empty">
            <div className="failure-image-container">
              <Img className="failure-image" src={imgUrls.failed} alt="" />
            </div>
            <div className="title">Safe could not be created</div>
            <div className="subtitle">
              The blockchain transaction to create the safe failed
            </div>
            <Button
              className="view-transaction-button"
              type="button"
              onClick={redirectToEtherscan}
            >
              View Transaction on{' '}
              {isPolygonMainnet ? 'Polygonscan' : 'Etherscan'}
            </Button>
            <Button
              className="goto-review-button"
              type="button"
              onClick={handleGotoReview}
            >
              Back to review details
            </Button>
          </div>
        );

      case views['import.chooseSafe']:
        return (
          <div className="container-small">
            <div
              className="back-button-container"
              onClick={gotoView(views['onboard.select'])}
            >
              <Img
                className="back-button"
                src={imgUrls.backButton}
                alt="go back"
              />
            </div>
            <div className="title">
              <div className="text">Import Safe</div>
            </div>
            <div className="content">
              {getGnosisSafesLoading ? (
                <div className="d-flex align-items-center justify-content-center mt-5">
                  <Loading color="primary" width="50px" height="50px" />
                </div>
              ) : (
                gnosisSafes?.length > 0 &&
                gnosisSafes.map((safe, index) => (
                  <div
                    key={safe}
                    className="safe-container"
                    onClick={selectGnosisSafe(safe)}
                  >
                    <div className="safe-details-wrapper">
                      <Img className="safe-icon" src={imgUrls.safe} alt="" />
                      <div className="safe-details">
                        <div className="safe-name">Gnosis Safe {index + 1}</div>
                        <div className="safe-address">
                          {minifyAddress(safe)}
                        </div>
                      </div>
                    </div>
                    <Img
                      className="safe-right"
                      src={imgUrls.caretRight}
                      alt=""
                    />
                  </div>
                ))
              )}
            </div>
            <div className="bottom-text" onClick={refreshGnosisSafes}>
              Safe not loaded?
            </div>
          </div>
        );

      case views['import.org']:
        return (
          <div className="container-medium">
            <div
              className="back-button-container"
              onClick={gotoView(views['import.chooseSafe'])}
            >
              <Img
                className="back-button"
                src={imgUrls.backButton}
                alt="go back"
              />
            </div>
            <div className="title">
              <div className="text">Enter organization details</div>
            </div>
            <div className="content">
              <Input
                className="organisation-name"
                name="organisationName"
                value={organisationName}
                onChange={onOrganisationNameChange}
                placeholder="Organisation Name"
              />
              <div className="light-text">
                You will be registered on parcel with this name
              </div>
              <div className="text">What defines your organization best?</div>
              <div className="organisation-types">
                {organisationTypes.map(organisationType => {
                  const imgUrl =
                    organisationType.name === organisation.name
                      ? imgUrls.organisationTypes[organisationType.name]
                          .selected
                      : imgUrls.organisationTypes[organisationType.name].normal;
                  return (
                    <div
                      key={organisationType.name}
                      className="organisation-type"
                      onClick={selectOrganisationType(organisationType.name)}
                    >
                      <Img
                        className="organisation-type-image"
                        src={imgUrl}
                        alt={organisationType.name}
                      />
                    </div>
                  );
                })}
              </div>
              <Button
                className="bottom-button"
                type="button"
                onClick={gotoView(views['import.owners'])}
              >
                Proceed
              </Button>
            </div>
          </div>
        );

      case views['import.owners']:
        return (
          <div className="container-medium">
            <div
              className="back-button-container"
              onClick={gotoView(views['import.org'])}
            >
              <Img
                className="back-button"
                src={imgUrls.backButton}
                alt="go back"
              />
            </div>
            <div className="title">
              <div className="text">Enter the names of the owners</div>
            </div>
            <div className="content">
              {fetchingSafeDetails ? (
                <div className="d-flex align-items-center justify-content-center mt-5">
                  <Loading color="primary" width="50px" height="50px" />
                </div>
              ) : (
                owners.map((owner, index) => (
                  <div key={owner.id} className="owner-container">
                    <Input
                      className="owner-name"
                      name={`owner-name-${owner.id}`}
                      value={owner.name}
                      onChange={onOwnerNameChange(owner.id)}
                      required
                      placeholder="Name"
                    />
                    <Input
                      className="owner-address"
                      name={`owner-address-${owner.id}`}
                      value={owner.address}
                      disabled
                    />
                  </div>
                ))
              )}

              <Button
                className="bottom-button"
                type="button"
                onClick={gotoView(views['import.review'])}
              >
                Proceed
              </Button>
            </div>
          </div>
        );

      case views['import.review']:
        return (
          <div className="container-medium">
            <div className="review-ribbon-container">
              <Img className="review-ribbon" src={imgUrls.ribbon} alt="" />
            </div>
            <div
              className="back-button-container"
              onClick={gotoView(views['import.owners'])}
            >
              <Img
                className="back-button"
                src={imgUrls.backButton}
                alt="go back"
              />
            </div>
            <div className="title">
              <div className="text">Review Details</div>
            </div>
            <div className="content">
              <div className="review-organisation-name">{organisationName}</div>
              <div className="review-light-text">
                {organisation.name} •{' '}
                {`${gnosisSafeThreshold} out of ${gnosisSafeOwners.length} owner approvals required per transaction`}
              </div>
              <div className="review-owner-list">
                {owners.map((owner, index) => (
                  <div key={owner.id} className="review-owner-container">
                    <div className="review-owner-index">{index + 1}</div>
                    <div className="review-owner-details">
                      <div className="review-owner-name">{owner.name}</div>
                      <div className="review-owner-address">
                        {owner.address}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Button
                className="bottom-button"
                type="button"
                onClick={handleImport}
              >
                Import Safe to Parcel
              </Button>
            </div>
          </div>
        );

      case views['import.loading']:
        return (
          <div className="container-empty">
            <LoginLoader />
            <div className="title">Importing Safe to Parcel...</div>
            <div className="subtitle">
              This process might take a few moments.
              <br />
              Please do not leave this page
            </div>
          </div>
        );

      default:
        return 'fail';
    }
  };

  // if (view === views['onboard.connect']) {
  //   return (
  //     <div
  //       style={{
  //         background: `url(${imgUrls.onboardingBackground})`,
  //         width: '100%',
  //         height: '90rem',
  //         opacity: 1,
  //         backgroundPosition: 'top',
  //         backgroundSize: 'cover',
  //         backgroundRepeat: 'no-repeat',
  //       }}
  //     >
  //       <div
  //         style={{
  //           // margin: "0 auto",
  //           marginLeft: '12%',
  //           // maxWidth: "calc(100% - 24rem)",
  //           display: 'flex',
  //           flexDirection: 'column',
  //         }}
  //       >
  //         <div className="logo-container" style={{ margin: '4.8rem 0 6rem 0' }}>
  //           <Img
  //             style={{ height: '3.2rem' }}
  //             className="logo"
  //             src={imgUrls.logo}
  //             alt="parcel"
  //           />
  //         </div>
  //         <p
  //           className="onboard-title"
  //           style={{
  //             fontWeight: 'bold',
  //             fontSize: '56px',
  //             lineHeight: '76px',
  //             letterSpacing: '-0.02em',
  //             color: '#fff',
  //           }}
  //         >
  //           Crypto Treasury <br />
  //           Management
  //         </p>
  //         <p
  //           className="onboard-text"
  //           style={{
  //             fontWeight: '300',
  //             fontSize: '4rem',
  //             lineHeight: '4.8rem',
  //             letterSpacing: '-0.01em',
  //             color: '#fff',
  //             margin: '1.6rem 0 4.8rem 0',
  //           }}
  //         >
  //           Simplified.
  //         </p>
  //         <div className="content">
  // <Button
  //   style={{
  //     width: '19.2rem',
  //     height: '4.8rem',
  //     fontWeight: 'bold',
  //     fontSize: '1.8rem',
  //     lineHeight: '2.2rem',
  //   }}
  //   type="button"
  //   onClick={connectWallet}
  //   loading={loadingConnect}
  //   disabled={loadingConnect}
  // >
  //             {account ? 'Get Started' : 'Connect Wallet'}
  //           </Button>
  //         </div>
  //       </div>
  //       {/* <div
  //         style={{
  //           background: `url(${landingFooter})`,
  //           width: "100%",
  //           height: "100%",
  //           opacity: 1,
  //           backgroundPosition: "top",
  //           backgroundSize: "cover",
  //           backgroundRepeat: "no-repeat",
  //         }}
  //       ></div> */}
  //     </div>
  //   );
  // }

  return (
    <LoginPage>
      <div className="login-container">
        <div>
          <div className="wallet-info-wrapper">
            <div className="network-selector-container">
              <NetworkSelector networkId={networkId} />
            </div>
            {account && (
              <>
                <div className="wallet-info-container">
                  <div className="wallet-details">
                    <Img
                      className="wallet-image"
                      src={imgUrls.wallet}
                      alt="parcel"
                    />
                    <div className="wallet-address">
                      {minifyAddress(account, 5)}
                    </div>
                    <CopyButton
                      style={{ marginLeft: 8, marginTop: 11 }}
                      id="address"
                      tooltip="Wallet Address"
                      value={account}
                    />
                  </div>
                  <Button
                    className="disconnect-button"
                    onClick={disconnectWallet}
                  >
                    Disconnect Wallet
                  </Button>
                </div>
              </>
            )}
          </div>

          <div className="logo-container">
            <Img className="logo" src={imgUrls.logo} alt="parcel" />
          </div>
          {renderView()}
        </div>
        <WalletNotSupportedModal
          isOpen={showWalletNotSupported}
          closeModal={hideWalletNotSupportedModal}
        />
      </div>
    </LoginPage>
  );
};

export default Login;
