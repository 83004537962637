import CloseIcon from 'assets/icons/dashboard/close-icon.svg';
import Img from 'components/common/Img';
import {
  Table,
  TableBody,
  TableDivider,
  TableHead,
  TableInfo,
} from 'components/common/Table';
import TokenImg from 'components/common/TokenImg';
import { getClickable } from 'components/common/Web3Utils';
import format from 'date-fns/format';
import { useActiveWeb3React } from 'hooks';
import React, { Fragment, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTransactionDetails } from 'store/layout/actions';
import {
  makeSelectIsTransactionDetailsOpen,
  makeSelectTransactionDetailsSidebar,
} from 'store/layout/selectors';
import {
  makeSelectTokenList,
  makeSelectTokensDetails,
} from 'store/tokens/selectors';
import { TableContainer } from './styles';
import TransactionDetails from './styles/TransactionSidebar';

function isObjEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const sidebarStyles = {
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '0',
    zIndex: '20',
  },
  bmMenu: {
    background: '#fff',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#fff',
  },
  bmItemList: {
    color: '#373737',
  },
  bmItem: {
    // display: "flex",
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.05)',
    top: '0',
    zIndex: '10',
  },
};

function TransactionDetailsSidebar() {
  const isTransactionDetailsOpen = useSelector(
    makeSelectIsTransactionDetailsOpen()
  );
  const transactionDetails = useSelector(makeSelectTransactionDetailsSidebar());
  const tokenDetails = useSelector(makeSelectTokensDetails());
  const [automatedTransaction, setAutomatedTransaction] = useState({});
  const tokenList = useSelector(makeSelectTokenList());
  const { chainId } = useActiveWeb3React();

  const dispatch = useDispatch();

  const handleStateChange = state => {
    dispatch(toggleTransactionDetails(state.isOpen));
  };

  const closeSidebar = () => {
    dispatch(toggleTransactionDetails(false));
  };

  useEffect(() => {
    if (!transactionDetails) return;
    const sortedTransaction = {};

    const { transactions } = transactionDetails;

    Object.values(transactions).forEach(txDetails => {
      const paidOn = format(new Date(txDetails.paidOn), 'dd MMM yyyy');
      if (sortedTransaction[paidOn]) {
        sortedTransaction[paidOn].push(txDetails);
      } else {
        sortedTransaction[paidOn] = [txDetails];
      }
    });

    setAutomatedTransaction(sortedTransaction);
  }, [transactionDetails]);

  const renderTableContent = () => {
    if (!isObjEmpty(transactionDetails)) {
      return (
        <>
          {Object.entries(automatedTransaction).map(
            ([createdOn, transaction], id) => {
              const [date, month, year] = createdOn.split(' ');
              const todayDate = format(new Date(), 'dd MMM yyyy');

              return (
                <Fragment key={`${createdOn}${id}`}>
                  <TableDivider className="flex align-items-center">
                    <td>{todayDate === createdOn ? 'today' : `${year}`}</td>
                    <td></td>
                    <td></td>
                  </TableDivider>

                  {transaction &&
                    transaction.map(
                      (
                        { paidOn, transactionHash, salaryToken, salaryAmount },
                        idx
                      ) => {
                        const lastPaidOnDate = format(
                          new Date(paidOn),
                          'dd MMM'
                        );
                        let expectedToken;
                        if (
                          salaryToken ===
                          '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
                        ) {
                          expectedToken = tokenList.find(
                            item => item.address === 'ETH'
                          );
                        } else {
                          expectedToken = tokenList.find(
                            item => item.address === salaryToken
                          );
                        }

                        return (
                          <tr key={`${paidOn}-${idx}`}>
                            <td>{lastPaidOnDate}</td>
                            <td>
                              <TokenImg icon={expectedToken.icon}></TokenImg>{' '}
                              {`${salaryAmount} ${expectedToken.name}`}
                            </td>
                            <td>
                              {getClickable(
                                transactionHash,
                                chainId,
                                'tx',
                                true
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}
                </Fragment>
              );
            }
          )}
        </>
      );
    } else {
      return (
        <TableInfo
          style={{
            fontSize: '1.4rem',
            fontWeight: '500',
            textAlign: 'center',
            height: '10rem',
          }}
        >
          <td colSpan={6}>No transactions found!</td>
        </TableInfo>
      );
    }
  };

  const renderData = transactionDetails => {
    if (!transactionDetails) return;

    const { paidTo, paidIn, lastPaidOn, personInfoDetails, transactions } =
      transactionDetails;

    let firstName = '-';
    let lastName = '-';
    let address = '-';
    if (personInfoDetails) {
      firstName = personInfoDetails.firstName || '-';
      lastName = personInfoDetails.lastName || '-';
      address = personInfoDetails.address || '-';
    }

    return (
      <div className="transaction-sidebar">
        <div className="space">
          <div className="close" onClick={closeSidebar}>
            <Img src={CloseIcon} alt="close" />
          </div>
          <p className="color-secondary">PAID TO</p>
          <p className="title">{`${firstName} ${lastName}`}</p>
          <p className="address">
            {getClickable(address, chainId, 'address', true)}
          </p>
        </div>
        <TableContainer className="mt-24">
          <Table>
            <TableHead>
              <tr>
                <th style={{ width: '20%' }}>Date</th>
                <th style={{ width: '30%' }}>Disbursement</th>
                <th style={{ width: '30%' }}>Hash</th>
              </tr>
            </TableHead>
            <TableBody>{renderTableContent()}</TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  return (
    <TransactionDetails
      styles={sidebarStyles}
      right
      customBurgerIcon={false}
      customCrossIcon={false}
      disableAutoFocus
      isOpen={isTransactionDetailsOpen}
      onStateChange={state => handleStateChange(state)}
      width={380}
    >
      {renderData(transactionDetails)}
    </TransactionDetails>
  );
}

export default memo(TransactionDetailsSidebar);
