import React from 'react';
import { format } from 'date-fns';

import Img from 'components/common/Img';
import UpIcon from 'assets/icons/dashboard/up-arrow.svg';
import DownIcon from 'assets/icons/dashboard/down-arrow.svg';
import CopyButton from 'components/common/Copy';
import { minifyAddress } from 'components/common/Web3Utils';
import StatusText from './StatusText';
import { TransactionDetails } from './styles';
import { TRANSACTION_MODES } from 'constants/transactions';
import { formatNumber } from 'utils/number-helpers';
import EtherscanLink from 'components/common/EtherscanLink';
import Collapse from 'reactstrap/lib/Collapse';

export default function Summary({ txDetails, paidTeammates }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  if (!txDetails) return null;
  const {
    transactionHash: txDetailsHash,
    tokenValue,
    tokenCurrency,
    fiatValue,
    transactionFees,
    status,
    createdOn,
    transactionMode,
    to,
  } = txDetails;

  const isSpendingLimit = transactionMode === TRANSACTION_MODES.SPENDING_LIMITS;
  const isAutomation = transactionMode === TRANSACTION_MODES.AUTOMATION;
  return (
    <TransactionDetails>
      <div className="title">Transaction Details</div>
      <div className="toggle" onClick={toggleIsOpen}>
        {isOpen ? (
          <Img className="icon ml-2" src={UpIcon} alt="collapse" />
        ) : (
          <Img className="icon ml-2" src={DownIcon} alt="expand" />
        )}
      </div>

      <Collapse isOpen={isOpen}>
        <div className="detail-cards">
          <div className="detail-card">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <div className="detail-title">Transaction Hash</div>
                <div className="detail-subtitle">
                  {minifyAddress(txDetailsHash)}
                </div>
              </div>
              <div className="icons">
                <CopyButton
                  id="address"
                  tooltip="transaction hash"
                  value={txDetailsHash}
                  className="mr-3"
                />
                <EtherscanLink
                  id="etherscan-link-summary"
                  type="tx"
                  hash={txDetailsHash}
                />
              </div>
            </div>
          </div>

          {isSpendingLimit ? (
            <div className="detail-card">
              <div className="detail-title">Allowance</div>
              <div className="detail-subtitle">
                US ${formatNumber(fiatValue)}
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div className="detail-card">
                <div className="detail-title">Paid To</div>
                <div className="detail-subtitle">
                  {paidTeammates && paidTeammates.length} people
                </div>
              </div>

              <div className="detail-card">
                <div className="detail-title">Total Amount</div>
                <div className="detail-subtitle">
                  {isAutomation
                    ? `${JSON.parse(to).gasPrice ?? 0} GWEI`
                    : `US $${formatNumber(fiatValue)} ${formatNumber(
                        tokenValue,
                        5
                      )} ${tokenCurrency}`}
                </div>
              </div>
            </React.Fragment>
          )}

          <div className="detail-card">
            <div className="detail-title">Transaction Fees</div>
            <div className="detail-subtitle">
              {formatNumber(transactionFees, 5)} ETH
            </div>
          </div>

          <div className="detail-card">
            <div className="detail-title">Created Date & Time</div>
            <div className="detail-subtitle">
              {format(new Date(createdOn), 'dd/MM/yyyy HH:mm:ss')}
            </div>
          </div>

          <div className="detail-card">
            <div className="detail-title">Status</div>
            <div className="detail-subtitle">
              <StatusText status={status} />
            </div>
          </div>
        </div>
      </Collapse>
    </TransactionDetails>
  );
}
