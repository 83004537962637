import React from 'react';
import Img from 'components/common/Img';

import NotFoundPng from 'assets/images/not-found.png';

import { Background } from './styles';
import Button from 'components/common/Button';

export default function NotFound() {
  const redirectTo = link => () => {
    window.location = link;
  };
  return (
    <Background>
      <Img src={NotFoundPng} alt="not found" width="430px" />
      <div className="title">Oops, something broke</div>
      <div className="bottom-buttons">
        <Button className="bottom-button" onClick={redirectTo('/dashboard')}>
          Go to Dashboard
        </Button>
      </div>
    </Background>
  );
}
