import React from 'react';
import { connectModal as reduxModal } from 'redux-modal';
import { useSelector } from 'react-redux';

import { Modal, ModalHeader, ModalBody } from 'components/common/Modal';
import {
  makeSelectOrganisationName,
  makeSelectOwnerSafeAddress,
} from 'store/global/selectors';
import { AddFunds } from './styles';
import CopyButton from 'components/common/Copy';
import EtherscanLink from 'components/common/EtherscanLink';
import EthereumQRplugin from 'ethereum-qr-code';

export const MODAL_NAME = 'add-funds-modal';

const qrPlugin = new EthereumQRplugin();

const EthereumQRCode = props => {
  const { to, size } = props;

  React.useEffect(() => {
    const generateQRCode = () => {
      qrPlugin.toCanvas(
        { to },
        {
          selector: `#QRCode`,
          size,
        }
      );
    };
    generateQRCode();
  }, [to, size]);

  return <div id="QRCode" />;
};

function AddFundsModal(props) {
  const { show, handleHide } = props;

  const safeAddress = useSelector(makeSelectOwnerSafeAddress());
  const orgName = useSelector(makeSelectOrganisationName());

  return (
    <Modal isOpen={show} toggle={handleHide}>
      <ModalHeader title="Add Funds" toggle={handleHide} />
      <ModalBody width="55rem" minHeight="auto">
        <AddFunds>
          <div className="desc">
            This is the address of your Safe. Deposit funds by scanning the QR
            code or copying the address below. Only send Ether and ERC-20 token
            to this address.
          </div>
          {orgName && <div className="name">{orgName}</div>}
          {safeAddress && (
            <div className="qr-code">
              <EthereumQRCode to={safeAddress} size={200} />
            </div>
          )}
          <div className="address">
            <div>{safeAddress}</div>
            <CopyButton
              id="address"
              tooltip="address"
              value={safeAddress}
              className="mx-3"
            />
            <EtherscanLink
              id="etherscan-link-add-funds"
              type="address"
              address={safeAddress}
            />
          </div>
        </AddFunds>
      </ModalBody>
    </Modal>
  );
}

export default reduxModal({ name: MODAL_NAME })(AddFundsModal);
