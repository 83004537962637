import Button from 'components/common/Button';

import { TransactionsByTeams } from 'components/Dashboard/styles';
import { FilePayoutContainer } from 'components/People/styles';
import TransactionsTableByTeams from 'components/Transactions/TransactionsTableByTeams';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Pencil } from 'assets/icons/pencil.svg';

import {
  makeSelectIsMultiOwner,
  makeSelectOrganisationType,
  makeSelectOwnerSafeAddress,
  makeSelectIsMetaTxEnabled,
} from 'store/global/selectors';
import { makeSelectIsMetaTxEnabled as makeSelectIsMetaTxLimitAllowed } from 'store/metatx/selectors';
import { makeSelectNonce } from 'store/safe/selectors';
import { useActiveWeb3React, useLocalStorage, useMassPayout } from 'hooks';
import transactionsReducer from 'store/transactions/reducer';
import transactionsSaga from 'store/transactions/saga';
import multisigReducer from 'store/multisig/reducer';

import { makeSelectPrices, makeSelectTokenList } from 'store/tokens/selectors';
import { formatNumber } from 'utils/number-helpers';
import { useHistory } from 'react-router-dom';
import {
  addTransaction,
  clearTransactionHash,
} from 'store/transactions/actions';
import { cryptoUtils } from 'parcel-sdk';
import { createMultisigTransaction } from 'store/multisig/actions';
import { getNonce } from 'store/safe/actions';
import { getTokens } from 'store/tokens/actions';
import { getInvitations } from 'store/invitation/actions';
import { getMetaTxEnabled } from 'store/metatx/actions';
import { useInjectSaga } from 'utils/injectSaga';
import safeReducer from 'store/safe/reducer';
import safeSaga from 'store/safe/saga';
import { useInjectReducer } from 'utils/injectReducer';
import multisigSaga from 'store/multisig/saga';
import { TRANSACTION_MODES } from 'constants/transactions';

import { Input } from 'components/common/Form';
import { useForm } from 'react-hook-form';

import {
  makeSelectMetaTransactionHash,
  makeSelectAmountPerToken,
  makeSelectSelectedPeopleGroupByTeams,
} from 'store/transactions/selectors';
import getTransactionReceipts from 'utils/getTransactionReceipts';
import { getFinalMetaTransactionHash } from 'utils/meta-tx-helpers';
import { networkId } from 'constants/networks';
import {
  EditConfirmation,
  MassPayoutContainer,
} from 'components/Payments/styles';
import metaTxSaga from 'store/metatx/saga';
import metaTxReducer from 'store/metatx/reducer';

const metaTxKey = 'metatx';
const getFormattedAmount = (amount = '') => {
  let formattedAmount;
  formattedAmount = `$${formatNumber(amount.split('.')[0], 2)}.${
    amount.split('.')[1]
  }`;

  if (isNaN(amount)) {
    return 'Invalid Amount';
  }

  return formattedAmount || '';
};

const getTotalTokens = (payees = []) => {
  return payees.reduce((total, { salaryAmount }) => {
    total += Number(salaryAmount);
    return total;
  }, 0);
};

const MassPayoutTeams = ({ handleHide }) => {
  const [teamsToPaid, setTeamsToPaid] = useState('');
  const [editOn, setEditOn] = useState(false);
  const [amountEnteredPerToken, setAmountEnteredPerToken] = useState({});
  const [teamsChangedToken, setTeamsChangedToken] = useState([]);

  const safeKey = 'safe';
  const transactionsKey = 'transactions';
  const multisigKey = 'multisig';

  const { register, watch } = useForm({
    mode: 'onChange',
  });

  const [encryptionKey] = useLocalStorage('ENCRYPTION_KEY');

  const tokenList = useSelector(makeSelectTokenList());
  const prices = useSelector(makeSelectPrices());
  const nonce = useSelector(makeSelectNonce());
  const isMultiOwner = useSelector(makeSelectIsMultiOwner());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const isMetaEnabled = useSelector(makeSelectIsMetaTxEnabled());
  const isMetaTxLimitAllowed = useSelector(makeSelectIsMetaTxLimitAllowed());
  const txHashFromMetaTx = useSelector(makeSelectMetaTransactionHash());
  const teamsToPaidGlobalState = useSelector(
    makeSelectSelectedPeopleGroupByTeams()
  );
  const amountPerTokenGlobalState = useSelector(makeSelectAmountPerToken());

  const {
    loadingTx,
    txHash,
    recievers,
    txData,
    setTxData,
    payoutRecivers,
    loadingMetaTx,
    setLoadingMetaTx,
    teamsMassPayout,
  } = useMassPayout();
  const organisationType = useSelector(makeSelectOrganisationType());
  const dispatch = useDispatch();
  const history = useHistory();
  const { library, account } = useActiveWeb3React();

  useInjectSaga({ key: transactionsKey, saga: transactionsSaga });
  useInjectSaga({ key: safeKey, saga: safeSaga });
  useInjectSaga({ key: multisigKey, saga: multisigSaga });
  useInjectSaga({ key: metaTxKey, saga: metaTxSaga });

  useInjectReducer({ key: safeKey, reducer: safeReducer });
  useInjectReducer({ key: transactionsKey, reducer: transactionsReducer });
  useInjectReducer({ key: multisigKey, reducer: multisigReducer });
  useInjectReducer({ key: metaTxKey, reducer: metaTxReducer });

  const desc = watch('description') && watch('description');
  const getTotalAmount = useCallback(
    (payees = []) => {
      return payees.reduce((total, { salaryAmount, salaryToken }) => {
        if (salaryToken === 'USD') {
          total += Number(salaryAmount);
        } else {
          total += prices[salaryToken]
            ? prices[salaryToken] * Number(salaryAmount)
            : 0;
        }
        return total;
      }, 0);
    },
    [prices]
  );

  useEffect(() => {
    if (ownerSafeAddress) {
      dispatch(getInvitations(ownerSafeAddress));
      dispatch(getNonce(ownerSafeAddress));
      dispatch(getTokens(ownerSafeAddress));
      dispatch(getMetaTxEnabled(ownerSafeAddress));
    }
  }, [ownerSafeAddress, dispatch]);

  useEffect(() => {
    if (
      txHashFromMetaTx &&
      isMetaEnabled &&
      !isMultiOwner &&
      isMetaTxLimitAllowed
    ) {
      setLoadingMetaTx(true);
      dispatch(clearTransactionHash());
      const intervalId = setInterval(async () => {
        const newMetaHash = await getFinalMetaTransactionHash(
          txHashFromMetaTx,
          networkId
        );
        if (newMetaHash) {
          getTransactionReceipts(
            library,
            dispatch,
            ownerSafeAddress,
            '',
            newMetaHash,
            false
          );
          handleHide(true);
          clearInterval(intervalId);
        }
      }, 3000);
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    txHashFromMetaTx,
    isMetaEnabled,
    isMultiOwner,
    isMetaTxLimitAllowed,
  ]);

  useEffect(() => {
    if (teamsToPaidGlobalState && amountPerTokenGlobalState) {
      setTeamsToPaid(teamsToPaidGlobalState);

      setAmountEnteredPerToken(amountPerTokenGlobalState);
    }
  }, [teamsToPaidGlobalState, amountPerTokenGlobalState]);

  useEffect(() => {
    let amt = 0;
    let selectedToken = '';
    let tokenValue = -1;

    if (teamsToPaid && amountEnteredPerToken) {
      Object.entries(teamsToPaid).map(([_, payees], idx) => {
        return (amt += getTotalAmount(payees));
      });

      // selectedToken = Object.entries(teamsToPaid)
      //   .map(([_, payees], idx) => payees[0].salaryToken)
      //   .join('|');
      selectedToken = Object.keys(amountEnteredPerToken).join('|');
      const tokenKey = Object.keys(amountEnteredPerToken);
      tokenValue =
        tokenKey.length > 1 ? -1 : amountEnteredPerToken[tokenKey[0]];
    }

    if (txHash) {
      if (
        encryptionKey &&
        recievers &&
        ownerSafeAddress &&
        // totalAmountToPay &&
        selectedToken &&
        account
      ) {
        const description = desc || 'Mass Payout Across Multiple Teams';
        const to = cryptoUtils.encryptDataUsingEncryptionKey(
          JSON.stringify(payoutRecivers),
          encryptionKey,
          organisationType
        );
        // const to = selectedTeammates;

        dispatch(
          addTransaction({
            to,
            safeAddress: ownerSafeAddress,
            createdBy: account,
            transactionHash: txHash,
            tokenValue: tokenValue,
            tokenCurrency: selectedToken,
            fiatValue: amt.toFixed(2),
            addresses: recievers.map(({ address }) => address),
            paymentType: 'teamsMassPayout',
            description,
            transactionMode: TRANSACTION_MODES.TEAMS_MASS_PAYOUT,
            nonce: nonce,
          })
        );
        handleHide(true);
      }
    } else if (txData) {
      if (
        encryptionKey &&
        recievers &&
        ownerSafeAddress &&
        // totalAmountToPay &&
        selectedToken &&
        account
      ) {
        const to = cryptoUtils.encryptDataUsingEncryptionKey(
          JSON.stringify(payoutRecivers),
          encryptionKey,
          organisationType
        );
        const description = desc || 'Mass Payout Across Multiple Teams';
        if (!isMultiOwner) {
          // threshold = 1 or single owner
          dispatch(
            addTransaction({
              to,
              safeAddress: ownerSafeAddress,
              createdBy: account,
              txData,
              tokenValue: tokenValue,
              tokenCurrency: selectedToken,
              fiatValue: amt.toFixed(2),
              addresses: recievers.map(({ address }) => address),
              paymentType: 'teamsMassPayout',
              description,
              transactionMode: TRANSACTION_MODES.TEAMS_MASS_PAYOUT,
              nonce: nonce,
              isMetaEnabled,
            })
          );
          setTxData(undefined);
          if (!isMetaEnabled) handleHide(true);
        } else {
          // threshold > 1
          dispatch(
            createMultisigTransaction({
              to,
              safeAddress: ownerSafeAddress,
              createdBy: account,
              txData,
              tokenValue: tokenValue,
              tokenCurrency: selectedToken,
              fiatValue: amt.toFixed(2),
              fiatCurrency: 'USD',
              addresses: recievers.map(({ address }) => address),
              nonce: nonce,
              transactionMode: TRANSACTION_MODES.TEAMS_MASS_PAYOUT,
              paymentType: 'teamsMassPayout',
              description,
            })
          );
          handleHide(true);
        }
      }
    }
  }, [
    txHash,
    encryptionKey,
    recievers,
    dispatch,
    ownerSafeAddress,
    txData,
    setTxData,
    account,
    isMultiOwner,
    nonce,
    history,
    prices,
    organisationType,
    getTotalAmount,
    payoutRecivers,
    handleHide,
    desc,
    isMetaEnabled,
    teamsToPaid,
    amountEnteredPerToken,
  ]);

  const handleTeamsMassPayout = async payouts => {
    await teamsMassPayout(
      payouts,
      isMultiOwner,
      nonce,
      isMetaEnabled,
      isMetaTxLimitAllowed
    );
    if (isMetaEnabled && isMetaTxLimitAllowed) {
      setLoadingMetaTx(true);
    }
  };

  const handleAmountEdit = async (teamDetails, value) => {
    if (value < 0) {
      return;
    }
    const { peopleId, team: teamName, token } = teamDetails;
    let newTotalPerSelectedToken = 0;
    const newObjectArray = teamsToPaid[teamName].map((payee, index) => {
      if (payee.peopleId === peopleId) return { ...payee, salaryAmount: value };
      else
        return {
          ...payee,
        };
    });
    const stateToSetTeamsToPaid = {
      ...teamsToPaid,
      [teamName]: newObjectArray,
    };

    Object.entries(stateToSetTeamsToPaid).forEach(([key, value], idx) => {
      value.forEach(({ salaryAmount, salaryToken }) => {
        if (salaryToken === token) {
          newTotalPerSelectedToken += Number(salaryAmount);
        }
      });
    });

    setAmountEnteredPerToken({
      ...amountEnteredPerToken,
      [token]: newTotalPerSelectedToken,
    });
    setTeamsToPaid({ ...stateToSetTeamsToPaid });
  };

  const handleSelectToken = ({ teamDetails, tokenSelected, teamName }) => {
    const newObjectArray = teamDetails.map(payee => {
      const amountInUsd =
        payee.salaryToken === 'USD'
          ? payee.salaryAmount
          : prices[payee.salaryToken] * payee.salaryAmount;

      const getChangedAmount =
        payee.salaryToken === 'USD'
          ? payee.salaryAmount / prices[tokenSelected]
          : amountInUsd / prices[tokenSelected];

      return {
        ...payee,
        salaryAmount: getChangedAmount,
        salaryToken: tokenSelected,
      };
    });
    const stateToSetTeamsToPaid = {
      ...teamsToPaid,
      [teamName]: newObjectArray,
    };

    const teamsWhoChangedTokens = [...teamsChangedToken];
    const teamId = teamDetails[0]?.departmentId;
    if (!teamsWhoChangedTokens.includes(teamId)) {
      teamsWhoChangedTokens.push(teamId);
    }

    setTeamsChangedToken(teamsWhoChangedTokens);
    setTeamsToPaid({ ...stateToSetTeamsToPaid });
  };

  const renderTeamsData = () => {
    const insufficientBalance = ({ token, payees }) => {
      const tokenExists = tokenList.filter(({ name }) => name === token)[0];
      const totalTokenAmount = getTotalTokens(payees);

      if (tokenExists && tokenExists.balance) {
        return {
          insufficientBalanceFlag:
            totalTokenAmount > parseFloat(tokenExists.balance) ||
            amountEnteredPerToken[token] > parseFloat(tokenExists.balance),
          balance: parseFloat(tokenExists.balance).toFixed(2),
          tokenExists: true,
        };
      } else if (token === 'USD') {
        return {
          insufficientBalanceFlag: true,
          selectToken: true,
          tokenExists: true,
        };
      } else {
        return {
          insufficientBalanceFlag: true,
          balance: '0',
          tokenExists: false,
        };
      }
    };

    const checkIfInsufficientBalance = () => {
      return Object.entries(teamsToPaid).map(([_, payees]) => {
        const tokenPerTeam = payees[0].salaryToken;
        const isInsufficientBalance = insufficientBalance({
          token: tokenPerTeam,
          payees,
        });

        return isInsufficientBalance;
      });
    };

    const checkIfAnyUsd = () => {
      return Object.entries(teamsToPaid).find(([_, payees]) => {
        const tokenPerTeam = payees[0].salaryToken;
        return tokenPerTeam === 'USD';
      });
    };

    const hasBalanceIssues = checkIfInsufficientBalance().some(
      e => e.insufficientBalanceFlag === true
    );

    const containsAnyUsd = checkIfAnyUsd()?.length > 0;

    const getTotalPayees = () => {
      let payeeCount = 0;
      Object.entries(teamsToPaid).map(([_, payees]) => {
        return (payeeCount += payees.length);
      });

      return payeeCount;
    };

    const getAmount = () => {
      let amt = 0;
      Object.entries(teamsToPaid).map(([_, payees]) => {
        return (amt += getTotalAmount(payees));
      });

      return amt.toFixed(2);
    };

    const handleFinishEditing = type => {
      if (type === 'finish') {
        setEditOn(false);
        return;
      }
      if (type === 'cancel') {
        setTeamsToPaid(teamsToPaidGlobalState);
        setAmountEnteredPerToken(amountPerTokenGlobalState);
        setEditOn(false);
      }
    };

    const renderEditConfirmation = () => {
      return (
        <EditConfirmation>
          <Button
            type="button"
            width="20rem"
            style={{
              backgroundColor: 'rgba(115, 103, 240, 0.1)',
              color: '#7367F0',
              marginRight: '20px',
            }}
            onClick={() => handleFinishEditing('cancel')}
          >
            Cancel Editing
          </Button>
          <Button
            type="button"
            width="20rem"
            disabled={hasBalanceIssues}
            onClick={() => handleFinishEditing('finish')}
          >
            Finish Editing
          </Button>
        </EditConfirmation>
      );
    };

    return (
      <div>
        <MassPayoutContainer style={{ padding: '0 4rem 2rem 4rem' }}>
          <div className="team-details-table">
            <div className="outer-flex mt-5">
              <div className="d-flex">
                <div className="title">Teams Details</div>
                {editOn && (
                  <div className="ml-3 editing">
                    <Pencil className="icon" /> Editing
                  </div>
                )}
              </div>
              <div className="select-all">
                {!editOn && !containsAnyUsd && (
                  <div className="edit-amount" onClick={() => setEditOn(true)}>
                    <Pencil className="icon" />
                    <div className="title" disabled={!containsAnyUsd}>
                      Edit amount
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </MassPayoutContainer>
        <TransactionsByTeams>
          <div className="d-flex align-items-center table-head">
            <div>Teams</div>
            <div>No. of Payees</div>
            <div>Total Token Amount</div>
            <div>Total USD Amount</div>
          </div>

          <div className="table-divider"></div>

          <div className="table-body">
            {Object.entries(teamsToPaid).map(([teamName, payees], idx) => {
              const tokenPerTeam = payees[0].salaryToken;
              const isInsufficientBalance = insufficientBalance({
                token: tokenPerTeam,
                payees,
              });
              return (
                <TransactionsTableByTeams
                  key={teamName}
                  team={teamName}
                  token={tokenPerTeam}
                  idx={idx}
                  isInsufficientBalance={isInsufficientBalance}
                  payees={payees}
                  isAddressInvalid={[]}
                  isPayDetailsValid={undefined}
                  editOn={editOn}
                  handleAmountEdit={handleAmountEdit}
                  handleSelectToken={handleSelectToken}
                  teamsToPaid={teamsToPaid}
                  teamsChangedToken={teamsChangedToken}
                />
              );
            })}
            <div className="table-row d-flex align-items-center">
              <div></div>
              <div className="footer-row">
                Total <span>{getTotalPayees()}</span>
              </div>
              <div></div>
              <div className="footer-row">
                Total <span>{getFormattedAmount(getAmount())}</span>
              </div>
            </div>
          </div>
        </TransactionsByTeams>

        <div
          className="d-flex justify-content-center"
          style={{ margin: '2rem 4rem' }}
        >
          <div style={{ width: '100%', marginRight: '2rem' }}>
            <Input
              type="text"
              name="description"
              style={{ minWidth: '30rem' }}
              register={register}
              placeholder="description (optional)"
            />
          </div>

          {!editOn && (
            <Button
              type="button"
              width="15rem"
              onClick={() => handleTeamsMassPayout(teamsToPaid)}
              disabled={loadingMetaTx || hasBalanceIssues}
              loading={loadingTx || loadingMetaTx}
            >
              Confirm
            </Button>
          )}
        </div>
        {editOn && renderEditConfirmation()}
      </div>
    );
  };

  return <FilePayoutContainer>{renderTeamsData()}</FilePayoutContainer>;
};

export default MassPayoutTeams;
