// import { BigNumber } from "@ethersproject/bignumber";
import { parseUnits, formatUnits } from '@ethersproject/units';
import fromExponential from 'from-exponential';
// Hex helpers
export function joinHexData(hexData) {
  return `0x${hexData
    .map(hex => {
      const stripped = hex.replace(/^0x/, '');
      return stripped.length % 2 === 0 ? stripped : '0' + stripped;
    })
    .join('')}`;
}

export function getHexDataLength(hexData) {
  return Math.ceil(
    (hexData.startsWith('0x') ? hexData.length - 2 : hexData.length) / 2
  );
}

export function toHex(v) {
  return `0x${Number(v.toString()).toString(16)}`;
}

// Transaction helpers
export const defaultTxOperation = 0;
export const defaultTxValue = 0;
export const defaultTxData = '0x';

export function standardizeTransaction(tx) {
  return {
    operation: tx.operation ? tx.operation : defaultTxOperation,
    to: tx.to,
    value: tx.value ? tx.value.toString() : defaultTxValue,
    data: tx.data ? tx.data : defaultTxData,
  };
}

export const getAmountInWei = (tokenAmount, decimals) => {
  // return BigNumber.from(tokenAmount).mul(
  //   BigNumber.from(String(10 ** decimals))
  // );

  let amount = tokenAmount;
  if (typeof tokenAmount === 'string') {
    const [whole, fraction] = tokenAmount.split('.');
    if (fraction) {
      amount = whole + '.' + fraction.slice(0, decimals);
    } else {
      amount = whole;
    }
  } else if (typeof tokenAmount === 'number') {
    amount = tokenAmount.toFixed(decimals);
  }

  return parseUnits(amount, decimals);
};

export const getAmountFromWei = (tokenAmount, decimals, precision) => {
  let amount = tokenAmount;
  if (typeof tokenAmount === 'string') {
    if (tokenAmount.includes('e')) {
      amount = fromExponential(tokenAmount);
    } else {
      amount = tokenAmount.split('.')[0];
    }
  }
  if (precision) {
    return parseFloat(formatUnits(amount, decimals)).toFixed(precision);
  }
  return parseFloat(formatUnits(amount, decimals));
};
