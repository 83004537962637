export const ADD_TRANSACTION = 'parcel/transactions/ADD_TRANSACTION';
export const ADD_TRANSACTION_SUCCESS =
  'parcel/transactions/ADD_TRANSACTION_SUCCESS';
export const ADD_TRANSACTION_ERROR =
  'parcel/transactions/ADD_TRANSACTION_ERROR';

export const VIEW_TRANSACTIONS = 'parcel/transactions/VIEW_TRANSACTIONS';
export const VIEW_TRANSACTIONS_SUCCESS =
  'parcel/transactions/VIEW_TRANSACTIONS_SUCCESS';
export const VIEW_TRANSACTIONS_ERROR =
  'parcel/transactions/VIEW_TRANSACTIONS_ERROR';

export const GET_TRANSACTION_BY_ID =
  'parcel/transactions/GET_TRANSACTION_BY_ID';
export const GET_TRANSACTION_BY_ID_SUCCESS =
  'parcel/transactions/GET_TRANSACTION_BY_ID_SUCCESS';
export const GET_TRANSACTION_BY_ID_ERROR =
  'parcel/transactions/GET_TRANSACTION_BY_ID_ERROR';

export const CLEAR_TRANSACTION_HASH =
  'parcel/transactions/CLEAR_TRANSACTION_HASH';

export const SET_SELECTED_TEAM = 'parcel/transactions/SET_SELECTED_TEAM';

export const SET_SELECTED_ADDRESS = 'parcel/transactions/SET_SELECTED_ADDRESS';

export const SET_SELECTED_PEOPLE = 'parcel/transactions/SET_SELECTED_PEOPLE';
export const SET_AMOUNT_PER_TOKEN = 'parcel/transactions/SET_AMOUNT_PER_TOKEN';
export const SET_SELECTED_PEOPLE_GROUP_BY_TEAMS =
  'parcel/transactions/SET_SELECTED_PEOPLE_GROUP_BY_TEAMS';
