import React, { useState, useEffect } from 'react';
import CheckBox from 'components/common/CheckBox';
import {
  Table,
  TableHead,
  TableBody,
  TableDivider,
  TableInfo,
} from 'components/common/Table';
import { DetailsTableContainer } from './styles';
import { Skeleton } from '@material-ui/lab';
import TokenImg from 'components/common/TokenImg';
import HoverActionsTable from './HoverActionsTable';
import ReactTooltip from 'react-tooltip';
import { useActiveWeb3React } from 'hooks';
import { getClickable } from 'components/common/Web3Utils';
import { ReactComponent as NoTransaction } from '../../assets/icons/no-transaction.svg';
function DetailTable({
  allPeople,
  selectedPeople,
  handleSelectOne,
  selectAllPeople,
  handleSelectAllPeople,
  setTokensToRender,
  loadingPeople,
}) {
  const [hovered, setHovered] = useState(null);
  const [shouldActive, setShouldActive] = useState(false);
  const skeletonArray = Array(3).fill('');
  useEffect(() => {
    if (shouldActive === false) {
      setHovered(false);
    }
  }, [shouldActive]);

  const { chainId } = useActiveWeb3React();
  const customUSD = (
    <span
      style={{
        marginRight: '1rem',
        width: '2rem',
        height: '2rem',
        display: 'inline-block',
        textAlign: 'center',
        lineHeight: '2rem',
        borderRadius: '100%',
        border: '0.1px solid black',
      }}
    >
      $
    </span>
  );
  return (
    <DetailsTableContainer>
      <Table>
        <TableHead>
          <tr>
            <th
              style={{ width: '50%', cursor: 'pointer' }}
              className="d-flex align-items-center"
            >
              {' '}
              <CheckBox
                type="checkbox"
                id={`checkbox`}
                name={`checkbox`}
                checked={selectAllPeople}
                checkType="flat"
                onChange={() => handleSelectAllPeople()}
              />{' '}
              NAME
            </th>
            <th style={{ width: '20%' }}>TEAM</th>
            <th style={{ width: '15%' }}>DISBURSEMENT</th>
            <th style={{ width: '20%' }}></th>
          </tr>
        </TableHead>
        <TableDivider className="flex align-items-center">
          <td /> <td /> <td />
        </TableDivider>
        <TableBody>
          {loadingPeople &&
            skeletonArray.map((item, index) => (
              <tr key={index}>
                <td>
                  <div className="d-flex align-items-center">
                    <Skeleton variant="rect" height={'1.4rem'} width={'16px'} />
                    <Skeleton
                      variant="rect"
                      height="1.4rem"
                      width="10rem"
                      className="ml-4"
                    />
                  </div>
                </td>
                <td>
                  <Skeleton variant="rect" height="1.4rem" width="10rem" />
                </td>
                <td align="left">
                  <Skeleton variant="rect" height="1.4rem" width="10rem" />
                </td>
                <td align="left"></td>
              </tr>
            ))}
          {allPeople.length === 0 && !loadingPeople && (
            <TableInfo
              style={{
                fontSize: '1.4rem',
                fontWeight: '500',
                textAlign: 'center',
                height: '10rem',
              }}
            >
              <td colSpan={4}>No People Found</td>
            </TableInfo>
          )}

          {!loadingPeople &&
            allPeople?.map((people, index) => {
              return (
                <tr
                  key={people.peopleId}
                  style={{
                    backgroundColor: '#fff',
                  }}
                  // onClick={()=>handleSelectOne(people)}
                  onMouseEnter={() =>
                    !shouldActive && setHovered(people.peopleId)
                  }
                  onMouseLeave={() => {
                    if (shouldActive) {
                      return;
                    } else {
                      setHovered(null);
                    }
                  }}
                >
                  <td className="d-flex align-items-center">
                    <CheckBox
                      type="checkbox"
                      id={`${people.peopleId}checkbox`}
                      name={`checkbox`}
                      checked={selectedPeople?.some(
                        item => item.peopleId === people.peopleId
                      )}
                      onChange={e => {
                        e.stopPropagation();
                        handleSelectOne(people);
                      }}
                    />
                    <div
                      id={`${people.peopleId}peopl-address`}
                      data-for={`${people.peopleId}peopl-address`}
                      data-tip={people.address}
                    >
                      {people.firstName} {people.lastName} (
                      {getClickable(people.address, chainId, 'address', true)})
                    </div>
                    <ReactTooltip
                      id={`${people.peopleId}peopl-address`}
                      place={'bottom'}
                      type={'dark'}
                      effect={'solid'}
                    />
                  </td>
                  <td>{people.departmentName}</td>
                  <td>
                    <div className="d-flex align-items-center">
                      {people.salaryToken === 'USD' ? (
                        customUSD
                      ) : (
                        <TokenImg
                          token={people.salaryToken}
                          className={'mr-3'}
                          style={{
                            width: '2rem',
                            height: '2rem',
                            marginRight: '8px !important',
                          }}
                        />
                      )}
                      <span
                        className="mt-1"
                        style={{ fontSize: '14px', lineHeight: '17px' }}
                      >
                        {people.salaryAmount}
                      </span>
                    </div>
                  </td>
                  <td align="right">
                    <div className="d-flex justify-content-end">
                      {hovered && hovered === people.peopleId && (
                        <HoverActionsTable
                          id={people.peopleId}
                          data={people}
                          shouldActive={setShouldActive}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
        </TableBody>
      </Table>
    </DetailsTableContainer>
  );
}

export default DetailTable;
