import produce from 'immer';
import {
  TOGGLE_NOTIFICATION,
  TOGGLE_PEOPLE_DETAILS,
  SET_PEOPLE_DETAILS,
  TOGGLE_SAFE_DETAILS,
  TOGGLE_TRANSACTION_DETAILS,
  SET_TRANSACTION_DETAILS,
} from './action-types';

export const initialState = {
  isNotificationOpen: false,
  isPeopleDetailsOpen: false,
  isSafeDetailsOpen: false,
  peopleDetails: null,
  transactionDetails: null,
  isTransactionDetails: false,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case TOGGLE_NOTIFICATION:
        draft.isNotificationOpen = action.show;
        break;
      case TOGGLE_PEOPLE_DETAILS:
        draft.isPeopleDetailsOpen = action.show;
        break;
      case TOGGLE_SAFE_DETAILS:
        draft.isSafeDetailsOpen = action.show;
        break;
      case SET_PEOPLE_DETAILS:
        draft.peopleDetails = action.peopleDetails;
        break;
      case TOGGLE_TRANSACTION_DETAILS:
        draft.isTransactionDetails = action.show;
        break;
      case SET_TRANSACTION_DETAILS:
        draft.transactionDetails = action.txDetails;
        break;
    }
  });

export default reducer;
