import styled from 'styled-components/macro';

export const ScheduleFooterWrapper = styled.div`
  .mb-8 {
    margin-bottom: 0.8rem;
  }
  .summary-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 0;
  }
  .summary {
    font-size: 12px;
    line-height: 14px;
    color: #373737;
    font-weight: normal;
  }
  .footer {
    position: relative;
    height: 3.4rem;
    align-items: center;
    display: flex;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    color: rgba(55 55 55, 0.6);
    ${'' /* opacity: 0.6; */}
    &::before {
      content: '';
      width: calc(100% + 8rem);
      height: 3.4rem;
      left: -4rem;
      bottom: 0;
      position: absolute;
      background: #dddcdc;
      opacity: 0.2;
    }
  }
`;
