import { metaTxResubmitEndpoint } from 'constants/endpoints';

export const getFinalMetaTransactionHash = async (metaTxHash, networkId) => {
  const resp = await fetch(
    `${metaTxResubmitEndpoint}transactionHash=${metaTxHash}&networkId=${networkId}`
  );
  const result = await resp.json();
  if (result.data.newStatus === 'CONFIRMED') {
    return result.data.newHash;
  }
};
