import Plus from 'assets/icons/plus.svg';
import Img from 'components/common/Img';
import { Modal, ModalBody } from 'components/common/Modal';
import React from 'react';
import { connectModal as reduxModal } from 'redux-modal';
import { AutomationsGuideContainer } from './styles';
import CloseIcon from 'assets/icons/dashboard/close-icon.svg';
import EditIcon from 'assets/icons/edit.svg';
import DeleteIcon from 'assets/icons/delete-circle.svg';

export const MODAL_NAME = 'automation-guide-modal';

const stepsGuide = [
  {
    title: 'Creating an Automation',
    icon: Plus,
    listItems: [
      'Automations are created directly from the ‘New Transfer’ button.',
      'Select the payout type to ‘Recurring’, a new section to schedule will come.',
      'Add payees and fill the schedule. Review your automation and confirm.',
    ],
  },
  {
    title: 'Stop Automations',
    icon: DeleteIcon,
    listItems: [
      'Automations can be stopped under the ‘Automations’ tab.',
      'Click on the ‘Edit’ button to start selecting active automated payouts for individual(s)',
      'Click on ‘Stop Automations’ to confirm your selection.',
    ],
  },
  {
    title: 'Editing Automation Schedules',
    icon: EditIcon,
    listItems: ['We’re adding support for edit functionality soon.'],
  },
];

const faqList = [
  {
    question: 'What is Maximum GAS Price and how does it work?',
    answer:
      'It is the maximum gas price you’re willing to pay for automated payments. We recommended it to be at least 40 Gwei.',
  },
];
function AutomationsGuideModal(props) {
  const { show, handleHide } = props;

  return (
    <Modal isOpen={show} toggle={handleHide}>
      <ModalBody width="90rem" minHeight="auto">
        <AutomationsGuideContainer>
          <div className="d-flex align-items-center header-wrapper">
            <div className="header">Learn how automations work</div>
            <div onClick={handleHide} className="close-btn">
              <Img src={CloseIcon} alt="close" />
            </div>
          </div>

          {stepsGuide.map(({ title, listItems, icon }, idx) => (
            <div key={title} className="wrapper">
              <div className="d-flex align-items-center mb-4">
                <Img className="icon" src={icon} alt="icon" />
                <div className="title">{title}</div>
              </div>

              <div className="list-container">
                {listItems.map((item, i) => (
                  <div key={item} className="item text">
                    {idx !== 2 && `${i + 1}.`} {'  '}
                    {item}
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div className="divider"></div>

          <div className="title">FAQ’s</div>

          {faqList.map(({ question, answer }) => (
            <div key={question} className="faq-wrapper">
              <div className="question">{question}</div>
              <div className="text answer">{answer}</div>
            </div>
          ))}

          <div className="contact-us">
            Didn’t find your issue? {'  '}
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://discord.gg/Kg78anBhsM"
              >
                Contact Support
              </a>
            </span>
          </div>
        </AutomationsGuideContainer>
      </ModalBody>
    </Modal>
  );
}

export default reduxModal({ name: 'automation-guide-modal' })(
  AutomationsGuideModal
);
