import styled from 'styled-components/macro';

export default styled.div`
  max-width: 98.5%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8vh;
`;
