export const SET_OWNER_NAME = 'parcel/global/SET_OWNER_NAME';
export const SET_OWNER_ADDRESS = 'parcel/global/SET_OWNER_ADDRESS';
export const SET_OWNER_DETAILS = 'parcel/global/SET_OWNER_DETAILS';
export const SET_OWNERS_AND_THRESHOLD =
  'parcel/global/SET_OWNERS_AND_THRESHOLD';
export const SET_ORGANISATION_TYPE = 'parcel/global/SET_ORGANISATION_TYPE';
export const CLEAR_GLOBAL_STATE = 'parcel/global/CLEAR_GLOBAL_STATE';

export const GET_SAFE_INFO = 'parcel/global/GET_SAFE_INFO';
export const GET_SAFE_INFO_SUCCESS = 'parcel/global/GET_SAFE_INFO_SUCCESS';
export const GET_SAFE_INFO_ERROR = 'parcel/global/GET_SAFE_INFO_ERROR';

export const GET_SAFE_VERSION_SUCCESS =
  'parcel/global/GET_SAFE_VERSION_SUCCESS';
