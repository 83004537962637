import produce from 'immer';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  IMPORT_SAFE,
  OPEN_SAFE,
  SWITCH_SAFE,
} from './action-types';

export const initialState = {
  loading: false,
  switchingSafe: false,
  error: false,
  safeAddress: '',
  log: '',
  flag: 0,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SWITCH_SAFE:
        draft.switchingSafe = true;
        break;

      case LOGIN_USER:
        draft.loading = true;
        draft.error = false;
        draft.safeAddress = '';
        draft.log = '';
        draft.flag = 0;
        break;

      case OPEN_SAFE:
        draft.loading = false;
        break;

      case LOGIN_USER_SUCCESS:
        draft.safeAddress = action.safeAddress;
        draft.log = action.log;
        draft.loading = false;
        draft.switchingSafe = false;
        break;

      case LOGIN_USER_ERROR:
        draft.error = action.error;
        draft.loading = false;
        draft.switchingSafe = false;
        break;

      case IMPORT_SAFE:
        draft.flag = action.flag;
        draft.loading = false;
        break;
    }
  });

export default reducer;
