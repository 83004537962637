import styled from 'styled-components/macro';

export default styled.thead`
  width: 100%;
  background-color: #ffffff;

  tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 0.4rem;
  }
  tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 0.4rem;
  }

  th {
    height: 5rem;
    text-align: left;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #8d8e91;

    &:first-of-type {
      border-top-left-radius: 0.4rem;
    }
    &:last-of-type {
      border-top-right-radius: 0.4rem;
    }
  }

  th:first-child {
    padding-left: 2rem;
  }
  th:last-child {
    padding-right: 2rem;
  }

  @media (max-width: 600px) {
    th:first-child {
      padding-left: 1rem;
    }
    th:last-child {
      padding-right: 1rem;
    }
  }
`;
