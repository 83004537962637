import Avatar from 'components/common/Avatar';
import {
  Table,
  TableBody,
  TableHead,
  TableLoader,
  Title,
} from 'components/common/Table';
import { useInjectReducer } from 'utils/injectReducer';
import TokenImg from 'components/common/TokenImg';
import { useContract, useLocalStorage } from 'hooks';
import React, { Fragment, useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { show } from 'redux-modal';
import {
  makeSelectOrganisationType,
  makeSelectOwnerSafeAddress,
} from 'store/global/selectors';
import {
  makeSelectPeople,
  makeSelectLoading as makeSelectLoadingPeople,
} from 'store/view-people/selectors';
import { addPayeesforDeleteion } from 'store/automations/actions';
import automationsReducer from 'store/automations/reducer';
import safeSaga from 'store/safe/saga';
import GasPriceModal, { MODAL_NAME as GAS_PRICE_MODAL } from './GasPriceModal';
import RemoveAutomationModal, {
  MODAL_NAME as REMOVE_AUTOMATION_MODAL,
} from './RemoveAutomationModal';
import AutomationsGuideModal, {
  MODAL_NAME as AUTOMATIONS_GUIDE_MODAL,
} from './AutomationsGuideModal';
import {
  ActionButton,
  AutomationsWrapper,
  Dot,
  GasLink,
  NotificationArea,
  NotifyArea,
} from './styles';
import AutomationModuleABI from 'constants/abis/AutomationModule.json';
import CheckBox from 'components/common/CheckBox';
import addresses from 'constants/addresses';
import ERC20ABI from 'constants/abis/ERC20.json';
import { getDecryptedDetails } from 'utils/encryption';
import { getAmountFromWei } from 'utils/tx-helpers';
import { formatNumber } from 'utils/number-helpers';
import { getNonce } from 'store/safe/actions';
import { getTokens } from 'store/tokens/actions';
import { useInjectSaga } from 'utils/injectSaga';
import { minifyAddress } from 'components/common/Web3Utils';
import { getAllPeople } from 'store/view-people/actions';
import viewPeopleSaga from 'store/view-people/saga';
import viewPeopleReducer from 'store/view-people/reducer';
import { ReactComponent as Delete } from '../../assets/icons/delete-bin.svg';
import { ReactComponent as Tag } from '../../assets/icons/tag.svg';
import { TableDivider, TableInfo } from 'components/common/Table/styles';
import { ReactComponent as NoTransaction } from '../../assets/icons/no-transaction.svg';
import { ReactComponent as AutomationGuide } from '../../assets/icons/automationguid.svg';
import { ReactComponent as LinkToTransaction } from '../../assets/icons/ic_external_link.svg';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { networkId } from '../../constants/networks';

const { AUTOMATION_MODULE_ADDRESS, ZERO_ADDRESS } = addresses;
const automationsKey = 'automations';
const viewPeopleKey = 'viewPeople';
const safeKey = 'safe';

let automationTime = {};

if (networkId === 4) {
  automationTime = {
    1: 'Every Minute',
    60: 'Hourly',
  };
}

export const frequencies = {
  ...automationTime,
  1440: 'Daily',
  10080: 'Weekly',
  43805: 'Monthly',
};

function isObjEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const renderLastPaidValue = rawTimestamp => {
  const milliseconds = rawTimestamp * 60000;
  const dateObject = new Date(milliseconds);

  return `${dateObject.getDate()} ${dateObject.toLocaleString('en-US', {
    month: 'short',
  })}  ${dateObject.getUTCFullYear()}  `;
};

const Automations = () => {
  const [encryptionKey] = useLocalStorage('ENCRYPTION_KEY');

  // Reducers
  useInjectReducer({ key: automationsKey, reducer: automationsReducer });

  const automationModule = useContract(
    AUTOMATION_MODULE_ADDRESS,
    AutomationModuleABI
  );
  const erc20Contract = useContract(ZERO_ADDRESS, ERC20ABI);

  useInjectSaga({ key: safeKey, saga: safeSaga });
  useInjectReducer({ key: viewPeopleKey, reducer: viewPeopleReducer });
  useInjectSaga({ key: viewPeopleKey, saga: viewPeopleSaga });

  const [loadingAutomatedTransactions, setLoadingAutomatedTransactions] =
    useState(false);
  const [people, setPeople] = useState([]);
  const [editTransactions, setEditTransactions] = useState(false);
  const [checked, setChecked] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [transactions, setTransactions] = useState({});
  const [automationsCount, setAutomationsCount] = useState(0);
  const [gasPrice, setGasPrice] = useState('');

  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const encryptedPeople = useSelector(makeSelectPeople());
  const organisationType = useSelector(makeSelectOrganisationType());
  const loadingPeople = useSelector(makeSelectLoadingPeople());
  const [updatedList, setUpdatedTransactionList] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [showDelete, setShowDelete] = useState({});
  const dispatch = useDispatch();
  const skeletonArray = Array(5).fill('');
  const history = useHistory();

  const getERC20Contract = useCallback(
    contractAddress => {
      if (contractAddress) return erc20Contract.attach(contractAddress);
      return erc20Contract;
    },
    [erc20Contract]
  );

  useEffect(() => {
    if (ownerSafeAddress) {
      dispatch(getNonce(ownerSafeAddress));
      dispatch(getTokens(ownerSafeAddress));
    }
  }, [ownerSafeAddress, dispatch]);

  useEffect(() => {
    if (!encryptedPeople) dispatch(getAllPeople(ownerSafeAddress));
  }, [dispatch, encryptedPeople, ownerSafeAddress]);

  useEffect(() => {
    if (encryptedPeople && encryptionKey) {
      const sortedDecryptedPeople = encryptedPeople
        .map(({ data, ...rest }) => {
          const { firstName, lastName, salaryAmount, salaryToken, address } =
            getDecryptedDetails(data, encryptionKey, organisationType);
          return {
            firstName,
            lastName,
            salaryAmount,
            salaryToken,
            address,
            ...rest,
          };
        })
        .sort((a, b) =>
          a.firstName &&
          b.firstName &&
          a.firstName.toUpperCase() > b.firstName.toUpperCase()
            ? 1
            : -1
        );

      const peopleMap = sortedDecryptedPeople.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.address]: cur,
        };
      }, {});

      setPeople(peopleMap);
    }
  }, [encryptedPeople, encryptionKey, organisationType, ownerSafeAddress]);

  useEffect(() => {
    const getAllAutomations = async () => {
      if (
        ownerSafeAddress &&
        automationModule &&
        !loadingPeople &&
        people !== undefined
      ) {
        setLoadingAutomatedTransactions(true);
        try {
          const start = 0;
          const pageSize = 255; // max uint8 value
          const allDelegates = await automationModule.getDelegates(
            ownerSafeAddress,
            start,
            pageSize
          );
          const { results: delegates } = allDelegates;
          const automations = {};
          const updatedTransactionsList = [];
          const delegatesPromises = delegates.map(async delegate => {
            const tokens = await automationModule.getTokens(
              ownerSafeAddress,
              delegate
            );
            automations[delegate] = {};
            if (tokens && tokens.length > 0) {
              const tokensPromises = tokens.map(async token => {
                const tokenAllowance = await automationModule.getTokenAllowance(
                  ownerSafeAddress,
                  delegate,
                  token
                );
                let tokenName = 'ETH';
                let tokenAddress = token;
                let decimals = 18;
                try {
                  const amount = getAmountFromWei(tokenAllowance[0], decimals);
                  if (amount !== 0) {
                    if (
                      tokenAddress !== addresses.ZERO_ADDRESS &&
                      tokenAddress !== addresses.OTHER_ZERO_ADDRESS
                    ) {
                      const erc20 = getERC20Contract(tokenAddress);
                      decimals = await erc20.decimals();
                      tokenName = await erc20.symbol();
                      automations[delegate][tokenName] = {
                        delegate: delegate,
                        allowanceAmount: getAmountFromWei(
                          tokenAllowance[0],
                          decimals
                        ),
                        spentAmount: getAmountFromWei(
                          tokenAllowance[1],
                          decimals
                        ),
                        resetTimeMin: tokenAllowance[2].toNumber(),
                        lastResetMin: tokenAllowance[3].toNumber(),
                        tokenName,
                        tokenAddress,
                      };
                    } else {
                      automations[delegate][tokenName] = {
                        delegate: delegate,
                        allowanceAmount: getAmountFromWei(
                          tokenAllowance[0],
                          decimals
                        ),
                        spentAmount: getAmountFromWei(
                          tokenAllowance[1],
                          decimals
                        ),
                        resetTimeMin: tokenAllowance[2].toNumber(),
                        lastResetMin: tokenAllowance[3].toNumber(),
                        tokenName: tokenName,
                        tokenAddress: addresses.ZERO_ADDRESS,
                      };
                    }
                  }
                } catch (error) {
                  console.error('error with token: ', tokenAddress, error);
                }
              });
              await Promise.all(tokensPromises);
            }
          });
          await Promise.all(delegatesPromises);

          const count = Object.keys(automations).reduce((acc, cur) => {
            return acc + Object.keys(automations[cur]).length;
          }, 0);

          setAutomationsCount(count);

          Object.keys(automations).forEach(address => {
            Object.keys(automations[address]).forEach(tokenAddress => {
              updatedTransactionsList.push({
                ...automations[address][tokenAddress],
                ...people[address],
              });
            });
          });

          const transactionsByTeamName = updatedTransactionsList.reduce(
            (accumulator, a) => {
              if (!a.departmentName) a.departmentName = 'payeee-without-team';
              accumulator[a.departmentName] =
                accumulator[a.departmentName] || [];
              accumulator[a.departmentName].push(a);
              return accumulator;
            },
            {}
          );
          setLoadingAutomatedTransactions(false);
          setTransactions(transactionsByTeamName || {});
          setUpdatedTransactionList(updatedTransactionsList);
        } catch (err) {
          console.error(err);
          setLoadingAutomatedTransactions(false);
          setTransactions({});
        }
      }
    };
    getAllAutomations();
  }, [
    automationModule,
    getERC20Contract,
    loadingPeople,
    ownerSafeAddress,
    people,
  ]);

  const showGasPriceModal = () => {
    dispatch(show(GAS_PRICE_MODAL));
  };

  const showRemoveAutomationModal = () => {
    dispatch(addPayeesforDeleteion(selectedRows));
    dispatch(show(REMOVE_AUTOMATION_MODAL));
  };

  const showAutomationsGuide = () => {
    dispatch(show(AUTOMATIONS_GUIDE_MODAL));
  };

  useEffect(() => {
    let flag =
      selectedRows.length && selectedRows.length === updatedList.length
        ? true
        : false;
    setAllChecked(flag);
  }, [selectedRows]);

  useEffect(() => {
    const getGasPriceValue = async () => {
      if (ownerSafeAddress && automationModule) {
        try {
          const gasPrice = await automationModule.maxGasPrice(ownerSafeAddress);
          setGasPrice(getAmountFromWei(gasPrice, 9));
        } catch (err) {
          console.error(err);
        }
      }
    };

    getGasPriceValue();
  }, [ownerSafeAddress, automationModule]);

  const handleChecked = ({
    delegate,
    tokenAddress,
    firstName,
    lastName,
    allowanceAmount,
    tokenName,
    resetTimeMin,
  }) => {
    let payeeDetails = {
      delegate,
      tokenAddress,
      firstName,
      lastName,
      allowanceAmount,
      tokenName,
      resetTimeMin,
    };

    let indexOfSelectedPerson = selectedRows.findIndex(
      el => el.delegate === delegate && el.tokenAddress === tokenAddress
    );

    let index = checked.indexOf(delegate + tokenAddress);
    if (index === -1) {
      setChecked([...checked, delegate + tokenAddress]);
    } else if (index !== -1) {
      let newArr = [...checked];
      newArr.splice(index, 1);
      setChecked(newArr);
    }

    if (indexOfSelectedPerson === -1)
      setSelectedRows([...selectedRows, payeeDetails]);
    else if (indexOfSelectedPerson !== -1) {
      let newArr = [...selectedRows];
      newArr.splice(indexOfSelectedPerson, 1);
      setSelectedRows(newArr);
    }
  };

  const handleCancelorEditGasPrice = () => {
    setEditTransactions(false);
    setChecked([]);
    setSelectedRows([]);
    dispatch(addPayeesforDeleteion([]));
    dispatch(getAllPeople(ownerSafeAddress));
  };

  const handleAllChecked = (e, checkedFlag) => {
    if (checkedFlag) {
      setAllChecked(false);
      setSelectedRows([]);
      setChecked([]);
    } else {
      const selected = [];
      const checkedResult = [];
      Object.values(transactions).map(list => {
        if (list && list.length) {
          list.map(
            (
              {
                firstName,
                lastName,
                lastResetMin,
                resetTimeMin,
                tokenAddress,
                delegate,
                tokenName,
                allowanceAmount,
              },
              idx
            ) => {
              const payeeDetails = {
                delegate,
                tokenAddress,
                firstName,
                lastName,
                tokenName,
                allowanceAmount,
                resetTimeMin,
              };

              checkedResult.push(delegate + tokenAddress);
              selected.push(payeeDetails);
            }
          );
        }
      });

      setChecked(checkedResult);
      setSelectedRows(selected);
    }
  };

  const handleDeleteButtonShow = (event, trigger, delegate) => {
    if (event.target.id === 'delete-button') {
      return;
    }

    if (trigger === 'out') {
      setShowDelete({
        ...showDelete,
        [delegate]: false,
      });
    } else if (trigger === 'enter') {
      setShowDelete({
        ...showDelete,
        [delegate]: true,
      });
    }
  };

  const deleteIndividualRecord = payeeDetails => {
    dispatch(addPayeesforDeleteion([payeeDetails]));
    dispatch(show(REMOVE_AUTOMATION_MODAL));
  };

  // const getInfo = async () => {
  //   setLoadingAutomatedTransactions(true);
  //   try {
  //     const start = 0;
  //     const pageSize = 255; // max uint8 value

  //     const allInfo = await automationModule.getInfo(
  //       ownerSafeAddress,
  //       start,
  //       pageSize
  //     );

  //     // const taskId = await automationModule.getTaskId(
  //     //   ownerSafeAddress,
  //     //   '0xc3dbf84Abb494ce5199D5d4D815b10EC29529ff8',
  //     //   '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
  //     // );

  //     setLoadingAutomatedTransactions(false);
  //   } catch (err) {
  //     console.error(err);
  //     setLoadingAutomatedTransactions(false);
  //     setTransactions({});
  //   }
  // };

  const renderAllTransactions = () => {
    if (loadingAutomatedTransactions || loadingPeople)
      return skeletonArray.map((item, index) => (
        <tr key={index}>
          <td>
            <CheckBox checked={false} />
          </td>
          <td>
            <Skeleton variant="rect" height="1.2rem" width="18rem" />
          </td>
          <td>
            <div className="d-flex align-items-center">
              <Skeleton
                variant="circle"
                width={'2.8rem'}
                height={'2.8rem'}
                className="mr-4"
                style={{ flex: '0 0 2.8rem' }}
              />
              <Skeleton variant="rect" height="1.2rem" width="10rem" />
            </div>
          </td>
          <td>
            <Skeleton variant="rect" height={'1.4rem'} width="10rem" />
          </td>
          <td>
            <Skeleton
              variant="rect"
              style={{ marginLeft: '4rem' }}
              height={'1.4rem'}
              width="10rem"
            />
          </td>
          <td></td>
        </tr>
      ));

    if (!isObjEmpty(transactions)) {
      return (
        transactions &&
        Object.entries(transactions).map(([entity, list], id) => (
          <Fragment key={`${entity}${id}`}>
            {entity !== 'payeee-without-team' && (
              <TableDivider className="flex align-items-center">
                <td>{entity}</td>
              </TableDivider>
            )}

            {list &&
              list.map(
                (
                  {
                    firstName,
                    lastName,
                    lastResetMin,
                    resetTimeMin,
                    tokenAddress,
                    delegate,
                    tokenName,
                    allowanceAmount,
                  },
                  idx
                ) => {
                  const payeeDetails = {
                    delegate,
                    tokenAddress,
                    firstName,
                    lastName,
                    tokenName,
                    allowanceAmount,
                    resetTimeMin,
                  };

                  return (
                    <tr
                      key={`${delegate}-${idx}`}
                      onMouseOver={event =>
                        handleDeleteButtonShow(event, 'enter', delegate)
                      }
                      onMouseOut={event =>
                        handleDeleteButtonShow(event, 'out', delegate)
                      }
                    >
                      <td>
                        <CheckBox
                          type="checkbox"
                          id={`checkbox${idx}`}
                          name={`checkbox${idx}`}
                          checked={
                            checked.includes(delegate + tokenAddress) || false
                          }
                          onChange={() =>
                            handleChecked({
                              ...payeeDetails,
                            })
                          }
                        />
                      </td>
                      <td
                        onClick={e => {
                          e.preventDefault();
                          handleChecked({
                            ...payeeDetails,
                          });
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <Avatar
                            className="mr-3"
                            firstName={firstName}
                            lastName={lastName}
                          />
                          <div>
                            {firstName
                              ? firstName + ' ' + lastName
                              : minifyAddress(delegate)}
                          </div>
                        </div>
                      </td>
                      <td
                        onClick={e => {
                          e.preventDefault();
                          handleChecked({
                            ...payeeDetails,
                          });
                        }}
                      >
                        <TokenImg token={tokenName} />
                        {formatNumber(allowanceAmount, 5)} {tokenName}
                      </td>
                      <td
                        onClick={e => {
                          e.preventDefault();
                          handleChecked({
                            ...payeeDetails,
                          });
                        }}
                      >
                        {frequencies[resetTimeMin]}
                      </td>
                      <td
                        onClick={e => {
                          e.preventDefault();
                          handleChecked({
                            ...payeeDetails,
                          });
                        }}
                        style={{ textAlign: 'end' }}
                      >
                        {renderLastPaidValue(lastResetMin + resetTimeMin)}
                      </td>
                      <td
                        onClick={() => deleteIndividualRecord(payeeDetails)}
                        className="d-flex align-items-center justify-content-center"
                      >
                        {showDelete[delegate] ? (
                          <Delete
                            id="delete-button"
                            style={{ width: '12px' }}
                          />
                        ) : null}
                      </td>
                    </tr>
                  );
                }
              )}
          </Fragment>
        ))
      );
    } else {
      return (
        <>
          <TableDivider className="flex align-items-center">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </TableDivider>
          <TableInfo
            style={{
              fontSize: '1.4rem',
              fontWeight: '500',
              textAlign: 'center',
              height: '10rem',
            }}
          >
            <td
              colSpan={6}
              style={{
                padding: '6.5rem 3rem',
                color: '#8D8E91',
                fontWeight: 'normal',
              }}
            >
              <NoTransaction style={{ opacity: '.6' }} />
              <p style={{ marginTop: '20px' }}>No Active Automations!</p>
            </td>
          </TableInfo>
        </>
      );
    }
  };

  return (
    <AutomationsWrapper>
      <div
        style={{ width: '100%', borderBottom: '1px solid #ebecef' }}
        className="d-flex align-items-center justify-content-between"
      >
        <Title heading={'Active Automations'} />
        <GasLink onClick={() => showGasPriceModal()}>
          {' '}
          <Tag /> Max GAS Price: {gasPrice} GWEI
        </GasLink>
      </div>

      {selectedRows.length ? (
        <NotificationArea>
          <NotifyArea>
            <Dot></Dot>
            {`Selected ${selectedRows.length} out of ${updatedList.length} automations`}
          </NotifyArea>
          <ActionButton onClick={() => showRemoveAutomationModal()}>
            <Delete
              style={{
                marginRight: '8px',
                height: '12px',
              }}
            />
            Stop Automation
          </ActionButton>
        </NotificationArea>
      ) : (
        <NotificationArea>
          <NotifyArea>
            <Dot></Dot>
            {`Payouts created through automations will be visible in the automated tab of transactions`}
          </NotifyArea>
          <div className="d-flex">
            <ActionButton onClick={() => showAutomationsGuide()}>
              <AutomationGuide
                style={{
                  marginRight: '8px',
                  height: '12px',
                }}
              />
              Learn about Automations
            </ActionButton>
            <ActionButton
              onClick={() =>
                history.push('/dashboard/transactions', {
                  activeTab: '3',
                })
              }
            >
              <LinkToTransaction
                style={{
                  marginRight: '8px',
                  height: '12px',
                }}
              />
              Go to Automated Transaction
            </ActionButton>
          </div>
        </NotificationArea>
      )}

      <Table
        style={{
          borderTop: '0',
          marginTop: '2rem',
        }}
      >
        <TableHead>
          <tr>
            <th style={{ width: '7%' }}>
              <CheckBox
                checked={allChecked}
                onClick={e =>
                  handleAllChecked(
                    e,
                    selectedRows.length &&
                      selectedRows.length === updatedList.length
                      ? true
                      : false
                  )
                }
              ></CheckBox>
            </th>
            <th style={{ width: '35%' }}>Payee</th>
            <th style={{ width: '29%' }}>Disbursement</th>
            <th style={{ width: '20%' }}>Frequency</th>
            <th style={{ width: '16%', textAlign: 'end' }}>Next Payment</th>
            <th style={{ width: '16%', textAlign: 'end' }}></th>
          </tr>
        </TableHead>

        {(loadingAutomatedTransactions || loadingPeople) && (
          <TableDivider className="flex align-items-center">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </TableDivider>
        )}

        <TableBody>{renderAllTransactions()}</TableBody>
      </Table>
      <GasPriceModal gasPrice={gasPrice} />
      <RemoveAutomationModal
        closeEditAutomations={handleCancelorEditGasPrice}
      />
      <AutomationsGuideModal />
    </AutomationsWrapper>
  );
};

export default Automations;
