export const GET_ALL_PEOPLE = 'parcel/viewPeople/GET_ALL_PEOPLE';
export const GET_ALL_PEOPLE_SUCCESS =
  'parcel/viewPeople/GET_ALL_PEOPLE_SUCCESS';
export const GET_ALL_PEOPLE_ERROR = 'parcel/viewPeople/GET_ALL_PEOPLE_ERROR';

export const GET_PEOPLE_BY_TEAM = 'parcel/viewPeople/GET_PEOPLE_BY_TEAM';
export const GET_PEOPLE_BY_TEAM_SUCCESS =
  'parcel/viewPeople/GET_PEOPLE_BY_TEAM_SUCCESS';
export const GET_PEOPLE_BY_TEAM_ERROR =
  'parcel/viewPeople/GET_PEOPLE_BY_TEAM_ERROR';

export const ADD_PEOPLE_FILTER = 'parcel/viewPeople/ADD_PEOPLE_FILTER';
export const REMOVE_PEOPLE_FILTER = 'parcel/viewPeople/REMOVE_PEOPLE_FILTER';
export const SET_SEARCH_NAME = 'parcel/viewPeople/SET_SEARCH_NAME';
