import styled from 'styled-components/macro';

export default styled.div`
  min-height: 100vh;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title,
  .subtitle {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  }

  .title {
    margin-top: 32px;
  }

  .subtitle {
    font-weight: normal;
  }

  .bottom-buttons {
    display: flex;
    margin-top: 20px;
    gap: 32px;

    bottom-button: {
      max-height: 48px;
      max-width: 300px;
      fontsize: 16;
      fontweight: 700;
      padding: 8px 72px;
    }
  }
`;
