import { Modal, ModalBody, ModalHeader } from 'components/common/Modal';
import MassPayoutTeams from 'components/MassPayoutTeams';
import React from 'react';
import { connectModal as reduxModal } from 'redux-modal';

export const MODAL_NAME = 'mass-payout-across-teams-modal';

function MassPayoutAcrossTeamsModal(props) {
  const { show, handleHide } = props;
  return (
    <Modal isOpen={show} toggle={handleHide}>
      <ModalHeader
        title={'Mass Payout Across Multiple Teams'}
        toggle={handleHide}
      />
      <ModalBody width="110rem" minHeight="auto">
        <MassPayoutTeams handleHide={handleHide} />
      </ModalBody>
    </Modal>
  );
}

export default reduxModal({ name: MODAL_NAME })(MassPayoutAcrossTeamsModal);
