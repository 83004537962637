import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { HeaderLink, NavBar, NavBarContent, NavGroup } from './styles';

import ConnectButton from 'components/Connect';
// import DashboardHeader from "./DashboardHeader";
import { useActiveWeb3React } from 'hooks';
import Button from 'components/common/Button';
import ParcelLogo from 'assets/images/parcel-logo-purple.png';
import Img from 'components/common/Img';

function PlainHeader() {
  const location = useLocation();
  const history = useHistory();
  const isLoginPage = location.pathname === '/';
  const isSignupPage = location.pathname === '/signup';
  const { account, onboard } = useActiveWeb3React();

  // if(location === "/") {
  //   return (
  //     <React.Fragment>
  //       <LayoutContainer>
  //         <Main>{children}</Main>
  //       </LayoutContainer>
  //     </React.Fragment>
  //   )
  // }

  const handleDisconnect = () => {
    if (onboard) {
      onboard.walletReset();
    }
  };

  const renderSignUpButton = () => (
    <Button
      type="button"
      onClick={() => {
        const searchParams = new URLSearchParams(location.search);
        history.push({ pathname: '/signup', search: searchParams.toString() });
      }}
      className="secondary ml-3 py-2 px-4"
    >
      Sign Up
    </Button>
  );

  const renderLoginButton = () => (
    <Button
      type="button"
      onClick={() => {
        const searchParams = new URLSearchParams(location.search);
        history.push({ pathname: '/', search: searchParams.toString() });
      }}
      className="secondary ml-3 py-2 px-4"
    >
      Login
    </Button>
  );

  return (
    <div>
      <NavBar white>
        <NavBarContent>
          <div className="d-flex justify-content-center align-items-center">
            <HeaderLink to="/" className="dashboard-link">
              <Img src={ParcelLogo} alt="parcel" width="160" />
            </HeaderLink>
          </div>
          <NavGroup>
            {!account ? (
              <ConnectButton className="py-2 px-4">Connect</ConnectButton>
            ) : (
              <Button
                className="py-2 px-4 secondary"
                onClick={handleDisconnect}
              >
                Disconnect
              </Button>
            )}
            {isLoginPage && renderSignUpButton()}
            {isSignupPage && renderLoginButton()}
          </NavGroup>
        </NavBarContent>
      </NavBar>
    </div>
  );
}

export default function Header() {
  const location = useLocation();

  if (
    location.pathname.includes('/dashboard') ||
    location.pathname.includes('/')
  )
    return null;

  return <PlainHeader />;
}
