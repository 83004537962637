import produce from 'immer';
import {
  EDIT_PEOPLE,
  EDIT_PEOPLE_SUCCESS,
  EDIT_PEOPLE_ERROR,
  DELETE_PEOPLE,
  DELETE_PEOPLE_SUCCESS,
  DELETE_PEOPLE_ERROR,
  DELETE_PEOPLE_BULK,
  DELETE_PEOPLE_BULK_SUCCESS,
  DELETE_PEOPLE_BULK_ERROR,
  DELETE_PEOPLE_BULK_LIST,
} from './action-types';

export const initialState = {
  error: false,
  updating: false,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case EDIT_PEOPLE:
      case DELETE_PEOPLE:
      case DELETE_PEOPLE_BULK:
        draft.updating = true;
        break;

      case EDIT_PEOPLE_SUCCESS:
      case DELETE_PEOPLE_SUCCESS:
      case DELETE_PEOPLE_BULK_SUCCESS:
        draft.updating = false;
        break;

      case EDIT_PEOPLE_ERROR:
      case DELETE_PEOPLE_ERROR:
      case DELETE_PEOPLE_BULK_ERROR:
        draft.updating = false;
        draft.error = action.error;
        break;

      case DELETE_PEOPLE_BULK_LIST:
        draft.peopleToBeDeleted = action.peopleToBeDeleted;
        break;
    }
  });

export default reducer;
