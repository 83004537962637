import styled from 'styled-components/macro';
import downArrow from 'assets/icons/down-arrow.svg';

export const PaymentScheduleWrapper = styled.div`
  .flex {
    display: flex;
  }
  .center {
    align-items: center;
  }
  .subtitle {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
  }
  .payment-schedule-items-grid {
    display: grid;
    grid-template-columns: 0.6fr 2fr;
    grid-gap: 1.9rem;
    margin-bottom: 3rem;
    align-items: center;
  }
  .payment-schedule-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  /* .endson-after-wrapper {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
  } */
  /* .endsOnInput {
    width: 6rem;
    height: 2.4rem;
    min-height: 2.4rem;
    margin: 0 0.6rem;
    padding: 0.6rem 1rem 0.4rem 1rem;
    font-size: 12px;
    line-height: 14px;
  } */
  .gas-price-container {
    margin-left: 5.4rem;
    input {
      font-weight: normal;
      font-size: 1.2rem;
      line-height: 1.4rem;
      padding: 1.4rem 0 1rem 1rem;
    }
    p {
      font-weight: normal;
      font-size: 1.2rem;
    }
  }
  .gas-price-input {
    margin: 1.6rem 0 1rem 0;
  }
  input[type='date'] {
    display: block;
    position: relative;
    ${'' /* width: 11.2rem; */}
    width: fit-content;
    height: 2.4rem;
    padding: 0.6rem 1rem 0.4rem 1rem;
    font-size: 12px;
    border: 1px solid #373737;
    border-radius: 2px;
    ${
      '' /* background: white
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='22' viewBox='0 0 20 22'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23688EBB' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(1 1)'%3E%3Crect width='18' height='18' y='2' rx='2'/%3E%3Cpath d='M13 0L13 4M5 0L5 4M0 8L18 8'/%3E%3C/g%3E%3C/svg%3E")
      right 1rem center no-repeat; */
    }
    background: white url(${downArrow}) right 1rem center no-repeat;
    cursor: pointer;
  }
  input[type='date']:focus {
    outline: none;
    border-color: #7367f0;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    opacity: 0;
    width: 2rem;
    cursor: pointer;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0.5rem;
    bottom: 0;
  }
  ::-webkit-datetime-edit {
  }
  ::-webkit-datetime-edit-fields-wrapper {
  }
  ::-webkit-datetime-edit-month-field:hover,
  ::-webkit-datetime-edit-day-field:hover,
  ::-webkit-datetime-edit-year-field:hover {
    background: rgba(0, 120, 250, 0.1);
  }
  ::-webkit-datetime-edit-text {
    opacity: 0;
  }
  ::-webkit-clear-button,
  ::-webkit-inner-spin-button {
    display: none;
  }
`;
