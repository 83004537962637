import { call, put, fork, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { hide } from 'redux-modal';

import {
  CONFIRM_MULTISIG_TRANSACTION,
  CREATE_MULTISIG_TRANSACTION,
  GET_MULTISIG_TRANSACTIONS,
  GET_MULTISIG_TRANSACTION_BY_ID,
  SUBMIT_MULTISIG_TRANSACTION,
  FETCH_MULTISIG_TRANSACTION_WITH_DELAY,
  GET_MULTISIG_TRANSACTIONS_DASHBOARD,
} from './action-types';
import {
  getMultisigTransactionsSuccess,
  getMultisigTransactionsError,
  createMultisigTransactionSuccess,
  createMultisigTransactionError,
  confirmMultisigTransactionSuccess,
  confirmMultisigTransactionError,
  submitMultisigTransactionSuccess,
  submitMultisigTransactionError,
  getMultisigTransactionByIdError,
  getMultisigTransactionByIdSuccess,
  fetchMultisigTransactionWithDelaySuccess,
  getMultisigTransactionById as getMultisigTransactionByIdAction,
  getMultisigTransactionsDashboardSuccess,
  getMultisigTransactionsDashboardError,
} from './actions';
import request from 'utils/request';
import {
  createMultisigTransactionEndpoint,
  getMultisigTransactionEndpoint,
  confirmMultisigTransactionEndpoint,
  submitMultisigTransactionEndpoint,
  getMultisigTransactionByIdEndpoint,
} from 'constants/endpoints';
import { MODAL_NAME as MASS_PAYOUT_MODAL } from 'components/Payments/MassPayoutModal';
import { MODAL_NAME as QUICK_TRANSFER_MODAL } from 'components/Payments/QuickTransferModal';
import { MODAL_NAME as NEW_SPENDING_LIMIT_MODAL } from 'components/SpendingLimits/NewSpendingLimitModal';
import { MODAL_NAME as MASS_PAYOUT_ACROSS_TEAMS_MODAL } from 'components/Payments/MassPayoutAcrossTeamsModal';
import { routeGenerators } from 'constants/routes/generators';

function* getMultisigTransactions(action) {
  const {
    safeAddress: { ownerSafeAddress, executedTransactions },
  } = action;

  const requestURL = `${getMultisigTransactionEndpoint}?safeAddress=${ownerSafeAddress}${
    executedTransactions ? `&executed=${executedTransactions}` : ''
  }`;
  const options = {
    method: 'GET',
  };

  try {
    const result = yield call(request, requestURL, options);
    yield put(getMultisigTransactionsSuccess(result.transactions));
    if (executedTransactions === 'false') {
      yield put(getMultisigTransactionsDashboardSuccess(result.transactions));
    }
  } catch (err) {
    yield put(getMultisigTransactionsError(err));
    if (executedTransactions === 'false') {
      yield put(getMultisigTransactionsDashboardError(err));
    }
  }
}

function* getMultisigTransactionsDashboard(action) {
  const requestURL = `${getMultisigTransactionEndpoint}?safeAddress=${action.ownerSafeAddress}&executed=false`;
  const options = {
    method: 'GET',
  };

  try {
    const result = yield call(request, requestURL, options);
    yield put(getMultisigTransactionsDashboardSuccess(result.transactions));
  } catch (err) {
    yield put(getMultisigTransactionsDashboardError(err));
  }
}

function* getMultisigTransactionById(action) {
  const requestURL = `${getMultisigTransactionByIdEndpoint}?safeAddress=${action.safeAddress}&transactionId=${action.transactionId}`;
  const options = {
    method: 'GET',
  };

  try {
    const result = yield call(request, requestURL, options);

    if (result.flag === 400) {
      yield put(push('/dashboard')); // not found
    } else {
      yield put(
        getMultisigTransactionByIdSuccess(
          result.transaction,
          result.executionAllowed
        )
      );
    }
  } catch (err) {
    yield put(getMultisigTransactionByIdError(err));
  }
}

function* createMultisigTransaction(action) {
  const requestURL = `${createMultisigTransactionEndpoint}`;

  const options = {
    method: 'POST',
    body: JSON.stringify(action.body),
    headers: {
      'content-type': 'application/json',
    },
  };

  try {
    const result = yield call(request, requestURL, options);
    yield put(
      createMultisigTransactionSuccess(result.transactionId, result.log)
    );
    yield put(
      push(
        routeGenerators.dashboard.transactionById({
          transactionId: result.transactionId,
        })
      )
    );
    yield put(hide(MASS_PAYOUT_ACROSS_TEAMS_MODAL));
    yield put(hide(MASS_PAYOUT_MODAL));
    yield put(hide(QUICK_TRANSFER_MODAL));
    yield put(hide(NEW_SPENDING_LIMIT_MODAL));
  } catch (err) {
    yield put(createMultisigTransactionError(err));
  }
}

function* confirmMultisigTransaction(action) {
  const requestURL = `${confirmMultisigTransactionEndpoint}`;

  const options = {
    method: 'POST',
    body: JSON.stringify(action.body),
    headers: {
      'content-type': 'application/json',
    },
  };

  try {
    const result = yield call(request, requestURL, options);
    yield put(
      confirmMultisigTransactionSuccess(result.transactionId, result.log)
    );
  } catch (err) {
    yield put(confirmMultisigTransactionError(err));
  }
}

function* submitMultisigTransaction(action) {
  const requestURL = `${submitMultisigTransactionEndpoint}`;

  const options = {
    method: 'POST',
    body: JSON.stringify(action.body),
    headers: {
      'content-type': 'application/json',
    },
  };

  try {
    const result = yield call(request, requestURL, options);
    yield put(
      submitMultisigTransactionSuccess(
        result.transactionHash,
        result.transactionId,
        result.log
      )
    );
  } catch (err) {
    yield put(submitMultisigTransactionError(err));
  }
}

function* fetchMultisigTransactionWithDelay(action) {
  yield delay(40000);
  yield put(
    getMultisigTransactionByIdAction(
      action.ownerSafeAddress,
      action.transactionId
    )
  );
  yield put(fetchMultisigTransactionWithDelaySuccess());
}

function* watchGetMultisigTransactions() {
  yield takeLatest(GET_MULTISIG_TRANSACTIONS, getMultisigTransactions);
}

function* watchGetMultisigTransactionsDashboard() {
  yield takeLatest(
    GET_MULTISIG_TRANSACTIONS_DASHBOARD,
    getMultisigTransactionsDashboard
  );
}

function* watchGetMultisigTransactionById() {
  yield takeLatest(GET_MULTISIG_TRANSACTION_BY_ID, getMultisigTransactionById);
}

function* watchCreateMultisigTransaction() {
  yield takeLatest(CREATE_MULTISIG_TRANSACTION, createMultisigTransaction);
}

function* watchConfirmMultisigTransaction() {
  yield takeLatest(CONFIRM_MULTISIG_TRANSACTION, confirmMultisigTransaction);
}

function* watchSubmitMultisigTransaction() {
  yield takeLatest(SUBMIT_MULTISIG_TRANSACTION, submitMultisigTransaction);
}

function* watchFetchMultisigTransactionWithDelay() {
  yield takeLatest(
    FETCH_MULTISIG_TRANSACTION_WITH_DELAY,
    fetchMultisigTransactionWithDelay
  );
}

export default function* multisig() {
  yield fork(watchGetMultisigTransactions);
  yield fork(watchGetMultisigTransactionsDashboard);
  yield fork(watchGetMultisigTransactionById);
  yield fork(watchCreateMultisigTransaction);
  yield fork(watchConfirmMultisigTransaction);
  yield fork(watchSubmitMultisigTransaction);
  yield fork(watchFetchMultisigTransactionWithDelay);
}
