import styled from 'styled-components/macro';

export default styled.div`
  .label-wrapper {
    font-size: 1.2rem;
    line-height: 1.4rem;
    display: flex;
    align-items: center;
    color: #232427;
  }

  .network-logo {
    margin-right: 1rem;
  }

  .selected-network-indicator {
    position: absolute;
    right: 0.6rem;
    width: 2rem;
  }

  .list-item-wrapper {
    display: flex;
    align-items: center;
  }
`;
