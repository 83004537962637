import styled from 'styled-components/macro';

export default styled.div`
  padding: 6rem 3rem;
  position: relative;

  .container-blur {
    filter: blur(16px);
    z-index: 1;
  }

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  .process-text {
    font-size: 1.6rem;
    font-weight: normal;
    text-align: center;
    color: #373737;
    margin-top: 2rem;
  }

  .view-tx {
    color: #7367f0 !important;
    margin: 1rem 0;
    text-align: center;
    font-size: 1.4rem;
  }

  .buttons {
    margin-top: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 2rem;
  }

  @media (max-width: 600px) {
    padding: 1rem;
  }
`;
