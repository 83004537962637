import React, { useState } from 'react';
import Select from 'react-select';
import { FixedSizeList as List } from 'react-window';
import EthereumLogo from 'assets/images/ethereum.png';
import PolygonLogo from 'assets/images/polygon.png';
import GreenTick from 'assets/icons/green-tick.svg';
import Img from 'components/common/Img';
import { NetworkSelectorWrapper } from './styles';

const inputStyles = {
  control: (styles, state) => ({
    ...styles,
    width: state.selectProps.width || '100%',
    minHeight: '4rem',
    borderRadius: '0.4rem',
    backgroundColor: '#ffffff',
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    color: '#232427',
    border: '.05rem solid rgba(129, 131, 135, 0.5)',
    '&:hover': {
      borderColor: '#7367f0',
    },
    boxShadow: 'none',
  }),
  option: styles => {
    return {
      ...styles,
      width: '100%',
      overflow: 'hidden',
      fontSize: '1.2rem',
      lineHeight: '1.4rem',
      backgroundColor: '#ffffff',
      color: '#232427',
      padding: '.9rem 1rem',

      '&:active': {
        backgroundColor: '#7367f024',
      },
    };
  },
  menu: (styles, state) => {
    return {
      ...styles,
      width: state.selectProps.width || '100%',
      fontSize: '1.4rem',
      border: '.05rem solid rgba(129, 131, 135, 0.5)',
      boxShadow: 'none',

      '&::before': {
        content: "'Select Network'",
        position: 'absolute',
        padding: '.6rem 1rem .4rem',
        fontSize: '.8rem',
        fontWeight: 'bold',
        lineHeight: '1rem',
        color: 'rgb(35 36 39 / 60%)',
        backgroundColor: '#F8F8F8',
        textTransform: 'uppercase',
        borderRadius: '.4rem .4rem 0 0',
        height: '2rem',
        zIndex: 10,
        width: '100%',
      },
    };
  },
  indicatorSeparator: styles => {
    return {
      display: 'none',
      ...styles,
    };
  },
  indicatorsContainer: styles => {
    return {
      ...styles,

      div: {
        padding: '.5rem',
      },
      svg: {
        fill: '#232427',
        height: '1.4rem',
      },
    };
  },
  input: styles => ({ ...styles }),
  placeholder: styles => ({ ...styles }),
  valueContainer: styles => {
    return {
      ...styles,
      overflow: 'unset',
      padding: '0 .8rem',

      img: {
        width: '2.4rem',
        marginRight: '.8rem',
      },
    };
  },
  singleValue: (styles, state) => {
    return {
      ...styles,
      margin: '0',
    };
  },
};

function MenuList(props) {
  const height = 102;
  const { children } = props;
  const slectedVal = props.getValue();
  const { value = '' } = slectedVal[0];

  return (
    <List
      height={height}
      itemCount={children.length || 1}
      itemSize={40}
      initialScrollOffset={0}
    >
      {({ index }) => (
        <div
          style={{
            marginTop: index === 0 ? '2rem' : '0',
          }}
        >
          <div className="list-item-wrapper">
            {children[index]}
            {value === children[index].props.data.value && (
              <img
                src={GreenTick}
                className="selected-network-indicator"
                alt=""
              />
            )}
          </div>
        </div>
      )}
    </List>
  );
}

const networkOptions = [
  {
    value: 'Ethereum',
    icon: EthereumLogo,
    label: (
      <div className="label-wrapper">
        <Img
          src={EthereumLogo}
          className="network-logo"
          alt="network"
          width="20"
        />
        Ethereum
      </div>
    ),
  },
  {
    value: 'Polygon',
    icon: PolygonLogo,
    label: (
      <div>
        <Img
          src={PolygonLogo}
          alt="network"
          width="20"
          className="network-logo"
        />
        Polygon
      </div>
    ),
  },
];
const NetworkSelector = ({ networkId }) => {
  const defaultValue =
    networkId === 1
      ? networkOptions[0]
      : networkId === 137
      ? networkOptions[1]
      : networkOptions[0];

  const [selectedNetwork, setSelectedNetwork] = useState(
    defaultValue && defaultValue.value ? defaultValue.value : ''
  );

  const checkIfSameValue = currVal => {
    if (selectedNetwork === currVal) return false;
    return true;
  };
  const handleChange = e => {
    setSelectedNetwork(e.value);

    if (checkIfSameValue(e.value)) {
      if (e.value === 'Polygon') {
        window.location.href = 'https://polygon.parcel.money/';
      } else {
        window.location.href = 'https://app.parcel.money/';
      }
    }
  };

  return (
    <NetworkSelectorWrapper>
      <Select
        name="network-controller"
        options={networkOptions}
        styles={inputStyles}
        components={{ MenuList }}
        defaultValue={defaultValue}
        onChange={handleChange}
        isSearchable={false}
      />
    </NetworkSelectorWrapper>
  );
};

export default NetworkSelector;
