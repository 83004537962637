import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from '@material-ui/lab';
import SendIcon from 'assets/icons/send.svg';
import TxPendingExecutedLogo from 'assets/icons/tx-execution-logo.svg';
import TxFailedLogo from 'assets/icons/tx-failed-logo.svg';
import { ReactComponent as NoTransaction } from 'assets/icons/no-transaction.svg';
import EtherscanLink from 'components/common/EtherscanLink';
import Img from 'components/common/Img';
import {
  Table,
  TableBody,
  TableDivider,
  TableHead,
  TableInfo,
} from 'components/common/Table';
import TokenImg from 'components/common/TokenImg';
import { replaceAddresswithMinification } from 'components/common/Web3Utils';
import { format } from 'date-fns';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  makeSelectIsMultiOwner,
  makeSelectOwnerSafeAddress,
} from 'store/global/selectors';
import { numToOrd } from 'utils/date-helpers';
import { titleCase } from 'utils/manipulators';
import { formatNumber } from 'utils/number-helpers';
import { TableContainer, TransactionStatus } from './styles';
import LinkIconPrimary from 'assets/icons/ic_external_link.svg';
import MultipleTokenImg from 'assets/icons/multitoken.svg';
import { TRANSACTION_MODES } from 'constants/transactions';

function isObjEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

const getTransactionStatus = ({
  confirmedCount,
  threshold,
  rejectedCount,
  isSuccessful,
  isExecuted,
  isMultiOwner,
}) => {
  let status;
  if (isMultiOwner) {
    if (isExecuted && !isSuccessful) {
      status = 'Failed';
    } else if (confirmedCount < threshold && rejectedCount < threshold) {
      status = 'Awaiting Signatures';
    } else if (confirmedCount >= threshold && isSuccessful) {
      status = 'Success';
    } else if (rejectedCount >= threshold && isSuccessful) {
      status = 'Rejected';
    } else if (
      (confirmedCount >= threshold || rejectedCount >= threshold) &&
      !isExecuted
    ) {
      status = 'Pending Execution';
    } else {
      status = 'Failed';
    }
  } else {
    if (!isExecuted) {
      status = 'Pending';
    }
    if (isExecuted && isSuccessful) {
      status = 'Success';
    } else if (isExecuted && !isSuccessful) {
      status = 'Failed';
    }
  }

  return status;
};

const TransactionsTable = ({ transactions, loading, threshold }) => {
  const history = useHistory();

  const isMultiOwner = useSelector(makeSelectIsMultiOwner());
  const [txListOnHover, setTxListOnHover] = useState(false);

  const getTransactionTitle = (paymentType, transactionMode) => {
    if (
      transactionMode === TRANSACTION_MODES.AUTOMATION ||
      paymentType === 'removal' ||
      paymentType === 'gas'
    ) {
      return 'Automated Payout';
    } else if (
      transactionMode === TRANSACTION_MODES.MASS_PAYOUT ||
      paymentType === 'team'
    ) {
      return 'Mass Payout';
    } else if (paymentType === 'quick') {
      return 'Quick Payout';
    } else {
      return 'Payout';
    }
  };

  const renderTableContent = () => {
    const skeletonArray = Array(3).fill('');

    if (loading) {
      return skeletonArray.map((item, index) => (
        <tr style={{ height: '76px' }} key={index}>
          <td>
            <CustomShimmer height="1.8rem" width="1.6rem" />
          </td>
          <td>
            <div className="d-flex align-items-center">
              <CustomShimmer
                width={'2.8rem'}
                height={'2.8rem'}
                className="mr-2"
                variant="circle"
                style={{
                  flex: '0 0 2.8rem',
                }}
              />
              <div
                className="d-flex "
                style={{ width: '100%', flexDirection: 'column' }}
              >
                <CustomShimmer height="1.4rem" className="mb-2" />
                <CustomShimmer
                  height="1.2rem"
                  width="14rem"
                  className="mb-2"
                  style={{
                    background:
                      'linear-gradient(90deg, rgba(141, 142, 145, 0.1) 0%, rgba(141, 142, 145, 0.2) 49.31%, rgba(141, 142, 145, 0) 100%, rgba(141, 142, 145, 0.1) 100%, rgba(141, 142, 145, 0.1) 100%)',
                  }}
                />
              </div>
            </div>
          </td>
          <td>
            <div className="d-flex align-items-center">
              <CustomShimmer
                variant="circle"
                width={'2.8rem'}
                height={'2.8rem'}
                className="mr-2"
                style={{ flex: '0 0 2.8rem' }}
              />
              <div
                className="d-flex "
                style={{ width: '100%', flexDirection: 'column' }}
              >
                <CustomShimmer className="mb-2" />
                <CustomShimmer className="mb-2" height="1.2rem" width="14rem" />
              </div>
            </div>
          </td>
          <td align="right">
            <CustomShimmer className="mb-2" width="100%" />
          </td>
          <td></td>
        </tr>
      ));
    }
    if (!isObjEmpty(transactions)) {
      return (
        <>
          {Object.entries(transactions).map(([createdOn, transaction], id) => {
            const [date, month, year] = createdOn.split(' ');
            const todayDate = format(new Date(), 'dd MMM yyyy');

            return (
              <Fragment key={`${createdOn}${id}`}>
                <TableDivider className="flex align-items-center">
                  <td>
                    {todayDate === createdOn
                      ? 'today'
                      : `${numToOrd(date)} ${month} ${year}`}
                  </td>
                  <td />
                  <td />
                  <td />
                </TableDivider>

                {transaction &&
                  transaction.map(
                    (
                      {
                        transactionId,
                        tokenValue,
                        tokenCurrency,
                        addresses,
                        description,
                        paymentType,
                        fiatValue,
                        isExecuted,
                        nonce,
                        confirmedCount,
                        rejectedCount,
                        isSuccessful,
                        paidTo,
                        transactionHash,
                        transactionMode,
                      },
                      idx
                    ) => {
                      const descriptionText = replaceAddresswithMinification(
                        description,
                        6,
                        6
                      );

                      const txStatus = getTransactionStatus({
                        confirmedCount,
                        threshold,
                        rejectedCount,
                        isSuccessful,
                        isExecuted,
                        isMultiOwner,
                      });

                      const transactionTitle =
                        paymentType === 'removal' && !!addresses?.length
                          ? 'Automations Stopped'
                          : paymentType === 'fileUpload' && !!addresses?.length
                          ? replaceAddresswithMinification(addresses[0], 6)
                          : replaceAddresswithMinification(paidTo, 6);

                      const multipleTokens = tokenCurrency.indexOf('|') > -1;

                      let displayAmount = <div>-</div>;

                      if (
                        tokenValue === -1 &&
                        (paymentType === 'fileUpload' || paymentType === 'team')
                      ) {
                        displayAmount = (
                          <div className="amountContainer d-flex align-items-center">
                            {multipleTokens ? (
                              <Img
                                src={MultipleTokenImg}
                                alt="MultipleTokenImg"
                              />
                            ) : (
                              <TokenImg token={tokenCurrency} />
                            )}

                            <div>
                              <div className="tokenAmount">
                                {multipleTokens
                                  ? 'Multiple Tokens'
                                  : `${formatNumber(
                                      tokenValue,
                                      5
                                    )} ${tokenCurrency}`}
                              </div>
                              {fiatValue !== -1 && (
                                <div className="amountInUSD">
                                  ${formatNumber(fiatValue, 5)}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      } else if (tokenCurrency === '_nil_') {
                        displayAmount = <div>-</div>;
                      } else {
                        displayAmount = (
                          <div className="amountContainer d-flex align-items-center">
                            {multipleTokens ? (
                              <Img
                                src={MultipleTokenImg}
                                alt="MultipleTokenImg"
                              />
                            ) : (
                              <TokenImg token={tokenCurrency} />
                            )}

                            <div>
                              <div className="tokenAmount">
                                {multipleTokens
                                  ? 'Multiple Tokens'
                                  : `${formatNumber(
                                      tokenValue,
                                      5
                                    )} ${tokenCurrency}`}
                              </div>
                              {fiatValue !== -1 && (
                                <div className="amountInUSD">
                                  ${formatNumber(fiatValue, 5)}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      }

                      return (
                        <tr
                          key={`${transactionId}-${idx}`}
                          onMouseOver={() => setTxListOnHover(transactionId)}
                          onMouseLeave={() => setTxListOnHover()}
                        >
                          <td
                            onClick={() =>
                              history.push(
                                `/dashboard/transactions/${transactionId}`
                              )
                            }
                          >
                            {nonce}
                          </td>
                          <td
                            onClick={() =>
                              history.push(
                                `/dashboard/transactions/${transactionId}`
                              )
                            }
                          >
                            <TransactionStatus
                              className="transactionStatusContainer d-flex align-items-center"
                              status={txStatus}
                            >
                              <div className="transactionStatus">
                                <Img src={SendIcon} alt="status-indicator" />
                                {(txStatus === 'Pending Execution' ||
                                  txStatus === 'Failed') && (
                                  <Img
                                    src={
                                      txStatus === 'Failed'
                                        ? TxFailedLogo
                                        : TxPendingExecutedLogo
                                    }
                                    className="TxStatusIndicator"
                                    alt="TxStatusIndicator"
                                  />
                                )}
                              </div>
                              <div>
                                <div
                                  className="payeeName"
                                  id="transactionTitle"
                                  data-for={'transactionTitle'}
                                  data-tip={
                                    paymentType === 'fileUpload' &&
                                    !!addresses?.length
                                      ? addresses[0]
                                      : paidTo
                                  }
                                >
                                  {' '}
                                  {transactionTitle}{' '}
                                  {(paymentType === 'team' ||
                                    (paymentType === 'fileUpload' &&
                                      addresses?.length > 1)) &&
                                    `( ${String(addresses.length).padStart(
                                      2,
                                      '0'
                                    )} People )`}{' '}
                                </div>

                                <ReactTooltip
                                  id={'transactionTitle'}
                                  place={'top'}
                                  type={'dark'}
                                  effect={'solid'}
                                  getContent={dataTip => dataTip}
                                />
                                <div className="status">{`${getTransactionTitle(
                                  paymentType,
                                  transactionMode
                                )} - ${titleCase(txStatus)}`}</div>
                              </div>
                            </TransactionStatus>
                          </td>
                          <td
                            onClick={() =>
                              history.push(
                                `/dashboard/transactions/${transactionId}`
                              )
                            }
                          >
                            {displayAmount}
                          </td>
                          <td
                            onClick={() =>
                              history.push(
                                `/dashboard/transactions/${transactionId}`
                              )
                            }
                          >
                            <div className="description">
                              {descriptionText ? `“${descriptionText}“` : '-'}
                            </div>
                          </td>
                          <td align="right">
                            <div className="d-flex align-items-center">
                              {txListOnHover === transactionId &&
                                transactionHash && (
                                  <EtherscanLink
                                    id="etherscan-link-add-funds"
                                    type="tx"
                                    hash={transactionHash}
                                    showTooltip={false}
                                    ImgSrc={LinkIconPrimary}
                                  />
                                )}

                              <div
                                className="expandLogo"
                                onClick={() =>
                                  history.push(
                                    `/dashboard/transactions/${transactionId}`
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  className="dropdown"
                                  icon={faAngleDown}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
              </Fragment>
            );
          })}
        </>
      );
    } else {
      return (
        <>
          <TableDivider className="flex align-items-center">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </TableDivider>
          <TableInfo
            style={{
              fontSize: '1.4rem',
              fontWeight: '500',
              textAlign: 'center',
              height: '10rem',
            }}
          >
            <td
              colSpan={6}
              style={{
                padding: '6.5rem 3rem',
                color: '#8D8E91',
                fontWeight: 'normal',
              }}
            >
              <NoTransaction style={{ opacity: '.6' }} />
              <p style={{ marginTop: '2rem' }}>No Transactions Found!</p>
            </td>
          </TableInfo>
        </>
      );
    }
  };
  return (
    <TableContainer>
      <Table style={{ marginTop: '1.6rem', borderRadius: '.4rem' }}>
        <TableHead>
          <tr>
            <th style={{ width: '10%' }}>ID</th>
            <th style={{ width: '30%' }}>Status</th>
            <th style={{ width: '25%' }}>Amount</th>
            <th style={{ width: '35%' }}>Description</th>
            <th style={{ width: '5%' }}></th>
          </tr>
        </TableHead>
        <TableBody>
          {loading && (
            <TableDivider className="flex align-items-center">
              <td /> <td /> <td /> <td /> <td />
            </TableDivider>
          )}

          {renderTableContent()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionsTable;

const CustomShimmer = ({
  variant = 'rect',
  height = '1.4rem',
  width = '21rem',
  style = {},
  className,
}) => {
  return (
    <Skeleton
      animation="wave"
      variant={variant}
      height={height}
      width={width}
      style={{
        ...style,
        borderRadius: `${variant === 'circle' ? '50%' : '.2rem'}`,
        background:
          'linear-gradient(90deg, rgba(141, 142, 145, 0.1) 0%, rgba(141, 142, 145, 0.2) 49.31%, rgba(141, 142, 145, 0) 100%, rgba(141, 142, 145, 0.1) 100%, rgba(141, 142, 145, 0.1) 100%)',
      }}
      className={className}
    />
  );
};
