import styled from 'styled-components/macro';

export const Container = styled.div``;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  // padding: 1.3em 0em;
  border-bottom: 1px solid #ebecef;
  align-items: center;
  .title {
    font-weight: 900;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #373737;
  }
  .actions {
    display: flex;
    align-items: center;
    .items {
      cursor: pointer;
      align-items: center;
      display: flex;
      color: #7367f0;
      font-weight: 900;
      font-size: 1.5rem;
      line-height: 1.9rem;
      margin-right: 2.2em;
      .text {
        margin-left: 1rem;
        margin-top: 5px;
      }
      .search-input {
        background-color: rgba(115, 103, 240, 0.03);
        width: 239px;
        color: #8d8e91;
        font-size: 1.4rem;
        padding: 5px 10px;
        border: 1px solid #ebecef;
        border-radius: 5px;
      }
      .MuiFilledInput-input {
        padding: 10px 10px !important;
      }
    }
    .search-text-box {
      .MuiFilledInput-root {
        font-family: inherit;
        background-color: rgba(115, 103, 240, 0.03) !important;
      }
    }
    .search-text-box:focus-within {
      outline: none !important;
      border: solid 0.1rem #7367f0 !important;
      background-color: #ffffff !important;
      border-radius: 5px;
    }
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 1.8em 0em;
`;

export const FilterCard = styled.div`
  background: #ffffff;
  border-radius: 0.4rem;
  width: 35%;
  margin-right: 1.8em;
  border: 0.1rem solid rgba(235, 236, 239, 0.8);

  .header {
    cursor: pointer;
    padding: 0 2em;
    height: 5rem;
    align-items: center;
    display: flex;
    border-bottom: 1px solid rgba(235, 236, 239, 0.8);
    .text {
      color: #8d8e91;
      font-size: 1.2rem;
      line-height: 1.4rem;
      font-weight: 500;
      letter-spacing: 0.08em;
    }
  }
  .section {
    padding: 1em 0em;
    .flex {
      display: flex;
    }
    .align-items-center {
      align-items: center;
    }
  }
`;

export const Items = styled.div`
  padding: 0em 1.8em;
  display: flex;
  background: ${props =>
    props.active ? 'rgba(115, 103, 240, 0.03)' : '#FFFFFF'};
  justify-content: space-between;
  cursor: pointer;
  height: 5rem;

  .text {
    color: #373737;
    font-size: 1.4rem;
  }
  .hover-actions {
    display: none;
  }
`;

export const DetailsTableContainer = styled.div`
  width: 100%;
`;

export const HoverActionIcons = styled.div`
  .icons {
    cursor: pointer;

    margin-right: 10px;

    fill: #7367f0;
    height: 1.7rem;
  }
  .dropdown-menu {
    padding: 0px !important;
    border: none;
    width: auto !important;
  }
`;

export const CurrencyHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  .token-img-holder {
    flex: 6rem;
    max-width: 6rem;
    text-align: center;
    margin-top: 10px;
    .usd-token {
      font-size: 2rem;
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
    }
    img {
      max-width: 3rem;
    }
  }
`;

export const FormFieldsWrapper = styled.div`
  .MuiInputBase-root,
  .MuiAutocomplete-paper,
  .MuiFormLabel-root {
    font-family: inherit;
  }
`;
