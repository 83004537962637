// import { tokens } from 'constants/index';
// import DAIIcon from 'assets/icons/tokens/DAI-icon.png';
// import USDCIcon from 'assets/icons/tokens/USDC-icon.png';

import TokenImg from 'components/common/TokenImg';

export const constructLabel = ({ token, imgUrl, component: Component }) => {
  // if (token === 'USD') {
  //   return (
  //     <div className="d-flex align-items-center">
  //       {imgUrl ? (
  //         <img src={imgUrl} alt={token} width="16" />
  //       ) : (
  //         <span style={{ width: 16 }} />
  //       )}
  //       <div className="ml-2 mt-1">{Component ? Component : token}</div>
  //     </div>
  //   );
  // } else {
  return (
    <div className="d-flex align-items-center">
      <TokenImg token={token} />
      <div className="ml-2 mt-1">{Component ? Component : token}</div>
    </div>
  );
  // }
};

export const defaultTokenOptions = [
  // {
  //   value: tokens.DAI,
  //   label: constructLabel({ token: tokens.DAI, imgUrl: DAIIcon }),
  // },
  // {
  //   value: 'USDC',
  //   label: constructLabel({ token: tokens.USDC, imgUrl: USDCIcon }),
  // },
];
