import React from 'react';
import { connectModal as reduxModal } from 'redux-modal';
import { Modal, ModalHeader, ModalBody } from 'components/common/Modal';
import RemoveAutomation from 'components/RemoveAutomation';

export const MODAL_NAME = 'remove-automation-modal';

function RemoveAutomationModal(props) {
  const { show, handleHide, closeEditAutomations } = props;

  return (
    <Modal isOpen={show} toggle={handleHide}>
      <ModalHeader title={'Stop Automation'} toggle={handleHide} />
      <ModalBody width="90rem" minHeight="auto">
        <RemoveAutomation
          handleHide={handleHide}
          closeEditAutomations={closeEditAutomations}
        />
      </ModalBody>
    </Modal>
  );
}

export default reduxModal({ name: MODAL_NAME })(RemoveAutomationModal);
