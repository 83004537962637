import React, { useEffect } from 'react';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import defaultTokenIcon from 'assets/icons/tokens/Default-icon.jpg';

export default function CustomSelect({
  options,
  placeholder,
  renderOptions,
  defaultValue,
  name,
  helperText,
  error,
  onChange,
  id,
  disabled,
  clearable,
  formik,
  value,
}) {
  const filter = createFilterOptions();

  const optionsToRender = {
    tokens: option => {
      return (
        <div className="token-img">
          {option.symbol === 'USD' ? (
            <span className="usd-token">$</span>
          ) : (
            <img
              src={option?.logoURI || defaultTokenIcon}
              alt="tokenLogo"
              style={{ height: '14px', width: '14px' }}
            ></img>
          )}
          <div>{option.symbol}</div>
        </div>
      );
    },
    teams: option => {
      return <div>{option.name}</div>;
    },
  };

  return (
    <Autocomplete
      key={id}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          formik.setFieldValue(name, newValue, false);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          formik.setFieldValue(name, newValue.inputValue, false);
        } else {
          formik.setFieldValue(
            name,
            renderOptions === 'tokens' ? newValue?.symbol : newValue?.name,
            false
          );
        }
      }}
      filterOptions={(options, params) => {
        const filtered = options && params ? filter(options, params) : [];

        // Suggest the creation of a new value
        if (
          params.inputValue !== '' &&
          filtered.length === 0 &&
          renderOptions === 'teams'
        ) {
          filtered?.push(
            renderOptions === 'tokens'
              ? {
                  inputValue: params.inputValue,
                  symbol: `Add "${params.inputValue}"`,
                }
              : {
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}"`,
                }
          );
        }

        return filtered;
      }}
      // selectOnFocus
      // clearOnBlur
      handleHomeEndKeys
      disablePortal={true}
      options={options}
      style={{ flex: '100%' }}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return renderOptions === 'tokens' ? option.symbol : option.name;
      }}
      id={id}
      name={name}
      value={value}
      autoHighlight
      selectOnFocus
      disabled={disabled}
      renderOption={option =>
        renderOptions === 'tokens'
          ? optionsToRender.tokens(option)
          : optionsToRender.teams(option)
      }
      disableClearable={clearable}
      freeSolo={renderOptions === 'tokens' ? false : true}
      renderInput={params => {
        return (
          <TextField
            {...params}
            InputLabelProps={{
              style: {
                fontSize: 14,
                color: '#8D8E91',
                fontFamily: 'Avenir Pro',
              },
            }}
            variant="filled"
            fullWidth
            disableUnderline={true}
            error={error || null}
            helperText={helperText || null}
            FormHelperTextProps={{
              style: {
                fontSize: 12,
              },
            }}
            inputProps={{
              ...params.inputProps,
              style: {
                fontSize: 14,
                border: 'none',
                padding: '0px !important',
              },
            }}
            InputProps={{
              ...params.InputProps,
              style: {
                fontFamily: 'Avenir Pro',
              },
            }}
            label={placeholder}
          />
        );
      }}
    />
  );
}
