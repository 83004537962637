import styled from 'styled-components/macro';

export default styled.div`
  background: rgba(221, 220, 220, 0.4);

  width: 100%;
  height: 100%;
  padding-top: 11.2rem;

  .loader {
    margin: 0 auto;
    width: max-content;
  }

  .loader-title {
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;

    margin-top: 5.6rem;
  }

  .loader-subtitle {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    margin-top: 1.6rem;
  }

  .children-container {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    width: max-content;

    margin: 4rem auto 0;
  }
`;
