import styled from 'styled-components/macro';

export default styled.tr`
  background-color: #f8f9fa !important;
  height: 3.2rem !important;
  border-top: 0.1rem solid rgba(235, 236, 239, 0.8) !important;
  border-bottom: 0.1rem solid rgba(235, 236, 239, 0.8) !important;

  td {
    font-size: 1rem !important;
    line-height: 1.2rem !important;
    letter-spacing: 0.08em !important;
    text-transform: uppercase !important;
    color: #8d8e91 !important;
    height: unset;
    background-color: #f8f9fa !important;
    overflow: unset !important;
    cursor: default;
  }
`;
