import styled from 'styled-components/macro';

export default styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 16% 1fr;

  @media (max-width: 978px) {
    grid-template-columns: 1fr;
  }
`;
