import styled from 'styled-components/macro';

export default styled.div`
  margin-top: 1.6rem;

  tr {
    padding: 2rem 0;
  }

  .amountContainer {
    img {
      width: 2.8rem;
      height: 2.8rem;
      margin-right: 1rem !important;
    }

    .tokenAmount {
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: #373737;
    }

    .amountInUSD {
      font-weight: normal;
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: #8d8e91;
      margin-top: 0.4rem;
    }
  }
  .description {
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #373737;
  }

  .expandLogo {
    text-align: center;
    margin-left: 1.8rem;

    svg {
      color: #8d8e91;
      transform: rotate(-90deg);
      width: 1.2rem;
      height: 1.2rem;
    }
  }
`;
