import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import Button from 'components/common/Button';
// import { InfoCard, TeamContainer } from './styles';
import CustomSelect from './CustomSelect';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { cryptoUtils } from 'parcel-sdk';

import {
  makeSelectOwnerSafeAddress,
  makeSelectOrganisationType,
} from 'store/global/selectors';
import {
  makeSelectTokensDetails,
  makeSelectLoading,
  makeSelectTokensDropdown,
} from 'store/tokens/selectors';
import addPeopleReducer from 'store/add-people/reducer';
import addPeopleSaga from 'store/add-people/saga';
import { addPeople } from 'store/add-people/actions';
import {
  makeSelectLoading as makeSelectAddingPeople,
  makeSelectError as makeSelectErrorInAdd,
} from 'store/add-people/selectors';
import modifyPeopleReducer from 'store/modify-people/reducer';
import modifyPeopleSaga from 'store/modify-people/saga';
import { editPeople } from 'store/modify-people/actions';
import {
  makeSelectUpdating,
  makeSelectError as makeSelectErrorInUpdate,
} from 'store/modify-people/selectors';
import { makeSelectLoading as makeSelectLoadingPeople } from 'store/view-people/selectors';
import { makeSelectLoading as makeSelectLoadingTeam } from 'store/view-teams/selectors';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { useActiveWeb3React, useLocalStorage } from 'hooks';
import {
  makeSelectTeamIdToDetailsMap,
  makeSelectTeams,
} from 'store/view-teams/selectors';
import { constructLabel } from 'utils/tokens';
import ErrorText from 'components/common/ErrorText';
import { FormFieldsWrapper } from './styles';
import defaultTokenIcon from 'assets/icons/tokens/Default-icon.jpg';

export const MODAL_NAME = 'add-single-people-modal';
const addPeopleKey = 'addPeople';
const modifyPeopleKey = 'modifyPeople';

const AddPeople = props => {
  const validationSchema = yup.object({
    firstName: yup.string('').required('First Name is required'),
    lastName: yup.string(''),
    address: yup
      .string('')
      .matches(/^0x[a-fA-F0-9]{40}$/, 'Enter valid address')
      .required('Address is required'),
    team: yup.string().required('Team is required'),
    token: yup.string().required('Token is required'),
    amount: yup.number().required('Amount is required'),
  });
  const [tokens, setTokens] = useState([]);

  const {
    show,
    handleHide,
    isEditMode,
    defaultValues,
    peopleId,
    addOpen,
    setAddOpen,
    tokensToRender,
    setTokensToRender,
    editOpen,
    setEditOpen,
    loading,
  } = props;
  const [encryptionKey] = useLocalStorage('ENCRYPTION_KEY');
  const [teamsDropdown, setTeamsDropdown] = useState([]);
  const { account } = useActiveWeb3React();

  const { register, handleSubmit, errors, control, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });
  const hasTeamChanged = watch('team');

  // Reducers
  useInjectReducer({ key: addPeopleKey, reducer: addPeopleReducer });
  useInjectReducer({ key: modifyPeopleKey, reducer: modifyPeopleReducer });

  // Sagas
  useInjectSaga({ key: addPeopleKey, saga: addPeopleSaga });
  useInjectSaga({ key: modifyPeopleKey, saga: modifyPeopleSaga });

  const dispatch = useDispatch();

  const safeAddress = useSelector(makeSelectOwnerSafeAddress());
  const loadingTokenList = useSelector(makeSelectLoading());
  const loadingPeople = useSelector(makeSelectLoadingPeople());
  const loadingTeams = useSelector(makeSelectLoadingTeam());
  const adding = useSelector(makeSelectAddingPeople());
  const updating = useSelector(makeSelectUpdating());
  const allTeams = useSelector(makeSelectTeams());
  const teamIdToDetailsMap = useSelector(makeSelectTeamIdToDetailsMap());
  const organisationType = useSelector(makeSelectOrganisationType());
  const errorInAdd = useSelector(makeSelectErrorInAdd());
  const errorInUpdate = useSelector(makeSelectErrorInUpdate());
  const tokenDetails = useSelector(makeSelectTokensDetails());
  const tokensDropdown = useSelector(makeSelectTokensDropdown());
  useEffect(() => {
    setTokens(tokensToRender ? Object.values(tokensToRender) : []);
  }, [tokensToRender]);

  useEffect(() => {
    if (tokens.length === 1) {
      formik.setFieldValue('token', tokens[0].symbol, false);
    }
  }, [tokens]);

  useEffect(() => {
    setTeamsDropdown(allTeams);
  }, [allTeams]);

  const onSubmit = values => {
    const { firstName, lastName, address, team, token, amount } = values;
    try {
      const tokenInfo = tokenDetails && tokenDetails[values.token];
      let tempDepartmentId = defaultValues?.departmentId;

      teamsDropdown.map(i => {
        if (i.name === team) {
          tempDepartmentId = i.departmentId;
        }
        return i;
      });

      if (account && safeAddress && tokenInfo) {
        const encryptedEmployeeDetails =
          cryptoUtils.encryptDataUsingEncryptionKey(
            JSON.stringify({
              firstName,
              lastName,
              salaryAmount: amount,
              salaryToken: token,
              address,
            }),
            encryptionKey,
            organisationType
          );

        const body = {
          encryptedEmployeeDetails,
          safeAddress,
          createdBy: account,
          departmentId: tempDepartmentId,
          departmentName: team,
          tokenInfo,
        };

        if (isEditMode) {
          // Update
          dispatch(editPeople({ ...body, peopleId: defaultValues.peopleId }));
        } else {
          // Add
          dispatch(addPeople(body));
        }
      }
      if (isEditMode) {
        setEditOpen(false);
      } else {
        setAddOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {}, [teamsDropdown]);
  const formik = useFormik({
    initialValues: {
      firstName: isEditMode ? defaultValues.firstName : '',
      lastName: isEditMode ? defaultValues.lastName : '',
      address: isEditMode ? defaultValues.address : '',
      team: isEditMode ? defaultValues.departmentName : '',
      token: isEditMode ? defaultValues.salaryToken : '',
      amount: isEditMode ? defaultValues.salaryAmount : '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      onSubmit(values);
    },
  });
  useEffect(() => {
    if (formik.values.team && formik.values.team !== '') {
      //   const { tokenInfo } = teamIdToDetailsMap[formik.values.team];

      let newTokentoRender = [];
      allTeams?.map(team => {
        if (team.name === formik.values.team) {
          newTokentoRender.push(team.tokenInfo);
        }
        return team;
      });
      if (newTokentoRender.length === 1) {
        setTokensToRender(newTokentoRender);
      } else {
        setTokensToRender(tokenDetails);
      }
    }
  }, [formik.values.team]);
  return (
    <div role="tabpanel" hidden={props.value !== props.index}>
      {props.value === props.index && (
        <>
          <div className="customDropDownEdit">
            {isEditMode ? <div className="title">Edit Person</div> : <></>}
            <form onSubmit={formik.handleSubmit}>
              <FormFieldsWrapper className="select-wrapper custom-mui-select">
                <TextField
                  // placeholder="Name"
                  id="firstName"
                  variant="filled"
                  fullWidth
                  name="firstName"
                  label="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  FormHelperTextProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                  style={{
                    marginTop: 10,
                  }}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      fontFamily: 'Avenir Pro',
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: 14,
                      border: '1px solid rgba(235, 236, 239, 0.8)',
                      background: '#FFFFFF',
                      fontFamily: 'Avenir Pro',
                    },
                  }}
                />
                <TextField
                  // placeholder="Name"
                  id="lastName"
                  variant="filled"
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  style={{
                    marginTop: 10,
                  }}
                  FormHelperTextProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      fontFamily: 'Avenir Pro',
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: 14,
                      border: '1px solid rgba(235, 236, 239, 0.8)',
                      background: '#FFFFFF',
                      fontFamily: 'Avenir Pro',
                    },
                  }}
                />
                <TextField
                  // placeholder="Wallet Address"
                  id="address"
                  variant="filled"
                  fullWidth
                  label="Wallet Address"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  FormHelperTextProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                  helperText={formik.touched.address && formik.errors.address}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      fontFamily: 'Avenir Pro',
                    },
                  }}
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: 14,
                      border: '1px solid rgba(235, 236, 239, 0.8)',
                      background: '#FFFFFF',
                      fontFamily: 'Avenir Pro',
                    },
                  }}
                />

                <CustomSelect
                  options={teamsDropdown}
                  name="team"
                  id="team"
                  placeholder={'Select Team'}
                  renderOptions="teams"
                  value={formik.values.team}
                  formik={formik}
                  clearable={isEditMode}
                  onChange={formik.handleChange}
                  error={formik.touched.team && Boolean(formik.errors.team)}
                  helperText={formik.touched.team && formik.errors.team}
                  disabled={loadingTeams}
                />

                <div className="d-flex select-token-wrapper">
                  <CustomSelect
                    options={tokens}
                    name="token"
                    id="token"
                    placeholder={'Currency'}
                    renderOptions="tokens"
                    value={formik.values.token}
                    defaultValue={formik.values.token}
                    formik={formik}
                    disabled={
                      tokens.length === 1 || loadingTokenList ? true : false
                    }
                    onChange={formik.handleChange}
                    clearable={true}
                    //error={formik.touched.token && Boolean(formik.errors.token)}
                    //helperText={formik.touched.token && formik.errors.token}
                    loading={loadingTokenList}
                  />
                  {formik.values.token !== '' && formik.values.token ? (
                    formik.values.token === 'USD' ? (
                      <span
                        className="usd-token"
                        style={{
                          fontSize: '18px',
                          height: '2.2rem',
                          width: '2.4rem',
                          lineHeight: '2rem',
                        }}
                      >
                        $
                      </span>
                    ) : (
                      <img
                        src={
                          tokens.find(el => el.symbol === formik.values.token)
                            ?.logoURI || defaultTokenIcon
                        }
                        alt="tokenLogo"
                        style={{ height: '25px', width: '25px' }}
                      ></img>
                    )
                  ) : null}
                </div>
                {formik.touched.token && Boolean(formik.errors.token) && (
                  <div
                    style={{ color: 'red', fontSize: 12 }}
                    className="my-2 ml-3"
                  >
                    {formik.errors.token}
                  </div>
                )}

                <TextField
                  // placeholder="Amount"
                  id="amount"
                  variant="filled"
                  type="number"
                  fullWidth
                  label="Amount"
                  name="amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                  FormHelperTextProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      fontFamily: 'Avenir Pro',
                    },
                  }}
                  style={{
                    marginTop: '10px',
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: 14,
                      border: '1px solid rgba(235, 236, 239, 0.8)',
                      background: '#FFFFFF',
                      fontFamily: 'Avenir Pro',
                    },
                  }}
                />
              </FormFieldsWrapper>
              <div className="action-buttons">
                <Button
                  type="button"
                  onClick={() =>
                    isEditMode ? setEditOpen(false) : setAddOpen(false)
                  }
                  style={{
                    background: 'rgba(115, 103, 240, 0.1)',
                    color: '#7367F0',
                    fontWeight: 600,
                    width: '100%',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  loading={updating || adding}
                  style={{
                    background: '#7367F0',
                    color: '#FFFFFF',
                    marginLeft: '10px',
                    fontWeight: 600,
                    width: '100%',
                  }}
                >
                  {isEditMode ? 'Save' : 'Add'}
                </Button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default AddPeople;
