import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeSelectTokenList, makeSelectLoading } from 'store/tokens/selectors';
import { getTokens } from 'store/tokens/actions';
import { makeSelectOwnerSafeAddress } from 'store/global/selectors';
import {
  Table,
  TableHead,
  TableBody,
  TableLoader,
  TableInfo,
  Title,
  TableDivider,
} from 'components/common/Table';
import { formatNumber } from 'utils/number-helpers';
import TokenImg from 'components/common/TokenImg';
import { Skeleton } from '@material-ui/lab';

export default function Assets() {
  const dispatch = useDispatch();
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());

  // Selectors
  const loading = useSelector(makeSelectLoading());
  const tokenList = useSelector(makeSelectTokenList());

  useEffect(() => {
    if (ownerSafeAddress) {
      dispatch(getTokens(ownerSafeAddress));
    }
  }, [ownerSafeAddress, dispatch]);

  const renderAssets = () => {
    if (loading) return;

    return tokenList.map(({ icon, name, usd, balance }) => (
      <tr style={{ cursor: 'default' }} key={name}>
        <td className="d-flex align-items-center">
          <TokenImg style={{ width: '2rem', height: '2rem' }} icon={icon} token={name} />
          <div className="ml-4 mt-1">{name}</div>
        </td>
        <td style={{ textAlign: 'end' }}>
          {formatNumber(balance)} {name}
        </td>
        <td style={{ textAlign: 'end' }}>
          <span>${formatNumber(usd)} USD</span>
        </td>
      </tr>
    ));
  };

  const RenderEmptyTable = () => {
    const skeletonArray = Array(3).fill('');

    return skeletonArray.map((_, index) => (
      <tr key={index}>
        <td>
          <div className="d-flex align-items-center">
            <CustomShimmer
              style={{ marginRight: '1.6rem' }}
              variant="circle"
              height="2rem"
              width="2rem"
            />
            <CustomShimmer height="1.4rem" width="12rem" />
          </div>
        </td>
        <td align="right">
          <CustomShimmer height="1.4rem" width="8rem" />
        </td>
        <td align="right">
          <CustomShimmer height="1.4rem" width="8rem" />
        </td>
      </tr>
    ));
  };

  return (
    <div>
      <Title heading={'Assets'} />
      <Table style={{ marginTop: '1.2rem' }}>
        <TableHead>
          <tr>
            <th style={{ width: '70%' }}>Name</th>
            <th style={{ width: '15%', textAlign: 'end' }}>Balance</th>
            <th style={{ width: '15%', textAlign: 'end' }}>Fiat Value</th>
          </tr>
        </TableHead>
        <TableBody>
          <TableDivider>
            <td /> <td /> <td />
          </TableDivider>
          {loading ? (
            <RenderEmptyTable />
          ) : tokenList?.length ? (
            renderAssets()
          ) : (
            <TableInfo
              style={{
                fontSize: '1.4rem',
                fontWeight: '500',
                textAlign: 'center',
                height: '10rem',
              }}
            >
              <td colSpan={6}>No funds</td>
            </TableInfo>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

const CustomShimmer = ({
  variant = 'rect',
  height = '1.4rem',
  width = '21rem',
  style = {},
  className,
}) => {
  return (
    <Skeleton
      animation="wave"
      variant={variant}
      height={height}
      width={width}
      style={{
        ...style,
        borderRadius: `${variant === 'circle' ? '50%' : '.2rem'}`,
        background:
          'linear-gradient(90deg, rgba(141, 142, 145, 0.1) 0%, rgba(141, 142, 145, 0.2) 49.31%, rgba(141, 142, 145, 0) 100%, rgba(141, 142, 145, 0.1) 100%, rgba(141, 142, 145, 0.1) 100%)',
      }}
      className={className}
    />
  );
};
