import styled from 'styled-components/macro';

export default styled.div`
  padding: 4rem 4rem 0 4rem;
  position: relative;

  .flex {
    display: flex;
    align-items: baseline;
  }
  .center {
    align-items: center;
  }

  .container-blur {
    filter: blur(16px);
    z-index: 1;
  }

  .recipient-container {
    display: grid;
    grid-template-columns: 1fr 0.6fr;
    grid-gap: 2rem;
  }
  .mt-5 {
    margin-top: 5rem !important;
  }
  .ml-2 {
    margin-left: 2rem !important;
  }
  .divider {
    width: 100%;
    height: 0.05rem;
    background: #c7c7c7;
    margin-top: 4rem;
  }
  .paying-to-input-wrapper {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 2rem;
  }
  .gelato-wrapper {
    span {
      margin: 0 0.8rem;
    }
    img {
      width: '11.5rem';
    }
  }
  .footer-button-wrapper {
    padding: 4rem 4rem 4rem 0;
    display: flex;
    justify-content: flex-start;
  }

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  .outer-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    font-size: 1.4rem;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #373737;
    margin-bottom: 2rem;
  }

  @media (max-width: 978px) {
    padding: 3rem 2rem 0 2rem;
    .outer-flex {
      flex-direction: column;
      grid-gap: 2rem;
      align-items: flex-start;
    }
  }

  .warning-container {
    position: relative;
    height: 2.8rem;
    align-items: center;
    display: flex;
    background: #fcbc04;
    padding: 0 3.1rem;
    border-radius: 0 0 0.2rem 0.2rem;
    .text {
      font-size: 1.2rem;
      font-weight: bold;
      color: #ffffff;
    }
    svg {
      margin-right: 1.55rem;
      width: 1.33rem;
    }
  }
`;
