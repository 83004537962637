import React from 'react';
import { useSelector } from 'react-redux';

import Img from 'components/common/Img';
import { getDefaultIconIfPossible } from 'constants/index';
import { makeSelectTokenIcons } from 'store/tokens/selectors';

export default function TokenImg({ icon, token, width, className, ...rest }) {
  const icons = useSelector(makeSelectTokenIcons());

  if (token === 'USD') {
    return (
      <span
        {...rest}
        style={{
          marginLeft: '0.2rem',
          marginRight: '1.7rem',
          width: '2rem',
          height: '2rem',
          display: 'inline-block',
          textAlign: 'center',
          lineHeight: '2rem',
          borderRadius: '100%',
          border: '0.1px solid black',
          padding: '0 .5rem',
        }}
      >
        $
      </span>
    );
  }

  return (
    <Img
      src={icon ? icon : getDefaultIconIfPossible(token, icons)}
      alt={token || 'token'}
      className={className || 'mr-2'}
      width={width || '20'}
      {...rest}
    />
  );
}
