import React, { useState, useEffect } from 'react';
import { ReactComponent as Send } from 'assets/icons/send.svg';
import { ReactComponent as Pencil } from 'assets/icons/pencil.svg';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';
import { HoverActionIcons } from './styles';

import { useSelector } from 'react-redux';

import Button from 'components/common/Button';
import { Popover as PopOver } from 'reactstrap';

import { makeSelectTokensDetails } from 'store/tokens/selectors';
import { useDispatch } from 'react-redux';
import { deletePeople } from 'store/modify-people/actions';
import { makeSelectOwnerSafeAddress } from 'store/global/selectors';
import { makeSelectUpdating } from 'store/modify-people/selectors';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import modifyPeopleReducer from 'store/modify-people/reducer';
import modifyPeopleSaga from 'store/modify-people/saga';
import { MODAL_NAME as QUICK_TRANSFER_MODAL } from 'components/Payments/QuickTransferModal';
import { setSelectedAddress } from 'store/transactions/actions';
import { show } from 'redux-modal';
import AddPeople from './AddPeople';

const modifyPeople = 'modifyPeople';

function HoverActionsTable({ id, data, shouldActive }) {
  useInjectReducer({ key: modifyPeople, reducer: modifyPeopleReducer });

  useInjectSaga({ key: modifyPeople, saga: modifyPeopleSaga });
  const dispatch = useDispatch();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [tokensToRender, setTokensToRender] = useState([]);
  const [dataToEdit, setDataToEdit] = useState({
    token: 'DAI',
    teamName: '',
  });
  // let editRef = useRef()
  // let deleteRef = useRef(null)
  const [indices, setIndices] = useState({
    popOver1: 1,
    popOver2: 2,
  });

  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const updating = useSelector(makeSelectUpdating());

  const tokenDetails = useSelector(makeSelectTokensDetails());
  useEffect(() => {
    setTokensToRender(tokenDetails);
  }, [tokenDetails]);
  useEffect(() => {
    setIndices({ ...indices, popOver1: Math.random() });
    setIndices({ ...indices, popOver2: Math.random() });
  }, [id]);
  const confirmDelete = () => {
    dispatch(deletePeople(ownerSafeAddress, id));
  };
  const showQuickTransferModal = data => {
    dispatch(setSelectedAddress(data));
    dispatch(show(QUICK_TRANSFER_MODAL));
  };
  useEffect(() => {
    if (editOpen || deleteOpen) {
      shouldActive(true);
    } else {
      shouldActive(false);
    }
  }, [editOpen, deleteOpen]);

  return (
    <HoverActionIcons className="d-flex" key={id}>
      <Send
        width={16}
        className="icons"
        onClick={e => {
          showQuickTransferModal(data);
          e.stopPropagation();
        }}
      />

      <div
        id={`Popover2${id}`}
        type="button"
        onClick={e => e.stopPropagation()}
      >
        <Pencil className="icons" />
        <PopOver
          placement="bottom"
          isOpen={editOpen}
          id="editPopover"
          target={`Popover2${id}`}
          trigger="legacy"
          toggle={e => {
            e.stopPropagation();
            setEditOpen(!editOpen);
          }}
        >
          <AddPeople
            defaultValues={data}
            isEditMode={true}
            setTokensToRender={setTokensToRender}
            tokensToRender={tokensToRender}
            setDataToEdit={setDataToEdit}
            dataToEdit={dataToEdit}
            setEditOpen={setEditOpen}
            editOpen={editOpen}
          />
        </PopOver>
      </div>

      <div
        id={`Popover3${id}`}
        type="button"
        onClick={e => e.stopPropagation()}
      >
        <Delete className="icons" />

        <PopOver
          placement="bottom"
          isOpen={deleteOpen}
          target={`Popover3${id}`}
          toggle={() => {
            setDeleteOpen(!deleteOpen);
          }}
          trigger="legacy"
        >
          <DeleteMenu
            confirmDelete={confirmDelete}
            setDeleteOpen={setDeleteOpen}
            deleteOpen={deleteOpen}
            data={data}
          />
        </PopOver>
      </div>
    </HoverActionIcons>
  );
}

const DeleteMenu = ({ confirmDelete, setDeleteOpen, deleteOpen, data }) => {
  return (
    <div>
      <div className="customDropDown">
        <div className="title">Are you sure you want to delete</div>
        <div className="title">
          <strong>
            {data.firstName}
            {data.lastName}
          </strong>
          {/* <div>{data.address}</div> */}
        </div>
        {/* <div className="title-muted">
          <div>PS: Deleting a team will delete all</div>{' '}
          <div> constituent people in it.</div>
        </div> */}

        <div className="action-buttons">
          <Button
            type="button"
            width="12rem"
            style={{
              background: 'rgba(115, 103, 240, 0.1)',
              color: '#7367F0',
              fontWeight: 600,
            }}
            onClick={() => setDeleteOpen(!deleteOpen)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            width="12rem"
            style={{
              background: '#FF4B55',
              color: '#FFFFFF',
              marginLeft: '10px',
              fontWeight: 600,
            }}
            onClick={() => confirmDelete()}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HoverActionsTable;
