import React, { useEffect, useState } from 'react';
import { connectModal as reduxModal } from 'redux-modal';
import { Modal, ModalHeader, ModalBody } from 'components/common/Modal';
import { Input } from 'components/common/Form';
import { useForm } from 'react-hook-form';
import { GasPriceModalContainer } from './styles';
import Button from 'components/common/Button';
import { useActiveWeb3React, useMassPayout } from 'hooks';
import { makeSelectNonce } from 'store/safe/selectors';
import {
  makeSelectIsMultiOwner,
  makeSelectOwnerSafeAddress,
  makeSelectIsMetaTxEnabled,
} from 'store/global/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import multisigReducer from 'store/multisig/reducer';
import transactionsReducer from 'store/transactions/reducer';
import safeReducer from 'store/safe/reducer';
import {
  addTransaction,
  clearTransactionHash,
} from 'store/transactions/actions';
import metaTxReducer from 'store/metatx/reducer';
import metaTxSaga from 'store/metatx/saga';
import { useInjectSaga } from 'utils/injectSaga';
import { makeSelectMetaTransactionHash } from 'store/transactions/selectors';
import { createMultisigTransaction } from 'store/multisig/actions';
import { TRANSACTION_MODES } from 'constants/transactions';
import { getNonce } from 'store/safe/actions';
import { getTokens } from 'store/tokens/actions';
import getTransactionReceipts from 'utils/getTransactionReceipts';
import { getFinalMetaTransactionHash } from 'utils/meta-tx-helpers';
import { getMetaTxEnabled } from 'store/metatx/actions';
import { makeSelectIsMetaTxEnabled as makeSelectIsMetaTxLimitAllowed } from 'store/metatx/selectors';
import { networkId } from 'constants/networks';

const multisigKey = 'multisig';
const transactionsKey = 'transactions';
const safeKey = 'safe';
const metaTxKey = 'metatx';

export const MODAL_NAME = 'gas-price-modal';

function GasPriceModal(props) {
  const { show, handleHide, gasPrice = '0' } = props;

  const { library, account } = useActiveWeb3React();

  const [loadingTx, setLoadingTx] = useState(false);

  const [maxGasPrice, setMaxGasPrice] = useState(gasPrice);
  const nonce = useSelector(makeSelectNonce());
  const isMultiOwner = useSelector(makeSelectIsMultiOwner());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const isMetaEnabled = useSelector(makeSelectIsMetaTxEnabled());
  const txHashFromMetaTx = useSelector(makeSelectMetaTransactionHash());
  const isMetaTxLimitAllowed = useSelector(makeSelectIsMetaTxLimitAllowed());
  const {
    txHash,
    txData,
    setTxData,
    updateGasPriceLimit,
    loadingMetaTx,
    setLoadingMetaTx,
  } = useMassPayout();

  const { register, handleSubmit } = useForm({
    mode: 'onChange',
  });

  useInjectReducer({ key: transactionsKey, reducer: transactionsReducer });
  useInjectReducer({ key: multisigKey, reducer: multisigReducer });
  useInjectReducer({ key: safeKey, reducer: safeReducer });
  useInjectReducer({ key: metaTxKey, reducer: metaTxReducer });

  useInjectSaga({ key: metaTxKey, saga: metaTxSaga });

  const dispatch = useDispatch();

  useEffect(() => {
    if (ownerSafeAddress) {
      dispatch(getNonce(ownerSafeAddress));
      dispatch(getTokens(ownerSafeAddress));
      dispatch(getMetaTxEnabled(ownerSafeAddress));
    }
  }, [ownerSafeAddress, dispatch]);

  useEffect(() => {
    if (
      txHashFromMetaTx &&
      isMetaEnabled &&
      !isMultiOwner &&
      isMetaTxLimitAllowed
    ) {
      setLoadingMetaTx(true);
      dispatch(clearTransactionHash());
      const intervalId = setInterval(async () => {
        const newMetaHash = await getFinalMetaTransactionHash(
          txHashFromMetaTx,
          networkId
        );
        if (newMetaHash) {
          getTransactionReceipts(
            library,
            dispatch,
            ownerSafeAddress,
            '',
            newMetaHash,
            false
          );
          handleHide();
          setTimeout(() => {
            setLoadingMetaTx(false);
          }, 5000);
          clearInterval(intervalId);
        }
      }, 3000);
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    txHashFromMetaTx,
    isMetaEnabled,
    isMultiOwner,
    isMetaTxLimitAllowed,
  ]);

  useEffect(() => {
    const to = JSON.stringify({ gasPrice: maxGasPrice });
    if (txHash) {
      dispatch(
        addTransaction({
          to,
          safeAddress: ownerSafeAddress,
          createdBy: account,
          transactionHash: txHash,
          tokenValue: -1,
          tokenCurrency: '_nil_',
          fiatValue: -1,
          fiatCurrency: 'USD',
          addresses: [],
          transactionMode: TRANSACTION_MODES.AUTOMATION,
          paymentType: 'gas',
          description: 'max gas price change',
          nonce: nonce,
        })
      );
    } else if (txData) {
      if (!isMultiOwner) {
        // threshold = 1 or single owner
        dispatch(
          addTransaction({
            to,
            safeAddress: ownerSafeAddress,
            createdBy: account,
            txData,
            tokenValue: -1,
            tokenCurrency: '_nil_',
            fiatValue: -1,
            fiatCurrency: 'USD',
            addresses: [],
            transactionMode: TRANSACTION_MODES.AUTOMATION,
            paymentType: 'gas',
            description: 'max gas price change',
            nonce: nonce,
            isMetaEnabled,
          })
        );
        setTxData(undefined);
      } else {
        // threshold > 1
        dispatch(
          createMultisigTransaction({
            to,
            safeAddress: ownerSafeAddress,
            createdBy: account,
            txData,
            tokenValue: -1,
            tokenCurrency: '_nil_',
            fiatValue: -1,
            fiatCurrency: 'USD',
            addresses: [],
            transactionMode: TRANSACTION_MODES.AUTOMATION,
            paymentType: 'gas',
            description: 'max gas price change',
            nonce: nonce,
          })
        );
      }
    }
  }, [
    account,
    dispatch,
    isMultiOwner,
    nonce,
    ownerSafeAddress,
    setTxData,
    txData,
    txHash,
    isMetaEnabled,
  ]);

  const onSubmit = async values => {
    setLoadingTx(true);

    await updateGasPriceLimit(
      values.gasPriceAutomations,
      isMultiOwner,
      nonce,
      isMetaEnabled,
      isMetaTxLimitAllowed
    );
    setLoadingTx(false);
    if (isMetaEnabled && isMetaTxLimitAllowed) {
      setLoadingMetaTx(true);
    } else {
      handleHide();
    }
  };

  return (
    <Modal isOpen={show} toggle={handleHide}>
      <ModalHeader title={'Gas Price'} toggle={handleHide} />
      <ModalBody width="45rem" minHeight="auto">
        <GasPriceModalContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="title" style={{ margin: 0 }}>
              Maximum Gas Price for Automations (GWEI)
            </div>
            <Input
              type="number"
              className="gas-price-input"
              name="gasPriceAutomations"
              register={register}
              placeholder="200"
              onChange={e => setMaxGasPrice(e.target.value)}
              defaultValue={gasPrice}
              min={1}
              step={1}
            />
            <p>
              This will change the maximum GAS price across all automations.
              Your automations will only be executed if the GAS price is lower
              or equal to this number
            </p>
            <div className="cta-wrapper">
              <Button
                type="button"
                width="20rem"
                style={{
                  minWidth: '18rem',
                  background: 'rgba(115, 103, 240, 0.1)',
                  color: '#7367F0',
                  fontWeight: 600,
                }}
                onClick={handleHide}
              >
                Go back
              </Button>
              <Button
                type="submit"
                width="20rem"
                style={{ minWidth: '18rem' }}
                loading={loadingTx || loadingMetaTx}
              >
                Confirm
              </Button>
            </div>
          </form>
        </GasPriceModalContainer>
      </ModalBody>
    </Modal>
  );
}

export default reduxModal({ name: 'gas-price-modal' })(GasPriceModal);
