export const TRANSACTION_MODES = {
  MASS_PAYOUT: 0,
  QUICK_TRANSFER: 1,
  SPENDING_LIMITS: 2,
  AUTOMATION: 3,
  CSV_PAYOUT: 4,
  TEAMS_MASS_PAYOUT: 5,
};

export const TRANSACTION_STATUS = {
  COMPLETED: 0,
  PENDING: 1,
  FAILED: 2,
};
