import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadIcon from 'assets/icons/dashboard/upload-icon.svg';
import MassPayoutIcon from 'assets/icons/navbar/mass-payout.svg';
import PaySomeoneIcon from 'assets/icons/navbar/pay-someone.svg';
import Img from 'components/common/Img';
import FilePayoutModal, {
  MODAL_NAME as FILE_PAYOUT_MODAL,
} from 'components/Payments/FilePayoutModal';
import MassPayoutModal, {
  MODAL_NAME as MASS_PAYOUT_MODAL,
} from 'components/Payments/MassPayoutModal';
import MassPayoutAcrossTeamsModal from 'components/Payments/MassPayoutAcrossTeamsModal';
import QuickTransferModal, {
  MODAL_NAME as QUICK_TRANSFER_MODAL,
} from 'components/Payments/QuickTransferModal';
import TransactionSubmittedModal from 'components/Payments/TransactionSubmittedModal';
import { useDropdown } from 'hooks';
import React from 'react';
import { useDispatch } from 'react-redux';
import { show } from 'redux-modal';
import { NewTransfer } from './styles';

export default function NewTransferDropdown() {
  const { open, toggleDropdown } = useDropdown();

  const dispatch = useDispatch();

  const showMassPayoutModal = () => {
    dispatch(show(MASS_PAYOUT_MODAL));
  };

  const showQuickTransferModal = () => {
    dispatch(show(QUICK_TRANSFER_MODAL));
  };

  const showFilePayoutModal = () => {
    dispatch(show(FILE_PAYOUT_MODAL));
  };

  return (
    <NewTransfer onClick={toggleDropdown}>
      <div className="text">New Transfer</div>
      <FontAwesomeIcon icon={faAngleDown} className="ml-2" color="#fff" />
      <div className={`transfer-dropdown ${open && 'show'}`}>
        <div className="transfer-option" onClick={showMassPayoutModal}>
          <Img src={MassPayoutIcon} alt="mass-payout" className="icon" />
          <div className="name">Mass Payout</div>
        </div>
        <div className="transfer-option" onClick={showQuickTransferModal}>
          <Img src={PaySomeoneIcon} alt="pay-someone" className="icon" />
          <div className="name">Pay Someone</div>
        </div>
        <div className="transfer-option" onClick={showFilePayoutModal}>
          <Img src={UploadIcon} alt="pay-someone" className="icon" />
          <div className="name">Payout via csv</div>
        </div>
      </div>

      <MassPayoutModal />
      <QuickTransferModal />
      <TransactionSubmittedModal />
      <FilePayoutModal />
      <MassPayoutAcrossTeamsModal />
    </NewTransfer>
  );
}
