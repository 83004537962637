import { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import { findNetworkNameByWeb3ChainId } from 'constants/networks';
import SomethingWentWrongPng from 'assets/images/Something_went_wrong_2.png';

import { useActiveWeb3React } from 'hooks';
import { WrongNetwork } from './styles';

const requiredNetworkName = process.env.REACT_APP_NETWORK_NAME;

const NetworkModal = () => {
  const { active, chainId } = useActiveWeb3React();
  const [show, setShow] = useState(false);
  const isEthereumInjected = !!window.ethereum;

  useEffect(() => {
    if (
      active &&
      chainId &&
      findNetworkNameByWeb3ChainId(chainId) !== requiredNetworkName
    )
      setShow(true);
    else setShow(false);
  }, [chainId, active]);

  return (
    <Modal
      isOpen={show || !isEthereumInjected}
      centered
      contentClassName="custom-modal-content"
    >
      <ModalBody style={{ padding: '0px' }}>
        <WrongNetwork>
          <div className="text-center">
            <div className="pb-3">
              <img
                src={SomethingWentWrongPng}
                alt="error"
                width="380"
                height="320"
                className="mb-4"
              />
            </div>
            <h4 className="title pb-2">
              {!isEthereumInjected
                ? 'Please connect a wallet'
                : 'Your wallet is on a different network!'}
            </h4>
            {isEthereumInjected && (
              <div className="subtitle">
                Select "{requiredNetworkName}" to continue.
              </div>
            )}
          </div>
        </WrongNetwork>
      </ModalBody>
    </Modal>
  );
};

export default NetworkModal;
