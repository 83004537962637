import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useContract } from 'hooks';
import { useInjectReducer } from 'utils/injectReducer';

import GnosisSafeABI from 'constants/abis/GnosisSafe.json';

import {
  makeSelectOwnerName,
  makeSelectOwnerSafeAddress,
} from 'store/global/selectors';
import { getSafeVersionSuccess } from 'store/global/actions';
import globalReducer from 'store/global/reducer';

import OverviewCard from './OverviewCard';
import AssetsCard from './AssetsCard';
import RecentTxCard from './RecentTxCard';

import { Greeting, CardsGrid } from './styles';

const globalKey = 'global';

export default function Dashboard() {
  const ownerName = useSelector(makeSelectOwnerName());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());

  const dispatch = useDispatch();

  useInjectReducer({ key: globalKey, reducer: globalReducer });

  const safeContract = useContract(ownerSafeAddress, GnosisSafeABI);

  useEffect(() => {
    const getVersion = async () => {
      const version = await safeContract.VERSION();

      dispatch(getSafeVersionSuccess(version));
    };
    getVersion();
  }, [ownerSafeAddress, safeContract, dispatch]);

  return (
    <div>
      <Greeting>Hey, {ownerName}</Greeting>
      <CardsGrid>
        <OverviewCard />
        <AssetsCard />
        <RecentTxCard />
      </CardsGrid>
    </div>
  );
}
