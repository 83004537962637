import React from 'react';
import { connectModal as reduxModal } from 'redux-modal';
import { Modal, ModalHeader, ModalBody } from 'components/common/Modal';
import BulkDeletePeople from 'components/BulkDeletePeople';

export const MODAL_NAME = 'bulk-delete-people-modal';

function BulkDeletePeopleModal(props) {
  const { show, handleHide, setSelectedPeople } = props;
  return (
    <Modal isOpen={show} toggle={handleHide}>
      <ModalHeader title={'Review Deletion'} toggle={handleHide} />
      <ModalBody width="90rem" minHeight="auto">
        <BulkDeletePeople
          handleHide={handleHide}
          setSelectedPeople={setSelectedPeople}
        />
      </ModalBody>
    </Modal>
  );
}

export default reduxModal({ name: MODAL_NAME })(BulkDeletePeopleModal);
