import styled from 'styled-components/macro';
import { slide as Menu } from 'react-burger-menu';

export default styled(Menu)`
  position: relative;
  .people-details-header {
    width: 100%;
    height: 6rem;
    padding: 0 3rem;
    background-color: #e3e1fc;
    display: flex !important;
    justify-content: space-between;
    align-items: center;

    &:focus {
      outline: none;
    }

    .title {
      font-size: 1.6rem;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #373737;
    }

    .close {
      cursor: pointer;
    }
  }

  .transaction-sidebar {
    padding: 72px 0rem;

    .mt-24 {
      margin-top: 2.4rem;
    }

    .space {
      padding: 0rem 2rem;
    }
    .color-secondary {
      color: #8d8e91 !important;
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.08em;
      font-size: 1.2rem;
      line-height: 1.4rem;
      margin-bottom: 1rem !important;
    }

    .address {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7rem;
      color: #373737;
      margin-top: 0.8rem !important;
    }

    .title {
      font-weight: 900;
      font-size: 2rem;
      line-height: 2.4rem;
      color: #373737;
    }

    .close {
      float: none;
      margin-bottom: 30px;
      cursor: pointer;
    }
  }

  .modify-buttons {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 30rem;
  }

  .bm-overlay {
    left: 0 !important;
  }
`;
