import styled from 'styled-components/macro';

export default styled.div`
  position: relative;

  .container-blur {
    filter: blur(16px);
    z-index: 1;
  }

  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  .outer-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .inner-flex {
    display: flex;
    align-items: center;
  }

  .editing {
    padding: 3px 5px;
    background: #7367f0;
    color: #ffffff;
    font-size: 10px;
    margin-bottom: 14px;
    border-radius: 2px;
    .icon {
      margin-right: 3px;
      color: #ffffff;
      font-size: 10px;
    }
  }
  .editamount {
    background: #ffffff;
    border: 1px solid rgba(221, 220, 220, 0.5);
    border-radius: 2px;
    padding: 8px 10px;
    color: #373737;
    font-size: 14px;
    width: 50%;
    margin-left: 5px;
  }
  .editamount:focus {
    outline: none;
    border: solid 0.1rem #7367f0;
    background-color: #ffffff;
  }
  .copytoall {
    background: #ffffff;
    border: 1px solid rgba(221, 220, 220, 0.5);
    border-radius: 2px;
    padding: 8px 8px;
    margin-left: 1px;
    color: #373737;
    font-size: 14px;
  }

  .title {
    font-size: 1.4rem;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #373737;
    margin-bottom: 1.4rem;
  }

  .select-all {
    display: flex;
    justify-content: flex-end;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #373737;
    margin-bottom: 1rem;

    .edit-amount {
      display: flex;
      margin-right: 3rem;
      cursor: pointer;

      .icon {
        fill: #7367f0;
        height: 1.4rem;
        width: 1.4rem;
      }
      .title {
        font-weight: 900;
        font-size: 1.4rem;
        color: #7367f0;
        margin: 0 0 0 1rem;
      }
    }
  }

  .payment-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
  }
  .mt-5 {
    margin-top: 5rem !important;
  }
  .gelato-wrapper {
    span {
      margin: 0 0.8rem;
    }
    img {
      width: '11.5rem';
    }
  }
  .team-details-table {
    tr {
      position: relative;
      .warning-icon {
        position: absolute;
        right: 2.33rem;
      }
    }
  }
  .table-footer {
    position: relative;
    height: 2.8rem;
    align-items: center;
    display: flex;
    background: #fcbc04;
    padding: 0 3.1rem;
    border-radius: 0 0 0.2rem 0.2rem;
    .text {
      font-size: 1.2rem;
      font-weight: bold;
      color: #ffffff;
    }
    svg {
      margin-right: 1.55rem;
      width: 1.33rem;
    }
  }

  @media (max-width: 978px) {
    padding: 3rem 2rem;
    .outer-flex,
    .inner-flex {
      flex-direction: column;
      grid-gap: 2rem;
      align-items: flex-start;
    }
  }
`;
