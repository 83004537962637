export const GET_INVITATIONS = 'parcel/invitation/GET_INVITATIONS';
export const GET_INVITATIONS_SUCCESS =
  'parcel/invitation/GET_INVITATIONS_SUCCESS';
export const GET_INVITATIONS_ERROR = 'parcel/invitation/GET_INVITATIONS_ERROR';

export const CREATE_INVITATION = 'parcel/invitation/CREATE_INVITATION';
export const CREATE_INVITATION_SUCCESS =
  'parcel/invitation/CREATE_INVITATION_SUCCESS';
export const CREATE_INVITATION_ERROR =
  'parcel/invitation/CREATE_INVITATION_ERROR';

export const ACCEPT_INVITATION = 'parcel/invitation/ACCEPT_INVITATION';
export const ACCEPT_INVITATION_SUCCESS =
  'parcel/invitation/ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_ERROR =
  'parcel/invitation/ACCEPT_INVITATION_ERROR';

export const APPROVE_INVITATION = 'parcel/invitation/APPROVE_INVITATION';
export const APPROVE_INVITATION_SUCCESS =
  'parcel/invitation/APPROVE_INVITATION_SUCCESS';
export const APPROVE_INVITATION_ERROR =
  'parcel/invitation/APPROVE_INVITATION_ERROR';
