import GreenTick from 'assets/icons/green-tick.svg';
import CloseIcon from 'assets/icons/navbar/close.svg';
import Img from 'components/common/Img';
import Loading from 'components/common/Loading';
import { minifyAddress } from 'components/common/Web3Utils';
import { useActiveWeb3React } from 'hooks';
import produce from 'immer';
import { uniqueId } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOwnerDetails } from 'store/global/actions';
import { makeSelectOwnerSafeAddress } from 'store/global/selectors';
import { toggleSafeDetails } from 'store/layout/actions';
import { makeSelectIsSafeDetailsOpen } from 'store/layout/selectors';
import { switchSafe } from 'store/login/actions';
import loginReducer from 'store/login/reducer';
import loginSaga from 'store/login/saga';
import { makeSelectSafeSwitching } from 'store/login/selectors';
import {
  chooseSafe,
  getParcelSafes,
  getSafeOwners,
} from 'store/loginWizard/actions';
import loginWizardReducer from 'store/loginWizard/reducer';
import loginWizardSaga from 'store/loginWizard/saga';
import {
  makeSelectGnosisSafeOwners,
  makeSelectParcelLoading,
  makeSelectSafes,
} from 'store/loginWizard/selectors';
import { getOverview } from 'store/overview/actions';
import overviewReducer from 'store/overview/reducer';
import overviewSaga from 'store/overview/saga';
import { getTokens } from 'store/tokens/actions';
import { viewTransactions } from 'store/transactions/actions';
import transactionsReducer from 'store/transactions/reducer';
import transactionsSaga from 'store/transactions/saga';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { SafeDetails } from './styles';

const loginKey = 'login';
const loginWizardKey = 'loginWizard';
const transactionsKey = 'transactions';
const overviewKey = 'overview';

const organisationTypes = [
  {
    name: 'Individual',
    value: 'individual',
    type: 1,
    isPrivate: true,
  },
  {
    name: 'Company',
    value: 'company',
    type: 1,
    isPrivate: true,
  },
  {
    name: 'DAO',
    value: 'dao',
    type: 1,
    isPrivate: false,
  },
];

const initialPageState = {
  organisation: organisationTypes[2],
  owners: [],
  threshold: 1,
};

function SafeDetailsSidebar() {
  const dispatch = useDispatch();
  const { account } = useActiveWeb3React();
  const [pageState, setPageState] = useState(initialPageState);

  // Reducers
  useInjectReducer({ key: loginKey, reducer: loginReducer });
  useInjectReducer({ key: loginWizardKey, reducer: loginWizardReducer });
  useInjectReducer({ key: overviewKey, reducer: overviewReducer });
  useInjectReducer({ key: transactionsKey, reducer: transactionsReducer });

  // Sagas
  useInjectSaga({ key: loginKey, saga: loginSaga });
  useInjectSaga({ key: loginWizardKey, saga: loginWizardSaga });
  useInjectSaga({ key: overviewKey, saga: overviewSaga });
  useInjectSaga({ key: transactionsKey, saga: transactionsSaga });

  const isSafeDetailsOpen = useSelector(makeSelectIsSafeDetailsOpen());
  const ownerSafeAddress = useSelector(makeSelectOwnerSafeAddress());
  const safes = useSelector(makeSelectSafes());
  const safesLoading = useSelector(makeSelectParcelLoading());
  const gnosisSafeOwners = useSelector(makeSelectGnosisSafeOwners());
  const switchingInProgress = useSelector(makeSelectSafeSwitching());

  useEffect(() => {
    if (isSafeDetailsOpen) {
      dispatch(getParcelSafes(account));
    }
  }, [account, dispatch, isSafeDetailsOpen]);

  useEffect(() => {
    setPageState(p =>
      produce(p, draft => {
        if (gnosisSafeOwners.length) {
          draft.owners = gnosisSafeOwners.map(item => ({
            id: String(uniqueId()),
            name: '',
            address: item,
          }));
        } else {
          draft.owners = [
            {
              id: String(uniqueId()),
              name: '',
              address: account,
            },
          ];
        }
      })
    );
  }, [gnosisSafeOwners, account]);

  const handleStateChange = state => {
    dispatch(toggleSafeDetails(state.isOpen));
  };

  const closeSidebar = () => {
    dispatch(toggleSafeDetails(false));
  };

  const handleSafeSwitch = address => {
    const { organisation, owners, threshold } = pageState;

    if (address && address !== ownerSafeAddress && !switchingInProgress) {
      const ownerName = safes
        ?.find(
          safe =>
            String(safe.safeAddress).toLowerCase() ===
            String(address).toLowerCase()
        )
        ?.owners?.find(
          owner =>
            String(owner.owner).toLowerCase() === String(account).toLowerCase()
        )?.name;

      dispatch(setOwnerDetails(ownerName, address, account));
      dispatch(chooseSafe(address));
      dispatch(viewTransactions(address));
      dispatch(getOverview(address));
      dispatch(getTokens(address));
      dispatch(getSafeOwners(address));
      dispatch(
        switchSafe({
          owners,
          threshold,
          organisationType: organisation.type,
          address,
          log: '',
          account,
        })
      );
    }
  };

  return (
    <SafeDetails
      styles={{
        ...sidebarStyles,
        bmOverlay: {
          ...sidebarStyles.bmOverlay,
          backdropFilter: switchingInProgress ? 'blur(4rem)' : 'blur(0)',
        },
      }}
      left
      customBurgerIcon={false}
      customCrossIcon={false}
      disableAutoFocus
      isOpen={isSafeDetailsOpen}
      onStateChange={state => handleStateChange(state)}
      width={'16%'}
    >
      <div className="container d-flex">
        <div className="close" onClick={closeSidebar}>
          <Img src={CloseIcon} alt="close" />
        </div>

        <div className="title">Your Safes</div>

        <div className="list">
          {safes && safes.length > 0
            ? safes.map(({ name, safeAddress }, idx) => (
                <div
                  className="safe d-flex align-items-center"
                  key={idx}
                  onClick={() => handleSafeSwitch(safeAddress)}
                >
                  <div>
                    <div className="name">{name}</div>
                    <div className="address">{minifyAddress(safeAddress)}</div>
                  </div>
                  {safeAddress === ownerSafeAddress && (
                    <div>
                      <Img src={GreenTick} alt="selected" />
                    </div>
                  )}
                </div>
              ))
            : safesLoading && (
                <div className="d-flex align-items-center justify-content-center">
                  <Loading color="primary" width="35px" height="35px" />
                </div>
              )}
        </div>
      </div>
    </SafeDetails>
  );
}

const sidebarStyles = {
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '0',
    zIndex: 9999,
  },
  bmMenu: {
    background: '#fff',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#fff',
  },
  bmItemList: {
    color: '#373737',
  },
  bmItem: {},
  bmOverlay: {
    background: 'rgb(35 36 39 / 50%)',
    top: '0',
    zIndex: 9999,
  },
};

export default memo(SafeDetailsSidebar);
